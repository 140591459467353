define("bocce/templates/components/next-live-class", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Q8JwPBml",
    "block": "{\"symbols\":[\"@upcomingLiveClass\",\"@contactTeacher\"],\"statements\":[[10,\"div\"],[14,0,\"dashboard-widget next-live-class-wrapper\"],[12],[2,\"\\n  \"],[10,\"i\"],[14,0,\"live-class-clock fa fa-clock\"],[12],[13],[2,\"\\n  \"],[10,\"h2\"],[14,0,\"dashboard-widget__header\"],[12],[2,\"Next Live Class\"],[13],[2,\"\\n\"],[6,[37,1],[[32,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"next-live-class-brief-time\"],[12],[2,\"\\n      \"],[1,[32,1,[\"event_start_at_formatted\"]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"next-live-class-moreinfo\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"next-live-class__course\"],[12],[2,\"\\n        \"],[10,\"a\"],[15,6,[31,[[32,1,[\"url\"]]]]],[14,\"target\",\"_blank\"],[14,0,\"next-live-class__course-title\"],[12],[1,[32,1,[\"course_name\"]]],[13],[2,\"\\n        \"],[10,\"p\"],[14,0,\"next-live-class__course-instructor\"],[12],[2,\"With \"],[1,[32,1,[\"teacher_name\"]]],[2,\" \"],[11,\"button\"],[24,0,\"contact-teacher\"],[4,[38,0],[[32,0],[32,2],[32,1,[\"teacher_id\"]]],null],[12],[10,\"i\"],[14,0,\"fa fa-envelope\"],[14,\"aria-hidden\",\"\"],[12],[13],[13],[13],[2,\"\\n      \"],[13],[2,\"\\n\"],[6,[37,1],[[32,1,[\"is_ongoing\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"a\"],[15,6,[31,[[32,1,[\"url\"]]]]],[14,0,\"next-live-class__button\"],[14,\"target\",\"_blank\"],[12],[2,\"Join Live Class\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"next-live-class__no-class\"],[12],[2,\"\\n      \"],[10,\"p\"],[12],[2,\"No upcoming Live Classes\"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"if\"]}",
    "meta": {
      "moduleName": "bocce/templates/components/next-live-class.hbs"
    }
  });
});