define("bocce/models/dashboard", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    userTermData: (0, _model.attr)(),
    user_id: (0, _model.attr)('number'),
    user_name: (0, _model.attr)('string'),
    terms: (0, _model.attr)(),
    fingerprint: (0, _model.attr)('string'),
    next_live_class: (0, _model.attr)()
  });
});