define("bocce/routes/classroom", ["exports", "bocce/mixins/routable", "bocce/mixins/lesson-redirect"], function (_exports, _routable, _lessonRedirect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  var _default = _exports.default = Ember.Route.extend(_routable.default, _lessonRedirect.default, {
    session: Ember.inject.service(),
    gainsight: Ember.inject.service(),
    init(...args) {
      this._super(...args);
      this.panelConfig = this.panelConfig || {
        withinApp: 'syllabus',
        fromOutside: 'syllabus',
        instructor: 'activity'
      };
    },
    // NK: This is more of a workaround than a clean solution. Right now we assume that "findAll"
    //     calls will only retrieve models related to the current course, but this causes items
    //     from other courses to show up when switching courses (among other issues). The real
    //     fix is to rewrite adapters so that they're more context-aware, and to rewrite the app
    //     logic so that we don't use findAll when we really just want the models for a specific
    //     course. That will take more time than we have right now, so I'm putting this in until
    //     the next cleanup-friendly push.
    unloadCourseModels() {
      this.store.unloadAll('assignment');
      this.store.unloadAll('page');
      this.store.unloadAll('discussion');
      this.store.unloadAll('quiz');
    },
    beforeModel: function (transition) {
      let params = transition.to.find(item => item.name === 'classroom').params;
      /* eslint-disable-next-line ember/no-get */
      let previous_course_id = this.get('session.course.id');
      let surveyIncomplete = this.session.get('courseConcludedNoSurvey');
      if (surveyIncomplete) {
        this.transitionTo('classroom.lessons.survey', {
          queryParams: {
            concluded: true
          }
        });
      }
      window.store = this.store;
      window.session = this.session;
      if (!previous_course_id || params.course_id !== previous_course_id) {
        this.unloadCourseModels();
        return this.store.findRecord('course', params.course_id, {
          reload: true
        }).then(course => {
          /* eslint-disable-next-line ember/no-get */
          let section = course.get('sections').objectAt(0),
            /* eslint-disable-next-line ember/no-get */
            section_id = section.get('id');
          this.session.set('section', section);

          // session.course should be available now so invoke the Gainsight service and render some tags.
          this.gainsight.renderIdentifyGainsightPxTag(new Date());
          this.gainsight.renderGlobalContextGainsightPxTag(new Date());
          this.session.get('user').then(u => {
            //Notes are currently only used by instructors
            if (this.session.get('isInstructor')) {
              $.ajax({
                type: 'GET',
                url: `/interface/user_notes/${section.get('id')}/section`,
                success: async notes => {
                  u.set('notes', notes);
                },
                error: e => {
                  u.set('notes', {});
                }
              });
            } else {
              u.set('notes', {});
            }
          });
          let body = document.querySelector('body');

          /* eslint-disable-next-line ember/no-get */
          if (course.get('isSample')) {
            body.classList.add('sample');
          } else {
            body.classList.remove('sample');
          }

          /* eslint-disable-next-line ember/no-get */
          if (course.get('isPublic')) {
            body.classList.add('public_course');
          } else {
            body.classList.remove('public_course');
          }
          this.store.nestResources('section', {
            course: params.course_id
          });
          this.store.nestResources('lesson', {
            course: params.course_id
          });
          this.store.nestResources('discussion', {
            section: section_id
          });
          this.store.nestResources('poll', {
            section: section_id
          });
          this.store.nestResources('event', {
            course: params.course_id
          });
          this.store.nestResources('page', {
            section: section_id
          });
          this.store.nestResources('assignment', {
            section: section_id
          });
          this.store.nestResources('quiz', {
            section: section_id
          });
          this.store.nestResources('response', {
            section: section_id
          });
          this.store.nestResources('gradebook', {
            course: params.course_id
          });

          /* eslint-disable-next-line ember/no-get */
          if (course.get('isArchived')) {
            Ember.debug('Course has been archived and is read-only');

            /* eslint-disable-next-line ember/no-get */
            let user_id = parseInt(this.session.get('user.id'), 10);
            this.store.peekAll('enrollment').forEach(enrollment => {
              /* eslint-disable-next-line ember/no-get */
              if (parseInt(enrollment.get('user.id'), 10) === user_id) {
                /* eslint-disable-next-line ember/no-get */
                let role = enrollment.get('role');
                section.set('role', role);
                if (window.newrelic) {
                  window.newrelic.setCustomAttribute('role', role);
                }
              }
            });
            return fetch(`/interface/courses/${params.course_id}?for=user`).then(res => res.json()).then(extra_data => {
              let grades = extra_data.grades;
              delete extra_data.grades;
              this.store.pushPayload(extra_data);
              if (grades) {
                for (let grade of grades) {
                  let sub = this.store.peekRecord('submission', grade.submission_id);
                  sub.set('grade', grade.grade);
                  sub.set('score', grade.score);
                }
              }

              /* eslint-disable-next-line ember/no-get */
              if (section.get('role') === 'student') {
                let assns = this.store.peekAll('assignment');
                assns.forEach(assn => {
                  /* eslint-disable-next-line ember/no-get */
                  let my_sub = assn.get('submissions').find(s => {
                    /* eslint-disable-next-line ember/no-get */
                    return parseInt(s.get('user.id'), 10) === user_id;
                  });
                  if (my_sub) {
                    /* eslint-disable-next-line ember/no-get */
                    assn.set('currentUserSubmission', my_sub.get('id'));
                    /* eslint-disable-next-line ember/no-get */
                    assn.set('currentUserSubmissionGrade', my_sub.get('grade'));
                    assn.set('currentUserSubmissionScore', my_sub.get('score'));
                    /* eslint-disable-next-line ember/no-get */
                    assn.set('grade_matches_current_submission', true);
                  }
                });
              }
            });
          } else {
            let role = section.get('role');
            if (window.newrelic && role) {
              window.newrelic.setCustomAttribute('role', role);
            }
          }
        }, msg => Ember.debug('Course load error', msg));
      }
    },
    model: function () {
      return Ember.RSVP.hash({
        /* eslint-disable-next-line ember/no-get */
        section: this.session.get('section'),
        /* eslint-disable-next-line ember/no-get */
        classroom: this.session.get('course')
        // PRM: This breaks some courses, such as #311.  I have no idea why.
        //lessons: this.store.findAll('lesson'),
      });
    },
    afterModel: function (model) {
      if (!model.classroom) {
        // Redirect to dashboard
        this.transitionTo('dashboard');
        // set local storage to pop up dashboard message
        localStorage.setItem('dashboardMessageNoClassPerm', 'true');
      }

      /* eslint-disable-next-line ember/no-get */
      document.title = model.classroom.get('title') + ' - Berklee Online';

      /* eslint-disable-next-line ember/no-get */
      if (this.get('session.isInstructor')) {
        this.session.set('panel', 'activity');
      } else {
        this.session.set('panel', this.defaultPanel);
      }
    },
    redirect() {
      /**
          IF URL given, go there if user has access
           (following logic is in lesson-redirect)
          ELSE IF user has not visited course, go to very first item in syllabus.
          ELSE IF user has access to most recently visited page, go there.
          ELSE Go to introduction of current lesson.
           This goes in redirect() instead of afterModel() because using
          redirect() means that we do not repeat th\e classroom route's
          model hooks.
      */
      var lp = this.paramsFor('classroom.lessons');
      const currentWeek = this.get("session.course.term.week");
      let hasItemID = lp && Object.prototype.hasOwnProperty.call(lp, 'item_id') && lp.item_id;
      let noAccess = lp.lesson_id > currentWeek && this.session.get('course.earlyUnlock') < lp.lesson_id;
      let ignoreNoAccess = this.session.get("isAdmin") || this.session.get("isStaff") || this.session.get("isInstructor");
      if (!hasItemID || noAccess && !ignoreNoAccess) {
        return this.getRedirectLessonInfo().then(info => {
          let {
            lesson_id,
            item_id
          } = info;
          this.replaceWith('classroom.lessons', lesson_id, item_id);
        });
      }
    },
    renderTemplate: function () {
      let notificationController = this.controllerFor('notifications');
      let conversationsController = this.controllerFor('conversations');
      let gradebookController = this.controllerFor('gradebook');
      let eventsController = this.controllerFor('events');
      this.render(); // Render primary template

      this.render('notifications', {
        // the template to render
        into: 'classroom',
        outlet: 'notification-outlet',
        // the template to render into
        controller: notificationController,
        model: false
      });
      this.render('gradebook', {
        into: 'classroom',
        outlet: 'gradebook',
        controller: gradebookController
      });
      this.render('conversations', {
        into: 'classroom',
        outlet: 'conversations',
        controller: conversationsController,
        model: false
      });
      this.render('events', {
        into: 'classroom',
        outlet: 'events',
        controller: eventsController
      });
      let userprofileCtl = this.controllerFor('userprofile');
      this.render('userprofile', {
        // the template to render
        into: 'classroom',
        outlet: 'userprofile',
        // the template to render into
        controller: userprofileCtl
      });

      /* eslint-disable-next-line ember/no-get */
      if (this.get('session.isStaticContent') && ( /* eslint-disable-next-line ember/no-get */
      this.get('session.course.isSample') || this.get('session.course.isPublic'))) {
        /* eslint-disable-next-line ember/no-get */
        let currentUser = this.get('session.user');

        /* eslint-disable-next-line ember/no-get */
        Appcues.identify(currentUser.get('id').toString(), {
          /* eslint-disable-next-line ember/no-get */
          name: currentUser.get('name'),
          /* eslint-disable-next-line ember/no-get */
          email: currentUser.get('email')
        });
      }
    },
    setupController(controller) {
      this._super(...arguments);
      controller.setProperties({
        courseDiscussions: null,
        courseAssignments: null
      });
      this.store.findAll('discussion').then(d => {
        controller.set('courseDiscussions', d);
        controller.set('courseRequiredDiscussions', d.filterBy('is_required'));
      });
      this.store.findAll('assignment').then(a => {
        controller.set('courseAssignments', a);
      });
      this.store.findAll('quiz').then(q => {
        controller.set('courseQuizzes', q);
      });
    }
  });
});