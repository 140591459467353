define("bocce/components/buttons/add-button", ["exports", "bocce/components/buttons/async-button"], function (_exports, _asyncButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _asyncButton.default.extend({
    classNames: ['add']
  });
});