define("bocce/helpers/array-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.arrayItem = arrayItem;
  _exports.default = void 0;
  function arrayItem(params /*, hash*/) {
    let array = params[0];
    let index = params[1];
    return array[index];
  }
  var _default = _exports.default = Ember.Helper.helper(arrayItem);
});