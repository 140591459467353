define("bocce/controllers/classroom/lessons/event-new", ["exports", "bocce/config/environment", "bocce/mixins/discussable", "bocce/mixins/editable", "bocce/mixins/uploadable", "bocce/mixins/audio-rec", "bocce/mixins/video-rec", "bocce/mixins/rtc-rec", "bocce/mixins/nested-resources", "bocce/mixins/enmodal", "bocce/mixins/webex", "bocce/utilities/dialog", "lodash.isequal"], function (_exports, _environment, _discussable, _editable, _uploadable, _audioRec, _videoRec, _rtcRec, _nestedResources, _enmodal, _webex, _dialog, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.sendEventsToInterfaceAndSendEmail = sendEventsToInterfaceAndSendEmail;
  // app/controllers/classroom/lessons/event-new.js

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  // create the default start time; default
  // is the next half-hour increment
  function defaultTime() {
    let start = moment().tz('America/New_York').add(5, 'minutes');
    start.utcOffset(-5);
    if (start.minutes() < 30) {
      start.startOf('hour').minute(30);
    } else {
      start.startOf('hour').add(1, 'hour');
    }
    return start;
  }
  let baseEventDate = defaultTime();
  var _default = _exports.default = Ember.Controller.extend(_discussable.default, _enmodal.default, _editable.default, _uploadable.default, _audioRec.default, _videoRec.default, _rtcRec.default, _nestedResources.default, _webex.default, {
    init(...args) {
      this._super(...args);
      this.eventsModel = this.eventsModel || Ember.ArrayProxy.create({
        content: Ember.A([{
          bodyInput: '',
          titleInput: '',
          startDate: baseEventDate,
          additionalSectionsCourseIds: Ember.ArrayProxy.create({
            content: Ember.A([])
          }),
          shown: true
        }])
      });
      this.savedData = this.savedData || [{
        title: '',
        body: ''
      }];
      this.set('contentNotDirty', true);
      this.extraSectionToggled = false;
      this.showTemplateManager = false;
      this.templates.onTemplateChange = this.actions.changeTemplate.bind(this);
      this.templates.onDeleteTemplate = this.actions.onDeleteTemplate.bind(this);
    },
    //Calculated event properties
    events: Ember.computed('eventsModel.@each.{bodyInput,titleInput,startDate,shown,additionalSectionsCourseIds}', function () {
      for (let i = 0; i < this.eventsModel.length; i++) {
        let event = this.eventsModel.objectAt(i);
        if (event.startDate) {
          Ember.set(event, 'startDate_date', event.startDate.format('YYYY-MM-DD'));
          Ember.set(event, 'startDate_time', event.startDate.format('HH:mm'));
        }
        Ember.set(event, 'hideCascadeDate', event === this.eventsModel.lastObject);
        Ember.set(event, 'disableCascadeDate', !!!event.startDate);
        const bodyWithSpaceForNewLines = event.bodyInput.replaceAll(/<\s*br\s*\/?>/g, ' ');
        Ember.set(event, 'bodyInputTextOnly', Ember.$('<div/>').html(bodyWithSpaceForNewLines).text());
        Ember.set(event, 'incomplete', !!!event.startDate || !event.titleInput || !event.bodyInput);
      }
      return this.eventsModel;
    }),
    //Keep the 'contentNotDirty' property up-to-date
    eventsUpdated: Ember.observer('eventsModel.@each.{bodyInput,titleInput,startDate,shown,additionalSectionsCourseIds}', function () {
      const contentNotDirty = (0, _lodash.default)(this.savedData, this.events.map(em => ({
        title: em.titleInput,
        body: em.bodyInput
      })));
      this.set('contentNotDirty', contentNotDirty);
    }),
    editorHasContent: Ember.computed('contentNotDirty', function () {
      return !this.get('contentNotDirty');
    }),
    application: Ember.inject.controller(),
    templates: Ember.inject.service(),
    classroom: Ember.inject.controller(),
    // required by DiscussableMixin
    discussion: Ember.inject.controller('classroom.lessons.discussion'),
    // required by DiscussableMixin
    newDiscussion: Ember.inject.controller('classroom.lessons.discussion-new'),
    // required by DiscussableMixin
    lessons: Ember.inject.controller('classroom.lessons'),
    // required by UploadableMixin

    modalTitle: Ember.computed('session.isInstructor', function () {
      /* eslint-disable-next-line ember/no-get */
      if (this.get('session.isInstructor')) {
        return 'New Live Class';
      } else {
        return 'New Live Session';
      }
    }),
    frequencyText: 'weeks',
    sectionDrawerToggled: false,
    readyToSave: Ember.computed('eventsModel.@each.{bodyInput,titleInput,startDate}', 'classroom.activeNewEvent', 'isWorking', function () {
      let readyToSave = true;
      const events = this.get('events');
      const self = this;
      events.forEach(event => {
        readyToSave = readyToSave && Ember.$.trim(event.titleInput.replace(/&nbsp;/g, ' ').replace(/<!---->/g, ' ')).length > 0 && /* eslint-disable-next-line ember/no-jquery */
        Ember.$.trim(event.bodyInput.replace(/&nbsp;/g, ' ').replace(/<!---->/g, ' ')).length > 0 && !!event.startDate && event.startDate.isValid() && !self.isWorking;
      });
      /* eslint-disable-next-line ember/no-jquery */
      return readyToSave;
    }),
    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    otherEnrolledSections: Ember.computed(function () {
      // returns the other current-term sections the user is enrolled in
      /* eslint-disable-next-line ember/no-get */
      let filteredCodes = this.get('classroom.otherCurrentTermSections');
      if (filteredCodes.length > 6) {
        /* eslint-disable-next-line ember/no-incorrect-calls-with-inline-anonymous-functions */

        Ember.run.scheduleOnce('afterRender', this, () => {
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('.floating-modal#new-event .rte-editor-input').addClass('height-limited');
        });
      }
      return filteredCodes || [];
    }),
    //Clear out the new event modal data.
    resetData() {
      this.eventsModel.setObjects([{
        bodyInput: '',
        titleInput: '',
        startDate: baseEventDate,
        additionalSectionsCourseIds: Ember.ArrayProxy.create({
          content: Ember.A([])
        }),
        shown: true
      }]);
      this.savedData = [{
        title: '',
        body: ''
      }];
      this.extraSectionToggled = false;
      this.set('contentNotDirty', true);
      this.templates.selectedTemplateIndex = -1;
    },
    saveEvents() {
      this.set('isWorking', true);
      const section = this.session.get('section');
      const course = section.get('course');
      const now = moment().tz('America/New_York'),
        self = this,
        courseId = course.get('id'),
        eventData = [];
      let priorDate = false,
        invalidDate = false,
        duplicateDates = [],
        eventDates = [];
      this.eventsModel.forEach((em, index) => {
        let start_time = em.startDate;
        const eventDataSingle = {
          courseIds: [],
          event: {}
        };
        if (start_time.unix() < now.unix()) {
          priorDate = true;
          this.set('isWorking', false);
          return;
        }
        if (!start_time.isValid()) {
          invalidDate = true;
          this.set('isWorking', false);
          return;
        }

        // Force the value of the time input to avoid DST conversion
        start_time = `${start_time.format('YYYY-MM-DD')}T${em.startDate_time}:00`;
        let start_time_moment = moment(start_time),
          end_time_moment = moment(start_time).add("1", "hours");
        let startTimeMomentFormatted = start_time_moment.format('YYYY-MM-DDTHH:mm:ss');
        eventDataSingle.event = {
          title: em.titleInput,
          description: this.parseEmbed(em.bodyInput) ? this.parseEmbed(em.bodyInput) : '',
          futureEvent: true,
          startAt: startTimeMomentFormatted,
          endAt: end_time_moment.format('YYYY-MM-DDTHH:mm:ss')
        };

        //Keeping track of duplicate dates
        let duplicateDate = eventDates.find(ed => ed.date === startTimeMomentFormatted);
        if (duplicateDate) {
          let title1 = duplicateDate.title;
          let title2 = em.titleInput;
          duplicateDates.push({
            title1,
            title2,
            date: startTimeMomentFormatted
          });
        }
        eventDates.push({
          title: em.titleInput,
          date: startTimeMomentFormatted
        });
        eventDataSingle.courseIds.push(courseId);
        em.additionalSectionsCourseIds.forEach(additionalSectionCourseId => {
          eventDataSingle.courseIds.push(additionalSectionCourseId);
        });
        eventData.push(eventDataSingle);
      });
      if (priorDate) {
        alert('You can only schedule live classes in the future.');
        return;
      } else if (invalidDate) {
        alert('Please choose a valid date for the scheduled live class.');
        return;
      } else if (duplicateDates.length > 0) {
        let {
          title1,
          title2,
          date
        } = duplicateDates[0];
        (0, _dialog.default)(`You have two events scheduled for ${moment(date).format('MMM Do, YYYY h:mm a') + " ET"}: ${title1}, ${title2}. Are you sure you wish to proceed?`, ['Yes', 'No']).then(choice => {
          if (choice === 'Yes') {
            self.set('isWorking', false);
            self.send('destroyEditor');
            self.send('closeEventModal');
            sendEventsToInterfaceAndSendEmail.call(this, eventData);
          } else {
            this.set('isWorking', false);
          }
        });
      } else {
        self.set('isWorking', false);
        self.send('destroyEditor');
        self.send('closeEventModal');
        sendEventsToInterfaceAndSendEmail.call(this, eventData);
      }
    },
    setFullDate(event) {
      if (event.startDate_date && event.startDate_time) {
        let dateString = `${event.startDate_date}T${event.startDate_time}:00`,
          startDate = moment.tz(dateString, 'America/New_York');
        Ember.set(event, 'startDate', startDate);
      } else {
        Ember.set(event, 'startDate', "");
      }
    },
    setShowTemplateManager(show) {
      this.set('showTemplateManager', show);
    },
    actions: {
      openTutorial() {
        window.open(_environment.default.APP.multiSchedulerOverviewUrl, '_blank').focus();
      },
      onDeleteTemplate() {
        if (this.templates.selectedTemplateIndex === -1) {
          this.resetData();
        }
        if (this.templates.templates.length === 0) {
          this.setShowTemplateManager(false);
        }
      },
      changeTemplate(template) {
        if (template && template.events) {
          this.eventsModel.setObjects(template.events.map((te, index) => ({
            titleInput: te.title,
            bodyInput: te.body,
            additionalSectionsCourseIds: Ember.ArrayProxy.create({
              content: Ember.A([])
            })
          })));
        } else {
          this.resetData();
        }
        this.savedData = this.eventsModel.map(em => ({
          title: em.titleInput,
          body: em.bodyInput
        }));
      },
      updateDate(index, e) {
        const event = this.events.objectAt(index);
        Ember.set(event, 'startDate_date', e.target.value);
        this.setFullDate(event);
      },
      updateTime(index, e) {
        const event = this.events.objectAt(index);
        Ember.set(event, 'startDate_time', e.target.value);
        this.setFullDate(event);
      },
      resetData() {
        this.resetData();
      },
      cascadeDate(index) {
        if (index != null && index >= 0 && index < this.eventsModel.length) {
          const date = this.eventsModel.objectAt(index).startDate;
          if (!date) {
            return;
          }
          for (let i = index + 1; i < this.eventsModel.length; i++) {
            let event = this.eventsModel.objectAt(i);
            Ember.set(event, 'startDate', moment(date).add(7 * (i - index), 'd'));
          }
        }
      },
      cascadeSection(index) {
        if (index != null && index >= 0 && index < this.eventsModel.length) {
          const additionalSectionsCourseIds = this.eventsModel.objectAt(index).additionalSectionsCourseIds;
          for (let i = index + 1; i < this.eventsModel.length; i++) {
            let event = this.eventsModel.objectAt(i);
            event.additionalSectionsCourseIds.setObjects(additionalSectionsCourseIds.toArray());
          }
        }
      },
      updateTemplate() {
        this.templates.updateTemplate(this.eventsModel);
      },
      saveTemplate() {
        this.templates.saveTemplate(this.eventsModel);
      },
      deleteEvent: function (index) {
        if (index != null && index >= 0 && index < this.eventsModel.length) {
          const self = this;
          (0, _dialog.default)('Are you sure you wish to delete this event?', ['Yes', 'No']).then(choice => {
            if (choice === 'Yes') {
              self.eventsModel.removeAt(index, 1);

              //Make sure that the single event is showing if it is the only one left.
              if (self.eventsModel.length === 1) {
                const event = self.eventsModel.objectAt(0);
                Ember.set(event, 'shown', true);
              }
            }
          });
        }
      },
      destroyEditor() {
        this.resetData();
      },
      resetData() {
        this.resetData();
      },
      toggleShowEventDetails: function (index) {
        if (index != null && index >= 0 && index < this.eventsModel.length) {
          const event = this.eventsModel.objectAt(index);
          Ember.set(event, 'shown', !event.shown);
        }
      },
      addEvent: function () {
        this.eventsModel.forEach(em => {
          Ember.set(em, 'shown', false);
        });
        this.eventsModel.pushObject({
          bodyInput: '',
          titleInput: '',
          startDate: moment(baseEventDate).add(7 * this.eventsModel.length, 'd'),
          additionalSectionsCourseIds: Ember.ArrayProxy.create({
            content: Ember.A([])
          }),
          shown: true
        });
      },
      saveEvents: function () {
        if (!this.extraSectionToggled && this.otherEnrolledSections.length > 0) {
          (0, _dialog.default)('You have not added any additional sections. Continue?', ['Yes', 'No']).then(choice => {
            if (choice === 'Yes') {
              this.saveEvents();
            }
          });
        } else {
          this.saveEvents();
        }
      },
      toggleExtraSection: function (section, index) {
        if (index != null && index >= 0 && index < this.eventsModel.length) {
          const event = this.eventsModel.objectAt(index);
          let additionalSectionsCourseIds = event.additionalSectionsCourseIds,
            existingIndex = -1;
          additionalSectionsCourseIds.forEach((extraSection, esIndex) => {
            if (extraSection === section.courseId) {
              existingIndex = esIndex;
            }
          });
          if (existingIndex >= 0) {
            additionalSectionsCourseIds.removeAt(existingIndex, 1);
          } else {
            additionalSectionsCourseIds.pushObject(section.courseId);
          }
          this.extraSectionToggled = true;
        }
      },
      closeEventModal: function () {
        this.set('repBox', false);
        this.send('closeModals', false);
        this.set('flatpickrFirstOpened', false);
      },
      setShowTemplateManager(show) {
        this.setShowTemplateManager(show);
      }
    }
  });
  function sendEventsToInterfaceAndSendEmail(eventData, start_chat = false) {
    const self = this;
    const batchEventData = [];
    eventData.forEach(ed => {
      ed.courseIds.forEach(courseId => {
        batchEventData.push({
          event: {
            ...ed.event,
            course: courseId
          }
        });
      });
    });
    Ember.$.ajax({
      type: 'POST',
      url: '/interface/batch-ops/batchEvent',
      data: JSON.stringify({
        events: batchEventData
      }),
      success(batchResult) {
        Ember.$('.activity-filters .filter-action .current').click();
        if (self.eventsModel) {
          self.eventsModel.setObjects([{
            bodyInput: '',
            titleInput: '',
            startDate: baseEventDate,
            additionalSectionsCourseIds: Ember.ArrayProxy.create({
              content: Ember.A([])
            }),
            shown: true
          }]);
        }
        //Key events off of course id
        const courseEvents = batchResult.events.reduce((aggregated, r) => {
          const event = r.event;
          if (aggregated[event.course]) {
            aggregated[event.course].push(event);
          } else {
            aggregated[event.course] = [event];
          }
          return aggregated;
        }, {});
        const sendEmail = () => {
          Ember.$.ajax({
            type: 'POST',
            url: '/interface/event-emails/scheduled',
            data: JSON.stringify({
              events: courseEvents
            }),
            success(data) {
              if (data.failedEmails && data.failedEmails.length > 0) {
                (0, _dialog.default)(`E-mails for the following courses failed to send: ${data.failedEmails.join(',')}`);
              }
            },
            error(jqXHR, textStatus) {
              (0, _dialog.default)('Unknown error occurred sending e-mails. Try again? Warning: this may send duplicate e-mails.', ['Yes', 'No']).then(choice => {
                if (choice === 'Yes') {
                  sendEmail();
                }
              });
            }
          });
        };
        sendEmail();
        if (start_chat) {
          let any_course_events = courseEvents[Object.keys(courseEvents)[0]];
          let any_chat = any_course_events[0];
          self.send('startChat', any_chat, any_chat.id);
          self.set('isWorking', false);
          self.transitionToRoute('classroom.lessons');
          (0, _dialog.default)('Events successfully scheduled. It may be a minute or two before it starts up.');
        } else {
          self.transitionToRoute('classroom.lessons');
          (0, _dialog.default)('Events successfully scheduled. It may be a minute or two before you see them.');
        }
      },
      error(jqXHR, textStatus) {
        (0, _dialog.default)('Failed to schedule event.  Please retry.');
        self.set('isWorking', false);
      }
    });
  }
});