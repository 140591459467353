define("bocce/templates/interactions/imageexplorer/stage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "fc8Iur5d",
    "block": "{\"symbols\":[\"btn\",\"hotspot\"],\"statements\":[[10,\"div\"],[14,0,\"stage-container border scrollbar-mobile\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"stage\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"content\"],[12],[2,\"\\n      \"],[2,[34,0]],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"hotspot\"],[15,1,[31,[[32,2,[\"id\"]]]]],[15,5,[32,2,[\"css\"]]],[15,\"data-target-slide\",[31,[[32,2,[\"targetSlide\"]]]]],[12],[13],[2,\"\\n\"]],\"parameters\":[2]}]]],[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"button\"],[14,0,\"user-button\"],[15,1,[31,[[32,1,[\"id\"]]]]],[15,5,[32,1,[\"css\"]]],[15,\"data-target-slide\",[31,[[32,1,[\"targetSlide\"]]]]],[15,\"aria-label\",[31,[[32,1,[\"title\"]]]]],[12],[2,\"\\n          \"],[1,[32,1,[\"title\"]]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"imgEl\",\"hotspots\",\"-track-array\",\"each\",\"buttons\"]}",
    "meta": {
      "moduleName": "bocce/templates/interactions/imageexplorer/stage.hbs"
    }
  });
});