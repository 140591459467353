define("bocce/mixins/interactions/image_explorer", ["exports", "bocce/mixins/support/util", "bocce/mixins/support/render-template", "bocce/mixins/boot"], function (_exports, util, _renderTemplate, _boot) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CMS_EDITOR_DATA = 'cms-editor-data';
  function ImageExplorer($el, data) {
    util.bindAll(this, ['prevSlide', 'nextSlide', 'componentClick', 'componentMouseout', 'componentMouseover', 'navigateToSlide']);
    this.$el = $el;

    // cache container and set it to autosize by default ;
    this.$container = this.$el.find('.interaction_content').addClass('autosize');

    // take a look at this when redo panorama
    this.extra_width = 4;
    this.data = data;

    // paths of cms images. will be overwritten with full image objs ;
    this.images = this.data.assets.images;
  }
  ImageExplorer.prototype.init = function () {
    // preload images for faster loading and to obtain width/height info ;
    return this.preloadImages(this.images);
  };
  ImageExplorer.prototype.preloadImages = function (imgArray) {
    var i, img, path, that, _len, _ref, onload;
    this.$container.html(this.$el.find('.loading-template').html());
    that = this;
    _ref = this.images;
    return new Promise(resolve => {
      onload = function () {
        // overwrite images array with full image el obj;
        imgArray[this.imageIndex] = {
          'src': this.src,
          'width': this.width,
          'height': this.height
        };
        if (this.imageIndex === 0) {
          resolve(that.showFirstSlide());
        }
      };
      for (i = 0, _len = _ref.length; i < _len; i++) {
        path = _ref[i];
        img = new Image();
        img.src = path;
        img.imageIndex = i;
        img.onload = onload;
      }
    });
  };
  ImageExplorer.prototype.showFirstSlide = function () {
    var parsedJSON;
    parsedJSON = JSON.parse(this.data.initial_json);
    // adapt parsed JSON data to avoid repetitive operations;
    this.slides = this.adaptData(parsedJSON);
    // merge additional data from CMS editor tables;
    this.slides = this.addTableContentItems(this.slides);
    // keep track of the slide the user is currently viewing;
    this.currentSlide = void 0;
    this.currentSlideIndex = 0;
    return this.loadSlide(0);
  };

  // put the data in a better format for the template;

  ImageExplorer.prototype.adaptData = function (data) {
    var button, hotspot, img, slide, _i, _j, _k, _len, _len2, _len3, _ref, _ref2;
    for (_i = 0, _len = data.length; _i < _len; _i++) {
      slide = data[_i];
      img = this.images[slide.imageIndex];
      slide.info_width = img.width + this.extra_width;
      if (slide.options && slide.options.panoramaOpts) {
        if (slide.options.panoramaOpts.maskWidth) {
          slide.info_width = slide.options.panoramaOpts.maskWidth + this.extra_width;
        }
        if (slide.options.panoramaOpts.maskHeight) {
          slide.info_height = slide.options.panoramaOpts.maskHeight;
        }
      }
      if (!img.src) {
        slide.imgEl = `<img src="${img}" />`;
      } else {
        slide.imgEl = `<img src="${img.src}" width="${img.width}" height="${img.height}" />`;
      }
      slide.initialText = unescape(slide.initialText);
      // take the $ CSS objs and smush them into a string
      // that the template can inject into an el's style tag
      _ref = slide.hotspots;
      for (_j = 0, _len2 = _ref.length; _j < _len2; _j++) {
        hotspot = _ref[_j];
        hotspot.css = this.cssToString(hotspot.css);
      }
      _ref2 = slide.buttons;
      for (_k = 0, _len3 = _ref2.length; _k < _len3; _k++) {
        button = _ref2[_k];
        button.css = this.cssToString(button.css);
        button.title = unescape(button.title);
      }
    }
    return data;
  };
  ImageExplorer.prototype.cssToString = function (css) {
    var prop, str, val;
    str = '';
    for (prop in css) {
      val = css[prop];
      str += '' + prop + ':' + val + ';';
    }
    return str;
  };

  // merge cms editor data into json data;

  ImageExplorer.prototype.addTableContentItems = function (dataArray) {
    this.$el.find('.interaction_data table').each(function (i) {
      var items;
      items = [];
      /* eslint-disable-next-line ember/no-jquery */
      Ember.$(this).find('tr').each(function (row_num) {
        if (row_num === 0) {
          return;
        }

        /* eslint-disable-next-line ember/no-jquery */
        let item = new ContentItem(Ember.$(this));
        if (item.id) {
          items.push(item);
        }
      });
      return dataArray[i].cmsEditorData = items;
    });
    return dataArray;
  };
  ImageExplorer.prototype.loadSlide = function (index) {
    this.currentSlideIndex = index;
    this.currentSlide = this.slides[index];
    return (0, _renderTemplate.default)('image_explorer', 'info_bottom', this.currentSlide).then(content => {
      if (this.$container.children().length) {
        this.$container.children().replaceWith(content);
      } else {
        this.$container.append(content);
      }
      this.initNav();
      this.createComponents(this.currentSlide);
      this.initBranching();
      if (this.currentSlide.options.duration) {
        this.startTimer(this.currentSlide.options.duration);
      }
      if (this.currentSlide.options.panorama) {
        this.createPanorama(this.currentSlide.options.panoramaOpts);
      }
      this.updateSlideCount(index + 1, this.slides.length);
    });
  };
  ImageExplorer.prototype.initNav = function () {
    this.$container.find('.next').click(this.nextSlide);
    this.$container.find('.prev').click(this.prevSlide);
    // hide nav if we only have one slide or if this is a timed slide;
    if (this.slides.length === 1) {
      return this.$container.find('.nav').hide();
    }
  };
  ImageExplorer.prototype.createComponents = function (slide) {
    // If the user has specified some data in the CMS editor,
    // map it to the proper components
    var $component, data, styles, hotspots_length, _i, _len, _ref;
    hotspots_length = 0;
    if (slide.cmsEditorData) {
      _ref = slide.cmsEditorData;
      var $info = this.$container.find('.info');
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        data = _ref[_i];
        $component = this.$container.find('#' + data.id);
        $component.data(CMS_EDITOR_DATA, data);
        if (data.rolloverContent.length) {
          $component.mouseover(this.componentMouseover);
          hotspots_length += data.rolloverContent.length;
        }
        if (data.popupContent.length) {
          $component.click(this.componentClick).addClass('pointer-cursor');
        }
        if (data.css) {
          styles = $component.attr('style') + data.css;
          $component.attr('style', styles);
        }
      }
      // If the 'info (text)' area is unused, hide it
      if (typeof slide.initialText !== 'string' || slide.initialText.length === 0 && hotspots_length === 0) {
        return $info.hide();
      }
      // if the initial text is missing but hotspots are used, put in filler text
      else if (typeof slide.initialText !== 'string' || slide.initialText.length === 0 && hotspots_length > 0) {
        return $info.html('<p class="blank"></p>');
      }
    }
  };
  ImageExplorer.prototype.initBranching = function () {
    // set up branching:
    // this value is stored in data-target-slide attr
    var _this = this;
    this.$container.find('.user-button, .hotspot').each(function (index, el) {
      var $compEl;
      /* eslint-disable-next-line ember/no-jquery */
      $compEl = Ember.$(el);
      if ($compEl.data('target-slide').length) {
        return $compEl.click(_this.navigateToSlide);
      }
    });
  };
  ImageExplorer.prototype.navigateToSlide = function (e) {
    var index, targetSlide;
    /* eslint-disable-next-line ember/no-jquery */
    targetSlide = Ember.$(e.target).data('target-slide');
    index = this.getSlideIndexByName(targetSlide);
    return this.loadSlide(index);
  };
  ImageExplorer.prototype.getSlideIndexByName = function (name) {
    var i, slide, _len, _ref;
    _ref = this.slides;
    for (i = 0, _len = _ref.length; i < _len; i++) {
      slide = _ref[i];
      if (slide.name === name) {
        return i;
      }
    }
  };
  ImageExplorer.prototype.componentMouseover = function (e) {
    var $info, $component, $components, data, height, prevHeight;
    /* eslint-disable-next-line ember/no-jquery */
    data = Ember.$(e.target).data(CMS_EDITOR_DATA);
    $component = this.$container.find('#' + data.id);
    $components = this.$container.find('.hotspot');
    $info = this.$el.find('.info');
    prevHeight = $info.height();
    $info.html(data.rolloverContent);
    height = $info.height();
    // sticky rollover
    $components.removeClass('stuck');
    $component.addClass('stuck');
    // lazy lock the min-height of the info area to decrease page jumps.
    // this requires much less work than computing all possible
    // heights and choosing the largest one
    if (height > prevHeight) {
      $info.css('min-height', height + 'px');
    }

    // clear in case a course dev floats some content ;
    /* eslint-disable-next-line ember/no-jquery */
    return Ember.$('<div></div>').css('clear', 'both').appendTo($info);
  };
  ImageExplorer.prototype.componentMouseout = function () {
    var $info, $components;
    $info = this.$el.find('.info');
    $components = this.$container.find('.hotspot');
    $components.removeClass('stuck');
    // revert the height back to normal on mouseout. This prevents the
    // box from remaining large if one caption is longer than others.
    $info.css('min-height', '0');
    return $info.html('<p>' + this.currentSlide.initialText + '</p>');
  };
  ImageExplorer.prototype.componentClick = function (e) {
    e.preventDefault();

    /* eslint-disable-next-line ember/no-jquery */
    const popupContent = Ember.$(e.target).data(CMS_EDITOR_DATA).popupContent;

    // do nothing if there is no popupContent
    if (!popupContent.length) {
      return;
    }

    // Put the content inside a div because otherwise it gets WAY too wide.
    /* eslint-disable-next-line ember/no-jquery */
    const content = Ember.$('<div class=\'image-explorer\'></div>');
    content.html('');
    for (let i = 0; i < popupContent.length; ++i) {
      content.append(popupContent[i]);
    }

    //append the content to the popup_container div.
    /* eslint-disable-next-line ember/no-jquery */
    const popup_container = Ember.$('.popup-container');
    const popup_content = popup_container.find('.content');
    popup_content.html('');
    popup_content.append(content);
    popup_container.addClass('active');
    _boot.default.mixins[1].properties.boot_area(popup_content, true, true, false, false, true);
  };
  ImageExplorer.prototype.startTimer = function (duration) {
    var ms,
      _this = this;
    ms = duration * 1000;
    setTimeout(function () {
      return _this.nextSlide();
    }, ms);
    return this.$container.find('.nav').hide();
  };
  ImageExplorer.prototype.createPanorama = function (opts) {
    var $content, $img, $stage, config, css, offset, x1, x2, y1, y2;
    css = {
      'overflow': 'hidden'
    };
    if (opts.maskWidth) {
      css.width = opts.maskWidth + 'px';
    }
    if (opts.maskHeight) {
      css.height = opts.maskHeight + 'px';
    }
    $stage = this.$container.find('.stage');
    $stage.css(css);
    $img = $stage.find('img:first');
    config = {};
    // set drag boundaries if image is masked so the user can't drag
    // it outside of the stage
    if ($img.width() > $stage.width() || $img.height() > $stage.height()) {
      offset = $stage.offset();
      x1 = $stage.width() - $img.width() + offset.left;
      y1 = $stage.height() - $img.height() + offset.top;
      x2 = offset.left;
      y2 = offset.top;
      config.containment = [x1, y1, x2, y2];
    }
    $content = $stage.find('.content');
    $content.draggable(config);
    // set initial position, if user specifies values ;
    $content.css({
      'left': (opts.x || 0) + 'px',
      'top': (opts.y || 0) + 'px'
    });
    return $content.addClass('move-cursor');
  };
  ImageExplorer.prototype.nextSlide = function (e) {
    if (e) {
      e.preventDefault();
    }
    this.currentSlideIndex++;
    if (this.currentSlideIndex >= this.slides.length) {
      this.currentSlideIndex = 0;
    }
    return this.loadSlide(this.currentSlideIndex);
  };
  ImageExplorer.prototype.prevSlide = function (e) {
    if (e) {
      e.preventDefault();
    }
    this.currentSlideIndex--;
    if (this.currentSlideIndex < 0) {
      this.currentSlideIndex = this.slides.length - 1;
    }
    return this.loadSlide(this.currentSlideIndex);
  };
  ImageExplorer.prototype.updateSlideCount = function (current, total) {
    this.$el.find('.count').text(`${current} of ${total}`);
  };
  function ContentItem($tableRow) {
    var offset, tds;
    tds = $tableRow.find('td');
    /* eslint-disable-next-line ember/no-jquery */
    this.id = Ember.$.trim(Ember.$(tds[0]).text());
    /* eslint-disable-next-line ember/no-jquery */
    this.title = Ember.$.trim(Ember.$(tds[1]).text());
    /* eslint-disable-next-line ember/no-jquery */
    this.popupContent = Ember.$(tds[2]).children();
    /* eslint-disable-next-line ember/no-jquery */
    this.rolloverContent = Ember.$.trim(Ember.$(tds[3]).html());
    /* eslint-disable-next-line ember/no-jquery */
    this.css = Ember.$.trim(Ember.$(tds[4]).text());
    /* eslint-disable-next-line ember/no-jquery */
    this.popupWidth = Ember.$.trim(Ember.$(tds[5]).text());
    /* eslint-disable-next-line ember/no-jquery */
    this.popupHeight = Ember.$.trim(Ember.$(tds[6]).text());
    /* eslint-disable-next-line ember/no-jquery */
    offset = Ember.$.trim(Ember.$(tds[7]).text()).split(',');
    this.offsetX = offset[0];
    this.offsetY = offset[1];
  }
  var _default = _exports.default = ImageExplorer;
});