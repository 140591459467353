define("bocce/helpers/show-discussions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // app/helpers/show-ungraded.js
  // Show ungraded items in the dashboard
  var _default = _exports.default = Ember.Helper.helper(function (inp) {
    var isDiscussion = inp[0],
      showDiscussions = inp[1];
    if (!isDiscussion) {
      return true;
    }
    if (isDiscussion && showDiscussions) {
      return true;
    }
    return false;
  });
});