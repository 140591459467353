define("bocce/mirage/factories/lesson", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    afterCreate(lesson, server) {
      lesson.update({
        items: server.createList('item', 1)
      });
    }
  });
});