define("bocce/services/adblock-detector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdblockDetectorService extends Ember.Service {
    async detect() {
      // Check dismissedAdblockWarning localStorage item -- if it exists, we don't need to check for adblock
      let dismissedAdblockWarning = localStorage.getItem('dismissedAdblockWarning');
      if (dismissedAdblockWarning) {
        return false;
      }
      return await this.checkUsingHttpRequest();
    }
    checkUsingHttpRequest() {
      return new Promise(resolve => {
        const testURL = '/ads/native.js';
        fetch(testURL).then(response => {
          if (response.status !== 200) {
            resolve(true);
          } else {
            resolve(false);
          }
        }).catch(() => {
          resolve(true);
        });
      });
    }
  }
  _exports.default = AdblockDetectorService;
});