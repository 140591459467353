define("bocce/mixins/interactions/fill_in_the_blanks_quiz", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Fill in the blanks quiz
   **/

  function FillInTheBlanksQuiz($el) {
    this.NUM_ATTEMPTS = 3; // how many times user can try before we allow them to see the answer

    this.el = $el;
    this.interactionData = $el.find('.interaction_data');
    this.container = $el.find('.interaction_content');

    // generic class for all form elements
    this.FORM_EL = 'form-el';
    this.FORM_EL_CLASS = '.' + this.FORM_EL;
    this.data = [];
    this.numQuestions = 0;
    this.attempts = 0;
  }
  FillInTheBlanksQuiz.prototype = {
    init: function () {
      if (this.el.hasClass('grid') || this.el.hasClass('Grid')) {
        this.initGrid();
        return;
      }
      this.initNormal();
    },
    initNormal: function () {
      var dataRef = this.data;
      var tableHeaders = []; // store table headers here to avoid multiple calls to row 0

      this.renderConstantUI();
      this.interactionData.find('table tr').each(function (i, tr) {
        var rowData = [];

        /* eslint-disable-next-line ember/no-jquery */
        Ember.$(tr).find('td').each(function (j) {
          // store headers from row 0
          // if a header if empty, it will be assumed that it should visually
          // be grouped with the previous header. i.e. a fill in the blank
          // with multiple fields

          /* eslint-disable-next-line ember/no-jquery */
          var $td = Ember.$(this);
          var paras = $td.find('p'),
            cellText;
          if (paras.length > 1) {
            cellText = $td.html();
          } else if (paras.length === 1) {
            cellText = paras.html();
          } else {
            /* eslint-disable-next-line ember/no-jquery */
            cellText = Ember.$.trim($td.text());
          }

          /* eslint-disable-next-line ember/no-jquery */
          var correctAnswer = Ember.$.trim($td.find('.correct').text());
          var answers = [];

          // check to see if user has supplied an unordered list. if so,
          // display a dropdown instead of a form field
          /* eslint-disable-next-line ember/no-jquery */
          var list = Ember.$(this).find('ul');
          if (list.length > 0) {
            list.find('li').each(function () {
              /* eslint-disable-next-line ember/no-jquery */
              var choice = Ember.$.trim(Ember.$(this).text());
              answers.push(choice);
            });
          } else if (correctAnswer) {
            answers.push(correctAnswer);
          } else {
            answers.push(cellText); // only one answer
          }
          if (i === 0) {
            tableHeaders.push(cellText);
            return true;
          }
          rowData.push(new FillInTheBlanksQuiz.QuizItem(tableHeaders[j], answers, correctAnswer));
        });
        if (i > 0) {
          dataRef.push(rowData); // don't push for header row
        }
      });
      this.numQuestions = dataRef.length;
      this.render();
    },
    initGrid: function () {
      let columnData = [];
      this.renderGridUI();
      this.interactionData.find('table tr').each(function (i, tr) {
        var rowData = [];

        /* eslint-disable-next-line ember/no-jquery */
        Ember.$(tr).find('td').each(function () {
          // store headers from row 0
          // if a header if empty, it will be assumed that it should visually
          // be grouped with the previous header. i.e. a fill in the blank
          // with multiple fields

          /* eslint-disable-next-line ember/no-jquery */
          var $td = Ember.$(this);
          var paras = $td.find('p'),
            cellText;
          if (paras.length > 1) {
            cellText = $td.html();
          } else if (paras.length === 1) {
            cellText = paras.html();
          } else {
            /* eslint-disable-next-line ember/no-jquery */
            cellText = Ember.$.trim($td.text());
          }

          /* eslint-disable-next-line ember/no-jquery */
          var correctAnswer = Ember.$.trim($td.find('.correct').text());
          var answers = [];
          var staticCell = false;

          // check to see if user has supplied an unordered list. if so,
          // display a dropdown instead of a form field
          /* eslint-disable-next-line ember/no-jquery */
          var list = Ember.$(this).find('ul');
          if (list.length > 0) {
            list.find('li').each(function () {
              /* eslint-disable-next-line ember/no-jquery */
              var choice = Ember.$.trim(Ember.$(this).text());
              answers.push(choice);
            });
          } else if (correctAnswer) {
            answers.push(correctAnswer);
          } else {
            staticCell = cellText; // Static Cell Text
          }
          rowData.push({
            isStatic: staticCell,
            correctAnswer: correctAnswer,
            value: answers
          });
        });
        columnData.push(rowData);
      });
      this.renderGrid(columnData);
    },
    renderGrid: function (gridData) {
      /* eslint-disable-next-line ember/no-jquery */
      this.gridContainer = Ember.$('<div id="question" class="fill-in-the-blanks-quiz grid-quiz"></div>').appendTo(this.container);
      for (let i = 0; i < gridData.length; i++) {
        /* eslint-disable-next-line ember/no-jquery */
        var gridRow = Ember.$('<div class="fill-in-the-blanks-quiz grid-row"/>').appendTo(this.gridContainer);
        for (let j = 0; j < gridData[i].length; j++) {
          /* eslint-disable-next-line ember/no-jquery */
          var gridItem = Ember.$('<div class="fill-in-the-blanks-quiz grid-item"/>').appendTo(gridRow);
          if (gridData[i][j].correctAnswer.length > 0) {
            this.addQuestion(gridData[i][j], gridItem);
          } else if (gridData[i][j].isStatic) {
            /* eslint-disable-next-line ember/no-jquery */
            Ember.$('<p>' + gridData[i][j].isStatic + '</p>').appendTo(gridItem);
          }
        }
      }
    },
    render: function () {
      var currentQuestion = this.data.shift();
      /* eslint-disable-next-line ember/no-jquery */
      this.questionContainer = Ember.$('<div id="question" class="question"></div>').appendTo(this.container);
      /* eslint-disable-next-line ember/no-jquery */
      this.buttonContainer = Ember.$('<div class="button-container"></>').appendTo(this.container);
      for (var i = 0, len = currentQuestion.length; i < len; i++) {
        var itemData = currentQuestion[i];

        /* eslint-disable-next-line ember/no-jquery */
        var itemContainer = Ember.$('<div class="item"></>').appendTo(this.questionContainer);
        /* eslint-disable-next-line ember/no-jquery */
        var valueContainer = Ember.$('<div class="item-value"></>').appendTo(itemContainer);

        // if title exists, add title container and then run addTitle function
        if (itemData.title.length > 0) {
          /* eslint-disable-next-line ember/no-jquery */
          var titleContainer = Ember.$('<div class="item-title"></>').appendTo(itemContainer);
          this.addTitle(itemData, titleContainer);
        }

        // if this has a correctAnswer, assume it's a question
        if (itemData.correctAnswer.length) {
          this.addQuestion(itemData, valueContainer);
        } else {
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('<p>' + itemData.value[0] + '</p>').appendTo(valueContainer);
        }
      }
      this.moveButtons(this.buttonContainer);
      this.notifyContentContainer('CC:ShowContent');
    },
    addTitle: function (itemData, titleContainer) {
      // print value of itemData if it isn't flagged as 'correct' or empty form field if it is

      var itemTitle = itemData.title;
      /* eslint-disable-next-line ember/no-jquery */
      Ember.$('<p>' + itemTitle + '</p>').appendTo(titleContainer);
    },
    addQuestion: function (itemData, valueContainer) {
      if (itemData.value.length === 1) {
        // input field for one answer
        /* eslint-disable-next-line ember/no-jquery */
        var input = Ember.$('<input type="text" class="' + this.FORM_EL + '" size="' + itemData.value[0].length + '"></input>').appendTo(valueContainer);
        input.data('correctAnswer', itemData.correctAnswer);
      } else {
        // dropdown for multiple answers
        /* eslint-disable-next-line ember/no-jquery */
        var menu = Ember.$('<select name="options" class="' + this.FORM_EL + '"></select>').appendTo(valueContainer);
        for (var j = 0, valLen = itemData.value.length; j < valLen; j++) {
          var choice = itemData.value[j];
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('<option value="' + choice + '">' + choice + '</option>').appendTo(menu);
        }
        menu.data('correctAnswer', itemData.correctAnswer);
      }
    },
    moveButtons: function (buttonContainer) {
      // Move all buttons to bottom of container.
      this.checkAnswerBtn.appendTo(buttonContainer);
      this.nextQuestionBtn.appendTo(buttonContainer);
      this.showCorrectBtn.appendTo(buttonContainer);
      this.tryAgainBtn.appendTo(buttonContainer);
    },
    notifyContentContainer: function (eventType) {
      // trigger an event to notify any listening content containers of the question
      var index = this.numQuestions - this.data.length - 1;
      /* eslint-disable-next-line ember/no-jquery */
      Ember.$(this.el).trigger(eventType, index);
    },
    checkAnswer: function (e) {
      var that = e.data; // passing in a reference to 'this'
      var numCorrect = 0; // keep track of how many fields are correct

      // input fields
      /* eslint-disable-next-line ember/no-jquery */
      var inputs = Ember.$(that.el.find(that.FORM_EL_CLASS));
      inputs.each(function () {
        /* eslint-disable-next-line ember/no-jquery */
        var answer = Ember.$(this).data().correctAnswer;

        /* eslint-disable-next-line ember/no-jquery */
        if (Ember.$(this).val() === answer) {
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$(this).removeClass('incorrect');
          numCorrect++;
        } else {
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$(this).addClass('incorrect');
        }
      });
      if (inputs.length === numCorrect) {
        /* eslint-disable-next-line ember/no-jquery */
        var answer = Ember.$(this).data().correctAnswer;
        /* eslint-disable-next-line ember/no-jquery */
        if (Ember.$(this).val() === answer) {
          numCorrect++;
        }
        that.showFeedback('Correct!');
        that.waitForNext();
      } else {
        that.showFeedback('Incorrect. Try again.');
        that.attempts++;
      }

      // check attempts. if more than set amount, show button to reveal answers
      if (that.attempts === that.NUM_ATTEMPTS) {
        that.showCorrectBtn.show();
      }
    },
    checkAnswerGrid: function (e) {
      var that = e.data; // passing in a reference to 'this'
      var numCorrect = 0; // keep track of how many fields are correct

      // input fields
      /* eslint-disable-next-line ember/no-jquery */
      var inputs = Ember.$(that.el.find(that.FORM_EL_CLASS));
      inputs.each(function () {
        /* eslint-disable-next-line ember/no-jquery */
        var answer = Ember.$(this).data().correctAnswer;

        /* eslint-disable-next-line ember/no-jquery */
        if (Ember.$(this).val() === answer) {
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$(this).removeClass('fill-in-the-blanks-quiz incorrect');
          numCorrect++;
        } else {
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$(this).addClass('fill-in-the-blanks-quiz incorrect');
        }
      });
      if (inputs.length === numCorrect) {
        that.quizCompleteGrid();
        return;
      } else {
        that.showFeedback('Incorrect. Try again.');
        that.attempts++;
      }

      // check attempts. if more than set amount, show button to reveal answers
      if (that.attempts === that.NUM_ATTEMPTS) {
        that.showCorrectBtn.show();
      }
    },
    showFeedback: function (msg) {
      // todo: might be useful to make an actual feedback box class
      this.feedback.text(msg);
      this.feedback.stop();
      this.feedback.fadeTo(100, 1).delay(750).fadeOut(1000);
    },
    waitForNext: function () {
      this.checkAnswerBtn.hide();
      this.nextQuestionBtn.fadeIn();
    },
    nextQuestion: function (e) {
      var that = e.data; // ref to this

      that.nextQuestionBtn.hide();
      that.attempts = 0; // reset attempts

      // check to see if we've completed the quiz
      if (that.data.length === 0) {
        that.quizComplete();
        return;
      }

      // kill feedback if its still animating
      that.checkAnswerBtn.show();
      that.questionContainer.remove();
      that.render();
    },
    showCorrect: function (e) {
      var that = e.data; // ref to this
      var inputs = that.el.find(that.FORM_EL_CLASS);
      inputs.each(function () {
        /* eslint-disable-next-line ember/no-jquery */
        var answer = Ember.$(this).data().correctAnswer;
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$(this).val(answer);
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$(this).removeClass('fill-in-the-blanks-quiz incorrect');
      });
      that.showCorrectBtn.hide();
      that.showFeedback('Correct answers revealed.');
      that.waitForNext();
    },
    quizComplete: function () {
      var prevHeight = this.questionContainer.height();
      this.showFeedback('Quiz Complete. Nice job!');
      this.questionContainer.html('<strong>Quiz Complete. Click "Try Again" to replay.</strong>').height(prevHeight).css('text-align', 'center');
      this.notifyContentContainer('CC:ClearContent');
      this.tryAgainBtn.show();
    },
    quizCompleteGrid: function () {
      var prevHeight = this.gridContainer.height();
      this.showFeedback('Quiz Complete. Nice job!');
      this.gridContainer.html('<strong class="complete">Quiz Complete. Click "Reset" to replay.</strong>').height(prevHeight).css('text-align', 'center');
      this.notifyContentContainer('CC:ClearContent');
    },
    resetQuiz: function (e) {
      var that = e.data; // ref to this

      // CL: could dry this up a bit
      that.checkAnswerBtn.show();
      that.tryAgainBtn.hide();
      that.questionContainer.remove();
      that.el.find('input').remove();
      that.init();
    },
    resetGrid: function (e) {
      var that = e.data;
      that.checkAnswerGridBtn.show();
      that.gridContainer.remove();
      that.el.find('input').remove();
      that.init();
    },
    renderConstantUI: function () {
      // CL: ideally this stuff would be templated out

      /* eslint-disable-next-line ember/no-jquery */
      this.feedback = Ember.$('<div class="feedback fill-in-the-blanks-quiz" style="display: none;"/>').appendTo(this.container);
      this.feedback.hide();
      this.checkAnswerBtn = FillInTheBlanksQuiz.ButtonFactory('Check Answer', this, this.checkAnswer, this.container);
      this.nextQuestionBtn = FillInTheBlanksQuiz.ButtonFactory('Next Question', this, this.nextQuestion, this.container, 'attention');
      this.nextQuestionBtn.hide();
      this.showCorrectBtn = FillInTheBlanksQuiz.ButtonFactory('Show Correct?', this, this.showCorrect, this.container, 'show-correct-button');
      this.showCorrectBtn.hide();
      this.tryAgainBtn = FillInTheBlanksQuiz.ButtonFactory('Try Again?', this, this.resetQuiz, this.container, 'attention');
      this.tryAgainBtn.hide();
    },
    renderGridUI: function () {
      /* eslint-disable-next-line ember/no-jquery */
      this.feedback = Ember.$('<div class="feedback fill-in-the-blanks-quiz" style="display: none;"/>').appendTo(this.container);
      this.feedback.hide();
      this.checkAnswerGridBtn = FillInTheBlanksQuiz.ButtonFactory('Check Answer', this, this.checkAnswerGrid, this.container);
      this.showCorrectBtn = FillInTheBlanksQuiz.ButtonFactory('Show Correct?', this, this.showCorrect, this.container, 'show-correct-button');
      this.showCorrectBtn.hide();
      this.resetGridBtn = FillInTheBlanksQuiz.ButtonFactory('Reset', this, this.resetGrid, this.container, 'attention');
    }
  };

  // todo: consider pluralizing 'value' or renaming all together now that it's an array
  FillInTheBlanksQuiz.QuizItem = function (title, value, correctAnswer) {
    this.title = title;
    this.value = value;
    this.correctAnswer = correctAnswer;
  };
  FillInTheBlanksQuiz.ButtonFactory = function (value, parentThis, clickFunc, container, optionalClass) {
    /* eslint-disable-next-line ember/no-jquery */
    var btn = Ember.$('<input type="button" value="' + value + '" class="fill-in-the-blanks-quiz button"></input>').appendTo(container);
    btn.bind('click', parentThis, clickFunc);
    btn.addClass(optionalClass);
    return btn;
  };
  var _default = _exports.default = FillInTheBlanksQuiz;
});