define("bocce/templates/components/classroom/current-lesson-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "bAiXpAlL",
    "block": "{\"symbols\":[],\"statements\":[[2,[34,0]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"text\"]}",
    "meta": {
      "moduleName": "bocce/templates/components/classroom/current-lesson-item.hbs"
    }
  });
});