define("bocce/components/quiz-questions/file-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let FileUpload = _exports.default = (_dec = Ember._tracked, _dec2 = Ember._action, (_class = class FileUpload extends Ember.Component {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "fileDownloading", _descriptor, this);
    }
    didInsertElement() {
      if (!this.quizzes.hasScore) {
        const self = this;
        const attemptData = this.question.get('attempt_data');
        const {
          fileUploadElem
        } = attemptData;
        const attachments = this.question.get('attempt_data.attachments');
        Ember.$(`#quiz-file-upload-file-${this.index}`).on('change', async event => {
          if (event.target.files.length > 0) {
            Ember.set(self.question, 'given_answer', event.target.files[0]);
            Ember.set(self.question, 'attempt_data', {
              ...attemptData,
              fileUploadElem: event.target
            });
          } else {
            Ember.set(self.question, 'given_answer', 0);
            Ember.set(self.question, 'attempt_data', {
              ...attemptData,
              fileUploadElem: null
            });
          }
        });
        if ((!attachments || attachments.length === 0) && fileUploadElem) {
          const existingFileUploadElem = document.getElementById(`quiz-file-upload-file-${this.index}`);
          document.getElementById(`quiz-file-upload-file-wrapper-${this.index}`).replaceChild(fileUploadElem, existingFileUploadElem);
        }
      }
    }
    async downloadFile(attachmentId, fileName) {
      this.question.set('fileDownloading', true);
      const response = await fetch(`/api/v1/files/${attachmentId}`);
      const text = await response.text();
      const {
        url
      } = JSON.parse(text.replace(/^while\(1\);/, ''));
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      this.question.set('fileDownloading', false);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fileDownloading", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "downloadFile", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "downloadFile"), _class.prototype)), _class));
});