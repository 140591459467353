define("bocce/templates/classroom/lessons/admin/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "wt7eKdo+",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h2\"],[12],[2,\"Loading\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "bocce/templates/classroom/lessons/admin/loading.hbs"
    }
  });
});