define("bocce/mixins/interactions/sound_mixer", ["exports", "bocce/mixins/support/sound_mixer_core"], function (_exports, _sound_mixer_core) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = SoundMixer;
  function SoundMixer($el, data) {
    let $data_el = $el.find('.interaction_data'),
      mixer = {
        tracks: []
      };
    this.config = data.config;
    this.$el = $el;
    $el.addClass('sound-mixer');
    $data_el.find('tr').each(function (num, row) {
      if (num === 0) {
        return; // skip the header row
      }

      /* eslint-disable-next-line ember/no-jquery */
      var $cells = Ember.$(row).find('td'),
        trackPath = $cells[0].innerText,
        trackName = $cells[1].innerText;

      // Collect everything into a quiz question object literal
      mixer.tracks.push({
        trackPath: trackPath,
        trackName: trackName
      });
      Ember.debug('Track ' + num + ' path: ' + $cells[0].innerHTML);
      Ember.debug('Track ' + num + ' title: ' + $cells[1].innerHTML);
    });
    this.config.mixer = mixer;
  }
  SoundMixer.prototype.init = function () {
    new _sound_mixer_core.default(this.config, this.$el).run();
  };
});