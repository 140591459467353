define("bocce/controllers/conversations", ["exports", "bocce/mixins/discussable", "bocce/mixins/enmodal", "bocce/mixins/conversable"], function (_exports, _discussable, _enmodal, _conversable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  var _default = _exports.default = Ember.Controller.extend(_discussable.default, _conversable.default, _enmodal.default, {
    init(...args) {
      this._super(...args);
      this.sortProperties = this.sortProperties || ['lastActivity'];
      this.conversationSortRules = this.conversationSortRules || ['lastActivity:desc'];
    },
    classroom: Ember.inject.controller(),
    userprofileService: Ember.inject.service('userprofile'),
    conversation: Ember.inject.controller('classroom.lessons.conversation'),
    discussion: Ember.inject.controller('classroom.lessons.discussion'),
    // required by DiscussableMixin
    newDiscussion: Ember.inject.controller('classroom.lessons.discussion-new'),
    // required by DiscussableMixin

    sortAscending: false,
    filteredList: null,
    recipientId: null,
    courseInstructor: Ember.computed('session.teachingUser', function () {
      return this.get('session.teachingUser');
    }),
    sortedConversations: Ember.computed.sort('activeConversations', 'conversationSortRules'),
    activeConversations: Ember.computed('model.@each.{workflow_state,conversationPartners}', function () {
      let convos = this.model;
      if (convos) {
        return this.model.filterBy('active', true);
      }
      return [];
    }),
    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    unreadCountInbox: Ember.computed('model.@each.workflow_state', function () {
      /* eslint-disable-next-line ember/no-get */
      let conversationsLoaded = this.get('classroom.conversationsLoaded') || false;
      if (!conversationsLoaded) {
        /* eslint-disable-next-line ember/no-get */
        return this.get('session.course.numUnreadConversations');
      } else {
        return this.model.filterBy('workflow_state', 'unread').length;
      }
    }),
    actions: {
      closeModals: function () {
        this._super();
        this.conversation.send('deleteUnsentConversation');
      },
      pmInstructor: function () {
        this.transitionToRoute('classroom.lessons.conversation-new-with', this.get('courseInstructor.id'));
      }
    }
  });
});