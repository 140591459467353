define("bocce/mixins/interactions/audio_markers_quiz", ["exports", "bocce/mixins/support/render-template"], function (_exports, _renderTemplate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function AudioMarkersQuiz($el) {
    this.$el = $el;
    this.$stage = this.$el.find('.interaction_content').removeAttr('style');
    this.$data = this.$el.find('.interaction_data');
  }
  AudioMarkersQuiz.prototype = {
    // frequently used, lazily cached els
    $player: undefined,
    $progressBar: undefined,
    $image: undefined,
    $feedback: undefined,
    $loading: undefined,
    $markerBin: undefined,
    audioMarkers: [],
    numRemaining: 0,
    init: function () {
      this.audioMarkers = this.createAudioMarkers();
      let retval = (0, _renderTemplate.default)('audio_markers_quiz', 'main').then(content => {
        this.$stage.append(content);

        // cache frequently used els
        this.$progressBar = this.$stage.find('.progress_bar');
        this.$feedback = this.$stage.find('.feedback');
        this.$loading = this.$stage.find('.loading');
        this.addBgImage();
        this.addMarkers(this.audioMarkers);
      });
      retval.then(() => this.addPlayer());
      return retval;
    },
    addPlayer: function () {
      var that = this,
        $playerComponent = this.$data.find('.audio_tag').closest('.component');
      this.$stage.find('.player_container').append($playerComponent);

      // locate HTML5 Audio Tag
      this.$player = $playerComponent.find('.audio_tag')[0];

      // listen for player to be initialized and ready
      this.$player.load();
      that.$loading.fadeOut();
      that.$player.ontimeupdate = function () {
        that.onPlayerTimeUpdate(this);
      };
    },
    addBgImage: function () {
      var $image = this.$data.find('.image-component:first img');
      this.$stage.find('.image_container').append($image);

      // prevent 'ghost image' dragging
      $image.attr('draggable', 'false')
      // use image as an alternate seek area
      /* eslint-disable-next-line ember/no-jquery */.click(Ember.$.proxy(this.seekAudio, this));

      // cache the image
      this.$image = $image;
    },
    createAudioMarkers: function () {
      var $rows = this.$data.find('table:first tr'),
        audioMarkers = [];

      // create an audio marker for each row in
      // the dataTable (minus the header row)
      /* eslint-disable-next-line ember/no-jquery */
      $rows.each(Ember.$.proxy(function (i, row) {
        if (i === 0) {
          return; // skip header row
        }
        audioMarkers.push(new AudioMarker(row));
      }, this));
      return audioMarkers;
    },
    addMarkers: function (audioMarkers) {
      this.$markerBin = this.$stage.find('.marker_bin');

      /* eslint-disable-next-line ember/no-jquery */
      Ember.$(audioMarkers).each(Ember.$.proxy(function (i, audioMarker) {
        this.$markerBin.append(audioMarker.$el);
        /* eslint-disable-next-line ember/no-jquery */
        audioMarker.$el.bind('audioMarkerDropped', Ember.$.proxy(this.onAudioMarkerDropped, this))
        /* eslint-disable-next-line ember/no-jquery */.bind('correct', Ember.$.proxy(this.onCorrect, this))
        /* eslint-disable-next-line ember/no-jquery */.bind('incorrect', Ember.$.proxy(this.onIncorrect, this));
      }, this));
      this.numRemaining = audioMarkers.length;
    },
    onPlayerTimeUpdate: function (e) {
      var percentPlayed = Math.round(e.currentTime / e.duration * 100);
      this.updateProgressBar(percentPlayed);
    },
    updateProgressBar: function (percentPlayed) {
      this.$progressBar.css({
        left: percentPlayed + '%'
      });
    },
    seekAudio: function (e) {
      /* eslint-disable-next-line ember/no-jquery */
      var $target = Ember.$(e.target),
        clickX = e.offsetX || e.pageX - $target.position().left - $target.offset().left,
        // Firefox doesn't report offsetX...
        targetW = $target.width(),
        percent = clickX / targetW,
        time = this.$player.duration * percent;
      this.$player.currentTime = time;
    },
    onAudioMarkerDropped: function (e, audioMarker) {
      var imageRect = this.getElRect(this.$image),
        markerRect = this.getElRect(audioMarker.$el),
        normalizedLeft = markerRect.left - imageRect.left,
        // makes 0 $image left
        durationSecs = this.$player.duration,
        dropPercent = normalizedLeft / imageRect.width,
        dropTime = dropPercent * durationSecs,
        isCorrect = audioMarker.isSecsWithinRange(dropTime),
        isWithinBounds = this.isWithinBoundsOf(markerRect, imageRect);
      if (isCorrect && isWithinBounds) {
        audioMarker.correct();
        this.checkForCollisions(audioMarker);
      } else {
        audioMarker.incorrect();
      }
    },
    isWithinBoundsOf: function (rect1, rect2) {
      // don't check right because els can extend out of
      // the right edge of the interaction (long labels, etc)
      if (rect1.top >= rect2.top && rect1.bottom <= rect2.bottom && rect1.left >= rect2.left) {
        return true;
      }
      return false;
    },
    getElRect: function (el) {
      /* eslint-disable-next-line ember/no-jquery */
      var $el = Ember.$(el),
        offset = $el.offset();
      return {
        left: offset.left,
        right: offset.left + $el.width(),
        top: offset.top,
        bottom: offset.top + $el.height(),
        width: $el.width(),
        height: $el.height()
      };
    },
    checkForCollisions: function (audioMarker, markerTop) {
      var isColliding = false,
        imageTop = this.$image.offset().top - this.$markerBin.offset().top;

      // compare bounding box of the dropped audioMarker with all other markers
      // and make sure there's no overlap
      /* eslint-disable-next-line ember/no-jquery */
      Ember.$.each(this.audioMarkers, Ember.$.proxy(function (i, currentMarker) {
        // don't compare marker with itself
        if (audioMarker === currentMarker) {
          return;
        }
        isColliding = this.detectCollision(audioMarker.$el, currentMarker.$el);
        if (isColliding) {
          // If a markerTop is provided, set the val of newTop to it. Otherwise,
          // set the top position to roughly the top of the background image. This is
          // so that we can adjust the marker top before subsequent collision checks.
          // To put it simply, the top value of the marker is changed until the
          // marker doesn't collide with anything.
          var newTop = markerTop || imageTop;
          audioMarker.$el.css('top', newTop + 'px');

          // Recheck to make sure we're not hitting anything new.
          // Add 10 to move the marker down on the next check.
          this.checkForCollisions(audioMarker, newTop + 10);
          return false; // break out of loop
        }
      }, this));
    },
    detectCollision: function (elA, elB) {
      var rectA = this.createBoundingBox(elA),
        rectB = this.createBoundingBox(elB);

      // if any of these conditions are satisfied, we
      // can't have a collision
      if (rectA.topLeft.x >= rectB.bottomRight.x || rectA.bottomRight.x <= rectB.topLeft.x || rectA.topLeft.y >= rectB.bottomRight.y || rectA.bottomRight.y <= rectB.topLeft.y) {
        return false;
      }

      // if none of the conditions above are
      // satisfied, we have a hit!
      return true;
    },
    // return a more readable rect for use in collision
    // detection, position, etc
    createBoundingBox: function (el) {
      /* eslint-disable-next-line ember/no-jquery */
      var $el = Ember.$(el),
        pos = $el.position();

      // using bitwise | for rounding - fastest across most browsers
      return {
        topLeft: {
          x: pos.left | 0,
          y: pos.top | 0
        },
        bottomRight: {
          x: pos.left + $el.width() | 0,
          y: pos.top + $el.height() | 0
        }
      };
    },
    onCorrect: function () {
      this.numRemaining--;
      if (this.numRemaining === 0) {
        this.onAllCorrect();
      } else {
        this.showFeedback('<span class="correct">Correct! Nice job.</span>');
      }
    },
    onAllCorrect: function () {
      this.$feedback.hide();
      this.$stage.find('.try_again').fadeIn();
      this.$el.find('.try_again a')
      /* eslint-disable-next-line ember/no-jquery */.click(Ember.$.proxy(this.resetQuiz, this));
    },
    onIncorrect: function () {
      this.showFeedback('<span class="incorrect">Sorry, that\'s not correct. Try again.</span>');
    },
    showFeedback: function (html) {
      this.$feedback.hide().html(html).fadeIn();
    },
    resetQuiz: function (e) {
      e.preventDefault();
      this.$stage.find('.try_again').fadeOut();
      /* eslint-disable-next-line ember/no-jquery */
      Ember.$(this.audioMarkers).each(Ember.$.proxy(function (i, marker) {
        marker.reset(this.$markerBin);
      }, this));
      this.numRemaining = this.audioMarkers.length;
    }
  };
  function AudioMarker(tableRow) {
    /* eslint-disable-next-line ember/no-jquery */
    var data = Ember.$(tableRow).find('td'),
      /* eslint-disable-next-line ember/no-jquery */
      label = Ember.$(data[0]).text(),
      /* eslint-disable-next-line ember/no-jquery */
      time = Ember.$(data[1]).text(),
      /* eslint-disable-next-line ember/no-jquery */
      tolerance = Ember.$(data[2]).text();
    this.initEl(label);
    this.calculateTimes(time, tolerance);
  }
  AudioMarker.prototype = {
    $el: undefined,
    template: '<div class="audio_marker"></div>',
    minTime: 0,
    maxTax: 0,
    draggableConfig: {},
    initEl: function (label) {
      this.draggableConfig = {
        /* eslint-disable-next-line ember/no-jquery */
        start: Ember.$.proxy(this.onDragStart, this),
        /* eslint-disable-next-line ember/no-jquery */
        stop: Ember.$.proxy(this.onDragStop, this)
      };
      /* eslint-disable-next-line ember/no-jquery */
      this.$el = Ember.$(this.template).draggable(this.draggableConfig);
      this.$el.html(label);
    },
    calculateTimes: function (time, tolerance) {
      var secs = this.timeStrToSecs(time),
        timeDiff = secs - tolerance;
      tolerance = parseInt(tolerance, 10) || 5; // default tolerance is 5 secs

      this.minTime = timeDiff >= 0 ? timeDiff : 0;
      this.maxTime = secs + tolerance;
    },
    timeStrToSecs: function (time) {
      var mins = 0,
        secs = 0;

      // if time is in the format of mm:ss...
      if (time.indexOf(':') > -1) {
        time = time.split(':');
        mins = parseInt(time[0], 10);
        secs = parseInt(time[1], 10);
        secs = mins * 60 + secs;
      }
      // if time is just an integer...
      else {
        secs = parseInt(time, 10);
      }
      return secs;
    },
    onDragStart: function () {
      this.$el.addClass('left_arrow');
    },
    onDragStop: function () {
      var left = parseInt(this.$el.css('left'));
      this.$el.removeClass('left_arrow')
      // move el to the left 10px to account for arrows
      .css({
        left: left - 10 + 'px'
      }).trigger('audioMarkerDropped', [this]);
    },
    isSecsWithinRange: function (timeSecs) {
      return timeSecs >= this.minTime && timeSecs <= this.maxTime;
    },
    correct: function () {
      this.$el.draggable('disable').addClass('correct').removeClass('incorrect').trigger('correct');
    },
    incorrect: function () {
      this.$el.addClass('incorrect').trigger('incorrect');
    },
    reset: function (parentEl) {
      this.$el.draggable('destroy').appendTo(parentEl).removeClass('correct').removeAttr('style').draggable(this.draggableConfig).draggable('enable');
    }
  };
  var _default = _exports.default = AudioMarkersQuiz;
});