define("bocce/templates/components/side-panel/panel-list-item/portrait", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "oqnVdFhs",
    "block": "{\"symbols\":[],\"statements\":[[8,\"user-portrait\",[],[[\"@tagName\",\"@class\",\"@user\",\"@suppressClick\"],[\"button\",\"main-portrait\",[34,0,[\"user\"]],true]],null]],\"hasEval\":false,\"upvars\":[\"item\"]}",
    "meta": {
      "moduleName": "bocce/templates/components/side-panel/panel-list-item/portrait.hbs"
    }
  });
});