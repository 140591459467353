define("bocce/services/heartbeat", ["exports", "bocce/helpers/heartbeat"], function (_exports, _heartbeat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function urlExists(url) {
    return new Promise(resolve => {
      let http = new XMLHttpRequest();
      http.open('HEAD', url + '?_=' + new Date().getTime());
      http.setRequestHeader('Cache-Control', 'no-cache');
      http.onreadystatechange = function () {
        resolve(http.status !== 404);
      };
      http.send();
    });
  }
  var _default = _exports.default = Ember.Service.extend({
    init() {
      this._super(...arguments);
      // For easier debugging just change the frequency here. - JRW
      window.heartbeatFrequencyInMilliseconds = 30 * 1000;
      this.set('last_heartbeat', new Date());
      this.set('blurred', false);
    },
    missed_heartbeat: false,
    session: Ember.inject.service(),
    begin(notificationController) {
      this.set('notificationController', notificationController);

      // Very simple approach for right now.  If we're out of focus, skip
      // that particular heartbeat run.
      /* eslint-disable-next-line ember/no-jquery */
      Ember.$(window).on('blur', () => this.set('blurred', true));
      /* eslint-disable-next-line ember/no-jquery */
      Ember.$(window).on('focus', Ember.run.bind(this, () => {
        // If we were stopped, do a one-off run to resync immediately
        if (this.blurred && this.missed_heartbeat) {
          this.heartbeat(true);
        }
        this.set('blurred', false);
      }));
      Ember.run.next(this, this.heartbeat);
    },
    end() {
      /* eslint-disable-next-line ember/no-jquery */
      Ember.$(window).off('blur');
      /* eslint-disable-next-line ember/no-jquery */
      Ember.$(window).off('focus');
    },
    heartbeat(dont_reschedule_p, full_p) {
      const ctl = this.notificationController;
      (true && !(ctl) && Ember.assert('heartbeat must have a controller to which it can send notifications.', ctl));
      var bodyInput, cas, myText, arName;

      // Archive any text to running archive automatically
      /* eslint-disable-next-line ember/no-jquery */
      if (Ember.$('.floating-modal.active').length > 0 && Ember.$('.floating-modal.active .rte-editor-input').text().length > 0) {
        /* eslint-disable-next-line ember/no-jquery */
        let archiver = Ember.$('#archiver');
        /* eslint-disable-next-line ember/no-jquery */
        bodyInput = Ember.$('.floating-modal.active .rte-editor-input').html();
        archiver.addClass('saving');
        archiver.one('webkitAnimationEnd oanimationend msAnimationEnd animationend', function () {
          archiver.removeClass('saving');
        });
        if (localStorage.localDocs) {
          cas = JSON.parse(localStorage.localDocs);
        } else {
          cas = {};
        }
        /*
        if (localStorage.localDocRunner) {
          arName = localStorage.localDocRunner;
          cas[arName].textContent = bodyInput;
        } else {**/
        arName = 'Auto Save';
        myText = {
          isText: true,
          name: arName,
          textContent: bodyInput,
          type: 'text'
        };
        cas[myText.name] = myText;
        //        }

        localStorage.localDocs = JSON.stringify(cas);
      }

      /* eslint-disable-next-line ember/no-get */
      if (this.get('session.isStaticContent') || this.get('session.course.isReadOnly')) {
        return;
      }
      if (this.blurred && !dont_reschedule_p) {
        this.set('missed_heartbeat', true);
        if (window.heartbeatFrequencyInMilliseconds) {
          Ember.run.later(this, 'heartbeat', window.heartbeatFrequencyInMilliseconds);
        }
        return;
      }
      this.checkCurrent();
      this.set('missed_heartbeat', false);
      Ember.debug('Beep.');
      this.set('session.time', Date.now());
      let now = new Date(),
        full_reload_p = now - this.last_heartbeat > 120000 || full_p,
        // types that can be deleted and that also reference another type
        /* eslint-disable-next-line ember/no-get */
        currentSection = this.get('session.section.id');
      let routes = [/* eslint-disable-next-line ember/no-get */
      '/interface/courses/' + this.get('session.section.course.id') + '/heartbeat/' + this.get('session.section.id')];

      // Heartbeat is only good for 120 seconds of data.  If we run over that,
      // do a full refresh
      if (!full_reload_p) {
        routes.push('/interface/users/heartbeat');
      } else {
        routes.push('/interface/sections/' + currentSection + '/discussions/');
        routes.push('/interface/sections/' + currentSection + '/assignments/');
        routes.push('/interface/conversations/');
        /* eslint-disable-next-line ember/no-get */
        routes.push('/interface/courses/' + this.get('session.section.course.id') + '/events/');
      }
      this.set('last_heartbeat', now);
      (0, _heartbeat.default)(routes, ctl, ctl).always(() => {
        if (!dont_reschedule_p && window.heartbeatFrequencyInMilliseconds) {
          Ember.run.later(this, 'heartbeat', window.heartbeatFrequencyInMilliseconds);
        }
      });
    },
    // Ping JS/CSS files to make sure they are still current...
    checkCurrent() {
      let resultCss = this._searchFiles('link'),
        resultScript = this._searchFiles('script');
      if (resultCss.length > 0 && resultScript.length > 0) {
        urlExists(resultScript).then(jsResult => {
          if (jsResult) {
            urlExists(resultCss).then(cssResult => {
              this._notifyUpdates(cssResult);
            });
          } else {
            this._notifyUpdates(jsResult);
          }
        });
      }
    },
    _searchFiles(type) {
      const ctl = this.notificationController;
      let key,
        value,
        /* eslint-disable-next-line ember/no-get */
        result = ctl.get('session.bFiles' + type);
      if (!result) {
        let scripts = document.getElementsByTagName(type);
        for (key in scripts) {
          if (type === 'link') {
            value = scripts[key].href;
          } else {
            value = scripts[key].src;
          }
          if (!!value && value.indexOf('assets/bocce') > -1) {
            result = value;
            break;
          }
        }
        ctl.set('session.bFiles' + type, result);
      }
      return result;
    },
    _notifyUpdates(result) {
      if (!result) {
        let ctl = this.notificationController;
        /* eslint-disable-next-line ember/no-get */
        ctl.send('notify', 'We have made some updates - click here to reload this page.', true, null, null, 1000, ctl.get('session'), true);
      } else {
        Ember.debug('JS/CSS are current.');
      }
    }
  });
});