define("bocce/routes/index", ["exports", "bocce/mixins/routable"], function (_exports, _routable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable-next-line ember/no-mixins */
  var _default = _exports.default = Ember.Route.extend(_routable.default, {
    // The index route redirects to the dashboard
    beforeModel() {
      this.replaceWith('dashboard');
    }
  }); // TODO-FIXME: Need to call DS.store.unloadAll('lesson'), topic when changing course or lesson
});