define("bocce/helpers/longdate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // app/helpers/longdate.js
  // Convert date into long-form with Moment.JS
  var _default = _exports.default = Ember.Helper.helper(function (date) {
    if (date.length === 0) {
      return 'Date Incorrect';
    }
    return moment(date[0]).tz('America/New_York').format('MMMM Do, h:mmA') + ' ET';
  });
});