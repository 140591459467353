define("bocce/mixins/webex", ["exports", "bocce/utilities/dialog"], function (_exports, _dialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable-next-line ember/no-new-mixins */
  var _default = _exports.default = Ember.Mixin.create({
    session: Ember.inject.service(),
    gainsight: Ember.inject.service(),
    otherSectionChats: [],
    router: Ember.inject.service(),
    openWebex: function (api_url, data) {
      let options = {
          method: 'POST'
        },
        eventsCtl = this.eventsController || this.events;
      if (data) {
        options.body = JSON.stringify(data);
      }
      eventsCtl.set('isWorking', true);
      return fetch(api_url, options).then(r => {
        if (!r.ok) {
          throw r.json();
        }
        return r.json();
      }).then(dest_url => {
        window.open(dest_url, '_blank');
        eventsCtl.set('isWorking', false);
        return dest_url;
      }).catch(err => {
        Promise.resolve(err).then(message => {
          if (message === 1) {
            (0, _dialog.default)(`Please clear your browser cache before starting any Live Lessons. Not sure how? <a href="https://online.berklee.edu/help/browser/1500025-how-to-clear-your-browsers-cache" target="_blank">Here are instructions for how to do so.</a>`);
          } else if (typeof message === 'string') {
            (0, _dialog.default)(`Error starting chat: ${message}`);
          } else {
            (0, _dialog.default)('Unable to start live chat. Please try again in a few minutes.');
          }
        });
      }).finally(() => {
        eventsCtl.set('isWorking', false);
      });
    },
    actions: {
      startChat: function (meeting_details, meeting_id) {
        if (meeting_details.data) {
          meeting_details = meeting_details.data;
        }
        meeting_details.id = meeting_id;
        /* eslint-disable-next-line ember/no-get */
        let section_id = this.get('session.section.id');
        this.openWebex(`/interface/livechat/startChat/${section_id}`, meeting_details);
      },
      recordParticipation: function (meetingKeys) {
        /* eslint-disable-next-line ember/no-get */
        let course = this.get('session.course'),
          // Use a placeholder if customGradeBookColumns is undefined
          customGradeBookColumns = course.get('customGradeBookColumns'),
          /* eslint-disable-next-line ember/no-get */
          currentUser = this.get('session.user'),
          meetingKeyColumn,
          numChatsAttendedColumn,
          saveCourse = false;

        /* eslint-disable-next-line ember/no-get */
        if (!this.get('session.isStudent') || !customGradeBookColumns) {
          return;
        }
        meetingKeyColumn = customGradeBookColumns.find(gbColumn => {
          return gbColumn.title === 'Live Class Meeting Keys';
        });
        numChatsAttendedColumn = customGradeBookColumns.find(gbColumn => {
          return gbColumn.title === 'Live Class Attendance';
        });

        // Sometimes, when empty, the column's data comes back as an empty array OR an empty object
        if (meetingKeyColumn.data.length === 0 || Object.keys(meetingKeyColumn.data).length === 0) {
          meetingKeyColumn.data = [{
            content: JSON.stringify(meetingKeys),
            user_id: currentUser.get('id')
          }];
          numChatsAttendedColumn.data = [{
            content: meetingKeys.length.toString(),
            user_id: currentUser.get('id')
          }];
          saveCourse = true;
        } else {
          // Canvas returns annoyingly returns single values in an array.
          let studentData = meetingKeyColumn.data[0],
            data = JSON.parse(studentData.content) || [];
          if (Array.isArray(data)) {
            meetingKeys.forEach(meetingKey => {
              if (data.indexOf(meetingKey) === -1) {
                data.push(meetingKey);
                saveCourse = true;
              }
            });
            meetingKeyColumn.data = [{
              content: JSON.stringify(data),
              user_id: currentUser.get('id')
            }];
            numChatsAttendedColumn.data = [{
              content: data.length.toString(),
              user_id: currentUser.get('id')
            }];
          }
        }
        if (saveCourse) {
          course.set('customGradeBookColumns', [meetingKeyColumn, numChatsAttendedColumn]);
          course.save();

          // Gainsight
          this.gainsight.renderChatGainsightPxTag(new Date());
        }
      },
      joinChat: function () {
        /* eslint-disable-next-line ember/no-get */
        let user = this.get('session.user'),
          /* eslint-disable-next-line ember/no-get */
          section_id = this.get('session.section.id');
        this.openWebex(`/interface/livechat/joinChat/${section_id}/${user.get('name').replace(/\//g, '')}/${user.get('email')}`).then(joinUrl => {
          let parts = joinUrl.split('/'),
            meetingKey = parts[parts.length - 1];
          this.router.transitionTo({
            queryParams: {
              backup_code: true
            }
          });
          this.send('recordParticipation', [meetingKey]);
        });
      },
      testChat: function (isStudent = false) {
        /* eslint-disable-next-line ember/no-get */
        let section_id = this.get('session.section.id');
        if (!isStudent) {
          this.openWebex(`/interface/livechat/createTestChat/${section_id}`);
        } else {
          this.openWebex(`/interface/livechat/createTestChat/${section_id}/student`);
        }
      },
      //JRW: Added when campus users started using our zoom instance
      // Open a tab so that they can login to zoom, then refresh when
      // the bocce tab regains focus.
      zoomLoginRedirect: function () {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$(window).focus(function () {
          location.reload(true);
        });
        window.open('https://berklee.zoom.us', '_blank');
      },
      rejoinChatAsHost: function (meeting_details, meeting_id) {
        meeting_details.id = meeting_id;
        /* eslint-disable-next-line ember/no-get */
        let section_id = this.get('session.section.id');
        this.openWebex(`/interface/livechat/joinChatAsHost/${section_id}`, meeting_details);
      },
      watchChatUrl: function (value) {
        if (value) {
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('.watch-button option:first').attr('selected', true);
          window.open(value, '_blank');
        }
      },
      watchArchivedChat: function (event_id, url = '') {
        this.store.findRecord('event', event_id).then(eventObject => {
          //If it doesn't have kaltura recordings, go to zoom
          if (!eventObject.get('kalturaRecordings') || eventObject.get('kalturaRecordings.length') === 0) {
            if (url && !url.isEmpty()) {
              window.open(url, '_blank');
            }
          }
          this.send('viewEvent', event_id);
        });
      }
    }
  });
});