define("bocce/mixins/lesson-redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable-next-line ember/no-new-mixins */
  var _default = _exports.default = Ember.Mixin.create({
    session: Ember.inject.service(),
    getRedirectLessonInfo() {
      /**
       *  IF user has not visited course, go to very first item in syllabus.
          ELSE IF user has access to most recently visited page, go there.
          ELSE Go to introduction of current lesson.
       */

      /* eslint-disable-next-line ember/no-get */
      return this.get('session.user').then(async user => {
        // Default values; if user hasn't visited the course, use these
        let lesson_id = 0;
        let item_id = 0;
        let lastviewed = user.get('lastviewed');
        let course_id = this.paramsFor('classroom').course_id;
        let visitedCourse = lastviewed && lastviewed[course_id];
        if (visitedCourse) {
          let info = lastviewed[course_id];
          lesson_id = +info.lesson;

          //Don't redirect to a page the user doesn't have access to 
          //(possibly due to early access being revoked)
          const currentWeek = this.session.get("course.term.week");
          const ignoreNoAccess = this.session.get("isAdmin") || this.session.get("isStaff") || this.session.get("isInstructor");
          if (lesson_id > currentWeek && this.session.get('course.earlyUnlock') < lesson_id && !ignoreNoAccess) {
            const lessons = await this.session.get('course.lessons');
            if (lessons.length >= currentWeek) {
              const items = await lessons.objectAt(currentWeek).get('items');
              lesson_id = currentWeek;
              item_id = items.objectAt(0).id;
            }
          } else {
            item_id = info.item_id;
          }
        }
        this.store.nestResources('item', [{
          course: course_id
        }, {
          lesson: lesson_id
        }]);
        return {
          lesson_id,
          item_id
        };
      });
    }
  });
});