define("bocce/mixins/interactions/abstract_quiz", ["exports", "bocce/mixins/support/render-template", "bocce/helpers/decode"], function (_exports, _renderTemplate, _decode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function AbstractQuiz($el, data, templateGroup) {
    // constants
    this.SHOW_QUESTION = 'ShowQuestion';
    this.CORRECT = 'Correct';
    this.INCORRECT = 'Incorrect';
    this.QUIZ_COMPLETE = 'QuizComplete';
    this.el = $el; // all events should be triggered off of this

    this.interactionData = $el.find('.interaction_data');
    this.container = $el.find('.interaction_content');
    this.templateGroup = templateGroup; // feel free to override these

    this.questionTemplateClass = 'main';
    this.quizCompleteTemplateClass = 'quiz_complete';
    this.maxAttempts = 3; // saved data

    // saved data
    this.data = data;
    this.tabindex = 0;

    /* eslint-disable-next-line ember/no-jquery */
    if (typeof this.data.initial_json == "string") {
      this.json = JSON.parse(this.data.initial_json || '[]');
    } else {
      this.json = this.data.initial_json;
    }
  }
  AbstractQuiz.prototype = {
    currentIndex: 0,
    numAttempts: 0,
    numCorrect: 0,
    numAnswers: 0,
    answerCorrect: function answerCorrect(numRemaining) {
      numRemaining = numRemaining || 0;
      this.el.trigger(this.CORRECT, {
        'numRemaining': numRemaining
      });
    },
    answerIncorrect: function answerIncorrect() {
      this.numAttempts++;
      this.el.trigger(this.INCORRECT);
    },
    checkAnswer: function checkAnswer() {// provide custom answer validation
    },
    currentQuestion: {},
    getCurrentQuestion: function () {
      // return a deep copy of the current question
      /* eslint-disable-next-line ember/no-jquery, ember/no-new-mixins */
      return Ember.$.extend(true, {}, this.json[this.currentIndex]);
    },
    nextQuestion: function (e) {
      if (e) {
        e.preventDefault();
      }
      this.currentIndex++;

      // check to see if we're done
      if (this.currentIndex === this.json.length) {
        this.quizComplete();
      } else {
        this.container.find(".question")?.focus();
        this.showQuestion(this.currentIndex);
      }
      return false;
    },
    resetQuiz: function () {
      // nextQuestion increments currentIndex so set to -1
      this.currentIndex = -1;
      this.nextQuestion();
      return false;
    },
    // this should never be called directly
    showQuestion: function () {
      // store a copy of the current question for manipulation, etc
      this.currentQuestion = this.getCurrentQuestion();
      return this.addTemplate(this.container, this.questionTemplateClass, this.currentQuestion, () => {
        this.el.trigger(this.SHOW_QUESTION);
      });
    },
    addTemplate: function addTemplate(el, templateClass, context, callback) {
      if (this.templateGroup === 'hot_spot_quiz') {
        var hotspotPoint = '';
        let isHotspotSvg = false;
        let $hotspotSvg = $('<svg class="highlight_svg"> </svg>');
        this.json.forEach(jsonObj => {
          jsonObj.hotSpotsAccessibility.forEach(hotspotObj => {
            let label = "";
            let displayLabel = false;
            if (hotspotObj.hotspotLabel) {
              label = hotspotObj.hotspotLabel;
              displayLabel = true;
            }
            if (hotspotObj.hotspotSvg) {
              if (!isHotspotSvg) {
                isHotspotSvg = true;
              }
              let svgObj = hotspotObj.hotspotSvg;
              let $rectEle = $('<' + svgObj.elementType + ' class="hot-spot-focus tababble ' + (displayLabel ? "show-hotspot-label" : "") + '" tabindex="' + hotspotObj.tabindex + '" aria-label="' + hotspotObj.aria_label + '"></' + svgObj.elementType + '>');
              for (const attrType in svgObj.attributeList) {
                $rectEle.attr(attrType, svgObj.attributeList[attrType]);
              }
              for (const styleType in svgObj.elementCss) {
                $rectEle.css(styleType, svgObj.elementCss[styleType]);
              }
              $hotspotSvg.append($rectEle);
            } else {
              hotspotPoint += '<div class="hot-spot-focus tababble ' + (displayLabel ? "show-hotspot-label" : "") + '" tabindex="' + hotspotObj.tabindex + '" aria-label="' + hotspotObj.aria_label + '" style="width: ' + hotspotObj.width + 'px; height: ' + hotspotObj.height + 'px; top: ' + hotspotObj.top + 'px; left: ' + hotspotObj.left + 'px; ">' + label + '</div>';
            }
          });
        });
        if (templateClass === this.quizCompleteTemplateClass) {
          this.container.html('<a href="#" class="try-again-btn hidden tababble" tabindex="1">Quiz Complete. Try again?</a>');
        } else {
          let templateCreate = '';
          templateCreate += '<div>';
          templateCreate += '<div class="question-container border-bottom">';
          templateCreate += '<p class="question tababble" tabindex="' + context.tabindex + '">' + (0, _decode.decode)(context.question) + '</p>';
          templateCreate += '</div>';
          if (context.audio_exits) {
            templateCreate += '<div style="" class="fa fa-play audio_player" tabindex="' + context.tabindex + '" aria-label="play pause" role="button"><audio src="' + context.audio_src + '"></audio></div>';
          }
          templateCreate += '<div class="feedback-container">';
          templateCreate += '<p class="feedback"  tabindex="-1"></p><a href="#" class="continue-btn hidden tababble"  tabindex="' + context.tabindex + '">Click to continue</a>';
          templateCreate += '</div>';
          templateCreate += '</div>';
          templateCreate += '<div class="clear"></div>';
          templateCreate += '<div class="image-area"><img src="">';
          if (isHotspotSvg) {
            templateCreate += $hotspotSvg.get(0).outerHTML;
          }
          templateCreate += hotspotPoint;
          templateCreate += '</div>';
          templateCreate += '<div class="clear"></div>';
          templateCreate += '<div class="question-count fr">1 of 3</div>';
          templateCreate += '<div class="clear"></div>';
          this.container.html(templateCreate);
        }
      } else {
        let templateCreate = '<div id="ember732" class="ember-view">';
        for (let i = 0; i < context.questions.length; i++) {
          let answerSet = context.questions[i].answerSets;
          this.tabindex = context.questions[i].tabindex;
          let optionData = "";
          if (answerSet.length > 0) {
            optionData = '<select tabindex="' + this.tabindex + '">';
            for (let j = 0; j < answerSet[0].answers.length; j++) {
              optionData += '<option>' + answerSet[0].answers[j] + '</option>';
            }
            optionData += '</select>';
          }
          templateCreate += '<div class="question">' + '<div class="text-line unanswered" tabindex="' + this.tabindex + '">' + context.questions[i].question + '</div>' + '<div class="answers fr">' + optionData + '</div>' + '</div>';
        }
        templateCreate += '<div class="clear"></div>' + '<div class="buttons">' + '<a href="#" class="checkAnswers button" tabindex="' + this.tabindex + '" role="button">Check Answers</a>' + '<a href="#" class="reset button" style="display: none;" tabindex="' + (this.tabindex + 1) + '" role="button">Reset Quiz</a>' + '</div>' + '<div class="feedback" tabindex="' + this.tabindex + '">' + '<span class="message"></span>' + '</div>' + '<div class="final-text"></div>' + '</div>';
        this.container.html(templateCreate);
      }
      if (typeof callback === "function") {
        callback();
      }
      return this.container;
    },
    addTemplate2: function (el, templateClass, context, callback) {},
    shuffleQuestions: function () {
      this.data = this.shuffleArray(this.data);
    },
    shuffleArray: function (arr) {
      return arr.sort(function () {
        return 0.5 - Math.random();
      });
    },
    quizComplete: function () {
      this.addTemplate(this.container, this.quizCompleteTemplateClass, this, () => {
        // send out useful info for visual reporting, sending to moodle, etc
        this.el.trigger(this.QUIZ_COMPLETE, {
          'numCorrect': this.numCorrect
        });
      });
    }
  };
  var _default = _exports.default = AbstractQuiz;
});