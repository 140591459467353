define("bocce/mixins/polls", ["exports", "bocce/utilities/dialog"], function (_exports, _dialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const descriptionCharacterLimit = 255;

  // Implement pollChoices as an ArrayProxy of EmberObjects; this simplifies bindings in the template
  /* eslint-disable-next-line ember/no-new-mixins */
  const PollChoice = Ember.Object.extend({
    init(...args) {
      this._super(...args);
      this.set('text', '');
    },
    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    remainingChars: Ember.computed('text', function () {
      (true && !(typeof this.text === 'string') && Ember.assert('PollChoice.text must be a string', typeof this.text === 'string'));
      /* eslint-disable-next-line ember/no-get */
      return descriptionCharacterLimit - this.get('text.length');
    })
  });

  /* eslint-disable-next-line ember/no-new-mixins */
  var _default = _exports.default = Ember.Mixin.create({
    init(...args) {
      this._super(...args);
      this._initializePoll();
    },
    pollDrawerOpen: false,
    _initializePoll() {
      let pollChoices = [];
      for (let i = 0; i < 2; ++i) {
        pollChoices.push(PollChoice.create());
      }
      this.set('pollChoices', Ember.ArrayProxy.create({
        content: Ember.A(pollChoices)
      }));
    },
    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    pollDescriptionLength: Ember.computed.reads('bodyInput.length'),
    remainingDescriptionChars: Ember.computed('pollDescriptionLength', function () {
      // poll description length is limited to 255 characters
      return descriptionCharacterLimit - this.pollDescriptionLength;
    }),
    pollStarted: Ember.computed(
    // NK: previously depended on text.length, which is invalid because @each only goes
    // one level deep. Since JS strings are immutable, the text value is reassigned
    // any time the input changes, so we can simply depend on .text. Also, doing it
    // this way makes Ember complain about one less thing.
    'pollChoices.@each.text', function () {
      let pollStarted = this.pollChoices.filter(item => {
        return item.get('text.length') > 0;
      });
      return pollStarted && pollStarted.get('length') > 0;
    }),
    actions: {
      togglePollsDrawer: function () {
        if (!this.pollDrawerOpen) {
          this.set('pollDrawerOpen', true);
        } else {
          (0, _dialog.default)('Are you sure you wish to delete this poll? This action CANNOT be undone!', ['Yes', 'No']).then(choice => {
            if (choice === 'Yes') {
              this.set('pollDrawerOpen', false);
              this.send('resetPoll');
            }
          });
        }
      },
      minmizePollsDrawer: function () {
        this.set('pollDrawerOpen', false);
      },
      addPollEntries: function () {
        this.pollChoices.pushObject(PollChoice.create());
      },
      clearPollChoice: function (choice) {
        this.pollChoices.removeAt(choice);
      },
      resetPoll() {
        this._initializePoll();
      },
      destroyEditor(...args) {
        this._super(...args);
        this.send('resetPoll');
      }
    }
  });
});