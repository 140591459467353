define("bocce/mixins/interactions/embedder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* jshint unused: false */

  function Embedder($el, data) {
    this.$el = $el;
    this.data = data.config;
    this.content = this.$el.find('.interaction_content');
    this.height = this.content.css('min-height');
    this.width = this.content.css('width');
    this.content.css('width', '').css('min-height', '');
    if (this.data.embed.indexOf('<iframe') > -1) {
      this.iframeData = this.data.embed;
    } else {
      this.iframeData = `<iframe allow="camera *;microphone *" width="${this.width}" height="${this.height}" src="${this.data.embed}" title="${this.data.embedType}">`;
    }
  }
  Embedder.prototype = {
    init: function () {
      let html = '<div class="embedder">' + this.iframeData + '</div>';
      this.content.html(html);
    }
  };
  var _default = _exports.default = Embedder;
});