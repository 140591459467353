define("bocce/services/attachment-manager", ["exports", "sanitize-html"], function (_exports, _sanitizeHtml) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let AttachmentManager = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._action, (_class = class AttachmentManager extends Ember.Service {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "session", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
      _initializerDefineProperty(this, "attachmentContainerActive", _descriptor3, this);
      _initializerDefineProperty(this, "attachmentsContainerMode", _descriptor4, this);
      _initializerDefineProperty(this, "attachments", _descriptor5, this);
      _initializerDefineProperty(this, "attachmentsReady", _descriptor6, this);
    }
    clearAllFiles() {
      this.attachments = [];
      this.attachmentsReady = true;
    }
    sanitize(string) {
      const sanitizeHtmlOptions = {
        // options for the sanitize-html package. see docs:
        // https://www.npmjs.com/package/sanitize-html#htmlparser2-options
        allowedTags: _sanitizeHtml.default.defaults.allowedTags.concat(['h2', 'u']),
        transformTags: {
          'h1': 'h2',
          // Word's header is h1, but our header is h2
          'a': function (tagName, attribs) {
            // filter out useless links because apparently the geniuses
            // at Microsoft decided to start including THOSE now
            if (!attribs['href'] || attribs['href'] === '') {
              return false;
            } else {
              return {
                tagName: tagName,
                attribs: {
                  href: attribs.href
                }
              };
            }
          }
        }
      };
      return (0, _sanitizeHtml.default)(string, sanitizeHtmlOptions);
    }
    generateVideoEmbedString(files) {
      var embeds = files.filterBy('deleted', false).filterBy('valid', true),
        string = '',
        i;
      for (i = 0; i < embeds.length; i++) {
        if (embeds[i].file.isUrl) {
          string += '<div class=\'video-container\'>';
          string += '<video class=\'post-video-embed\' preload=\'none\' controls controlsList=\'nodownload\'>';
          string += '<source src=\'' + embeds[i].file.url + '\' type=\'' + embeds[i].file.type + '\'>';
          string += '</video>';
          string += '<div class="sub-time"><i class="far fa-clock"></i> ' + embeds[i].file.created_at_formatted + '</div>';
          string += '</div>';
        }
      }
      return string;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "attachmentContainerActive", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "attachmentsContainerMode", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "attachments", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "attachmentsReady", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "clearAllFiles", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "clearAllFiles"), _class.prototype)), _class));
});