define("bocce/mixins/interactions/simple_slideshow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function TableRowContent(src, $stage) {
    /* eslint-disable-next-line ember/no-jquery */
    var $src = Ember.$(src).detach().children();
    /* eslint-disable-next-line ember/no-jquery */
    this.$el = Ember.$('<div>').append($src);
    this.$el.appendTo($stage);
  }
  function InteractionComponent(src, $stage) {
    /* eslint-disable-next-line ember/no-jquery */
    this.$el = Ember.$(src).find('.interaction_content');
    this.$el.appendTo($stage);
  }
  function SimpleSlideshow($el) {
    this.$el = $el;

    // grab :first because this interaction can have many nested interactions
    this.$container = this.$el.find('.interaction_content:first');

    // add autosizing class. this will be removed later if the
    // slide show contains Interaction Components
    this.$container.addClass('autosize');
    this.currentIndex = -1;
    this.addTemplate();
    this.cacheEls();
    this.initButtons();
    this.data = this.getData(this.$el, this.$container, this.$stage);
  }
  SimpleSlideshow.prototype = {
    init: function () {
      return this.nextSlide();
    },
    getData: function ($el, $container, $stage) {
      var divs = $el.find('.interaction_data:first > div');
      var data = [];

      /* eslint-disable-next-line ember/no-jquery */
      Ember.$(divs).each(function () {
        var $item, isTable, isInteraction;

        /* eslint-disable-next-line ember/no-jquery */
        $item = Ember.$(this);
        isTable = $item.hasClass('table-component');
        isInteraction = $item.find('.interaction_content').length > 0;
        if (isTable) {
          // pull out all td's from the table
          $item.find('td').each(function () {
            /* eslint-disable-next-line ember/no-jquery */
            var $td = Ember.$(this);
            data.push(new TableRowContent($td, $stage));
          });
        } else if (isInteraction) {
          data.push(new InteractionComponent($item, $stage));

          // Simple Slideshows with interactions need to be manually sized
          $container.removeClass('autosize');
        } else {
          throw 'Unsupported Content! This interaction accepts only table components and interaction components';
        }
      });
      return data;
    },
    addTemplate: function () {
      var html, template;
      html = '<div class="nav"><button class="prev" aria-label="Previous"><i class="fas fa-arrow-circle-left"> </i></button><span class="count">1 of 1</span><button class="next" aria-label="Next"><i class="fas fa-arrow-circle-right"></i></button></div><div class="stage scrollbar-mobile"></div>';
      /* eslint-disable-next-line ember/no-jquery */
      template = Ember.$(html).appendTo(this.$container);
      return template;
    },
    cacheEls: function () {
      this.$stage = this.$el.find('.stage:first');
      this.$count = this.$el.find('.count:first');
    },
    initButtons: function () {
      var $prevBtn, $nextBtn;
      $prevBtn = this.$el.find('.prev');
      $nextBtn = this.$el.find('.next');

      // use proxy to bind correct 'this' to handlers
      /* eslint-disable-next-line ember/no-jquery */
      $prevBtn.click(Ember.$.proxy(this.prevSlide, this));
      /* eslint-disable-next-line ember/no-jquery */
      $nextBtn.click(Ember.$.proxy(this.nextSlide, this));
    },
    prevSlide: function (e) {
      if (e) {
        e.preventDefault();
      }
      this.currentIndex--;
      if (this.currentIndex < 0) {
        this.currentIndex = this.data.length - 1;
      }
      this.loadSlide(this.currentIndex);
    },
    nextSlide: function (e) {
      if (e) {
        e.preventDefault();
      }
      this.currentIndex++;
      if (this.currentIndex >= this.data.length) {
        this.currentIndex = 0;
      }
      this.loadSlide(this.currentIndex);
    },
    updateSlideCount: function (index, total) {
      var current;
      current = index + 1;
      this.$count.text(current + ' of ' + total);
    },
    loadSlide: function (index) {
      var slide = this.data[index];
      this.$stage.find('video, audio').get().forEach(e => {
        // find media elements in the slide and stop them
        e.pause();
      });
      this.$stage.children().hide();
      slide.$el.show();
      this.updateSlideCount(index, this.data.length);
    }
  };
  var _default = _exports.default = SimpleSlideshow;
});