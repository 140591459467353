define("bocce/templates/components/quiz/submitted-in", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "zCPdoG4z",
    "block": "{\"symbols\":[\"@quizzes\"],\"statements\":[[6,[37,0],[[32,1,[\"quizSubmittedInText\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[15,0,[31,[\"submitted-in-group submitted-in \",[30,[36,0],[[32,1,[\"quizSubmittedInText\"]],\"animate\"],null]]]],[12],[2,\"\\n        \"],[10,\"span\"],[14,0,\"submitted-in-time\"],[12],[2,\"\\n            \"],[1,[32,1,[\"quizSubmittedInText\"]]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[11,\"span\"],[24,0,\"submitted-in-close\"],[4,[38,2],[\"click\",[30,[36,1],[[32,1,[\"closeTimeRemaining\"]]],null]],null],[12],[10,\"i\"],[14,0,\"fas fa-times fa-fw\"],[12],[13],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"fn\",\"on\"]}",
    "meta": {
      "moduleName": "bocce/templates/components/quiz/submitted-in.hbs"
    }
  });
});