define("bocce/helpers/format-grade", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatGrade = formatGrade;
  function formatGrade(gradeScore) {
    if (isNaN(gradeScore) || !gradeScore) {
      return gradeScore;
    }
    gradeScore = Number(gradeScore);
    let decPlaces = 0;
    if (Math.floor(gradeScore) !== gradeScore) {
      decPlaces = gradeScore.toString().split(".")[1].length || 0;
    }
    if (decPlaces > 4) {
      return gradeScore.toFixed(4) + "...";
    } else {
      return gradeScore;
    }
  }
  var _default = _exports.default = Ember.Helper.helper(fullGrade => {
    let grade = fullGrade[0];
    return formatGrade(grade);
  });
});