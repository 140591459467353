define("bocce/mixins/interactions/list_rollover", ["exports", "bocce/mixins/support/render-template"], function (_exports, _renderTemplate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function ListRollover($el) {
    this.$el = $el;
    this.$container = $el.find('.interaction_content');
  }
  var _default = _exports.default = ListRollover;
  ListRollover.prototype = {
    rollovers: [],
    init: function () {
      this.rollovers = this.createRolloversFromTable();
      this.detectAutosize();
      return (0, _renderTemplate.default)('list_rollover', 'main', {
        'rollovers': this.rollovers
      }).then(content => {
        this.$container.append(content);
        this.setRolloverEls(this.rollovers);
        this.addBgImage();
      });
    },
    createRolloversFromTable: function () {
      var $rows = this.$el.find('table:first tr'),
        rollovers = [];
      $rows.each(function (i) {
        /* eslint-disable-next-line ember/no-jquery */
        var $cells = Ember.$(this).find('td');
        // skip top header row
        if (i === 0) {
          return;
        }
        rollovers.push(new Rollover(i,
        // id
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$($cells[0]).html(),
        // content
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$($cells[1]).html(),
        // link
        /* eslint-disable-next-line ember/no-jquery */
        parseInt(Ember.$($cells[2]).text()),
        // x
        /* eslint-disable-next-line ember/no-jquery */
        parseInt(Ember.$($cells[3]).text()) // y
        ));
      });
      return rollovers;
    },
    detectAutosize: function () {
      // User can add a no-autosize class to interaction to
      // prevent the interaction from autosizing
      var $component = this.$el.closest('.interaction_component, .interaction-component');
      if (!$component.hasClass('no-autosize')) {
        $component.addClass('autosize');
      }
    },
    setRolloverEls: function (rollovers) {
      // link newly created els with context objects
      /* eslint-disable-next-line ember/no-jquery */
      Ember.$(rollovers).each(Ember.$.proxy(function (i, rollover) {
        rollover.setLinkEl(this.$el.find('.rollover_link')[i]);
        rollover.setContentEl(this.$el.find('.rollover_content')[i]);
      }, this));
    },
    addBgImage: function () {
      // The bg image is the first image the user places in the interaction area
      var $img = this.$el.find('.interaction_data img:first');
      $img.appendTo(this.$el.find('.bg_image'));
    }
  };

  // Rollover Class
  function Rollover(id, link, content, x, y) {
    this.id = id;
    this.link = link;
    this.content = content;
    this.x = (x || 0) + 'px';
    this.y = (y || 0) + 'px';
  }
  Rollover.prototype = {
    // these need to be set after they're added
    // to the template
    $linkEl: undefined,
    $contentEl: undefined,
    // link up template elements to object
    setLinkEl: function (el) {
      let counter = 0;

      /* eslint-disable-next-line ember/no-jquery */
      this.$linkEl = Ember.$(el);
      this.$linkEl
      /* eslint-disable-next-line ember/no-jquery */.mouseover(Ember.$.proxy(this.onLinkOver, this))
      /* eslint-disable-next-line ember/no-jquery */.mouseout(Ember.$.proxy(this.onLinkOut, this)).click(e => {
        counter++;
        if (counter % 2 === 1) {
          this.onLinkOver(e);
        } else {
          this.onLinkOut(e);
        }
      });
    },
    setContentEl: function (el) {
      /* eslint-disable-next-line ember/no-jquery */
      this.$contentEl = Ember.$(el);
    },
    onLinkOver: function (e) {
      e.preventDefault();
      this.$contentEl.show();
    },
    onLinkOut: function (e) {
      e.preventDefault();
      this.$contentEl.hide();
    }
  };
});