define("bocce/routes/classroom/lessons/submission-new", ["exports", "bocce/routes/classroom/lessons/modal-base"], function (_exports, _modalBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _modalBase.default.extend({
    setupController(controller, model) {
      this._super(controller, model);
      // Temp fix: currently the controller looks for 'activeAssignment'
      // when it should be looking for 'model'.
      controller.set('activeAssignment', model);
      controller.set('instructionsBooted', false);
    }
  });
});