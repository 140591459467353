define("bocce/components/legacy-boot", ["exports", "bocce/mixins/boot"], function (_exports, _boot) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable-next-line ember/no-mixins */
  var _default = _exports.default = Ember.Component.extend(_boot.default, {
    didInsertElement() {
      this.bootContent();
    },
    bootContent() {
      /* eslint-disable-next-line ember/no-jquery */
      let node = Ember.$(this.element).find('.bootable-area');
      if (node && node.length) {
        return this.boot_area(node, false, true, false, false, true);
      } else {
        return null;
      }
    }
  });
});