define("bocce/components/inbox/message-tile", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let MessageTile = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.computed('conversation', 'conversation.messages.[]', 'conversation.messages.length'), _dec3 = Ember.computed('conversation', 'conversation.message_count'), _dec4 = Ember.computed('conversation', 'conversation.conversationPartners.[]'), _dec5 = Ember.computed('args.conversation.conversationPartners.[]'), _dec6 = Ember.computed('this.args.conversation', 'this.args.conversation.isRead'), _dec7 = Ember.computed('this.args.conversation', 'this.args.conversation.conversationPartners'), _dec8 = Ember._action, (_class = class MessageTile extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "store", _descriptor, this);
    }
    get isValidMessage() {
      return this.args.conversation.conversationPartners.length > 0;
    }
    get replyCount() {
      let replyCount = this.args.conversation.message_count ? this.args.conversation.message_count - 1 : 0;
      if (replyCount > 0) {
        return replyCount;
      }
      return false;
    }
    get threePlusParticipants() {
      let allParticipants = this.args.conversation ? this.args.conversation.conversationPartners.toArray() : [];
      let totalParticipants = allParticipants.length;
      if (totalParticipants > 2) {
        return true;
      }
    }
    get enrichedConversationPartners() {
      return this.args.conversation.conversationPartners.map((partner, index, partners) => {
        return {
          ...partner,
          isLast: index === partners.length - 1
        };
      });
    }
    get conversationRead() {
      return this.args.conversation ? this.args.conversation.isRead : false;
    }
    get conversationPartners() {
      let allParticipants = this.args.conversation ? this.args.conversation.conversationPartners.toArray() : [];
      let totalParticipants = allParticipants.length;
      allParticipants[totalParticipants - 1].isLast = true;
      if (totalParticipants <= 2) {
        return allParticipants;
      }

      // Only keep the first 2 participants
      let partners = allParticipants.slice(0, 2);
      let moreCount = allParticipants.length - 2;
      let placeholderObject = {
        isPlaceholder: true,
        moreCount: moreCount
      };
      partners.push(placeholderObject);
      return partners;
    }
    viewConversation(conversation) {
      let isNew = this.args.conversation.isNew;
      if (isNew) {
        this.args.updateSelectedConversation(false, true, conversation);
      } else {
        this.args.updateSelectedConversation(conversation.id);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isValidMessage", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "isValidMessage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "replyCount", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "replyCount"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "threePlusParticipants", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "threePlusParticipants"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "enrichedConversationPartners", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "enrichedConversationPartners"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "conversationRead", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "conversationRead"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "conversationPartners", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "conversationPartners"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "viewConversation", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "viewConversation"), _class.prototype)), _class));
});