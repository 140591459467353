define("bocce/models/quiz_question", ["exports", "@ember-data/model", "bocce/mixins/quiz-question"], function (_exports, _model, _quizQuestion) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // app/models/quiz_question.js
  var _default = _exports.default = _model.default.extend(_quizQuestion.default, {
    position: (0, _model.attr)('number'),
    text: (0, _model.attr)('string'),
    points: (0, _model.attr)('number'),
    points_received: (0, _model.attr)('number'),
    correct_feedback: (0, _model.attr)('string'),
    wrong_feedback: (0, _model.attr)('string'),
    type: (0, _model.attr)('string'),
    given_answer: (0, _model.attr)(),
    correct_answer: (0, _model.attr)(),
    answers: (0, _model.attr)(),
    attempt_data: (0, _model.attr)(),
    checked: (0, _model.attr)('boolean'),
    multichoice: (0, _model.attr)('boolean'),
    correct: (0, _model.attr)(),
    //The quizzes 'service'
    quizzes: (0, _model.attr)(),
    component: Ember.computed('type', function () {
      return `quiz-questions/${this.get('type')}`;
    })
  });
});