define("bocce/mixins/routable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  if (!window.bocce) {
    window.bocce = {};
  }
  window.bocce.lastRoutes = [];

  /* eslint-disable-next-line ember/no-new-mixins */
  var _default = _exports.default = Ember.Mixin.create({
    setupController: function () {
      this._super.apply(this, arguments);
      window.bocce.lastRoutes.push(this.routeName);
    },
    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    defaultPanel: Ember.computed(function () {
      let panl = 'syllabus',
        cfg = this.panelConfig;
      let urlParam = this._router.currentURL.split("?start_panel=");
      if (urlParam.length > 1) {
        return urlParam[1];
      }
      if (cfg) {
        /* eslint-disable-next-line ember/no-get */
        if (this.get('session.isInstructor')) {
          panl = cfg.instructor;
        } else {
          panl = cfg.withinApp;
          if (window.bocce.lastRoutes >= 1) {
            panl = cfg.fromOutside;
          }
        }
      }
      return panl;
    })
  });
});