define("bocce/routes/course", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    afterModel(course) {
      let id = course.get('id');
      let code = course.get('code');
      let section = course.get('sections.firstObject.id');
      let path = `/${id}/${code}/${section}`;
      this.transitionTo(path);
    }
  });
});