define("bocce/routes/classroom/lessons/discussion", ["exports", "bocce/routes/classroom/lessons/modal-base"], function (_exports, _modalBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _modalBase.default.extend({
    beforeModel() {
      this._super();
      let surveyIncomplete = this.session.get('courseConcludedNoSurvey');
      if (surveyIncomplete) {
        this.transitionTo('classroom.lessons.survey', {
          queryParams: {
            concluded: true
          }
        });
      }
    },
    afterModel(model) {
      model.set('unread_count', 0);
      model.set('read', true);
    }
  });
});