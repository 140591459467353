define("bocce/templates/audio-rec", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "uM7A80Rd",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"audio-rec-panel standby\"],[12],[2,\"\\n  \"],[10,\"i\"],[14,0,\"fas fa-spinner fa-spin\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n  \"],[11,\"i\"],[24,\"role\",\"button\"],[24,0,\"fas fa-circle active\"],[24,\"aria-label\",\"Record Audio\"],[4,[38,0],[[32,0],\"audioRecRecord\"],null],[12],[13],[2,\"\\n  \"],[11,\"i\"],[24,\"role\",\"button\"],[24,0,\"fas fa-stop stop-recording\"],[24,\"aria-label\",\"Stop Recording Audio\"],[4,[38,0],[[32,0],\"audioRecStop\"],null],[12],[13],[2,\"\\n  \"],[11,\"i\"],[24,\"role\",\"button\"],[24,0,\"fas fa-stop stop-playback\"],[24,\"aria-label\",\"Stop Audio Playback\"],[4,[38,0],[[32,0],\"audioRecPrevStop\"],null],[12],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"time-code\"],[14,\"time_code\",\"00:00\"],[12],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"audio-rec-status\"],[12],[13],[2,\"\\n\\n  \"],[3,\" iOS-style Toggle for Echo Cancellation \"],[2,\"\\n  \"],[10,\"div\"],[14,0,\"toggle-container\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,0,\"toggle-label tooltip light\"],[14,\"tooltip\",\"Echo Cancellation helps remove the echo effect when recording audio. This can improve the audio quality in noisy environments, but it is not recommended for general use because it also tends to remove instrumental background audio.\"],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fas fa-info-circle\"],[12],[13],[2,\" Echo Cancellation\\n    \"],[13],[2,\"\\n    \"],[11,\"button\"],[24,1,\"echoCancellationToggleAudio\"],[16,0,[31,[\"slide-toggle \",[30,[36,2],[[35,1],\"on\",\"off\"],null]]]],[4,[38,0],[[32,0],\"echoCancellationToggleAudio\"],null],[12],[2,\"\\n      \"],[10,\"span\"],[14,0,\"slider round\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"echoCancellationEnabled\",\"if\"]}",
    "meta": {
      "moduleName": "bocce/templates/audio-rec.hbs"
    }
  });
});