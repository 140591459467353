define("bocce/routes/dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    session: Ember.inject.service(),
    gainsight: Ember.inject.service(),
    beforeModel: function () {
      this.gainsight.renderIdentifyGainsightPxTag(new Date());
      this.gainsight.renderGlobalContextGainsightPxTag(new Date());
    },
    model: function () {
      return this.store.findAll('dashboard');
    },
    renderTemplate: function () {
      this.render(); // Render primary template
    }
  });
});