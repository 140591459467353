define("bocce/templates/components/quiz/previous-questions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "rXyIq8Md",
    "block": "{\"symbols\":[\"data\",\"i\"],\"statements\":[[10,\"select\"],[15,\"disabled\",[32,0,[\"dropdownDisabled\"]]],[15,\"onchange\",[30,[36,2],[[32,0],\"setQuestionIndex\"],null]],[14,0,\"previous-questions\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,3],[[32,0,[\"quizzes\",\"questionIndex\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"option\"],[14,\"selected\",\"true\"],[12],[2,\"---Review Question---\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[35,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"option\"],[15,2,[32,2]],[15,\"received\",[32,1,[\"question\",\"points_received\"]]],[15,\"possible\",[32,1,[\"question\",\"points\"]]],[15,\"disabled\",[32,1,[\"disabled\"]]],[15,\"selected\",[30,[36,0],[[32,2],[32,0,[\"quizzes\",\"questionIndex\"]]],null]],[15,0,[31,[\"previous-question-index \",[32,1,[\"question\",\"correct\"]],\" \",[30,[36,1],[[32,1,[\"disabled\"]],\"disabled\"],null]]]],[14,4,\"button\"],[12],[2,\"\\n            \"],[1,[32,1,[\"text\"]]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[13]],\"hasEval\":false,\"upvars\":[\"same\",\"if\",\"action\",\"isnull\",\"questionData\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "bocce/templates/components/quiz/previous-questions.hbs"
    }
  });
});