define("bocce/helpers/epoch-to-dayhr", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // app/helpers/epoch-to-dayhr.js
  // Convert epoch time to days and hours
  var _default = _exports.default = Ember.Helper.helper(function (item) {
    if (!item) {
      return false;
    }
    // Convert seconds to hours/days in string form (e.g. 1d 2h)
    let days = Math.floor(item / 86400);
    let hours = Math.floor(item % 86400 / 3600);
    let dayString = days > 0 ? days + 'd ' : '';
    let hourString = hours > 0 ? hours + 'h' : '';
    return dayString + hourString;
  });
});