define("bocce/mixins/calendar-events", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable-next-line ember/no-new-mixins */
  var _default = _exports.default = Ember.Mixin.create({
    actions: {
      addCalendarEvent: function (itemName, itemDate_arg) {
        var url_chunk = 'https://www.google.com/calendar/render?action=TEMPLATE&text=' + itemName + '&location=Berklee+online&calendar-label=Discussion&dates=',
          itemDate = new Date(itemDate_arg.valueOf() + itemDate_arg.getTimezoneOffset() * 60000),
          yyyy = itemDate.getFullYear().toString(),
          MM = ('0' + (itemDate.getMonth() + 1).toString()).slice(-2),
          dd = ('0' + itemDate.getDate().toString()).slice(-2),
          hh = ('0' + itemDate.getHours()).slice(-2),
          mm = ('0' + itemDate.getMinutes()).slice(-2),
          ss = ('0' + itemDate.getSeconds()).slice(-2),
          formattedDate_init = yyyy + MM + dd + 'T' + hh + mm + ss + 'Z',
          formattedDate = formattedDate_init + '/' + formattedDate_init,
          url = url_chunk + formattedDate + '&details=' + encodeURIComponent('https://canvas.online-dev.berklee.edu/' + event.target.closest('a').getAttribute('href'));
        window.open(url, '_blank');
      }
    }
  });
});