define("bocce/mixins/interactions/rotatable", [], function () {
  "use strict";

  (function ($) {
    var methods = {
      init: function (options) {
        /* eslint-disable-next-line ember/no-jquery, ember/no-new-mixins */
        var settings = $.extend({
          'handleEl': this,
          'keepChildrenUpright': false,
          'snapAngle': undefined,
          'rotationMin': undefined,
          'rotationMax': undefined,
          'onMove': undefined,
          'disableDrag': false
        }, options);
        return this.each(function () {
          /* eslint-disable-next-line ember/no-jquery */
          var $el = $(this),
            lastAngleOfPrevMove = 0,
            firstAngleOfMove,
            prevAngle = 0,
            center = {
              'x': $el.offset().top + $el.width() / 2,
              'y': $el.offset().left + $el.height() / 2
            },
            data,
            onMouseMove,
            setIsClockwise,
            onMouseUp,
            checkConstraints,
            snapToGrid;
          $el.data('rotatable', {
            '$el': $el,
            '$handleEl': settings.handleEl,
            'angle': 0,
            '$children': $el.children(),
            'settings': settings,
            'isClockwise': true,
            'methods': methods
          });

          // pin all vars to el data so they can be accessed
          // across methods
          data = $el.data('rotatable');

          ///////////////////////
          // "private" methods //
          ///////////////////////

          data.$handleEl.mousedown(function () {
            /* eslint-disable-next-line ember/no-jquery */
            $(document).mouseup(onMouseUp);
            /* eslint-disable-next-line ember/no-jquery */
            $(document).mousemove(onMouseMove);
          });
          if (settings.disableDrag) {
            data.$handleEl.unbind('mousedown');
          }
          onMouseUp = function () {
            /* eslint-disable-next-line ember/no-jquery */
            $(document).unbind('mouseup', onMouseUp);
            /* eslint-disable-next-line ember/no-jquery */
            $(document).unbind('mousemove', onMouseMove);
            // keep track of where we left off so the rotatable doesn't
            // jump on next rotation
            lastAngleOfPrevMove = data.angle;
            firstAngleOfMove = undefined;
          };
          onMouseMove = function (e) {
            var angle, newAngle, pos;

            // convert mouse position from center of $el into a useful angle
            pos = Math.atan2(e.pageY - center.y, e.pageX - center.x);
            angle = pos / Math.PI * 180;
            angle = Math.round(angle);

            // keep angle between 0 and 360
            if (angle < 0) {
              angle += 360;
            }

            // no need to execute the rest if the angles are the same
            if (angle === prevAngle) {
              return;
            }

            // store the rotation direction
            setIsClockwise(prevAngle, angle);

            // store previous angle for comparison
            prevAngle = angle;

            // snap angle to grid
            newAngle = data.settings.snapAngle ? snapToGrid(angle, data.settings.snapAngle) : angle;

            // save the value of the first angle change of the move
            if (firstAngleOfMove === undefined) {
              firstAngleOfMove = newAngle;
            }

            // prevent the drag from jumping to a weird value by
            // factoring in the start angle
            newAngle -= -lastAngleOfPrevMove + firstAngleOfMove;

            // keep newAngle between 0 and 360
            if (newAngle < 0) {
              newAngle += 360;
            }
            if (newAngle > 360) {
              newAngle -= 360;
            }

            // make sure that angle is within user specified constraints
            newAngle = checkConstraints(newAngle);
            data.angle = newAngle;
            data.methods.updateEls($el, data.angle);
          };
          checkConstraints = function (angle) {
            // keep angle between the min and max values specificed
            if (!data.settings.rotationMin && !data.settings.rotationMax) {
              return angle;
            }

            // TODO: WOW, what a birdsnest of a mess this is... this needs
            //       cleanup ASAP. Since I decided to forego support for negative
            //       angles, this code allows for detection of rotation mins that
            //       start in the III/IV quadrant and have a max in I/II
            if (data.settings.rotationMin > data.settings.rotationMax) {
              if (angle >= data.settings.rotationMax && angle <= data.settings.rotationMin) {
                if (data.isClockwise) {
                  if (angle !== data.settings.rotationMin) {
                    angle = data.settings.rotationMax;
                  }
                } else {
                  if (angle !== data.settings.rotationMax) {
                    angle = data.settings.rotationMin;
                  }
                }
              }
            } else {
              if (angle <= data.settings.rotationMin) {
                angle = data.settings.rotationMin;
              } else if (angle >= data.settings.rotationMax) {
                angle = data.settings.rotationMax;
              }
            }
            return angle;
          };
          setIsClockwise = function (prevAngle, angle) {
            // determine if the user is rotating clockwise or counterclockwise
            var isIncreasing = angle > prevAngle,
              isCrossingZeroCW = data.isClockwise && angle < 180 && prevAngle > 180,
              isCrossingZeroCCW = !data.isClockwise && angle > 180 && prevAngle < 180,
              // clockwise = true, counterclockwise = false
              isClockwise = (isIncreasing || isCrossingZeroCW) && !isCrossingZeroCCW;
            data.isClockwise = isClockwise;
          };
          snapToGrid = function (value, gridSize) {
            return gridSize * Math.round(value / gridSize);
          };
        });
      },
      // pass in true if positive vs. negative
      // rotation matters in your situation
      getAngle: function (showDirection) {
        var data = this.data('rotatable');
        var angle = data.angle;
        if (!showDirection && showDirection !== undefined) {
          if (!data.isClockwise) {
            angle -= 360;
          }
        }
        return angle;
      },
      setAngle: function (angle, triggerOnMove) {
        /* eslint-disable-next-line ember/no-jquery */
        var data = $(this).data('rotatable');
        /* eslint-disable-next-line ember/no-jquery */
        data.methods.updateEls($(this), angle, triggerOnMove);
      },
      updateEls: function ($el, angle, triggerOnMove) {
        var data = $el.data('rotatable');

        // update css
        var css = this.getRotationCSS(angle);
        data.$handleEl.css(css);

        // update the $el too if it's not the same as the handle
        if (data.$handleEl !== data.$el) {
          data.$el.css(css);
        }

        // optional items
        if (data.settings.keepChildrenUpright) {
          this.uprightChildren($el, angle);
        }
        if (data.settings.onMove && triggerOnMove !== false) {
          data.settings.onMove.call(this, angle);
        }
        data.angle = angle;
      },
      uprightChildren: function ($el, angle) {
        var data = $el.data('rotatable');
        // invert angle to maintain child orientation when parent rotates
        data.$children.each(function () {
          /* eslint-disable-next-line ember/no-jquery */
          $(this).css(data.methods.getRotationCSS(-angle));
        });
      },
      getRotationCSS: function (angle) {
        var angleStr = 'rotate(' + angle + 'deg)';
        return {
          '-moz-transform': angleStr,
          '-webkit-transform': angleStr,
          '-ms-transform': angleStr,
          '-o-transform': angleStr
        };
      }
    };

    /* eslint-disable-next-line ember/no-jquery */
    $.fn.rotatable = function (method) {
      if (methods[method]) {
        return methods[method].apply(this, Array.prototype.slice.call(arguments, 1));
      } else if (typeof method === 'object' || !method) {
        return methods.init.apply(this, arguments);
      } else {
        /* eslint-disable-next-line ember/no-jquery */
        $.error('Method ' + method + ' does not exist on jQuery.rotatable');
      }
    };
  })(Ember.$);
});