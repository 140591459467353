define("bocce/components/buttons/submit-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: 'button',
    classNames: ['editor-save-btn'],
    classNameBindings: ['active'],
    attributeBindings: ['ariaLabel:aria-label', 'ariaDisabled:aria-disabled'],
    ariaLabel: Ember.computed('active', 'activeLabel', 'inactiveLabel', function () {
      if (this.active) {
        return this.activeLabel;
      } else {
        return this.inactiveLabel;
      }
    }),
    ariaDisabled: Ember.computed.not('active'),
    click() {
      if (this.active) {
        this.action();
      }
    }
  });
});