define("bocce/controllers/lobby", ["exports", "bocce/mixins/prefs", "bocce/mixins/menus", "bocce/utilities/logout"], function (_exports, _prefs, _menus, _logout) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  var _default = _exports.default = Ember.Controller.extend(_prefs.default, _menus.default, {
    sortedCourses: Ember.computed.sort('model', function (a, b) {
      let as = a.get('start_at'),
        bs = b.get('start_at');
      if (as > bs) {
        return -1;
      } else if (as < bs) {
        return 1;
      } else if (a.get('section') < b.get('section')) {
        return -1;
      } else {
        return 1;
      }
    }),
    current: Ember.computed('sortedCourses', function () {
      let model = this.sortedCourses;
      let current = model.filter(function (itm) {
        return itm.get('current');
      });
      return current;
    }),
    past: Ember.computed('sortedCourses', function () {
      let model = this.sortedCourses;
      let past = model.filter(function (itm) {
        return !itm.get('current');
      });
      return past;
    }),
    loadConversations: function () {
      let conversationsLoaded = this.conversationsLoaded || false,
        conversationsController = this.conversations;
      if (!conversationsLoaded) {
        return this.store.findAll('conversation').then(conversations => {
          conversationsController.set('model', conversations);
          this.set('conversationsLoaded', true);
        });
      }
    },
    actions: {
      toggleMessagePanel: function (open) {
        if (open) {
          this.set('messagePanelOn', true);
        } else {
          this.set('messagePanelOn', false);
        }
      },
      logout: function () {
        (0, _logout.default)();
      }
    }
  });
});