define("bocce/mixins/interactions/abstract_quiz", ["exports", "bocce/mixins/support/render-template"], function (_exports, _renderTemplate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function AbstractQuiz($el, data, templateGroup) {
    // constants
    this.SHOW_QUESTION = 'ShowQuestion';
    this.CORRECT = 'Correct';
    this.INCORRECT = 'Incorrect';
    this.QUIZ_COMPLETE = 'QuizComplete';
    this.el = $el; // all events should be triggered off of this
    this.interactionData = $el.find('.interaction_data');
    this.container = $el.find('.interaction_content');
    this.templateGroup = templateGroup;

    // feel free to override these
    this.questionTemplateClass = 'main';
    this.quizCompleteTemplateClass = 'quiz_complete';
    this.maxAttempts = 3;

    // saved data
    this.data = data;
    /* eslint-disable-next-line ember/no-jquery */
    this.json = Ember.$.parseJSON(this.data.initial_json || '[]');
  }
  AbstractQuiz.prototype = {
    currentIndex: 0,
    numAttempts: 0,
    numCorrect: 0,
    numAnswers: 0,
    answerCorrect: function (numRemaining) {
      numRemaining = numRemaining || 0;
      this.el.trigger(this.CORRECT, {
        'numRemaining': numRemaining
      });
    },
    answerIncorrect: function () {
      this.numAttempts++;
      this.el.trigger(this.INCORRECT);
    },
    checkAnswer: function () {
      // provide custom answer validation
    },
    currentQuestion: {},
    getCurrentQuestion: function () {
      // return a deep copy of the current question
      /* eslint-disable-next-line ember/no-jquery, ember/no-new-mixins */
      return Ember.$.extend(true, {}, this.json[this.currentIndex]);
    },
    nextQuestion: function (e) {
      if (e) {
        e.preventDefault();
      }
      this.currentIndex++;

      // check to see if we're done
      if (this.currentIndex === this.json.length) {
        this.quizComplete();
      } else {
        this.showQuestion(this.currentIndex);
      }
      return false;
    },
    resetQuiz: function () {
      // nextQuestion increments currentIndex so set to -1
      this.currentIndex = -1;
      this.nextQuestion();
      return false;
    },
    // this should never be called directly
    showQuestion: function () {
      // store a copy of the current question for manipulation, etc
      this.currentQuestion = this.getCurrentQuestion();
      return this.addTemplate(this.container, this.questionTemplateClass, this.currentQuestion).then(() => {
        this.el.trigger(this.SHOW_QUESTION);
      });
    },
    addTemplate: function (el, templateClass, context) {
      this.container.empty();
      return (0, _renderTemplate.default)(this.templateGroup, templateClass, context).then(content => el.append(content));
    },
    shuffleQuestions: function () {
      this.data = this.shuffleArray(this.data);
    },
    shuffleArray: function (arr) {
      return arr.sort(function () {
        return 0.5 - Math.random();
      });
    },
    quizComplete: function () {
      this.addTemplate(this.container, this.quizCompleteTemplateClass).then(() => {
        // send out useful info for visual reporting, sending to moodle, etc
        this.el.trigger(this.QUIZ_COMPLETE, {
          'numCorrect': this.numCorrect
        });
      });
    }
  };
  var _default = _exports.default = AbstractQuiz;
});