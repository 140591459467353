define("bocce/mixins/legacy-attachment-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable-next-line ember/no-new-mixins */
  var _default = _exports.default = Ember.Mixin.create({
    files: Ember.A([]),
    attachmentsToContainer: Ember.computed('files.@each.{progress,deleted,uploaded,uploading,name}', function () {
      let originalFiles = this.files;
      let clonedFiles = originalFiles.slice(0);
      return clonedFiles;
    }),
    _addFile(file, valid) {
      let extension = file.name.substr((~-file.name.lastIndexOf('.') >>> 0) + 2);
      let className = `extension-${extension}`.toLowerCase();
      let record = {
        file,
        valid,
        className,
        ignoreDownloadOnlyPrompt: file.ignoreDownloadOnlyPrompt,
        uploaded: false,
        deleted: false
      };
      this.files.pushObject(record);
      return record;
    },
    actions: {
      addValidFile(file) {
        return this._addFile(file, true);
      },
      addInvalidFile(file) {
        return this._addFile(file, false);
      },
      clearAllFiles() {
        this.setProperties({
          'files': [],
          'file_ids': [],
          'promises': []
        });

        //Not sure why adding this property to this.setProperties doesn't work.
        this.encoding_videos.clear();
      },
      deleteFile(file) {
        let fileRec = this.files.findBy('file', file);
        let ids = this.file_ids;

        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('audio, video').each(function () {
          this.pause();
        });
        ids.removeObject(ids.findBy('id', fileRec.uploaded_id));
        Ember.set(fileRec, 'deleted', true);
        return file;
      },
      undeleteFile(file) {
        let fileRec = this.files.findBy('file', file);
        Ember.set(fileRec, 'deleted', false);
        if (fileRec.uploaded_id) {
          this.file_ids.pushObject({
            'id': fileRec.uploaded_id
          });
        }
        return file;
      }
    }
  });
});