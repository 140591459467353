define("bocce/components/quiz-scenarios/quiz-single-graded-reviewing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class QuizSingleGradedReviewing extends Ember.Component {
    didInsertElement() {
      $(() => {
        if (!this.quizzes.quiz.introduction) {
          $(this.element).find('.question-index').first().css('border-top', 'none');
        }
      });
    }
  }
  _exports.default = QuizSingleGradedReviewing;
});