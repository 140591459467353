define("bocce/components/user-portrait", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    userprofileService: Ember.inject.service('userprofile'),
    tagName: 'button',
    classNames: ['portrait'],
    attributeBindings: ['ariaHidden:aria-hidden', 'ariaLabel:aria-label'],
    ariaHidden: 'false',
    ariaLabel: Ember.computed('user.name', function () {
      /* eslint-disable-next-line ember/no-get */
      return `View profile of ${this.get('user.name')}`;
    }),
    initials: Ember.computed('user.name', function () {
      /* eslint-disable-next-line ember/no-get */
      if (!this.get('user.name')) {
        return '';
      }
      let username = this.get('user.name').split(' ').map(part => part.charAt(0)).join('');
      // cut down to two initials
      return username.slice(0, 2);
    }),
    click() {
      if (!this.suppressClick && this.user) {
        let user = this.user;
        if (user.get && user.get('id')) {
          this.userprofileService.toggleUserProfile(user.get('id'));
        } else {
          this.userprofileService.toggleUserProfile(user.id);
        }
      }
    }
  });
});