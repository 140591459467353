define("bocce/mixins/interactions/list_rollover", ["exports", "bocce/mixins/support/render-template"], function (_exports, _renderTemplate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function ListRollover($element, data) {
    data = convertToJSON($element[0]);
    this.images = data.assets.images;
    if ($element.length) {
      $element.empty();
      this.el = $element;
    } else {
      this.el = Ember.$('<div id="' + data.slide_id + '" class="component interaction_component float-none clear-none interaction_booted autosize"></div>');
      Ember.$("#list-rollover-content").append(this.el);
    }
    let str = "";
    str += '<div class="interaction_content ListRollover" >';
    str += '<div class="content_area">';
    if (data.showImageCaption) {
      str += '<div class="rollover_component_caption">';
      str += '<div class="rollover_content component_caption">';
      str += '</div>';
      str += '</div>';
    }
    str += '<div class="image-wrapper">';
    str += '<div class="bg_image cf"><img src="" title="" alt=""></div>';
    str += '<div class="rollover_content rollover_content_image" >';
    str += '<img src=""  title="" alt="">';
    str += '</div>';
    str += '</div>';
    if (data.showImageDescription) {
      str += '<div class="rollover_component_description">';
      str += '<div class="rollover_content component_description">';
      str += '</div>';
      str += '</div>';
    }
    str += '</div>';
    str += '<div class="rollover_links"></div>';
    str += '</div>';
    this.el.html(str);
    this.interactionContent = this.el.find(".interaction_content");
    this.data = data;
  }
  function convertToJSON(element) {
    let baseImage = element.querySelector(".interaction_data img");
    let images = [{
      img_path: baseImage.src,
      alt: baseImage.alt
    }];
    let initialJsonData = [];
    let rows = element.querySelectorAll("table tr");
    rows.forEach((row, index) => {
      if (index > 0) {
        const data = row.querySelectorAll("td");
        initialJsonData.push({
          id: `image_${index}`,
          text: data[0].innerText,
          imageIndex: index,
          aria_lable: data[0].innerText,
          tabIndex: index,
          description: data[1].querySelector(".component_caption")?.innerText || ""
        });
        let imgChild = data[1].querySelector("img");
        images.push({
          img_path: imgChild.src,
          alt: imgChild.alt
        });
      }
    });
    return {
      "slide_id": element.id,
      "assets": {
        "audioTrack": [],
        images
      },
      "css": {
        "imageCss": {
          "width": baseImage.width,
          "height": baseImage.height
        },
        "rolloverLinksCss": {
          "width": "120px"
        },
        "descriptionTextCss": {
          "min-height": "30px"
        }
      },
      "initial_json": initialJsonData,
      "showImageCaption": false,
      "showImageDescription": true
    };
  }
  var _default = _exports.default = ListRollover;
  ListRollover.prototype = {
    init: function () {
      this.updateInteractions();
    },
    updateInteractions: function () {
      let self = this;
      // image
      let images = this.data.assets.images;
      let cssObj = this.data.css;

      // this.updateCss(this.interactionContent, cssObj.initialImageContainer);

      this.interactionContent.find(".content_area").css({
        "width": cssObj?.imageCss?.width
      });
      this.interactionContent.find(".content_area .bg_image img").attr("src", images[0].img_path).attr("alt", images[0].alt);
      this.updateCss(this.interactionContent.find(".content_area .bg_image img"), cssObj?.imageCss);
      this.updateCss(this.interactionContent.find(".rollover_content_image img"), cssObj?.imageCss);

      // rollover Text
      let $rolloverLinks = this.interactionContent.find(".rollover_links");
      let rollOverTextObjs = this.data.initial_json;
      let $currContainer = this.el;
      $rolloverLinks = this.updateCss($rolloverLinks, cssObj?.rolloverLinksCss);
      $rolloverLinks.empty().html("");
      if (self.data.showImageCaption) {
        $currContainer.find(".rollover_component_caption").css("width", cssObj?.imageCss?.width);
      }
      if (self.data.showImageDescription) {
        for (const key in cssObj.descriptionTextCss) {
          $currContainer.find(".rollover_component_description").css(key, cssObj?.descriptionTextCss[key]);
        }
      }
      let $rollover_ul = Ember.$("<ul></ul>");
      for (let i = 0; i < rollOverTextObjs.length; i++) {
        const rollOverObj = rollOverTextObjs[i];
        let $rollOver_li = Ember.$('<li></li>');
        let $rollOver_a = Ember.$('<a class="rollover_link tababble" role="button" href="javascript: return false;"  aria-label="' + rollOverObj.aria_lable + '" tabindex="' + rollOverObj.tabIndex + '"></a>');
        let $rollover_p = Ember.$('<p class="body">' + rollOverObj.text + '</p>');
        /* for (const key in rollOverObj.css) {
          $rollOver_li.css(key, rollOverObj.css[key]);
        } */
        if (!self.data.showImageCaption || !images[0].alt) {
          $currContainer.find(".rollover_component_caption").hide();
        } else {
          $currContainer.find(".rollover_component_caption").show();
        }
        if (!self.data.showImageDescription || !rollOverObj.description) {
          $currContainer.find(".rollover_component_description").hide();
        } else {
          $currContainer.find(".rollover_component_description").show();
        }
        // $rollover_p = self.updateCss($rollover_p, rollOverObj.css);
        $rollOver_a.on("mouseover focusin", function () {
          let imgIndx = rollOverObj.imageIndex;
          let currentImgObj = images[imgIndx];
          $currContainer.find(".rollover_content").find("img").attr("src", currentImgObj.img_path);
          $currContainer.find(".rollover_content").find("img").attr("alt", currentImgObj.alt);
          if (self.data.showImageCaption && currentImgObj.alt) {
            $currContainer.find(".rollover_component_caption").find(".component_caption").html("<p class='body'>" + currentImgObj.alt + "</p>");
          }
          if (self.data.showImageDescription && rollOverObj.description) {
            $currContainer.find(".rollover_component_description").find(".component_description").html("<p class='body'>" + rollOverObj.description + "</p>");
          }
          $currContainer.find(".rollover_content").show();
        }).on("mouseout focusout", function () {
          //jQuery('#div_2').fadeOut();
          $currContainer.find(".rollover_content").hide();
        });
        $rollOver_a.html($rollover_p);
        $rollOver_li.append($rollOver_a);
        $rollover_ul.append($rollOver_li);
      }
      $rolloverLinks.html($rollover_ul);
    },
    updateCss: function ($ele, cssObj) {
      if (cssObj) {
        for (const key in cssObj) {
          if ($ele.get(0).tagName === "IMG") {
            $ele.attr(key, cssObj[key]);
          }
          $ele.css(key, cssObj[key]);
        }
      }
      return $ele;
    }
  };

  // Rollover Class
  function Rollover(id, link, content, x, y) {
    this.id = id;
    this.link = link;
    this.content = content;
    this.x = (x || 0) + 'px';
    this.y = (y || 0) + 'px';
  }
  Rollover.prototype = {
    // these need to be set after they're added
    // to the template
    $linkEl: undefined,
    $contentEl: undefined,
    // link up template elements to object
    setLinkEl: function (el) {
      let counter = 0;

      /* eslint-disable-next-line ember/no-jquery */
      this.$linkEl = Ember.$(el);
      this.$linkEl
      /* eslint-disable-next-line ember/no-jquery */.mouseover(Ember.$.proxy(this.onLinkOver, this))
      /* eslint-disable-next-line ember/no-jquery */.mouseout(Ember.$.proxy(this.onLinkOut, this)).click(e => {
        counter++;
        if (counter % 2 === 1) {
          this.onLinkOver(e);
        } else {
          this.onLinkOut(e);
        }
      });
    },
    setContentEl: function (el) {
      /* eslint-disable-next-line ember/no-jquery */
      this.$contentEl = Ember.$(el);
    },
    onLinkOver: function (e) {
      e.preventDefault();
      this.$contentEl.show();
    },
    onLinkOut: function (e) {
      e.preventDefault();
      this.$contentEl.hide();
    }
  };
});