define("bocce/mixins/interactions/cycle_5", ["exports", "bocce/mixins/support/render-template", "bocce/mixins/interactions/rotatable"], function (_exports, _renderTemplate, _rotatable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function Cycle5Explorer(id, debug) {
    this.id = id;
    this.debug = debug;
    this.updateJigs = this.updateJigs.bind(this);
    this.onNoteWheelMove = this.onNoteWheelMove.bind(this);
    this.updateGrids = this.updateGrids.bind(this);
    this.checkForLydian = this.checkForLydian.bind(this);
    this.onFunctionWheelMove = this.onFunctionWheelMove.bind(this);
    /* eslint-disable-next-line ember/no-jquery */
    this.$el = Ember.$(this.id);
    this.$container = this.$el.find('.interaction_content');
    this.numRotations = 0;
    this.prevAngle = 0;
    this.continuousAngle = 0;
    this.init();
  }
  Cycle5Explorer.prototype.init = function () {
    return (0, _renderTemplate.default)('cycle_5', 'main').then(content => {
      this.$container.empty().html(content);
      this.$functionWheel = this.$el.find('#wheel #function-wheel');
      this.$functionWheelHandle = this.$el.find('#wheel #function-wheel-handle');
      this.$functionWheelLeft = this.$el.find('#wheel #function-wheel-left');
      this.$functionWheelRight = this.$el.find('#wheel #function-wheel-right');
      this.$noteWheel = this.$el.find('#wheel #note-wheel');
      this.$noteWheelHandle = this.$el.find('#wheel #note-wheel-handle');
      this.$noteWheelLeft = this.$el.find('#wheel #note-wheel-left');
      this.$noteWheelRight = this.$el.find('#wheel #note-wheel-right');
      this.$noteWheelNotes = this.$el.find('#wheel #note-wheel .note');
      this.$highlights = this.$el.find('#grids .highlight');
      this.$jigs = this.$el.find('#grids .jig');
      this.$functionWheel.rotatable({
        handleEl: this.$functionWheelHandle,
        onMove: this.onFunctionWheelMove,
        rotationMin: 330,
        rotationMax: 150,
        snapAngle: 30
      });
      this.$noteWheelHandle.rotatable({
        onMove: this.onNoteWheelMove,
        snapAngle: 30,
        disableDrag: true
      });
      this.initButtons();
      this.initNoteRings();
      this.addBackground();
      return this.updateNotes(0);
    });
  };
  Cycle5Explorer.prototype.addBackground = function () {
    if (opener) {
      /* eslint-disable-next-line ember/no-jquery */
      return Ember.$('body').addClass('Cycle5Explorer-popup-bg');
    }
  };
  Cycle5Explorer.prototype.initButtons = function () {
    var _this = this;
    this.$noteWheelLeft.click(function () {
      var angle;
      angle = _this.continuousAngle - 30;
      if (angle < -210) {
        angle = -210;
      }
      return _this.$noteWheelHandle.rotatable('setAngle', angle);
    });
    this.$noteWheelRight.click(function () {
      var angle;
      angle = _this.continuousAngle + 30;
      if (angle > 210) {
        angle = 210;
      }
      return _this.$noteWheelHandle.rotatable('setAngle', angle);
    });
    this.$functionWheelLeft.click(function () {
      var angle;
      angle = _this.$functionWheel.rotatable('getAngle') - 30;
      if (angle < -30) {
        return;
      }
      return _this.$functionWheel.rotatable('setAngle', angle);
    });
    return this.$functionWheelRight.click(function () {
      var angle;
      angle = _this.$functionWheel.rotatable('getAngle') + 30;
      if (angle < 330 && angle > 150) {
        return;
      }
      return _this.$functionWheel.rotatable('setAngle', angle);
    });
  };
  Cycle5Explorer.prototype.initNoteRings = function () {
    var innerImages, outerImages;
    innerImages = [];
    outerImages = [];
    this.$el.find('#inner-ring img').each(function () {
      /* eslint-disable-next-line ember/no-jquery */
      return innerImages.push(Ember.$(this).attr('src'));
    });
    this.$el.find('#outer-ring img').each(function () {
      /* eslint-disable-next-line ember/no-jquery */
      return outerImages.push(Ember.$(this).attr('src'));
    });
    this.innerImages = innerImages;
    this.outerImages = outerImages;
    this.lydianInnerPath = this.$el.find('img.lydian-inner').attr('src');
    this.lydianOuterPath = this.$el.find('img.lydian-outer').attr('src');
  };
  Cycle5Explorer.prototype.onFunctionWheelMove = function (angle) {
    var index;
    this.updateGrids(angle);
    index = angle / 30;
    this.updateWheelImages(index, this.innerImages, '#inner-ring img');
    this.updateWheelImages(index, this.outerImages, '#outer-ring img');
    return this.checkForLydian(angle);
  };
  Cycle5Explorer.prototype.updateWheelImages = function (index, imgArray, selector) {
    var len;
    len = imgArray.length;
    if (index === len) {
      index = 0;
    }
    this.$el.find(selector).each(function (i, el) {
      var newIndex;
      newIndex = i - index + len;
      if (newIndex >= len) {
        newIndex -= len;
      }
      /* eslint-disable-next-line ember/no-jquery */
      return Ember.$(el).attr('src', imgArray[newIndex]);
    });
  };
  Cycle5Explorer.prototype.checkForLydian = function (angle) {
    if (angle === 330) {
      this.$el.find('#inner-ring .lydian').attr('src', this.lydianInnerPath);
      return this.$el.find('#outer-ring .lydian').attr('src', this.lydianOuterPath);
    }
  };
  Cycle5Explorer.prototype.updateGrids = function (angle) {
    var angleMap;
    angleMap = {
      '0': '62px',
      '360': '62px',
      '-360': '62px',
      '30': '88px',
      '-330': '88px',
      '60': '114px',
      '-300': '114px',
      '90': '139px',
      '-270': '139px',
      '120': '166px',
      '-240': '166px',
      '150': '192px',
      '-210': '192px',
      '330': '36px',
      '-30': '36px'
    };
    return this.$highlights.css('top', angleMap[angle]);
  };
  Cycle5Explorer.prototype.onNoteWheelMove = function (angle) {
    if (this.prevAngle > 180 && angle < 180) {
      this.numRotations++;
    }
    if (this.prevAngle < 180 && angle > 180) {
      this.numRotations--;
    }
    if (this.numRotations < -1) {
      this.numRotations = -1;
      angle = -210;
    }
    if (this.numRotations > 0) {
      this.numRotations = 0;
      angle = 210;
    }
    this.prevAngle = angle;
    this.continuousAngle = angle + this.numRotations * 360;
    return this.updateNotes(this.continuousAngle);
  };
  Cycle5Explorer.prototype.updateNotes = function (angle) {
    var startIndex;
    startIndex = this.getStartIndex(angle);
    this.updateJigs(startIndex);
    return this.updateNoteWheel(startIndex);
  };
  Cycle5Explorer.prototype.getStartIndex = function (angle) {
    if (angle > 210) {
      angle = 210;
      this.cancelRotation(this.$noteWheelHandle, 210);
    }
    if (angle < -210) {
      angle = -210;
      this.cancelRotation(this.$noteWheelHandle, -210);
    }
    return Math.abs(angle / 30 - 12);
  };
  Cycle5Explorer.prototype.cancelRotation = function ($el, angle) {
    $el.rotatable('setAngle', angle, false);
    $el.css('cursor', 'default');
    /* eslint-disable-next-line ember/no-jquery */
    Ember.$(document).trigger('mouseup');
    return setTimeout(function () {
      return $el.css('cursor', 'move');
    }, 1000);
  };
  Cycle5Explorer.prototype.updateJigs = function (start) {
    var end, html, i;
    end = start + 6;
    if (end >= this.jig.length) {
      return;
    }
    this.$jigs.empty();
    html = '';
    for (i = start; start <= end ? i <= end : i >= end; start <= end ? i++ : i--) {
      html += '<div class="note">\n  ' + this.jig[i] + '\n</div>';
    }
    /* eslint-disable-next-line ember/no-jquery */
    return Ember.$(html).appendTo(this.$jigs);
  };
  Cycle5Explorer.prototype.updateNoteWheel = function (start) {
    var pointer,
      _this = this;
    pointer = 0;
    return this.$noteWheelNotes.each(function (index, el) {
      if (index < 7) {
        pointer = index + start;
      } else {
        pointer = start - 12 + index;
      }
      /* eslint-disable-next-line ember/no-jquery */
      return Ember.$(el).html(_this.jig[pointer]);
    });
  };
  Cycle5Explorer.prototype.jig = ['G<span class="accidental flat-symbol">♭♭</span>', 'D<span class="accidental flat-symbol">♭♭</span>', 'A<span class="accidental flat-symbol">♭♭</span>', 'E<span class="accidental flat-symbol">♭♭</span>', 'B<span class="accidental flat-symbol">♭♭</span>', 'F<span class="accidental flat-symbol">♭</span>', 'C<span class="accidental flat-symbol">♭</span>', 'G<span class="accidental flat-symbol">♭</span>', 'D<span class="accidental flat-symbol">♭</span>', 'A<span class="accidental flat-symbol">♭</span>', 'E<span class="accidental flat-symbol">♭</span>', 'B<span class="accidental flat-symbol">♭</span>', 'F', 'C', 'G', 'D', 'A', 'E', 'B', 'F<span class="accidental sharp-symbol">♯</span>', 'C<span class="accidental sharp-symbol">♯</span>', 'G<span class="accidental sharp-symbol">♯</span>', 'D<span class="accidental sharp-symbol">♯</span>', 'A<span class="accidental sharp-symbol">♯</span>', 'E<span class="accidental sharp-symbol">♯</span>', 'B<span class="accidental sharp-symbol">♯</span>', '<span class="accidental double-sharp-symbol">Fx</span>'];
  var _default = _exports.default = Cycle5Explorer;
});