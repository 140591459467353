define("bocce/routes/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    adBlockDetector: Ember.inject.service('adblockDetector'),
    model: function () {
      let basic_session = this.store.findRecord('session', 1);
      basic_session.then(s => {
        let userID = s.get('user.id');
        let userPromise = this.store.findRecord('user', userID, {
          reload: true
        });
        let environment = s.get('environment');
        this.session.set('user', userPromise);
        this.session.set('kaltura_session', s.get('kaltura'));
        this.session.set('environmentProcessed', {
          isProd: environment === 'production',
          isMain: environment === 'main',
          isPreview: environment == 'preview',
          name: environment
        });

        //TODO: This needs to be replaced with the EAPM equivalent
        //if ( window.newrelic ) {
        //  window.newrelic.setCustomAttribute('user_id', userID);
        //}

        this['login-refresh'].start();
      });
      let hasAdBlock = this.adBlockDetector.detect();
      hasAdBlock.then(hasAdBlock => {
        this.session.set('hasAdBlock', hasAdBlock);
      });
      return basic_session;
    },
    actions: {
      error: function (error) {
        if (error && error.errors && error.errors[0].status.toString() === '401') {
          window.location = '/login/saml?return_to=' + encodeURIComponent(window.location.href);
          return false;
        }
        return true;
      }
    }
  });
});