define("bocce/mirage/scenarios/default", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default(server) {
    // Create the test course
    let course = server.create('course');
    let section = server.create('section', {
      course
    });
    server.create('discussion', {
      section
    });
    server.create('assignment', {
      section
    });
    server.create('quiz', {
      section
    });
    let lesson = server.create('lesson', {
      course
    });
    let item = server.create('item', {
      lesson,
      title: 'something',
      type: 'Page'
    });
    server.create('page', {
      item,
      title: item.title,
      body: 'bla bla bla bla'
    });

    // NK: I'm not sure why this particular relationship needs to be
    // explicitly defined both ways, but it does I guess.
    course.lessons.add(lesson);
    course.save();

    // Create a user with 'lastviewed' data
    let lastviewed = {};
    lastviewed[course.id] = {
      lesson: lesson.id,
      item_id: item.id
    };
    let user = server.create('user', {
      lastviewed
    });

    // Create the one session to rule them all
    server.create('session', {
      user
    });
  }
});