define("bocce/helpers/image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.BocceImage = void 0;
  class BocceImage {
    constructor(data_uri) {
      this._data_uri = new Promise(acc => {
        acc(data_uri);
      });
    }
    static from_file(file) {
      return new Promise((acc, rej) => {
        let fr = new FileReader();
        fr.onload = e => {
          acc(new BocceImage(e.target.result));
        };
        fr.onerror = err => {
          rej(err);
        };
        fr.readAsDataURL(file);
      });
    }
    data() {
      return this._data_uri;
    }
    load() {
      return this._data_uri.then(data_uri => {
        if (this._img) {
          return this._img;
        }
        let count = 0;
        this._img = new Image();
        this._img.src = data_uri;
        return new Promise(acc => {
          let int_id = setInterval(() => {
            if (this._img.complete || count > 10) {
              clearInterval(int_id);
              acc(this._img);
            }
            count++;
          }, 50);
        });
      });
    }

    // https://yellowpencil.com/blog/cropping-images-with-javascript/
    crop(x, y, width, height, scale) {
      this._data_uri = this.load().then(img => {
        let can = document.createElement('canvas'),
          can_con = can.getContext('2d');
        can.width = width;
        can.height = height;
        let buf = document.createElement('canvas'),
          buf_con = buf.getContext('2d');
        buf.width = img.width;
        buf.height = img.height;
        buf_con.drawImage(img, 0, 0);
        can_con.drawImage(buf, x, y, width, height, 0, 0, width, height);
        if (scale && Number(scale) !== 1.0) {
          let sca = document.createElement('canvas'),
            sca_con = sca.getContext('2d');
          sca.width = width * scale;
          sca.height = height * scale;
          sca_con.drawImage(can, 0, 0, sca.width, sca.height);
          can = sca;
        }
        return can.toDataURL('image/png');
      });
      return this._data_uri;
    }
    crop_to_square(max_size) {
      return this.load().then(img => {
        let h = img.height,
          w = img.width,
          size,
          h_offset = 0,
          w_offset = 0,
          scale = 1.0;
        if (h > w) {
          size = w;
          h_offset = (h - w) / 2;
        } else {
          size = h;
          w_offset = (w - h) / 2;
        }
        if (max_size && size > max_size) {
          scale = max_size / size;
        }
        return this.crop(w_offset, h_offset, size, size, scale);
      });
    }

    // https://stackoverflow.com/a/15754051
    to_blob() {
      return this.data().then(uri => {
        let byteString = atob(uri.split(',')[1]);
        let ab = new ArrayBuffer(byteString.length);
        let ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], {
          type: 'image/png'
        });
      });
    }
  }
  _exports.BocceImage = BocceImage;
});