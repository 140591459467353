define("bocce/utilities/dialog", ["exports", "micromodal"], function (_exports, _micromodal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.textareaDialog = _exports.inputDialog = _exports.default = _exports.datetimeDialog = void 0;
  // Takes a message and an array of choices, and displays a dialog to
  // the user. Returns a promise that resolves to the user's selection.

  const NONE = 0;
  const INPUT = 1;
  const TEXTAREA = 2;
  const DATETIME = 3;
  function _default(message, choices = ['OK'], body = false, textInput = false, additionalClasses = []) {
    return dialog(message, choices, body, textInput ? INPUT : NONE, '', additionalClasses);
  }
  const inputDialog = (message, choices = ['OK'], body = false, defaultInput = '', additionalClasses = []) => {
    return dialog(message, choices, body, INPUT, defaultInput, additionalClasses);
  };
  _exports.inputDialog = inputDialog;
  const datetimeDialog = (message, choices = ['OK'], body = false, defaultInput = '', additionalClasses = []) => {
    return dialog(message, choices, body, DATETIME, defaultInput, additionalClasses);
  };
  _exports.datetimeDialog = datetimeDialog;
  const textareaDialog = (message, choices = ['OK'], body = false, defaultInput = '', additionalClasses = []) => {
    return dialog(message, choices, body, TEXTAREA, defaultInput, additionalClasses);
  };
  _exports.textareaDialog = textareaDialog;
  function dialog(message, choices = ['OK'], body = false, inputType, defaultInput, additionalClassesIn = []) {
    return new Promise(resolve => {
      let textInputHTML = '';
      if (inputType === INPUT) {
        textInputHTML = `<input type="text" value="${defaultInput}" class="dialog-text-input"></input>`;
      } else if (inputType === TEXTAREA) {
        textInputHTML = `<textarea type="text" class="dialog-text-input">${defaultInput}</textarea>`;
      } else if (inputType === DATETIME) {
        textInputHTML = `<input type="datetime-local" value="${defaultInput}" class="dialog-text-input"></input>`;
      }
      let uniqueId = Math.random().toString().slice(2);
      const bodyHTML = body ? `<div class="dialog-body-markup">${body}</div>` : '';
      const choicesHTML = choices.map((choice, index) => {
        return `<button data-option="${choice}" data-option-id="${index}" class="choice">${choice}</button>`;
      }).join('');
      let additionalClasses = null;
      if (Array.isArray(additionalClassesIn)) {
        additionalClasses = additionalClassesIn.length > 0 ? ' ' + additionalClassesIn.join(' ') : '';
      } else {
        additionalClasses = ' ' + additionalClassesIn;
      }
      let typeClass = '';
      if (inputType === INPUT) {
        typeClass = 'input';
      } else if (inputType === TEXTAREA) {
        typeClass = 'textarea';
      } else if (inputType === DATETIME) {
        typeClass = 'date';
      }
      const dialogHTML = `
    <div class="bocce-modal modal micromodal-slide" id="modal-${uniqueId}" aria-hidden="true">
      <div class="modal__overlay" tabindex="-1">
        <div class="${typeClass} ${additionalClasses} conf-dialog modal__container" role="dialog" aria-modal="true" aria-labelledby="modal-${uniqueId}-title">
          ${message}
          ${textInputHTML}
          ${bodyHTML}
          <footer class="modal__footer">
          ${choicesHTML}
          </footer>
        </div>
      </div>
    </div>`;
      document.body.insertAdjacentHTML('beforeend', dialogHTML);
      for (let choice of document.querySelectorAll(`#modal-${uniqueId} .conf-dialog .choice`)) {
        choice.addEventListener('click', function (event) {
          // If there's a text input, resolve to the input value.
          // Otherwise, resolve to the button that was pushed.
          if (inputType !== NONE) {
            const inputValue = document.querySelector('.dialog-text-input').value;
            const firstOptionSelected = event.target.dataset.optionId === '0';
            if (firstOptionSelected) {
              resolve(inputValue);
            } else {
              resolve(false);
            }
          } else {
            const selection = event.target.getAttribute('data-option');
            resolve(selection);
          }
          document.getElementById(`modal-${uniqueId}`).remove();
        });
      }
      _micromodal.default.show(`modal-${uniqueId}`, {
        onShow: modal => {
          let choice = document.querySelector(`#modal-${uniqueId} .conf-dialog .choice`);
          if (choice) {
            choice.focus();
          }
        },
        disableScroll: true
      });
    });
  }
  var _default2 = _exports.default = _default;
});