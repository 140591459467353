define("bocce/templates/interactions/rive/main", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "zzd1LVOB",
    "block": "{\"symbols\":[],\"statements\":[[10,\"canvas\"],[14,1,\"canvas\"],[14,\"width\",\"500\"],[14,\"height\",\"500\"],[12],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "bocce/templates/interactions/rive/main.hbs"
    }
  });
});