define("bocce/mixins/quiz", ["exports", "bocce/utilities/dialog", "bocce/js/quizzes/quizzes-factory"], function (_exports, _dialog, _quizzesFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable-next-line ember/no-new-mixins */
  var _default = _exports.default = Ember.Mixin.create({
    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    quiz: Ember.computed('item', 'data', function () {
      /* eslint-disable-next-line ember/no-get */
      let type = this.get('item.type').toLowerCase();
      if (type === 'quiz') {
        this.quizSetup();
        return this.quizzes.quiz;
      }
      return null;
    }),
    checkingAnswer: Ember.computed(() => false),
    quizSetup: function () {
      /* eslint-disable-next-line ember/no-get */
      let type = this.get('item.type').toLowerCase();
      if (type !== 'quiz') {
        return false;
      }
      let quiz = this.data;
      if (quiz) {
        if (!this.quizzes.initialized) {
          this.quizzes.initialize(quiz, session, this.lessons.store, this.gainsight);
        }

        /* eslint-disable-next-line ember/no-incorrect-calls-with-inline-anonymous-functions */
        Ember.run.scheduleOnce('afterRender', this, () => {
          /**
           * If a quiz question is inserted into the page upon page load, performedInitialRender will be false. This
           * tells the question component to not boot the question content since that will be done as a part of the page loading.
           * If a quiz question is inserted into the page after it loads, we need to boot the content. Set this property to true
           * so that the question component knows to do so.
           */
          this.quizzes.performedInitialRender = true;
        });
      }
    },
    srSpeak: function (text, priority) {
      let el = document.createElement("div");
      let id = 'speak-' + Date.now();
      el.setAttribute('id', id);
      el.setAttribute('aria-live', priority || 'polite');
      el.classList.add('sr-only');
      document.body.appendChild(el);
      window.setTimeout(function () {
        document.getElementById(id).innerHTML = text;
      }, 100);
      window.setTimeout(function () {
        document.body.removeChild(document.getElementById(id));
      }, 1000);
    },
    features: Ember.inject.service(),
    session: Ember.inject.service(),
    gainsight: Ember.inject.service(),
    quizzes: Ember.computed(function () {
      return _quizzesFactory.default.get(this.get('data.search_id'));
    }),
    allowSubmit: Ember.computed('features.staticContent', function () {
      return !this.features.isEnabled('staticContent');
    })
  });
});