define("bocce/mixins/support/layout", ["exports", "bocce/mixins/support/util"], function (_exports, util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.bounding_box = bounding_box;
  _exports.box_overlaps = box_overlaps;
  _exports.box_top = box_top;
  _exports.line_overlaps = line_overlaps;
  // Given a list of bounding boxes and the width,
  // height, and left position of a new box, return a height
  // offset that avoids overlaps.
  function box_top(boxes, width, height, left) {
    let rows;
    if (boxes.length === 0) {
      rows = [0];
    } else {
      rows = util.unique(util.sortAsc(boxes.map(b => b.tl.y)));

      // Add a final empty row as a catch-all
      rows.push(util.max(boxes.map(b => b.br.y)) + 8);
    }
    for (let row of rows) {
      let test_box = bounding_box(left, row, width, height),
        invalid = false;
      for (let b of boxes) {
        if (box_overlaps(b, test_box)) {
          invalid = true;
          break;
        }
      }
      if (!invalid) {
        return row;
      }
    }
    return 0;
  }
  function bounding_box(left_or_node, top, width, height) {
    let left = left_or_node,
      retval = {
        tl: {},
        br: {}
      };
    if (top === undefined) {
      if (!left.jquery) {
        /* eslint-disable-next-line ember/no-jquery */
        left = Ember.$(left);
      }
      let pos = left.offset(),
        padding = parseInt(left.css('padding-left') || 0, 10);
      retval.tl.x = (pos.left || 0) - padding;
      retval.tl.y = (pos.top || 0) - padding;
      retval.br.x = pos.left + left.width() || 0;
      retval.br.y = pos.top + left.height() || 0;
    } else {
      retval.tl.x = left_or_node;
      retval.tl.y = top;
      retval.br.x = left_or_node + width;
      retval.br.y = top + height;
    }
    return retval;
  }
  function line_overlaps(min1, max1, min2, max2) {
    return max1 >= min2 && max2 >= min1;
  }
  function box_overlaps(b1, b2) {
    return line_overlaps(b1.tl.x, b1.br.x, b2.tl.x, b2.br.x) && line_overlaps(b1.tl.y, b1.br.y, b2.tl.y, b2.br.y);
  }
});