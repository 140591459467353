define("bocce/components/assignment/assignment-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    init(...args) {
      this._super(...args);
      this.sortProperties = this.sortProperties || ['due_at'];
    },
    tagName: 'ul',
    classNames: 'assignment-list',
    sortedAssignments: Ember.computed.sort('assignments', 'sortProperties'),
    actions: {
      sendGoToAssignment(id) {
        this.goToAssignment(id);
      }
    }
  });
});