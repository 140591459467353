define("bocce/mirage/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    // These comments are here to help you get started. Feel free to delete them.

    /*
      Config (with defaults).
       Note: these only affect routes defined *after* them!
    */

    // this.urlPrefix = '';    // make this `http://localhost:8080`, for example, if your API is on a different server
    // this.namespace = '';    // make this `/api`, for example, if your API is namespaced
    // this.timing = 400;      // delay for each request, automatically set to 0 during testing

    /*
      Shorthand cheatsheet:
       this.get('/posts');
      this.post('/posts');
      this.get('/posts/:id');
      this.put('/posts/:id'); // or this.patch
      this.del('/posts/:id');
       https://www.ember-cli-mirage.com/docs/route-handlers/shorthands
    */

    // This passthrough is needed for the ember-cli-code-coverage addon to work properly;
    // see the docs for details:
    //
    // https://www.npmjs.com/package/ember-cli-code-coverage#create-a-passthrough-when-intercepting-all-ajax-requests-in-tests
    this.passthrough('/write-coverage');
    this.namespace = 'interface';
    this.get('/sessions/:id');
    this.get('/users/:id');
    this.get('/courses/:id');
    this.get('/sections/:id');
    this.get('/courses/:course_id/sections/:section_id', (schema, request) => {
      return schema.sections.find(request.params.section_id);
    });
    this.get('/courses/:course_id/gradebooks', (schema, request) => {
      let course = schema.courses.find(request.params.course_id);
      return {
        gradebook: course.gradebooks
      };
    });
    this.get('/courses/:course_id/lessons/:lesson_id', (schema, request) => {
      let course = schema.courses.find(request.params.course_id);
      let lessons = course.lessons;
      let lesson = lessons.filter(l => l.id === request.params.lesson_id).models[0];
      let items = lesson.items.models;
      return {
        lesson,
        item: items
      };
    });
    this.get('/sections/:section_id/discussions', (schema, request) => {
      let discussion = schema.sections.find(request.params.section_id).discussions;
      return discussion;
    });
    this.get('/sections/:id/assignments', (schema, request) => {
      return schema.sections.find(request.params.id).assignments;
    });
    this.get('/sections/:sec_id/assignments/:assign_id', (schema, request) => {
      return schema.assignments.find(request.params.assign_id);
    });
    // TODO: get things working so that this route isn't necessary. The interface
    // doesn't have a real endpoint that matches this, but our current method of
    // setting up nested resources is unreliable in the test environment. This
    // catches certain cases where submissions don't nest correctly during
    // acceptance test.
    this.get('/submissions/:submission_id', (schema, request) => {
      return schema.submissions.find(request.params.submission_id);
    });
    this.get('/sections/:sec_id/assignments/:assign_id/submissions/:sub_id', (schema, request) => {
      return schema.submissions.find(request.params.sub_id);
    });
    this.get('/sections/:id/quizzes', (schema, request) => {
      return schema.sections.find(request.params.id).quizzes;
    });
    this.get('/courses/:course_id/lessons/:lesson_id/items/:item_id', (schema, request) => {
      let course = schema.courses.find(request.params.id);
      let lesson = course.lessons.filter(l => l.id === request.params.lesson_id).models[0];
      let item = lesson.items.filter(i => i.id === request.params.item_id).models[0];
      return item;
    });
    this.put('/courses/:course_id/lessons/:lesson_id/items/:item_id', () => {
      return {};
    });
    this.put('/users/:id');
    this.get('/dashboards');
  }
});