define("bocce/mixins/support/local-storage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function getNamespace(id) {
    return window.location.hash.split('/').slice(1, 3) + '#' + id;
  }
  var _default = _exports.default = {
    saveData: function (id, data) {
      if (typeof data === 'object') {
        data = JSON.stringify(data);
      }
      localStorage.setItem(getNamespace(id), data);
    },
    getData: function (id) {
      var data = localStorage.getItem(getNamespace(id));
      if (!data) {
        return undefined;
      } else {
        try {
          return JSON.parse(data);
        } catch (e) {
          Ember.debug(e);
        }

        // if data isn't a parseable json object...
        return data;
      }
    }
  };
});