define("bocce/components/quiz-questions/multiple-answers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    init(...args) {
      this._super(...args);
      if (!this.question.get('given_answer')) {
        Ember.set(this.question, 'given_answer', []);
      }
    },
    actions: {
      changeAnswer(answerId, event) {
        let givenAnswer = this.question.get('given_answer');
        if (event.target.checked) {
          givenAnswer.push(answerId);
        } else {
          givenAnswer.splice(givenAnswer.indexOf(answerId), 1);
        }
        Ember.set(this.question, 'given_answer', [...givenAnswer]);
      }
    }
  });
});