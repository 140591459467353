define("bocce/components/bookmarks/bookmarks-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class BookmarksHeaderComponent extends Ember.Component {
    get showActions() {
      return this.bookmarksService.numBookmarksLessons || this.bookmarksService.courses.length > 1 || this.bookmarksService.hasMoreBookmarks;
    }
  }
  _exports.default = BookmarksHeaderComponent;
});