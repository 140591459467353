define("bocce/mixins/attachments-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable-next-line ember/no-new-mixins */
  var _default = _exports.default = Ember.Mixin.create({
    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    attachment_objects: Ember.computed('attachments.[]', function () {
      let attachments = this.attachments;
      let retval = [];
      for (let item of attachments) {
        // If the item is an object with a URL, that should be all we need to render the attachment component. If there
        // is no URL, assume that item either is or has an ID that we can use to get the attachment model from the store.
        if (typeof item === 'object' && item.url) {
          // If item doesn't already have a name or type, copy other property values that can work instead.
          Ember.set(item, 'name', item.name || item.display_name || item.filename);
          Ember.set(item, 'type', item.type || item['content-type']);
          retval.push(item);
        } else {
          // item can either be an ID, or an object with an ID property. If it's an object with an ID, use that.
          let id;
          if (Object.prototype.hasOwnProperty.call(item, 'id')) {
            id = item.id;
          } else {
            id = item;
          }
          if (id) {
            // This works because the attachment adapter (see adapters/attachment.js) prevents re-fetching attachment models
            // from the server. Attachment models should almost never be fetched individually; instead they should be
            // included as sideloaded models when fetching discussions, messages, submissions, etc. When they're sideloaded
            // the URL property includes a query string that Canvas uses to verify that the user has permission to view it.
            retval.push(this.store.findRecord('attachment', id));
          }
        }
      }
      return retval;
    })
  });
});