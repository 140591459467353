define("bocce/controllers/events", ["exports", "bocce/mixins/enmodal", "bocce/mixins/webex", "bocce/controllers/classroom/lessons/event-new", "bocce/utilities/dialog"], function (_exports, _enmodal, _webex, _eventNew, _dialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-observers */
  var _default = _exports.default = Ember.Controller.extend(_enmodal.default, _webex.default, {
    init(...args) {
      this._super(...args);
      this.sortChatsBy = this.sortChatsBy || ['startAt'];
      this.sortPastChatsBy = this.sortPastChatsBy || ['startAt:desc'];
    },
    event: Ember.inject.controller('classroom.lessons.event'),
    classroom: Ember.inject.controller(),
    eventsService: Ember.inject.service('events'),
    userprofileService: Ember.inject.service('userprofile'),
    application: Ember.inject.controller(),
    // required by WebexMixin
    events: Ember.inject.controller(),
    // required by WebexMixin
    newStudentEvent: Ember.inject.controller('classroom.lessons.student-event-new'),
    // required by WebexMixin

    showOnlyInstructorChats: false,
    upcomingChatsExpanded: false,
    /* eslint-disable-next-line ember/no-observers */
    instructorChatsFilterObserver: Ember.observer('showOnlyInstructorChats', function () {
      if (!this.showOnlyInstructorChats) {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.events-instructor-chat-toggle-container').removeClass('checked');
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.event.student-chat').removeClass('hidden');
      } else {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.events-instructor-chat-toggle-container').addClass('checked');
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.event.student-chat').addClass('hidden');
      }
    }),
    pastChats: Ember.computed('eventsList.[]', 'session.time', 'sesssion.user', function () {
      let now = new Date(),
        currentUserAcsId = this.get('session.user.acsUserId'),
        isInstructor = this.get('session.isInstructor'),
        isStaff = this.get('session.isStaff'),
        canShow = true;
      let pastChats = this.eventsList.filter(function (e) {
        let hasMedia = e.get('meetings.length') > 0 || e.get('s3Recordings.length') > 0 || e.get('kalturaRecordings.length') > 0,
          isPastTime = moment(e.get('endAt')) < now;
        if (e.get('privateLessonStudentId')) {
          canShow = e.get('privateLessonStudentId') === currentUserAcsId || isInstructor || isStaff;
        }
        return isPastTime && canShow && hasMedia;
      });
      return pastChats;
    }),
    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    currentChat: Ember.computed('eventsList.[]', 'session.{time,runningChatMeetingKey}', function () {
      if (!this.session.runningChatMeetingKey) {
        return null;
      }
      let chats = this.sortedChats;
      if (!chats || chats.get('length') === 0) {
        return null;
      }
      chats = chats.filter(chat => {
        if (chat.meeting_key != this.session.runningChatMeetingKey) {
          return false;
        }
        if (chat.get('privateLessonStudentId')) {
          return chat.get('privateLessonStudentId') === this.get('session.user.acsUserId') || this.get('session.isInstructor') || this.get('session.isStaff');
        }
        return true;
      });
      if (chats.get('length') === 0) {
        return null;
      }
      return chats.objectAt(0);
    }),
    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    upcomingChats: Ember.computed('eventsList.[]', 'session.time', 'currentChat', function () {
      let now = new Date(),
        chats = this.eventsList,
        cur = this.currentChat,
        teachingUser = this.get('session.teachingUser'),
        currentUserAcsId = this.get('session.user.acsUserId'),
        isInstructor = this.get('session.isInstructor'),
        isStaff = this.get('session.isStaff'),
        canShow = true;
      if (chats.get('length') === 0) {
        return [];
      }

      // JRW: If it's a Private Lesson, set the late start time to 5 minutes
      // If not, broaden the window to 30 minutes
      /* eslint-disable-next-line ember/no-get */
      let chatWindowInMinutes = this.get('session.course.isPrivateLesson') ? 5 : 30;
      return chats.filter(e => {
        let canStart = moment(e.get('startAt')) > now - chatWindowInMinutes * 60 * 1000,
          isNotCurrent = !cur || !cur.id || e.id != cur.id;
        if (e.get('privateLessonStudentId')) {
          canShow = e.get('privateLessonStudentId') === currentUserAcsId || isInstructor || isStaff;
        }
        // JRW: sometimes staff edit events and it changes the
        // owner to admin, this sets the event to the instructor
        // user.
        if (e.get('user').get('id') === "1") {
          e.set('user', teachingUser);
        }
        return canStart && isNotCurrent && canShow;
      });
    }),
    // NK: I'm reworking otherSectionChats to be an observer here, because
    // the computed property introduces side effects. Ideally we'll be
    // able to rewrite this at some point so that we can use only
    // computed properties.
    /* eslint-disable-next-line ember/no-observers */
    otherSectionChatsObserver: Ember.observer('upcomingChats', function () {
      // JRW: If the next chat starts within +/-10 minutes, check if it's a multisection chat.
      // If it is, set the model of the event controller to get the otherSectionChats
      // computed property.
      let chats = this.sortedUpcomingChats.filter(e => !e.get('isStudentChat')),
        now = moment(),
        tenMinutesInMilliseconds = 1000 * 60 * 10,
        nextUpcomingChat = chats.objectAt(0),
        otherSectionChatsPromise;

      /* eslint-disable-next-line ember/no-get */
      if (this.get('session.isInstructor') && nextUpcomingChat) {
        let start = moment(nextUpcomingChat.get('startAt'));
        if (start - tenMinutesInMilliseconds < now && start + tenMinutesInMilliseconds > now) {
          this.set('isWorking', true);
          otherSectionChatsPromise = this.eventsService.getOtherSectionChats(this.classroom, nextUpcomingChat.get('title'), nextUpcomingChat.get('startAt')).then(otherSectionChats => {
            // Attach the otherSectionChats event to the events list controller and send the length
            // of the array to the otherSectionChatsPromise handler
            this.set('isWorking', false);
            if (Array.isArray(otherSectionChats) && otherSectionChats.length !== 0) {
              this.set('otherSectionChats', otherSectionChats);
              return otherSectionChats.length;
            } else {
              return 0;
            }
          });

          //return the DS.PromiseObject (and its result)
          return otherSectionChatsPromise.then(result => {
            this.set('hasOtherSectionChats', result !== 0);
          });
        }
      }

      // default to false - doesn't get here if isInstructor is true and there's a chat to check
      return this.set('hasOtherSectionChats', false);
    }),
    // Returns the next chat, if it starts within 30 minutes from now
    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    chatStartingSoon: Ember.computed('upcomingChats', 'session.time', function () {
      let candidate = this.sortedUpcomingChats.objectAt(0);
      if (!candidate) {
        return null;
      }
      let start = moment(candidate.get('startAt'));
      if (start - moment() < 1800000) {
        return candidate;
      }
      return null;
    }),
    eventsList: Ember.computed.reads('session.course.events'),
    eventsLoaded: Ember.computed.reads('classroom.eventsLoaded'),
    otherCurrentTermSections: Ember.computed.reads('classroom.otherCurrentTermSections'),
    sortedChats: Ember.computed.sort('eventsList', 'sortChatsBy'),
    sortedUpcomingChats: Ember.computed.sort('upcomingChats', 'sortChatsBy'),
    sortedPastChats: Ember.computed.sort('pastChats', 'sortPastChatsBy'),
    hasChats: Ember.computed('eventsList.[]', function () {
      /* eslint-disable-next-line ember/no-get */
      return this.get('eventsList.length') - this.eventsList.filterBy('meetings').filterBy('endAt').length > 0;
    }),
    actions: {
      createSingleSectionQuickEvent() {
        let now = moment().tz('America/New_York');
        this.set('isWorking', true);
        const section = this.session.get('section');
        const course = section.get('course');
        const courseId = course.get('id');
        let eventDataSingle = {
          event: {
            title: 'Live Class',
            description: '',
            futureEvent: true,
            startAt: now.format('YYYY-MM-DDTHH:mm:ss'),
            endAt: now.add("1", "hours").format('YYYY-MM-DDTHH:mm:ss'),
            course
          },
          courseIds: [courseId]
        };
        _eventNew.sendEventsToInterfaceAndSendEmail.call(this, [eventDataSingle]);
      },
      viewEvent: function (event_id) {
        this.transitionToRoute('classroom.lessons.event', event_id, {
          queryParams: {
            backup_code: false
          }
        });
      },
      filterEvents: function (filterType) {
        switch (filterType) {
          case 'all':
            /* eslint-disable-next-line ember/no-jquery */
            Ember.$('.side-panel .chat-panel .events .past-event').show();
            break;
          case 'current':
            /* eslint-disable-next-line ember/no-jquery */
            Ember.$('.side-panel .chat-panel .events .past-event').hide();
            break;
          default:
            /* eslint-disable-next-line ember/no-jquery */
            Ember.$('.side-panel .chat-panel .events .past-event').hide();
        }
      },
      dismissStartChatConfirmation: function () {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.chat-confirmation').addClass('hidden');
      },
      toggleExpandUpcomingChats() {
        this.toggleProperty('upcomingChatsExpanded');
      },
      toggleInstructorChats: function () {
        this.set('showOnlyInstructorChats', !this.showOnlyInstructorChats);
      }
    }
  });
});