define("bocce/components/lessons/event-new-template-actions", ["exports", "bocce/utilities/dialog", "bocce/config/environment"], function (_exports, _dialog, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let EventNewTemplateActionsComponent = _exports.default = (_dec = Ember.inject.service('templates'), (_class = class EventNewTemplateActionsComponent extends Ember.Component {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "templatesService", _descriptor, this);
    }
    didInsertElement() {
      const self = this;
      Ember.$('.template-actions-content .template-select')

      //Keep track of the last selected option so that it can be reverted to if the user
      //decides not to change templates.
      .on('click', function () {
        Ember.$(this).data('lastSelected', Ember.$(this).find('option:selected'));
      }).on('change', function () {
        const lastSelected = Ember.$(this).data('lastSelected');

        //Verify the user wants to change templates if the content is dirty, i.e. content has been changed and
        //is now different from one of the saved templates.
        if (!self.contentNotDirty) {
          (0, _dialog.default)(_environment.default.APP.text.confirmChangeTemplate, ['Yes', 'No']).then(choice => {
            if (choice === 'No') {
              lastSelected.prop('selected', true);
              return false;
            }
            self.templatesService.changeTemplate(Ember.$(this).val());
          });
        } else {
          self.templatesService.changeTemplate(Ember.$(this).val());
        }
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "templatesService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
});