define("bocce/mixins/interactions/solfege_ladder", ["exports", "bocce/mixins/support/render-template", "bocce/mixins/support/util"], function (_exports, _renderTemplate, util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function SolfegeExercise($el, $svg, $selectSpeed, playSpeed, doNoteID, exerciseData) {
    // binds all methods to this
    util.bindAll(this);
    this.$el = $el;
    this.$svg = $svg;
    this.$selectSpeed = $selectSpeed;
    this.$btnAns = this.$el.find('#solfege-btn-answer');
    this.$btnNext = this.$el.find('#solfege-btn-next');
    this.$playCue = this.$el.find('#solfege-btn-play-cue');
    this.$retryQuiz = this.$el.find('#solfege-btn-retry-quiz');
    this.$btnLoop = this.$el.find('#solfege-btn-loop');
    this.$btnRandom = this.$el.find('#solfege-btn-random');
    this.$noteTiles = this.$el.parent().find('.note-rect');
    this.$modeSelector = this.$el.parent().find('#solfege-mode-selector');
    this.$quizMessages = this.$el.parent().find('#solfege-quiz-message');
    this.playSpeed = playSpeed;
    this.exerciseData = exerciseData;
    this.doNoteID = doNoteID;
    this.highlightSelectors = this.getSelectorsForNotes(exerciseData);
    this.chromaticOffsets = this.getExerciseNoteOffsets(exerciseData);
    this.isAnimating = false;
    this.piano = this.piano || window.piano;
  }
  SolfegeExercise.prototype = {
    init() {
      let $btnPlay = this.$el.find('#solfege-btn-play');
      // remove any previous attached highlights

      $btnPlay.off('click');
      this.$btnAns.off('click');
      this.$noteTiles.off('click');
      this.$selectSpeed.off('change');
      this.$modeSelector.off('change');
      this.$retryQuiz.off('click');
      this.$btnLoop.off('click');
      this.$btnRandom.off('click');
      this.$btnAns.addClass('hidden');
      this.$btnNext.addClass('hidden');
      // attach new highlights
      $btnPlay.click(this.playAction);
      this.$btnAns.click(this.answerAction);
      this.$noteTiles.click(this.selectQuizAnswer);
      this.$selectSpeed.on('change', this.selectSpeed);
      this.$modeSelector.on('change', this.checkMode);
      this.$retryQuiz.click(this.resetQuiz);
      this.$btnLoop.click(this.toggleLoop);
      this.$btnRandom.click(this.toggleRandom);
      this.loopMode = false;
      this.randomMode = false;
      this.randomOrder = [];
      this.$btnAns.addClass('hidden');
      this.$playCue.text('Play');
      this.$quizMessages.removeClass('solfege-message-refade');
      void this.$quizMessages[0].offsetWidth;
      this.verifyExerciseOctaves();
    },
    checkSelector(selector) {
      // Check if given selector exists as a child of this.$svg and isn't hidden
      let selectorExists = this.$svg.find('#' + selector).length > 0;
      if (!selectorExists) {
        return {
          exists: false,
          hidden: false
        };
      }
      let selectorIsHidden = this.$svg.find('#' + selector).hasClass('hidden');
      return {
        exists: selectorExists,
        hidden: selectorIsHidden
      };
    },
    findNearestOctave(note) {
      // Try low, then med, then high
      let octaves = ['low', 'med', 'high'];
      let octave = null;
      for (let o of octaves) {
        note.octave = o;
        let selector = note.octave + "-" + note.note + "-" + note.tone;
        let check = this.checkSelector(selector);
        if (check.exists && !check.hidden) {
          octave = o;
          break;
        }
      }
      return octave;
    },
    verifyExerciseOctaves() {
      // Check that all notes in the exercise have a visible selector
      let exercise = this.exerciseData;
      for (let n of exercise) {
        let selector = n.octave + "-" + n.note + "-" + n.tone;
        let check = this.checkSelector(selector);
        if (!check.exists) {
          // If the selector doesn't exist, try to find the nearest octave
          let octave = this.findNearestOctave(n);
          if (octave) {
            n.octave = octave;
          } else {
            // If we can't find a visible selector, hide the note
            n.octave = 'hidden';
          }
        }
      }
      this.highlightSelectors = this.getSelectorsForNotes(exercise);
    },
    checkMode() {
      // Available modes: Performance, Recognition, Quiz
      let mode = this.$modeSelector[0].value;
      if (!mode) {
        mode = 'performance';
      }
      if (mode !== 'quiz') {
        this.randomMode = false;
        this.loopMode = false;
        this.resetQuiz();
        this.$btnRandom.addClass('hidden');
        this.$btnLoop.addClass('hidden');
        if (mode === 'performance') {
          this.$playCue.text('Play');
          this.$svg.removeClass('quiz-mode');
          this.$svg.find('.note-rect').removeClass('wrong-note').removeClass('correct-note').removeClass('selected-note');
        }
      } else {
        this.$btnRandom.removeClass('hidden');
        this.$btnLoop.removeClass('hidden');
      }
      return mode;
    },
    resetQuiz() {
      this.currentNote = 0;
      this.tryCount = [];
      this.$svg.removeClass('quiz-mode');
      this.$quizMessages.removeClass('solfege-message-refade');
      this.$quizMessages.removeClass('solfege-message-permanent');
      this.$retryQuiz.addClass('hidden');
      this.$noteTiles.removeClass('animate-highlight');
      if (this.randomMode) {
        this.randomOrder = this.createRandomOrder();
      } else {
        this.randomOrder = [];
      }
    },
    toggleLoop() {
      this.loopMode = !this.loopMode;
      if (this.loopMode) {
        this.$btnLoop.addClass('active');
      } else {
        this.$btnLoop.removeClass('active');
      }
    },
    toggleRandom() {
      this.randomMode = !this.randomMode;
      if (this.randomMode) {
        this.$btnRandom.addClass('active');
      } else {
        this.$btnRandom.removeClass('active');
      }
      this.resetQuiz();
    },
    createRandomOrder() {
      let order = [];
      for (let i = 0; i < this.chromaticOffsets.length; i++) {
        order.push(i);
      }
      return this.shuffle(order);
    },
    shuffle(array) {
      let currentIndex = array.length;
      let temporaryValue;
      let randomIndex;

      // While there remain elements to shuffle...
      while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
      }
      return array;
    },
    playAction() {
      // Available modes: Performance, Recognition, Quiz
      // Performance mode: highlight notes without playing them
      // Recognition mode: play notes without highlighting them
      // Quiz mode: play notes one at a time and have the student click on each note before playing the next one

      let mode = this.checkMode();
      if (mode === 'performance') {
        this.highlightNotes();
      } else if (mode === 'recognition') {
        this.$svg.addClass('quiz-mode');
        this.$svg.find('.note-rect').removeClass('wrong-note').removeClass('correct-note').removeClass('selected-note');
        this.playNotesOffsetFromDo();
        this.$playCue.text('Replay');
      }
      if (mode === 'quiz') {
        this.$playCue.text('Replay Note');
        // this.$btnAns.removeClass('hidden');
        this.$retryQuiz.removeClass('hidden');
        this.$svg.find('.note-rect').removeClass('animate-highlight');
        this.$svg.addClass('quiz-mode');
        this.$quizMessages.removeClass('solfege-message-refade');
        this.$quizMessages.removeClass('solfege-message-permanent');
        void this.$quizMessages[0].offsetWidth;
        this.$quizMessages.html(`Now playing note ${this.currentNote + 1}...`);
        this.$quizMessages.addClass('solfege-message-refade');
        this.playSingleNoteOffsetFromDo();
      }
    },
    answerAction() {
      // Available modes: Performance, Recognition, Quiz
      // Performance mode: play notes without highlighting them
      // Recognition mode: highlight notes without playing them
      // Quiz mode: highlight the correct note in the series

      let mode = this.checkMode();
      if (mode === 'performance' || mode === 'recognition') {
        this.$playCue.text('Replay');
        this.playNotesOffsetFromDo();
        this.highlightNotes();
      } else if (mode === 'quiz') {
        this.showQuizAnswer();
      }
    },
    showQuizAnswer() {
      this.showSingleHighlight();
      this.$btnAns.addClass('hidden');
      this.tryCount[this.currentNote] = 4;
      this.currentNote += 1;

      // If we're at the end of the exercise, grade the quiz
      if (this.currentNote >= this.chromaticOffsets.length) {
        this.gradeQuiz();
      }
      this.$playCue.text('Play Next Note');
      this.$svg.removeClass('quiz-mode');
      this.$retryQuiz.removeClass('hidden');
    },
    getSelectorsForNotes(e) {
      let highlightSelectors = [];
      for (let n of e) {
        // get note rectangle
        let rect = this.$svg.find('#squares_layer #' + n.selector);
        // put them in variables to give back to init/player
        highlightSelectors.push(rect);
      }
      return highlightSelectors;
    },
    playHighlightsAndSounds() {
      this.playNotesOffsetFromDo();
      this.highlightNotes();
    },
    highlightNotes() {
      if (this.isAnimating == false) {
        let hs = this.highlightSelectors;
        let mode = this.checkMode();
        this.isAnimating = true;
        // mark buttons as un-clickable while isAnimating
        this.$el.toggleClass('disable-sound-btns');
        const setIsAnimatingToFalse = () => {
          // remove isAnimating class from buttons
          this.$el.toggleClass('disable-sound-btns');
          return this.isAnimating = false;
        };
        setTimeout(setIsAnimatingToFalse, hs.length * this.playSpeed);
        for (let i = 0; i < hs.length; i++) {
          let timeInMilliseconds = i * this.playSpeed;
          setTimeout(function () {
            hs[i].addClass('animate-highlight');
          }, timeInMilliseconds);
          setTimeout(() => {
            hs[i].removeClass('animate-highlight');
            if (mode === 'recognition') {
              hs[i].addClass('correct-note');
              if (i === hs.length - 1) {
                this.$svg.find('.note-rect.selected-note:not(.correct-note)').addClass('wrong-note');
              }
            }
          }, timeInMilliseconds + this.playSpeed);
        }
        this.$btnAns.removeClass('hidden');
        this.$btnNext.removeClass('hidden');
      }
    },
    selectSpeed() {
      this.playSpeed = this.$selectSpeed[0].value * 1000;
    },
    getExerciseNoteOffsets(e) {
      const noteOffsets = {
        1: 0,
        2: 2,
        3: 4,
        4: 5,
        5: 7,
        6: 9,
        7: 11
      };
      let chromaticOffsets = [];
      for (let n of e) {
        let offset = 0,
          noteNum = noteOffsets[n.note],
          octaveNum = 0,
          toneNum = 0;
        if (n.octave === 'low') {
          octaveNum += -12;
        } else if (n.octave === 'high') {
          octaveNum += 12;
        }
        if (n.tone === 'flat') {
          toneNum += -1;
        } else if (n.tone === 'sharp') {
          toneNum += 1;
        }
        chromaticOffsets.push(offset += noteNum += octaveNum += toneNum);
      }
      return chromaticOffsets;
    },
    playNotesOffsetFromDo() {
      if (this.isAnimating == false) {
        let noteIDs = this.chromaticOffsets.map(offset => this.doNoteID + offset);
        if (this.piano && this.piano.playNotes) {
          this.piano.playNotes(noteIDs, this.playSpeed, true);
        }
      }
    },
    currentNote: 0,
    tryCount: [],
    playSingleNoteOffsetFromDo() {
      let playNote = this.randomMode ? this.randomOrder[this.currentNote] : this.currentNote;
      if (this.isAnimating == false) {
        let noteID = this.chromaticOffsets[playNote] + this.doNoteID;
        if (this.piano && this.piano.playNotes) {
          this.piano.playNotes([noteID], this.playSpeed, true);
        }
        if (this.currentNote >= this.chromaticOffsets.length) {
          this.currentNote = 0;
          if (this.loopMode) {
            this.randomOrder = this.createRandomOrder();
          }
          this.$playCue.text('Retry');
        }
      }
    },
    showSingleHighlight() {
      let hs = this.highlightSelectors;
      let currentNote = this.randomMode ? this.randomOrder[this.currentNote] : this.currentNote;
      hs[currentNote].addClass('animate-highlight');
    },
    selectQuizAnswer(event) {
      let mode = this.$modeSelector[0].value;
      if (mode !== 'quiz' && mode !== 'recognition') {
        return;
      }
      let answer = Ember.$(event.target);
      let answerID = answer.attr('id');
      if (mode === 'recognition') {
        answer.toggleClass('selected-note');
        this.$btnAns.removeClass('hidden');
        return;
      }

      // Check click target against current note
      let currentNote = this.currentNote;
      let answerNote = this.randomMode ? this.randomOrder[currentNote] : currentNote;
      let hs = this.highlightSelectors;
      let correctAnswer = hs[answerNote].attr('id');
      if (answer && correctAnswer && answerID === correctAnswer) {
        // correct answer
        this.$quizMessages.removeClass('solfege-message-refade');
        this.tryCount[currentNote] = !this.tryCount[currentNote] ? 0 : this.tryCount[currentNote];
        void this.$quizMessages[0].offsetWidth;
        this.$btnAns.addClass('hidden');
        if (currentNote + 1 >= hs.length) {
          this.$quizMessages.html(`Correct!`);
          this.gradeQuiz();
        } else {
          this.$quizMessages.html(`Correct! Now playing note ${currentNote + 2} of ${hs.length}...`);
          this.$quizMessages.addClass('solfege-message-refade');
        }
        this.currentNote += 1;
        this.playSingleNoteOffsetFromDo();
      } else {
        // incorrect answer
        this.tryCount[currentNote] = this.tryCount[currentNote] ? this.tryCount[currentNote] + 1 : 1;
        let triesLeft = 3 - this.tryCount[currentNote];
        this.$quizMessages.removeClass('solfege-message-refade');
        void this.$quizMessages[0].offsetWidth;
        if (triesLeft <= 0) {
          this.$quizMessages.html(`Incorrect!`);
        } else {
          this.$quizMessages.text(`Incorrect! Try ${triesLeft} more time${triesLeft === 1 ? '' : 's'}...`);
        }
        this.$quizMessages.addClass('solfege-message-refade');
        if (this.tryCount[currentNote] >= 3) {
          this.$btnAns.removeClass('hidden');
        }
      }
    },
    gradeQuiz() {
      // Calculate the score by walking through this.tryCount and adding one point for every value less than 4
      let score = 0;
      let total = this.tryCount.length;
      for (let i = 0; i < total; i++) {
        if (this.tryCount[i] < 4) {
          score += 1;
        }
      }
      this.$quizMessages.text(`Quiz complete! You scored ${score} out of ${total}.`);
      this.$quizMessages.removeClass('solfege-message-refade');
      void this.$quizMessages[0].offsetWidth;
      this.$quizMessages.addClass('solfege-message-permanent');
    }
  };

  // constructor
  function SolfegeLadder($el) {
    // binds all methods to this
    util.bindAll(this);
    this.$el = $el;
    this.$interactionData = $el.find('.interaction_data');
    this.$containerEl = $el.find('.interaction_content');
    this.dataTables = this.$interactionData[0].getElementsByTagName('table');
    this.solfegeObject = this.formatData(this.dataTables);
    this.solfegeExercises = this.solfegeObject.exercises;
    this.solfegeType = this.solfegeObject.settings[0].type;
    this.solfegeScale = this.solfegeObject.settings[0].scale;
    this.doNoteID = getDoMIDIFromTable(this.solfegeScale);
    this.currentExercise = 0;
    this.piano = this.piano || window.piano;
  }
  const MIDIOffsetsFromC = {
    'C': 0,
    'D': 2,
    'E': 4,
    'F': 5,
    'G': 7,
    'A': 9,
    'B': 11
  };
  const accidentalOffsets = {
    'NATURAL': 0,
    'FLAT': -1,
    'SHARP': 1
  };
  function getDoMIDIFromTable(table) {
    let key = table;
    let [keyName, accidental] = key.toUpperCase().split(' ');
    return 36 + MIDIOffsetsFromC[keyName] + accidentalOffsets[accidental];
  }

  // prototype
  SolfegeLadder.prototype = {
    init() {
      this.$containerEl.attr('style', '');
      return (0, _renderTemplate.default)('solfege_ladder', 'main').then(content => {
        // put the rendered content on the page:
        this.$containerEl.append(content);

        // filter which notes show per scale type
        this.displayScale(this.solfegeType);

        // grab $selectors
        let $btnRef = this.$containerEl.find('#solfege-btn-ref'),
          $textRef = this.$containerEl.find('#solfege-scale-text'),
          $btnNext = this.$containerEl.find('#solfege-btn-next'),
          $btnBack = this.$containerEl.find('#solfege-btn-back'),
          $btnForward = this.$containerEl.find('#solfege-btn-forward'),
          $currentExercise = this.$containerEl.find('#exercise_number'),
          $totalExercise = this.$containerEl.find('#total_exercise_number');

        // set displays
        $textRef.text(this.solfegeScale);
        $totalExercise.text(this.solfegeObject.exercises.length);
        $currentExercise.text(this.currentExercise + 1);
        $btnRef.click(this.playRefTone);
        $btnNext.click(this.nextExercise);
        $btnForward.click(this.nextExercise);
        $btnBack.click(this.prevExercise);
        this.newExercise();
        if (this.solfegeExercises.length === 1) {
          $btnNext.remove();
          $btnForward.remove();
          $btnBack.remove();
        }
      });
    },
    nextExercise() {
      let $currentExercise = this.$containerEl.find('#exercise_number');
      if (this.currentExercise + 1 < this.solfegeExercises.length) {
        this.currentExercise += 1;
      } else {
        this.currentExercise = 0;
      }
      $currentExercise.text(this.currentExercise + 1);
      this.newExercise();
    },
    prevExercise() {
      let $currentExercise = this.$containerEl.find('#exercise_number');
      if (this.currentExercise > 0) {
        this.currentExercise -= 1;
      } else {
        this.currentExercise = this.solfegeExercises.length - 1;
      }
      $currentExercise.text(this.currentExercise + 1);
      this.newExercise();
    },
    newExercise() {
      let $exerciseEl = this.$containerEl.find('#solfege-exercise-area'),
        $svgEl = this.$containerEl.find('#solfege-svg-container'),
        $selectSpeed = this.$containerEl.find('#select_speed');
      let playSpeed = $selectSpeed[0].value * 1000;
      $svgEl.find('.note-rect').removeClass('animate-highlight').removeClass('selected-note').removeClass('wrong-note').removeClass('correct-note');
      let e = new SolfegeExercise($exerciseEl, $svgEl, $selectSpeed, playSpeed, this.doNoteID, this.solfegeExercises[this.currentExercise]);
      e.init();
    },
    formatData(dataTables) {
      let data = this.getDataFromTables(dataTables);
      data.exercises = this.formatExercises(data.exercises);
      return data;
    },
    convertLetterToOffsetNum(exercise) {
      // Convert named note to offset number
      let noteOffsets = {
        "do": 1,
        "re": 2,
        "mi": 3,
        "fa": 4,
        "sol": 5,
        "la": 6,
        "ti": 7,
        "di": 1,
        "ri": 2,
        "fi": 4,
        "si": 5,
        "li": 6,
        "ra": 2,
        "me": 3,
        "se": 5,
        "le": 6,
        "te": 7
      };

      // Find tone from note name
      let noteTones = {
        "do": "natural",
        "re": "natural",
        "mi": "natural",
        "fa": "natural",
        "sol": "natural",
        "la": "natural",
        "ti": "natural",
        "di": "sharp",
        "ri": "sharp",
        "fi": "sharp",
        "si": "sharp",
        "li": "sharp",
        "ra": "flat",
        "me": "flat",
        "se": "flat",
        "le": "flat",
        "te": "flat"
      };
      let currentNote = exercise.note ? exercise.note.toLowerCase() : null;
      let noteIsLetter = isNaN(currentNote);
      if (noteIsLetter) {
        exercise.note = noteOffsets[currentNote];
        exercise.tone = noteTones[currentNote];
      }
      return exercise;
    },
    formatExercises(exercises) {
      let exercisesCleaned = [];
      for (let k of exercises) {
        let arrayPosition = k.exercise_number - 1;
        k = this.convertLetterToOffsetNum(k);
        let selector = k.octave + "-" + k.note + "-" + k.tone;
        k.selector = selector;
        if (exercisesCleaned[arrayPosition]) {
          exercisesCleaned[arrayPosition].push(k);
        } else {
          let k_array = [k];
          exercisesCleaned.splice(arrayPosition, 0, k_array);
        }
      }
      return exercisesCleaned;
    },
    getDataFromTables(dataTables) {
      let keys = ['settings', 'exercises'];
      let columns = {
        'settings': ['type', 'scale'],
        'exercises': ['exercise_number', 'octave', 'note', 'tone']
      };
      let data = {};
      Ember.$(dataTables).each(function (i) {
        let table = dataTables[i],
          tableid = keys[i],
          column = columns[tableid];
        let arr = [];

        /* go through each row */
        Ember.$(table).find('tr').each(function (i) {
          /* skip first row (title row) */
          if (i === 0) {
            return true;
          }

          /* if table is empty, do not create object */
          if (/\S/.test(this.innerText) === true) {
            let args = {};
            /* go through each cell and match contents to preset keys */
            Ember.$(this).find('td').each(function (ndx) {
              if (Ember.$(this).children().length > 0) {
                args[column[ndx]] = Ember.$(this).text();
              }
            });
            arr.push(args);
          }
        });
        /* put keys into object under keyname of tableid */
        data[tableid] = arr;
      });
      return data;
    },
    displayScale(solfegeType) {
      let container = Ember.$(this.$containerEl);
      let scaleMin = container.find('.scale-maj-min'),
        scaleMod = container.find('.scale-modal'),
        scaleChrom = container.find('.scale-chrom'),
        exerciseCounter = container.find('.solfege-exercise-count');
      solfegeType = solfegeType.toLowerCase();
      if (solfegeType === 'major') {
        this.$el.find('.mode_selector').attr('id', 'layout_default');
        this.$el.find('.mode_selector').attr('class', 'mode_selector');
        this.$el.find('#solfege-title').text('Solfege Ladder');
        scaleMin.hide();
        scaleMod.hide();
        scaleChrom.hide();
      } else if (solfegeType === 'minor') {
        this.$el.find('.mode_selector').attr('id', 'layout_default');
        this.$el.find('.mode_selector').attr('class', 'mode_selector');
        this.$el.find('#solfege-title').text('Solfege Ladder');
        scaleMod.hide();
        scaleChrom.hide();
      } else if (solfegeType === 'modal') {
        this.$el.find('.mode_selector').attr('id', 'layout_default');
        this.$el.find('.mode_selector').attr('class', 'mode_selector');
        this.$el.find('#solfege-title').text('Solfege Ladder');
        scaleChrom.hide();
      } else if (solfegeType === 'drill major modes') {
        // Change id on class '.mode_selector' to '#layout_ttone_maj'
        this.$el.find('.mode_selector').attr('id', 'layout_ttone_maj');
        this.$el.find('#solfege-title').text('Tendency Tone Pairs');
        exerciseCounter.addClass('hidden');
      } else if (solfegeType === 'drill minor modes') {
        // Change id on class '.mode_selector' to '#layout_ttone_min'
        this.$el.find('.mode_selector').attr('id', 'layout_ttone_min');
        this.$el.find('#solfege-title').text('Tendency Tone Pairs');
        exerciseCounter.addClass('hidden');
      } else if (solfegeType === 'drill major key') {
        // Change id on class '.mode_selector' to '#layout_ttone_maj'
        this.$el.find('.mode_selector').attr('id', 'layout_ttone_maj');
        this.$el.find('.mode_selector').addClass('maj_only');
        this.$el.find('#solfege-title').text('Tendency Tone Pairs');
        exerciseCounter.addClass('hidden');
      } else if (solfegeType === 'drill minor key') {
        // Change id on class '.mode_selector' to '#layout_ttone_min'
        this.$el.find('.mode_selector').attr('id', 'layout_ttone_min');
        this.$el.find('.mode_selector').addClass('min_only');
        this.$el.find('#solfege-title').text('Tendency Tone Pairs');
        exerciseCounter.addClass('hidden');
      } else if (solfegeType.indexOf('expanding pallet') > -1) {
        let palletStep = solfegeType.split('expanding pallet ');
        let palletSize = palletStep.length > 1 ? palletStep[1] : 0;
        this.$el.find('.mode_selector').attr('id', 'layout_exp_pal');
        this.$el.find('#solfege-title').text('Tendency Tone Pairs');
        exerciseCounter.addClass('hidden');
        this.$el.find('.mode_selector').addClass('ep_step_' + palletSize);
      }
    },
    playRefTone() {
      let doNote = [this.doNoteID];
      if (this.piano && this.piano.playNotes) {
        this.piano.playNotes(doNote);
      }
    }
  };
  var _default = _exports.default = SolfegeLadder;
});