define("bocce/mixins/interactions/slideshow", ["exports", "bocce/mixins/support/render-template"], function (_exports, _renderTemplate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function Slideshow($element, data) {
    data = convertToJSON($element[0], data);

    // class vars
    this.el = $element;
    this.playerNum = $element[0].id.replace('area', '');

    /* eslint-disable-next-line ember/no-jquery */
    this.data = data.initial_json || [];
    this.images = data.assets.images.map(image => {
      return {
        img_path: image.img_path || image,
        alt: image.alt || ""
      };
    });
    this.zoomLimitMax = data.assets.assetsConfig.zoomLimitMax;
    this.zoomLimitMin = data.assets.assetsConfig.zoomLimitMin;
    this.defaultVolume = data.assets.assetsConfig.defaultVolume;
    this.audioPath = data.assets.audio_track[0];
    this.totalPages = this.getTotalPages(this.data);
    this.indxz = 0;
    this.isPlaybarAvailable = !!this.data[0].playbarPositions;

    // If you need to allow events to be passed through the wrapper. For example to allow clicking on a link or an input then you can use filterTarget like so.
    // this.el.find('.page_container').kinetic({
    //   cursor: " "
    // });
  }
  function convertToJSON(element, data) {
    /* eslint-disable-next-line ember/no-jquery */
    const initialJson = Ember.$.parseJSON(data.initial_json || null) || [];
    const images = data.assets.images;
    const audioPath = data.assets.audio_track[0];
    const jplayerPath = data.jplayer_swf_path;
    return {
      slide_id: element.id,
      assets: {
        audio_track: [audioPath],
        images,
        assetsConfig: {
          zoomLimitMin: -4,
          zoomLimitMax: 5,
          defaultVolume: 0.6
        }
      },
      jplayer_swf_path: jplayerPath,
      initial_json: initialJson
    };
  }
  Slideshow.prototype = {
    init: function () {
      // let content =
      //   `<div class="Slideshow__header slideshow-player">
      //         <audio class="player" src=""></audio>
      //         <div class="audio-player-controls">
      //             <button tabindex="11" class="playpause tababble"
      //                 aria-label="Play slideshow">
      //                 <i class="fa fa-play" aria-hidden=""></i>
      //             </button>
      //             <button tabindex="11" class="playpause inactive tababble"
      //                 aria-label="Pause slideshow">
      //                 <i class="fa fa-pause" aria-hidden=""></i>
      //             </button>
      //             <input id="player-seek" tabindex="12" class="player-seek tababble" min="0" max="100" step="1" value="0" onchange="" aria-label="Seek Audio" type="range">
      //
      //             <button tabindex="12" class="mute mute-off tababble" aria-label="Mute Audio">
      //                 <i class=" fa fa-volume-up" aria-hidden=""></i>
      //             </button>
      //             <button tabindex="12" class="mute mute-on inactive tababble"
      //                 aria-label="Unmute Audio">
      //                 <i class=" fa fa-volume-mute" aria-hidden=""></i>
      //             </button>
      //             <input id="volumeSeek" tabindex="12" class="volume-seek tababble" min="0.0"
      //                 max="1.0" value="0.6" step="0.1" onchange="" aria-label="Seek Audio volume"
      //                 type="range">
      //             <span class="speed-select-wrapper">
      //                 <select tabindex="12" class="speed-select tababble"
      //                     aria-label="Playback speed options">
      //                     <option aria-label="0.25" value="0.25">0.25</option>
      //                     <option aria-label="0.5" value="0.5">0.5</option>
      //                     <option aria-label="0.75" value="0.75">0.75</option>
      //                     <option aria-label="Normal" value="1">Normal</option>
      //                     <option aria-label="1.25" value="1.25">1.25</option>
      //                     <option aria-label="1.5" value="1.5">1.5</option>
      //                     <option aria-label="1.75" value="1.75">1.75</option>
      //                     <option aria-label="2.0" value="2">2.0</option>
      //                     <option aria-label="2.5" value="2.5">2.5</option>
      //                     <option aria-label="3.0" value="3">3.0</option>
      //                     option>
      //                 </select>
      //             </span>
      //             <button tabindex="12" class="loop tababble" aria-label="Loop Audio off">
      //                 <i class="fa fa-repeat" aria-hidden="true"></i>
      //             </button>
      //         </div>
      //
      //         <div class="zoom-controls">
      //             <button tabindex="13" class="zoom_in tababble" aria-label="Zoom In">
      //                 <i class="fa fa-search-plus" aria-hidden="true"></i>
      //             </button>
      //             <button tabindex="13" class="zoom_out tababble" aria-label="Zoom Out">
      //                 <i class="fa fa-search-minus" aria-hidden="true"></i>
      //             </button>
      //             <button tabindex="13" class="zoom_zero hidden tababble" aria-label="Reset Zoom">
      //                 <i class="fa fa-undo" aria-hidden="true"></i>
      //                 <i class="fa fa-minus" aria-hidden="true"></i>
      //             </button>
      //         </div>
      //
      //         <div class="page-controls fr">
      //             <button tabindex="15" class="back noclick tababble " aria-label="Previous Key">
      //                 <i class="fa fa-arrow-circle-left"></i>
      //             </button>
      //             <span class="pages">1 of 1</span>
      //             <button tabindex="15" class="forward tababble" aria-label="Next Page">
      //                 <i class="fa fa-arrow-circle-right" aria-hidden="true"></i>
      //             </button>
      //         </div>
      //     </div>
      //     <div data-id="3" class="page_container Slideshow__container grabbable">
      //         <div class="image_area Slideshow__show">
      //             <div class="image_container Slideshow__show--image">
      //                 <img src="">
      //             </div>
      //         </div>
      //         <div class="highlight_container Slideshow__show--highlight">
      //             <div class="playbar"></div> <svg class="highlight_svg" viewBox="0 0 140 150"
      //                 preserveAspectRatio="xMidYMid meet">
      //                 <rect class="highlight hidden"></rect>
      //             </svg>
      //         </div>
      //   </div>`;
      // let contentArea = this.el.find('.interaction_content');
      // contentArea.append(content);
      let primary_setup = (0, _renderTemplate.default)('Slideshow', 'main', {
        id: this.playerNum
      }).then(content => {
        let contentArea = this.el.find('.interaction_content');
        contentArea.append(content);
      });
      primary_setup.then(() => {
        this.images.forEach((path, index) => {
          let img = new Image();
          img.src = path.img_path;
          img.alt = path.alt;
          if (img.src.search(".svg") !== -1) {
            this.isSVG = true;
          }
          if (index === 0) {
            img.onload = () => {
              let contentArea = this.el.find('.interaction_content'),
                imgHeight = img.height,
                imgWidth = img.width;
              // find highlightSVG as a JavaScript object, because jQuery sets 'viewBox' incorrectly
              this.highlightSVG = contentArea[0].getElementsByClassName('highlight_svg')[0];
              this.firstImageDimensions = {
                height: imgHeight,
                width: imgWidth
              };
              this.zoomedIn = false;
              this.setHighlightViewbox(false, false, this.highlightSVG);
              // add first image
              this.changeImage(this.images, 0);
            };
          }
          this.images[index] = img;
        });
        this.addPlayer(this.getPlayer(), this.audioPath);
        let contentArea = this.el.find('.interaction_content');
        if (this.el.parents('.hideSlideshowControls')) {
          if (this.totalPages <= 1 || this.el.parents('.hideSlideshowControls').length > 0) {
            contentArea.find('.page-controls').addClass('hidden');
          }
        }

        // set initial page info
        this.setPages(1, this.totalPages);
        let self = this;
        //initialize nav links
        this.el.find('.page-controls .back').on('click', function () {
          self.backPage(self);
        });
        this.el.find('.page-controls .forward').on('click', function () {
          self.forwardPage(self);
        });

        // initialize zoom controls
        this.addZoomControls();

        // initialized playback rate controls
        this.addPlaybackSpeedControls();
      });
    },
    addBgImage: function (target, images, index) {
      /* eslint-disable-next-line ember/no-jquery */
      let img = Ember.$(images[index])[0],
        url = 'url(' + img.src + ')';
      target.addClass('bg-image');
      target.css('background-image', url);
    },
    addPlayer: function (playerEl, audioPath) {
      var seek = this.el.find('.audio-player-controls #player-seek'),
        controls = this.el.find('.audio-player-controls .playpause'),
        loop = this.el.find('.audio-player-controls .loop'),
        highlight = this.el.find('.highlight'),
        volumeSeek = this.el.find('.audio-player-controls #volumeSeek'),
        muteBtn = this.el.find('.audio-player-controls .mute'),
        muteOffBtn = this.el.find('.audio-player-controls .mute.mute-off'),
        muteOnBtn = this.el.find('.audio-player-controls .mute.mute-on'),
        playbar = this.el.find('.playbar'),
        _self = this;
      playerEl.attributes.src.value = audioPath;
      playerEl.load();

      /* eslint-disable-next-line ember/no-jquery */
      controls.on('click', Ember.$.proxy(this.playPauseAudio, {
        contex: this,
        audio: playerEl,
        controls: controls,
        playbar: playbar
      }));
      /* eslint-disable-next-line ember/no-jquery */
      loop.on('click', Ember.$.proxy(this.loopToggle, {
        audio: playerEl,
        loop: loop
      }));
      let beforeMuteVol = this.defaultVolume;
      muteBtn.on('click', function () {
        playerEl.muted = !playerEl.muted;
        if (playerEl.muted) {
          muteOnBtn.removeClass("inactive").focus();
          muteOffBtn.addClass("inactive");
          beforeMuteVol = playerEl.volume;
          volumeSeek.val(0);
          playerEl.volume = 0;
        } else {
          muteOnBtn.addClass("inactive");
          muteOffBtn.removeClass("inactive").focus();
          volumeSeek.val(beforeMuteVol);
          playerEl.volume = beforeMuteVol;
        }
      });

      // volume seek
      volumeSeek.on('input.fndtn.slider', Ember.$.proxy(this.seekVolume, {
        volSeek: volumeSeek,
        player: playerEl,
        muteOffBtn: muteOffBtn,
        muteOnBtn: muteOnBtn
      }));
      volumeSeek.val(this.defaultVolume);
      playerEl.volume = this.defaultVolume;

      /* eslint-disable-next-line ember/no-jquery */
      seek.on('change.fndtn.slider', Ember.$.proxy(this.seekAudio, playerEl));

      /* eslint-disable-next-line ember/no-jquery */
      Ember.$(playerEl)
      /* eslint-disable-next-line ember/no-jquery */.on('timeupdate', Ember.$.proxy(this.updateSeek, seek))
      /* eslint-disable-next-line ember/no-jquery */.on('ended', Ember.$.proxy(this.onEnded, controls, playbar))
      /* eslint-disable-next-line ember/no-jquery */.on('pause', Ember.$.proxy(this.onPaused, controls))
      /* eslint-disable-next-line ember/no-jquery */.on('playing', Ember.$.proxy(this.isPlaying, controls, playbar));
      if (this.isPlaybarAvailable) {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$(playerEl).on('timeupdate', Ember.$.proxy(this.onTimeUpdate, {
          context: this,
          highlight: highlight,
          highlightSVG: this.highlightSVG,
          playbar: playbar,
          player: playerEl
        })).on('timeupdate', Ember.$.proxy(this.highlightPlaybar, {
          context: this,
          playbar: playbar,
          player: playerEl
        }));
        playerEl.onloadeddata = function () {
          // let timeline = 0;
          for (let indx = 0; indx < _self.data.length; indx++) {
            if (_self.data[indx].playbarPositions) {
              let totalSectionTime = playerEl.duration / _self.audioSectionCount;
              let moveleftpoints = [{
                "time": 0,
                "leftVal": 0
              }];
              let leftMoveAmount = totalSectionTime * _self.data[indx].playbarPositions.moveAmount / 100;
              let temp1 = (10 - leftMoveAmount) / 100;
              let leftMoveCounter = leftMoveAmount;
              for (let i = 0; i < 100 / _self.data[indx].playbarPositions.moveAmount; i++) {
                moveleftpoints[i] = {
                  "time": _self.data[indx].time + leftMoveAmount,
                  "leftVal": (leftMoveAmount + temp1 * 10) * 10
                };
                moveleftpoints[i].leftVal = moveleftpoints[i].leftVal.toFixed(6);
                moveleftpoints[i].time = moveleftpoints[i].time.toFixed(6);
                leftMoveAmount = leftMoveAmount + leftMoveCounter;
              }
              _self.data[indx].playbarPositions.moveLeftPoints = moveleftpoints;
            }
          }
        };
        this.playbarVal.y = this.data[0].playbarPositions.y;
        this.playbarVal.x = this.data[0].playbarPositions.x;
        playbar.css({
          "top": this.playbarVal.y + "%",
          "left": this.playbarVal.x + "%",
          "height": this.data[0].playbarPositions.height + "%"
        });
      } else {
        playbar.addClass("hidden");
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$(playerEl).on('timeupdate', Ember.$.proxy(this.onTimeUpdate, {
          context: this,
          highlight: highlight,
          highlightSVG: this.highlightSVG,
          playbar: playbar,
          player: playerEl
        }));
      }
    },
    onTimeUpdate: function (e) {
      // round current time to 3 decimal places
      let currentTime = e.target.currentTime,
        i,
        item,
        time,
        bgImageIndex;
      // TODO: slightly inefficient, but doesn't seem to choke at all

      for (i = this.context.data.length - 1; i >= 0; i--) {
        item = this.context.data[i];
        time = item.time;

        // compares time and currentTime,
        // rounding to 3 decimal places.
        if (time - currentTime <= 0.001) {
          // only execute the rest of this if we encounter a
          // new page/item:
          if (i === this.context.currentIndex) {
            return;
          }

          // change image if necessary
          if (item.imageIndex !== this.context.currentImageIndex) {
            this.context.changeImage(this.context.images, item.imageIndex);

            // update page numbers for new images
            this.context.setPages(item.pageIndex, this.context.totalPages);
          }
          if (item.playbarPositions) {
            this.context.playbarVal = item.playbarPositions;
            this.playbar.css({
              "top": item.playbarPositions.y + "%",
              "left": item.playbarPositions.x + "%",
              "height": item.playbarPositions.height + "%"
            });

            // render the bg image if it exists
            bgImageIndex = item.playbarPositions.bgImageIndex;
            if (bgImageIndex !== null) {
              this.context.addBgImage(this.highlight, this.context.images, bgImageIndex);
            } else {
              this.context.removeBgImage(this.highlight);
            }
          } else {
            this.playbar.removeClass('show-playbar');
          }
          if (item.rect) {
            this.highlight.attr('class', 'highlight');
            this.highlight.attr('x', item.rect.x);
            this.highlight.attr('y', item.rect.y);
            this.highlight.attr('width', item.rect.w);
            this.highlight.attr('height', item.rect.h);

            // set color and opacity
            this.highlight.css('fill', item.rect.color);
            this.highlight.css('opacity', item.rect.opacity);

            // render the bg image if it exists
            bgImageIndex = item.rect.bgImageIndex;
            if (bgImageIndex !== null) {
              this.context.addBgImage(this.highlight, this.context.images, bgImageIndex);
            } else {
              this.context.removeBgImage(this.highlight);
            }
          } else {
            this.highlight.attr('class', 'highlight hidden');
          }
          this.context.currentIndex = i;
          break;
        }
      }
    },
    playbarVal: {
      y: 0,
      x: 0,
      height: 26,
      moveAmount: 0.25,
      bgImageIndex: null,
      moveLeftPoints: [],
      moveleftCounter: 1
    },
    highlightPlaybar: function (e) {
      if (this.player.duration) {
        for (let i = 0; i < this.context.playbarVal.moveLeftPoints.length; i++) {
          //if (time - this.player.currentTime <= 0.001) {
          if (this.player.currentTime > this.context.playbarVal.moveLeftPoints[i].time) {
            this.playbar.css({
              "left": this.context.playbarVal.moveLeftPoints[i].leftVal + "%"
            });
          }
        }
      }
    },
    // Audio Volume Methods
    seekVolume: function (item) {
      let currentVal = this.volSeek.val();
      if (currentVal === 0) {
        this.player.muted = true;
        this.player.volume = currentVal;
        this.muteOnBtn.removeClass("inactive");
        this.muteOffBtn.addClass("inactive");
      } else {
        this.player.muted = false;
        this.player.volume = currentVal;
        this.muteOnBtn.addClass("inactive");
        this.muteOffBtn.removeClass("inactive");
      }
    },
    // Audio Player Methods
    seekAudio: function (item) {
      if (this.duration) {
        this.currentTime = parseInt(item.target.value) / 100 * this.duration;
      }
    },
    updateSeek: function (item) {
      if (item.target.duration) {
        this.val(Math.round(1000 * (item.target.currentTime / item.target.duration)) / 10);
      } else {
        this.val(50);
      }
    },
    playPauseAudio: function () {
      if (this.audio.buffered.length < 1) {
        this.audio.load();
      }
      if (this.audio.paused) {
        this.audio.play();
      } else {
        this.audio.pause();
      }
    },
    loopToggle: function () {
      if (this.audio.loop) {
        this.loop.removeClass('active');
        this.audio.loop = false;
        this.loop.attr("aria-label", "Loop Audio off");
      } else {
        this.loop.addClass('active');
        this.audio.loop = true;
        this.loop.attr("aria-label", "Loop Audio on");
      }
      this.loop.focus();
    },
    onPaused: function () {
      this.removeClass('inactive');
      this.get(1).classList.add('inactive');
    },
    onEnded: function (evt) {
      this.removeClass('inactive');
      this.get(1).classList.add('inactive');
      /* eslint-disable-next-line ember/no-jquery */
      Ember.$(evt).removeClass('show-playbar');
    },
    isPlaying: function (evt) {
      this.removeClass('inactive');
      this.get(0).classList.add('inactive');
      /* eslint-disable-next-line ember/no-jquery */
      Ember.$(evt).addClass('show-playbar');
    },
    setHighlightViewbox: function (images, index, highlightSVG) {
      let imageWidth, imageHeight;
      if (images.length > 0 && index) {
        imageWidth = images[index].width;
        imageHeight = images[index].height;
      }
      if (!imageWidth || !imageHeight && imageWidth === 0 || imageHeight === 0) {
        imageWidth = this.firstImageDimensions.width;
        imageHeight = this.firstImageDimensions.height;
      }
      if (!highlightSVG || !imageWidth || !imageHeight) {
        return;
      }

      // change svg viewBox dimensions to match image's, so highlight is responsive
      highlightSVG.setAttribute('viewBox', '0 0 ' + imageWidth + ' ' + imageHeight);
    },
    changeImage: function (images, index) {
      let imageContainer, img, imageWidth, highlightContainer;
      imageContainer = this.el.find('.image_area > .image_container');
      imageContainer.empty();
      highlightContainer = this.el.find('.Slideshow .highlight_container');
      img = images[index];
      this.setHighlightViewbox(images, index, this.highlightSVG);

      /* eslint-disable-next-line ember/no-jquery */
      Ember.$(img).appendTo(imageContainer);
      if (this.isSVG) {
        imageWidth = "90%";
      } else {
        imageWidth = img.naturalWidth;
      }
      if (!imageWidth || imageWidth === 0) {
        imageWidth = this.firstImageDimensions.width;
      }
      if (this.zoomedIn === false) {
        imageContainer.css('max-width', imageWidth);
        highlightContainer.css('max-width', imageWidth);
      }
      this.currentImageIndex = index;
    },
    backPage: function (_self) {
      let time,
        buttonNext = _self.el.find('.page-controls .forward');
      buttonNext.removeClass('noclick');
      if (_self.currentPage > 1) {
        _self.currentPage -= 1;
        time = _self.getTimeForPage(_self.currentPage, _self.data);
        _self.getPlayer().currentTime = time;
      }
    },
    forwardPage: function (_self) {
      let time,
        buttonPrev = _self.el.find('.page-controls .back');
      buttonPrev.removeClass('noclick');
      if (_self.currentPage < _self.totalPages) {
        _self.currentPage += 1;
        time = _self.getTimeForPage(_self.currentPage, _self.data);
        _self.getPlayer().currentTime = time;
      }
    },
    getPlayer: function () {
      return this.el.find('audio').get(0);
    },
    getTimeForPage: function (pageIndex, data) {
      // TODO: figure out how to break this loop
      try {
        data.forEach(function (i) {
          if (i.pageIndex === pageIndex) {
            throw i.time;
          }
        });
      } catch (e) {
        return e;
      }
    },
    getTotalPages: function (data) {
      let currentImageIndex = -1;
      let total = 0;
      let notationCounter = 0;
      this.audioSectionCount = 0;
      for (let i = 0; i < data.length; i++) {
        const p = data[i];
        let index = p.imageIndex;
        if (currentImageIndex !== index) {
          currentImageIndex = index;
          total++;
        }
        notationCounter++;

        // add a page index onto data obj
        p.pageIndex = total;
      }
      this.audioSectionCount = notationCounter;
      return total;
    },
    removeBgImage: function (target) {
      target.css('background-image', '');
      target.removeClass('bg-image');
    },
    setPages: function (current, total) {
      this.currentPage = current;
      let buttonPrev = this.el.find('.page-controls .back');
      let buttonNext = this.el.find('.page-controls .forward');
      this.el.find('.page-controls .pages').text(current + ' of ' + total);
      this.el.find('.page-controls .pages').attr('aria-label', 'page ' + current + ' of ' + total);
      if (current >= total) {
        buttonNext.addClass('noclick');
        buttonNext.attr('aria-label', "Next Page disabled");
      } else if (current <= 1) {
        buttonPrev.addClass('noclick');
        buttonPrev.attr('aria-label', "Previous Page disabled");
      } else {
        buttonPrev.removeClass('noclick');
        buttonNext.removeClass('noclick');
        buttonNext.attr('aria-label', "Next Page");
        buttonPrev.attr('aria-label', "Previous Page");
      }
    },
    addPlaybackSpeedControls: function () {
      let $speedSelect = this.el.find('.audio-player-controls .speed-select'),
        audioPlayer = this.getPlayer(),
        self = this;
      $speedSelect.val(audioPlayer.playbackRate);
      $speedSelect.on('change', function (evt) {
        audioPlayer.playbackRate = evt.target.value;
        self.playbarVal.moveAmount = parseInt(audioPlayer.playbackRate);
      });
    },
    addZoomControls: function () {
      let zoomIn = this.el.find('.zoom-controls .zoom_in'),
        zoomOut = this.el.find('.zoom-controls .zoom_out'),
        zoomZero = this.el.find('.zoom-controls .zoom_zero'),
        pageContainer = this.el.find('.Slideshow .page_container'),
        highlightContainer = this.el.find('.Slideshow .highlight_container'),
        imageContainer = this.el.find('.Slideshow .image_area .image_container'),
        zoomLimitDown = this.zoomLimitMin,
        zoomLimitUp = this.zoomLimitMax;
      this.zoomIn = zoomIn;
      this.zoomOut = zoomOut;
      this.zoomZero = zoomZero;
      this.zoomDegree = 0;

      /* eslint-disable-next-line ember/no-jquery */
      zoomIn.on('click', Ember.$.proxy(this.zoom, {
        context: this,
        imageContainer: imageContainer,
        highlightContainer: highlightContainer,
        pageContainer: pageContainer,
        zoomLimitUp: zoomLimitUp,
        zoomLimitDown: zoomLimitDown
      }, 'in'));
      /* eslint-disable-next-line ember/no-jquery */
      zoomOut.on('click', Ember.$.proxy(this.zoom, {
        context: this,
        imageContainer: imageContainer,
        highlightContainer: highlightContainer,
        pageContainer: pageContainer,
        zoomLimitUp: zoomLimitUp,
        zoomLimitDown: zoomLimitDown
      }, 'out'));
      /* eslint-disable-next-line ember/no-jquery */
      zoomZero.on('click', Ember.$.proxy(this.zoom, {
        context: this,
        imageContainer: imageContainer,
        highlightContainer: highlightContainer,
        pageContainer: pageContainer,
        zoomLimitUp: zoomLimitUp,
        zoomLimitDown: zoomLimitDown
      }, 'zero'));
    },
    zoom: function (zoomDirection) {
      let btnZoomIn = this.context.zoomIn,
        btnZoomOut = this.context.zoomOut,
        btnZoomZero = this.context.zoomZero,
        imageContainer = this.imageContainer,
        highlightContainer = this.highlightContainer,
        pageContainer = this.pageContainer,
        _isSVG = this.context.isSVG;
      function zoomView(zoomDegree, zoomLimitUp) {
        btnZoomIn.removeClass('zoom_limit');
        let zoomPercent = zoomDegree * 50 + 100 + '%';
        if (_isSVG) {
          zoomPercent = zoomDegree * 10 + 90 + '%';
        }
        imageContainer.css('width', zoomPercent);
        highlightContainer.css('width', zoomPercent);
        switch (zoomDegree) {
          case 0:
            btnZoomIn.attr('aria-label', 'zoom in');
            btnZoomOut.attr('aria-label', 'zoom out');
            break;
          default:
            btnZoomIn.attr('aria-label', zoomDegree + 'X out of ' + zoomLimitUp + 'X zoom in');
            btnZoomOut.attr('aria-label', zoomDegree + 'X out of ' + zoomLimitUp + 'X zoom out');
            break;
        }
      }

      // update zoom_degree variable
      if (zoomDirection === 'in') {
        this.context.zoomDegree++;
      } else if (zoomDirection === 'out') {
        this.context.zoomDegree--;
      } else if (zoomDirection === 'zero') {
        this.context.zoomDegree = 0;
      }
      if (this.context.zoomDegree <= 0) {
        btnZoomZero.addClass('hidden');
        pageContainer.removeClass('zoom');
        if (this.context.zoomDegree < 0) {
          btnZoomZero.removeClass('hidden');
        }
      } else if (this.context.zoomDegree > 0) {
        btnZoomZero.removeClass('hidden');
        pageContainer.addClass('zoom');
      }

      // check for hitting zoom limits
      if (this.context.zoomDegree >= this.zoomLimitUp) {
        this.context.zoomDegree = this.zoomLimitUp;
        zoomView(this.context.zoomDegree, this.zoomLimitUp);
        btnZoomIn.addClass('zoom_limit');
      } else if (this.context.zoomDegree <= this.zoomLimitDown) {
        btnZoomOut.addClass('zoom_limit');
        this.context.zoomDegree = this.zoomLimitDown;
        zoomView(this.context.zoomDegree, this.zoomLimitUp);
        let imageWidth;
        if (_isSVG) {
          imageWidth = "90%";
        } else {
          imageWidth = imageContainer.find('img')[0].naturalWidth;
        }
        imageContainer.css('max-width', imageWidth);
        highlightContainer.css('max-width', imageWidth);
        this.context.zoomedIn = false;
      } else if (this.context.zoomDegree > this.zoomLimitDown && this.context.zoomDegree < this.zoomLimitUp) {
        btnZoomOut.removeClass('zoom_limit');
        imageContainer.css('max-width', 'none');
        highlightContainer.css('max-width', 'none');
        this.context.zoomedIn = true;
        zoomView(this.context.zoomDegree, this.zoomLimitUp);
      }
    },
    audioPath: '',
    currentImageIndex: 0,
    // keep track of the page we're on
    currentIndex: -1,
    currentPage: 1,
    data: [],
    images: [],
    el: {},
    defaultVolume: 1,
    zoomLimitMax: 3,
    zoomLimitMin: -3,
    isSVG: false,
    totalPages: 0,
    audioSectionCount: 0,
    isPlaybarAvailable: true
  };
  var _default = _exports.default = Slideshow;
});