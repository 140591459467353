define("bocce/models/comment", ["exports", "@ember-data/model", "bocce/mixins/attachments-mixin"], function (_exports, _model, _attachmentsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // app/models/comment.js
  /* eslint-disable-next-line ember/no-mixins */
  var _default = _exports.default = _model.default.extend(_attachmentsMixin.default, {
    db_id: (0, _model.attr)('number'),
    comment: (0, _model.attr)('string'),
    user: (0, _model.belongsTo)('user', {
      async: false
    }),
    is_deleted: (0, _model.attr)('boolean'),
    submission: (0, _model.belongsTo)('submission', {
      async: false
    }),
    file_ids: (0, _model.attr)(),
    attachments: (0, _model.attr)(),
    created_at: (0, _model.attr)('date')
  });
});