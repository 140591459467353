define("bocce/components/adblock-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    adblockWhitelistInstructions: false,
    actions: {
      dismissAdblockWarning() {
        this.session.set('hasAdBlock', false);
        localStorage.setItem('dismissedAdblockWarning', true);
      },
      toggleABWhitelistInstructions() {
        let whiteListInstructions = this.get('adblockWhitelistInstructions');
        this.set('adblockWhitelistInstructions', !whiteListInstructions);
      },
      imageInNewTab() {
        let imgSrc = event.target.src;
        if (imgSrc) {
          window.open(imgSrc, '_blank');
        }
      }
    }
  });
});