define("bocce/templates/components/side-panel/panel-list-item/quiz-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "HwiSanCD",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"assignment-submission-grade\"],[12],[2,\"\\n    \"],[1,[30,[36,2],[[35,1,[\"score\"]],[35,1,[\"possible\"]]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"i\"],[15,0,[34,0]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"iconClass\",\"latestAttempt\",\"letter-grade\",\"if\"]}",
    "meta": {
      "moduleName": "bocce/templates/components/side-panel/panel-list-item/quiz-icon.hbs"
    }
  });
});