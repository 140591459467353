define("bocce/controllers/userprofile", ["exports", "bocce/mixins/editable", "bocce/mixins/uploadable"], function (_exports, _editable, _uploadable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend(_editable.default, _uploadable.default, {
    classroom: Ember.inject.controller(),
    userprofileService: Ember.inject.service('userprofile'),
    activeUser: false,
    assignmentsLocked: true,
    unlockWeek: 2,
    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    self: Ember.computed('userprofileService.activeUser.content', function () {
      /* eslint-disable-next-line ember/no-get */
      if (this.get('userprofileService.activeUser.content.id') === this.get('session.user.id')) {
        return true;
      }
      return false;
    }),
    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    degree: Ember.computed('userprofileService.activeUser.content', function () {
      /* eslint-disable-next-line ember/no-get */
      var allEnrolled = this.get('userprofileService.activeUser.enrolled'),
        enrolledUser;
      if (allEnrolled) {
        /* eslint-disable-next-line ember/no-get */
        enrolledUser = allEnrolled.findBy('section.id', this.get('classroom.model.section.id'));
        if (enrolledUser) {
          return enrolledUser.get('degree');
        }
      }
      return '';
    }),
    /* eslint-disable-next-line ember/no-observers */
    earlyUnlockCheck: Ember.observer('userprofileService.activeUser.content', function () {
      /* eslint-disable-next-line ember/no-get */
      if (!this.get('session.isStaff')) {
        return;
      }
      /* eslint-disable-next-line ember/no-get */
      let userId = this.get('userprofileService.activeUser.content.id');
      /* eslint-disable-next-line ember/no-jquery */
      Ember.$.ajax({
        type: 'POST',
        url: '/interface/early_unlock_check/',
        data: JSON.stringify({
          user_id: userId,
          term_id: this.get('session.termID')
        }),
        success: latestUnlockWeek => {
          this.set('earlyUnlock', latestUnlockWeek);
        }
      });
    }),
    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    accommodation: Ember.computed('userprofileService.activeUser.content', function () {
      /* eslint-disable-next-line ember/no-get */
      let allEnrolled = this.get('userprofileService.activeUser.enrolled'),
        enrolledUser;
      if (allEnrolled) {
        /* eslint-disable-next-line ember/no-get */
        enrolledUser = allEnrolled.findBy('section.id', this.get('classroom.model.section.id'));
        if (enrolledUser && enrolledUser.get('accommodation')) {
          return enrolledUser.get('accommodation');
        }
      }
      return false;
    }),
    actions: {
      unlockAssignments: function (userId) {
        this.set('assignmentsLocked', false);
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$.ajax({
          type: 'POST',
          url: `/interface/sections/${this.session.get('section.id')}/assignments/0/user/${userId}/purge_assignment/`,
          data: {},
          dataType: 'text',
          success: () => {
            this.set('unlockingAssignments', false);
            Ember.debug('Assignments purged successfully');
          },
          error: error => {
            this.set('assignmentsLocked', true);
            Ember.debug('Unable to purge assignments. Something went wrong: ');
            Ember.debug(error);
          }
        });
      },
      stripAssignmentTimers: function (userId) {
        this.set('timersStripped', false);
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$.ajax({
          type: 'POST',
          url: `/interface/sections/${this.session.get('section.id')}/assignments/0/user/${userId}/strip_timer/`,
          data: {},
          dataType: 'text',
          success: () => {
            this.set('strippingTimers', false);
            Ember.debug('Assignment timers stripped successfully');
          },
          error: error => {
            this.set('timersStripped', true);
            Ember.debug('Unable to strip assignment timers. Something went wrong: ');
            Ember.debug(error);
          }
        });
      },
      refreshZoomId: function (userId) {
        /* eslint-disable-next-line ember/no-jquery */
        this.set('refreshingZoom', true);
        Ember.$.ajax({
          type: 'POST',
          url: `/interface/users/${userId}/refreshZoomId/`,
          data: {},
          dataType: 'text',
          success: () => {
            this.set('refreshingZoom', false);
            this.set('zoomRefreshed', true);
            Ember.debug('Zoom Credentials refreshed successfully!');
          },
          error: error => {
            this.set('refreshingZoom', false);
            Ember.debug('Unable to refresh user Zoom Credentials');
          }
        });
      },
      // Early lesson unlocking
      // Only usable by admins (requires auth)
      changeUnlockWeek: function (weekNumber) {
        this.set('unlockWeek', weekNumber);
      },
      // Revoke selected user's early access
      earlyLock: function (userId) {
        this.set("working", "Locking...");

        /* eslint-disable-next-line ember/no-jquery */
        Ember.$.ajax({
          type: 'POST',
          url: '/interface/early_lock/',
          data: JSON.stringify({
            user_id: userId,
            course_id: this.get('session.course.id'),
            term_id: this.get('session.termID')
          }),
          success: () => {
            console.log('User\'s early access has been revoked.');
            this.set('earlyUnlock', false);
            this.set("working", false);
          },
          error: e => {
            this.set("working", false);
            Ember.debug('error occurred while locking');
            Ember.debug(e);
          }
        });
      },
      // Grant selected user early access up until week # "unlockWeek"
      earlyUnlock: function (userId) {
        // This functionality is only available for admins
        // Interface will reject teacher (and student) attempts
        let unlockWeek = this.unlockWeek;
        /* eslint-disable-next-line ember/no-get */
        let termId = this.get('session.course.term.id');
        /* eslint-disable-next-line ember/no-get */
        let courseId = this.get('session.course.id');
        this.set('working', "Unlocking...");

        /* eslint-disable-next-line ember/no-jquery */
        Ember.$.ajax({
          type: 'POST',
          url: '/interface/early_unlock/',
          data: JSON.stringify({
            user_id: userId,
            term_id: termId,
            unlock_week: unlockWeek,
            course_id: courseId
          }),
          success: () => {
            this.set('working', false);
            this.set('earlyUnlock', this.unlockWeek);
          },
          error: e => {
            this.set('earlyUnlock', false);
            this.set('working', false);
            Ember.debug('error occurred while unlocking');
            Ember.debug(e);
          }
        });
      },
      closeProfile: function () {
        this.userprofileService.activeUser = false;
        this.userprofileService.isEditing = false;
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.user-profile').removeClass('active floating-modal');
        /* eslint-disable-next-line ember/no-jquery */
      },
      pm: function () {
        /* eslint-disable-next-line ember/no-get */
        let id = this.get('userprofileService.activeUser.id');
        this.send('closeProfile');
        this.transitionToRoute('classroom.lessons.conversation-new-with', id);
      },
      activityReport: function () {
        /* eslint-disable-next-line ember/no-get */
        var courseId = this.get('session.course.id'),
          /* eslint-disable-next-line ember/no-get */
          userId = this.get('userprofileService.activeUser.content.id');
        window.open('/courses/' + courseId + '/users/' + userId + '/usage', '_blank');
      },
      gradeReport: function () {
        /* eslint-disable-next-line ember/no-get */
        var courseId = this.get('session.course.id'),
          /* eslint-disable-next-line ember/no-get */
          userId = this.get('userprofileService.activeUser.content.id');
        window.open('/courses/' + courseId + '/grades/' + userId + '#tab-assignments', '_blank');
      },
      reportAtRisk: user => {
        let name = user.get('name'),
          email = user.get('email');
        if (name && email) {
          let atRiskWindow = window.open('', '_blank'),
            path = 'https://online.berklee.edu/at-risk-student-notification-form?student_name=';
          path += name + '&student_email=' + email;
          atRiskWindow.location = path;
        }
      },
      masquerade() {
        /* eslint-disable-next-line ember/no-get */
        let id = this.get('userprofileService.activeUser.id');
        let path = `/users/${id}/masquerade`;
        window.open(path, '_blank');
      }
    }
  });
});