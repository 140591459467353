define("bocce/helpers/percentage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // app/helpers/percentage.js
  // Calculate percentage
  var _default = _exports.default = Ember.Helper.helper(function (mod) {
    if (!mod[0] || !mod[1]) {
      return 0;
    }
    let percentRaw = mod[0] / mod[1] * 100;
    if (parseInt(percentRaw) === parseFloat(percentRaw)) {
      return percentRaw;
    }
    return percentRaw.toFixed(2);
  });
});