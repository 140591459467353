define("bocce/mixins/interactions/piano_quiz", ["exports", "bocce/mixins/support/util", "bocce/mixins/interactions/piano"], function (_exports, util, _piano) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function PianoQuiz($el, data) {
    var i,
      quiz = {
        oneKeyEntry: true,
        revealCorrectAnswerAfter: data.config.revealCorrectAnswerAfter,
        //Number of tries before 'show answer' appears (false for never)
        selectedKeys: [],
        maxKeys: 8,
        renderStaff: false,
        signature: '4/4',
        questions: []
      };
    this.config = data.config;
    this.intId = $el.attr('id'); // bind all methods to this

    util.bindAll(this);
    // debug('Starting piano quiz interaction ' + this.intId);
    this.config['placementId'] = this.intId;
    $el.addClass('pianoQuizKeyboard'); // This is the stuff from the component properties dialog,
    // eg "numberOfOctaves", "middleC"
    this.config['keyDirs'] = {};
    for (i = 0; i < 85; i++) {
      this.config['keyDirs']['key_' + i] = data['key_' + i];
    }
    $el.find('tr').each(function (num, row) {
      if (parseInt(num) === 0) {
        return; // skip the header row
      }
      /* eslint-disable-next-line ember/no-jquery */
      var $cells = Ember.$(row).find('td'),
        questionContent = $cells[0].innerHTML,
        firstNoteRaw = $cells[1].textContent.replace(new RegExp(' ', 'g'), ''),
        firstNote = firstNoteRaw.split(','),
        answerRaw = $cells[2].innerHTML,
        feedbackRight = $cells[3].textContent,
        feedbackWrong = $cells[4].textContent,
        orderMatters,
        previewInput,
        answer,
        i,
        l,
        keys = {
          'c1': 0,
          'c#1': 1,
          'db1': 1,
          'd1': 2,
          'd#1': 3,
          'eb1': 3,
          'e1': 4,
          'f1': 5,
          'f#1': 6,
          'gb1': 6,
          'g1': 7,
          'g#1': 8,
          'ab1': 8,
          'a1': 9,
          'a#1': 10,
          'bb1': 10,
          'b1': 11,
          'c2': 12,
          'c#2': 13,
          'db2': 13,
          'd2': 14,
          'd#2': 15,
          'eb2': 15,
          'e2': 16,
          'f2': 17,
          'f#2': 18,
          'gb2': 18,
          'g2': 19,
          'g#2': 20,
          'ab2': 20,
          'a2': 21,
          'a#2': 22,
          'bb2': 22,
          'b2': 23,
          'c3': 24,
          'c#3': 25,
          'db3': 25,
          'd3': 26,
          'd#3': 27,
          'eb3': 27,
          'e3': 28,
          'f3': 29,
          'f#3': 30,
          'gb3': 30,
          'g3': 31,
          'g#3': 32,
          'ab3': 32,
          'a3': 33,
          'a#3': 34,
          'bb3': 34,
          'b3': 35,
          'c4': 36,
          'c#4': 37,
          'db4': 37,
          'd4': 38,
          'd#4': 39,
          'eb4': 39,
          'e4': 40,
          'f4': 41,
          'f#4': 42,
          'gb4': 42,
          'g4': 43,
          'g#4': 44,
          'ab4': 44,
          'a4': 45,
          'a#4': 46,
          'bb4': 46,
          'b4': 47,
          'c5': 48,
          'c#5': 49,
          'db5': 49,
          'd5': 50,
          'd#5': 51,
          'eb5': 51,
          'e5': 52,
          'f5': 53,
          'f#5': 54,
          'gb5': 54,
          'g5': 55,
          'g#5': 56,
          'ab5': 56,
          'a5': 57,
          'a#5': 58,
          'bb5': 58,
          'b5': 59,
          'c6': 60,
          'c#6': 61,
          'db6': 61,
          'd6': 62,
          'd#6': 63,
          'eb6': 63,
          'e6': 64,
          'f6': 65,
          'f#6': 66,
          'gb6': 66,
          'g6': 67,
          'g#6': 68,
          'ab6': 68,
          'a6': 69,
          'a#6': 70,
          'bb6': 70,
          'b6': 71,
          'c7': 72,
          'c#7': 73,
          'db7': 73,
          'd7': 74,
          'd#7': 75,
          'eb7': 75,
          'e7': 76,
          'f7': 77,
          'f#7': 78,
          'gb7': 78,
          'g7': 79,
          'g#7': 80,
          'ab7': 80,
          'a7': 81,
          'a#7': 82,
          'bb7': 82,
          'b7': 83,
          'c8': 84
        }; // Try to 'smartly' determine if the interaction should use sharps or flats.
      // It's very rare that sharps and flats are mixed, so make the choice
      // based on the first match instead of trying to match every note.

      var accidental = /#/.test(answerRaw) ? '#' : 'b';
      if (firstNote[0] !== 'false') {
        for (i = 0, l = firstNote.length; i < l; i++) {
          firstNote[i] = keys[firstNote[i].toLowerCase()];
        }
      } else {
        firstNote = false;
      } // Check for bold

      if (answerRaw.indexOf('</strong>') > -1) {
        orderMatters = true;
      } else {
        orderMatters = false;
      } // Check for italic

      if (answerRaw.indexOf('</em>') > -1) {
        previewInput = 'arpeggio';
      } else {
        previewInput = 'chord';
      } // Strip out tags for and arrayify answer

      /* eslint-disable-next-line ember/no-jquery */
      answerRaw = Ember.$('<div/>').html(answerRaw).text().replace(new RegExp(' ', 'g'), '');
      answer = answerRaw.split(',');
      for (i = 0, l = answer.length; i < l; i++) {
        answer[i] = keys[answer[i].toLowerCase()];
      } // Collect everything into a quiz question object literal

      quiz.questions.push({
        questionContent: questionContent,
        feedbackWrong: feedbackWrong,
        feedbackRight: feedbackRight,
        orderMatters: orderMatters,
        firstNote: firstNote,
        previewInput: previewInput,
        isChord: true,
        // Leave true
        answer: answer,
        accidental: accidental
      });
    });
    this.config.quiz = quiz;
  }
  PianoQuiz.prototype = {
    init: function init() {
      new _piano.default(this.config).run();
    }
  };
  var _default = _exports.default = PianoQuiz;
});