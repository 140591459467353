define("bocce/components/side-panel/panel-list-item", ["exports", "bocce/models/discussion", "bocce/models/assignment", "bocce/models/quiz"], function (_exports, _discussion, _assignment, _quiz) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    session: Ember.inject.service(),
    tagName: 'li',
    classNames: ['activity', 'fade-in', 'panel-list-item'],
    classNameBindings: ['unread'],
    ariaRole: 'presentation',
    iconComponent: Ember.computed('item', function () {
      let item = this.item;
      let type = 'portrait';
      if (item instanceof _discussion.default && item.get('is_required')) {
        type = 'discussion-icon';
      } else if (item instanceof _assignment.default) {
        type = 'assignment-icon';
      } else if (item instanceof _quiz.default) {
        type = 'quiz-icon';
      }
      return `side-panel/panel-list-item/${type}`;
    }),
    itemRoute: Ember.computed('item', function () {
      let item = this.item;
      if (item instanceof _discussion.default) {
        return 'classroom.lessons.discussion';
      }
      if (item instanceof _assignment.default) {
        return 'classroom.lessons.assignments';
      }
      if (item instanceof _quiz.default) {
        return 'classroom.lessons.quiz';
      }
      return null;
    }),
    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    unread: Ember.computed('item.{unread,numUngradedSubmissions}', 'session.isInstructor', function () {
      /* eslint-disable-next-line ember/no-get */
      let teacherHasUngraded = this.get('session.isInstructor') && /* eslint-disable-next-line ember/no-get */
      this.get('item.numUngradedSubmissions') > 0;
      /* eslint-disable-next-line ember/no-get */
      return this.get('item.hasUnreadResponses') || teacherHasUngraded;
    })
  });
});