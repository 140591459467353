define("bocce/models/conversation_message", ["exports", "@ember-data/model", "bocce/mixins/attachments-mixin"], function (_exports, _model, _attachmentsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // app/models/conversation_message.js
  /* eslint-disable-next-line ember/no-mixins */
  var _default = _exports.default = _model.default.extend(_attachmentsMixin.default, {
    conversation: (0, _model.belongsTo)('conversation', {
      async: true
    }),
    author_id: (0, _model.belongsTo)('user', {
      async: true
    }),
    author: Ember.computed.alias('author_id'),
    body: (0, _model.attr)('string'),
    created_at: (0, _model.attr)(),
    attachments: (0, _model.attr)(),
    generated: (0, _model.attr)(),
    forwarded_messages: (0, _model.attr)(),
    belongsToSelf: (0, _model.attr)(),
    conversationPartners: (0, _model.hasMany)('user', {
      async: true
    }),
    participants: (0, _model.hasMany)('user', {
      async: true
    }),
    hide: (0, _model.attr)(),
    createdAtFormatted: Ember.computed('this.created_at', function () {
      return moment(this.created_at).tz('America/New_York').format('MMM D, YYYY h:mmA') + " ET";
    })
  });
});