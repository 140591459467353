define("bocce/mixins/interactions/timeline_knightlab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function TimelineKnightlab($el) {
    /* getting our DOM elements */
    this.$el = $el;
    this.$interactionData = $el.find('.interaction_data');
    this.$container = $el.find('.interaction_content');
    this.dataTables = this.$interactionData[0].getElementsByTagName('table');
  }

  /* prototype */
  TimelineKnightlab.prototype = {
    init() {
      this.addTemplate();
      let timeline_object = this.getDataFromTable(this.dataTables);
      window.timeline = new TL.Timeline('timeline-embed', timeline_object);
    },
    addTemplate: function () {
      var html, template;
      html = '<div id="errors" aria-hidden></div><div id="timeline-embed" style="width: 100%; height: 600px"></div>';
      /* eslint-disable-next-line ember/no-jquery */
      template = Ember.$(html).appendTo(this.$container);
      return template;
    },
    getDataFromTable: function (dataTables) {
      let keys = ['title', 'eras', 'events'];
      let columns = {
        'title': ['headline', 'text', 'background'],
        'events': ['start_year', 'start_month', 'start_day', 'end_year', 'end_month', 'end_day', 'display_date', 'headline', 'text', 'background', 'media_url', 'media_credit', 'media_caption', 'group'],
        'eras': ['start_year', 'start_month', 'start_day', 'end_year', 'end_month', 'end_day', 'headline']
      };
      let data = {};

      /* eslint-disable-next-line ember/no-jquery */
      Ember.$(this.dataTables).each(function (i) {
        let table = dataTables[i],
          tableid = keys[i],
          column = columns[tableid];
        let arr = [];

        /* go through each row */
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$(table).find('tr').each(function (i) {
          /* skip first row (title row) */
          if (i === 0) {
            return true;
          }

          /* if table is empty, do not create object */
          if (/\S/.test(this.innerText) === true) {
            let args = {};
            /* go through each cell and match contents to preset keys */
            /* eslint-disable-next-line ember/no-jquery */
            Ember.$(this).find('td').each(function (ndx) {
              /* eslint-disable-next-line ember/no-jquery */
              if (Ember.$(this).children().length > 0) {
                /* eslint-disable-next-line ember/no-jquery */
                args[column[ndx]] = Ember.$(this).html();
              }
            });
            arr.push(args);
          }
        });
        /* put keys into object under keyname of tableid (title/events/eras) */
        data[tableid] = arr;
      });
      data = this.formatData(data);
      return data;
    },
    formatData: function (data) {
      let formattedData = {};

      /* Title */
      if (data.title.length > 0) {
        let objTitle = {};
        if (data.title[0].headline || data.title[0].text) {
          objTitle['text'] = this.makeTextObject(data.title[0]);
        }
        if (data.title[0].background) {
          objTitle['background'] = this.makeBackgroundObject(data.title[0]);
        }
        if (data.title.length > 1) {
          this.throwError('Only one title slide allowed');
        }
        formattedData['title'] = objTitle;
      }
      /* Eras */
      if (data.eras.length > 0) {
        let arrEras = [];
        for (let era of data.eras) {
          let objEra = {};
          if (era.headline) {
            objEra['text'] = this.makeTextObject(era);
          }
          if (!era.start_year || !era.end_year) {
            this.throwError('Eras require start and end years');
          } else {
            objEra['start_date'] = this.makeDateObject(era, 'start');
            objEra['end_date'] = this.makeDateObject(era, 'end');
          }
          arrEras.push(objEra);
        }
        formattedData['eras'] = arrEras;
      }
      /* Events */
      if (data.events.length > 0) {
        let arrEvents = [];
        for (let event of data.events) {
          let objEvent = {};
          if (event.headline || event.text) {
            objEvent['text'] = this.makeTextObject(event);
          }
          if (event.start_year) {
            objEvent['start_date'] = this.makeDateObject(event, 'start');
            if (event.end_year) {
              objEvent['end_date'] = this.makeDateObject(event, 'end');
            }
          } else {
            this.throwError('Start year required');
          }
          if (event.background) {
            objEvent['background'] = this.makeBackgroundObject(event);
          }
          if (event.media_url) {
            objEvent['media'] = this.makeMediaObject(event);
          }
          if (event.display_date) {
            objEvent['display_date'] = this.stripHTML(event.display_date);
          }
          if (event.group) {
            objEvent['group'] = this.stripHTML(event.group);
          }
          arrEvents.push(objEvent);
        }
        formattedData['events'] = arrEvents;
      }
      return formattedData;
    },
    throwError: function (msg) {
      /* eslint-disable-next-line ember/no-jquery */
      Ember.$('#error').text('Error: ' + msg);
      Ember.debug('Error: ' + msg);
    },
    /* To take all that clogged cms html out of the data */
    stripHTML: function (str) {
      /* eslint-disable-next-line ember/no-jquery */
      let no_html = Ember.$(str)[0].innerText;
      return no_html;
    },
    makeTextObject: function (item) {
      let obj = {};
      if (item.headline.length > 0) {
        obj['headline'] = this.stripHTML(item.headline);
      }
      if (item.text) {
        obj['text'] = item.text;
      }
      return obj;
    },
    makeBackgroundObject: function (item) {
      let obj = {},
        itemBackgroundText;

      /* Check if item is or contains an img */
      /* eslint-disable-next-line ember/no-jquery */
      if (Ember.$(item.background).is('img')) {
        obj['url'] = item.background.src;
      }
      /* eslint-disable-next-line ember/no-jquery */else if (Ember.$(item.background).find('img').length > 0) {
        /* eslint-disable-next-line ember/no-jquery */
        obj['url'] = Ember.$(item.background).find('img')[0].src;
      }
      /* otherwise stripHTML & sort 'background' string into color or url category */else {
        itemBackgroundText = this.stripHTML(item.background);
        /* check if hex code */
        if ((itemBackgroundText.length === 7 || itemBackgroundText.length === 4) && itemBackgroundText.charAt(0) === '#') {
          obj['color'] = itemBackgroundText;
        }
        /* check if is within bounds of being a named web color */else if (itemBackgroundText.length < 21 && /^[a-z]+$/i.test(itemBackgroundText) === true) {
          obj['color'] = itemBackgroundText;
        }
        /* check if url (in cms, no http:// is attached) */else if (/\//.test(itemBackgroundText) === true) {
          obj['url'] = itemBackgroundText;
        } else {
          this.throwError('Background is not a URL, image, color hex code, or named web color.');
        }
      }
      return obj;
    },
    makeDateObject: function (item, type) {
      let obj = {};
      if (type === 'start') {
        if (item.start_year) {
          obj['year'] = this.stripHTML(item.start_year);
          if (item.start_month) {
            obj['month'] = this.stripHTML(item.start_month);
          }
          if (item.start_day) {
            obj['day'] = this.stripHTML(item.start_day);
          }
        } else {
          this.throwError('Year is required.');
        }
      } else if (type === 'end') {
        if (item.end_year) {
          obj['year'] = this.stripHTML(item.end_year);
          if (item.end_month) {
            obj['month'] = this.stripHTML(item.end_month);
          }
          if (item.end_day) {
            obj['day'] = this.stripHTML(item.end_day);
          }
        }
      }
      return obj;
    },
    makeMediaObject: function (item) {
      let obj = {};

      /* eslint-disable-next-line ember/no-jquery */
      if (Ember.$(item.media_url).is('img')) {
        obj['url'] = item.media_url.src;
      }
      /* eslint-disable-next-line ember/no-jquery */else if (Ember.$(item.media_url).find('img').length > 0) {
        /* eslint-disable-next-line ember/no-jquery */
        obj['url'] = Ember.$(item.media_url).find('img')[0].src;
      } else {
        obj['url'] = this.stripHTML(item.media_url);
      }
      if (item.media_credit) {
        obj['credit'] = this.stripHTML(item.media_credit);
      }
      if (item.media_caption) {
        obj['caption'] = this.stripHTML(item.media_caption);
      }
      return obj;
    }
  };
  var _default = _exports.default = TimelineKnightlab;
});