define("bocce/mixins/interactions/requirements", ["exports", "bocce/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function Requirements($el, data) {
    this.content = $el.find('.interaction_content');
    this.data = data;
  }
  Requirements.prototype = {
    init: async function () {
      const env = session.get('environmentProcessed');
      const isProd = env.isProd || env.isPreview || session.get('course.isGettingStarted');
      const baseUrl = isProd ? _environment.default.APP.interactions.requirements.prodUrlBase : _environment.default.APP.interactions.requirements.devUrlBase;
      let requirementsUrl = `${baseUrl}/${session.get('course.code').split(".")[0]}-v${this.data.config.version}.json`;
      try {
        const response = await fetch(requirementsUrl, {
          cache: "no-cache"
        });
        const result = await response.json();
        if (result.requirements) {
          this.content.html(result.requirements);
        } else {
          this.content.html(_environment.default.APP.text.noRequirements);
        }
      } catch (e) {
        this.content.html(_environment.default.APP.text.noRequirements);
      }
    }
  };
  var _default = _exports.default = Requirements;
});