define("bocce/templates/main-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "KeRTkjrC",
    "block": "{\"symbols\":[],\"statements\":[[10,\"main\"],[14,\"aria-label\",\"Course Content\"],[12],[2,\"\\n  \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[19,\"video-player\",[]],[2,\"\\n\"]],\"hasEval\":true,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "bocce/templates/main-panel.hbs"
    }
  });
});