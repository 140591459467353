define("bocce/components/lessons/lesson-wrapper", ["exports", "bocce/mixins/av-players", "bocce/mixins/notify"], function (_exports, _avPlayers, _notify) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  var _default = _exports.default = Ember.Component.extend(_avPlayers.default, _notify.default, {
    didInsertElement: function () {
      var ctl = this.parent;
      this.startAV();

      /* eslint-disable-next-line ember/no-jquery */
      Ember.$('img').on('error', function () {
        /* eslint-disable-next-line ember/no-jquery */
        var imgSrc = Ember.$(this).attr('src');
        if (imgSrc && imgSrc.length > 0 && this.getAttribute('att') !== '2') {
          this.setAttribute('att', '2');
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$(this).attr('src', imgSrc + '&att=2');
          Ember.debug('Missing image - attempting to fetch again');
        } else {
          Ember.debug('Already tried fetching image once during this runtime. Giving up.');
        }
      });

      /* eslint-disable-next-line ember/no-jquery */
      Ember.$('.main-panel')
      /* eslint-disable-next-line ember/no-jquery */.on('scrollstart', Ember.$.proxy(this.didScroll, this))
      /* eslint-disable-next-line ember/no-jquery */.on('scrollstop', Ember.$.proxy(this.scrollStop, this));

      /* eslint-disable-next-line ember/no-jquery */
      Ember.$('.side-panel')
      /* eslint-disable-next-line ember/no-jquery */.on('scrollstart', Ember.$.proxy(this.sidePanelScrollStart, this))
      /* eslint-disable-next-line ember/no-jquery */.on('scrollstop', Ember.$.proxy(this.sidePanelScrollStop, this));
      this.sidePanelScrollStop();

      /* eslint-disable-next-line ember/no-jquery */
      Ember.$('.main-panel-container').on('resize', Ember.$.proxy(this.didResize, this));

      // Temporary stopgap so boot functions run while refactoring
      ctl.getThingsRolling();
    },
    willDestroy() {
      this._super(...arguments);
      // kill all event handlers!
      /* eslint-disable-next-line ember/no-jquery */
      Ember.$('img').off('error');
      /* eslint-disable-next-line ember/no-jquery */
      Ember.$('.main-panel').off('scrollstart').off('strollstop');
      /* eslint-disable-next-line ember/no-jquery */
      Ember.$('.side-panel').off('scrollstart').off('scrollstop');
      /* eslint-disable-next-line ember/no-jquery */
      Ember.$('.main-panel-container').off('resize');
      if (this.avPlayerOnClickListener) {
        document.removeEventListener('click', this.avPlayerOnClickListener);
      }
    },
    willDestroyElement: function () {
      /* eslint-disable-next-line ember/no-jquery */
      Ember.$('.main-panel')
      /* eslint-disable-next-line ember/no-jquery */.off('scrollstart', Ember.$.proxy(this.didScroll, this))
      /* eslint-disable-next-line ember/no-jquery */.off('scrollstop', Ember.$.proxy(this.scrollStop, this));

      /* eslint-disable-next-line ember/no-jquery */
      Ember.$('.side-panel')
      /* eslint-disable-next-line ember/no-jquery */.on('scrollstart', Ember.$.proxy(this.sidePanelScrollStart, this))
      /* eslint-disable-next-line ember/no-jquery */.on('scrollstop', Ember.$.proxy(this.sidePanelScrollStop, this));

      /* eslint-disable-next-line ember/no-jquery */
      Ember.$('.main-panel-container').off('resize', Ember.$.proxy(this.didResize, this));
    },
    // Scrolling tracker
    didScroll: function () {
      //Show current course topic display when scrolling
      this.set('amScrolling', true);
    },
    scrollStop: function () {
      //Check to make sure nothing is loading
      /* eslint-disable-next-line ember/no-jquery */
      if (Ember.$('.main-panel.no-scroll').length > 0) {
        return;
      }
      //Update current topic, if necessary
      this.parent.send('updatePath');
      this.parent.set('amScrolling', false);
    },
    sidePanelScrollStart: function () {
      /* eslint-disable-next-line ember/no-jquery */
      Ember.$('.lesson-divider').removeClass('current-lesson');
    },
    sidePanelScrollStop: function () {
      /* eslint-disable-next-line ember/no-jquery */
      var firstItem = Ember.$('.syllabus > a:in-viewport').first(),
        currHeader = firstItem.prevAll('.syllabus-divider').first();
      if (currHeader.length === 0) {
        /* eslint-disable-next-line ember/no-jquery */
        currHeader = Ember.$('.syllabus .syllabus-divider').first();
      }

      /* eslint-disable-next-line ember/no-jquery */
      Ember.$('.syllabus-divider').removeClass('current-lesson');
      currHeader.addClass('current-lesson');
      let lessonNum = currHeader.attr('lesson_number');
      let lessonTitle = currHeader.attr('lesson_title');
      let newTitle;
      if (lessonNum === '0') {
        newTitle = 'Getting Started';
      } else {
        newTitle = `${lessonNum} - ${lessonTitle}`;
      }
      this.parent.send('updateShortcutsDrawer', newTitle);

      /* eslint-disable-next-line ember/no-jquery */
      Ember.$('.shortcut-list-item').removeClass('current');
      /* eslint-disable-next-line ember/no-jquery */
      Ember.$('.shortcut-list-item:nth(' + currHeader.attr('lesson_number') + ')').addClass('current');
    },
    // Half-height infinite scroll resizer
    didResize: function () {
      this.parent.send('resetHeight');
    }
  });
});