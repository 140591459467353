define("bocce/helpers/contains", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // app/helpers/same.js
  // Check if two provided strings are the same
  var _default = _exports.default = Ember.Helper.helper(function (inp) {
    var compare = inp[0],
      search = inp[1],
      compareTo = inp[2];
    if (search && search.length > 0) {
      return String(search[compareTo]) === String(compare);
    }
    return false;
  });
});