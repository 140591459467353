define("bocce/js/store-util", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.pushDeletion = pushDeletion;
  //Taken from: https://gist.github.com/runspired/96618af26fb1c687a74eb30bf15e58b6/#file-push-deletion-js
  function pushDeletion(store, type, id) {
    let record = store.peekRecord(type, id);
    if (record !== null) {
      let relationships = {};
      let hasRelationships = false;
      record.eachRelationship((name, {
        kind
      }) => {
        hasRelationships = true;
        relationships[name] = {
          data: kind === 'hasMany' ? [] : null
        };
      });
      if (hasRelationships) {
        store.push({
          data: {
            type,
            id,
            relationships
          }
        });
      }
      record.unloadRecord();
    }
  }
});