define("bocce/templates/components/quiz-questions/file-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "61ABA4yM",
    "block": "{\"symbols\":[\"attachment\",\"@index\",\"@question\",\"@quizzes\"],\"statements\":[[6,[37,1],[[32,4,[\"hasScore\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,1],[[32,3,[\"attempt_data\",\"attachments\",\"length\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,3,[\"attempt_data\",\"attachments\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[32,3,[\"fileDownloading\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"i\"],[14,0,\"fas fa-spinner fa-spin fa-fw\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[11,\"a\"],[24,0,\"submitted-attachment\"],[4,[38,0],[[32,0],\"downloadFile\",[32,1,[\"id\"]],[32,1,[\"name\"]]],null],[12],[1,[32,1,[\"name\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"parameters\":[]},{\"statements\":[[2,\"    no file uploaded\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[15,1,[31,[\"quiz-file-upload-file-wrapper-\",[32,2]]]],[12],[2,\"\\n    \"],[10,\"input\"],[14,0,\"quiz-question-choice\"],[15,1,[31,[\"quiz-file-upload-file-\",[32,2]]]],[14,4,\"file\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"if\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "bocce/templates/components/quiz-questions/file-upload.hbs"
    }
  });
});