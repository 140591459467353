define("bocce/templates/components/side-panel/panel-list-item/discussion-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "4ZCHcOqg",
    "block": "{\"symbols\":[],\"statements\":[[10,\"i\"],[15,0,[34,0]],[12],[13]],\"hasEval\":false,\"upvars\":[\"iconClass\"]}",
    "meta": {
      "moduleName": "bocce/templates/components/side-panel/panel-list-item/discussion-icon.hbs"
    }
  });
});