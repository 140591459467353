define("bocce/templates/components/inputs/toggle-switch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "/bNrk8nD",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"toggle-container\"],[12],[2,\"\\n  \"],[10,\"input\"],[14,1,\"toggle\"],[14,0,\"checkbox\"],[14,4,\"checkbox\"],[12],[13],[2,\"\\n  \"],[11,\"button\"],[24,\"for\",\"toggle\"],[16,0,[31,[\"switch \",[30,[36,0],[[32,0,[\"activated\"]],\"on\",\"\"],null]]]],[4,[38,1],[\"click\",[32,0,[\"toggleSwitch\"]]],null],[12],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"on\"]}",
    "meta": {
      "moduleName": "bocce/templates/components/inputs/toggle-switch.hbs"
    }
  });
});