define("bocce/controllers/classroom/lessons/conversation", ["exports", "bocce/mixins/discussable", "bocce/mixins/enmodal", "bocce/mixins/conversable", "bocce/mixins/editable", "bocce/mixins/uploadable", "bocce/mixins/audio-rec", "bocce/mixins/video-rec", "bocce/mixins/rtc-rec", "bocce/helpers/heartbeat", "bocce/utilities/dialog"], function (_exports, _discussable, _enmodal, _conversable, _editable, _uploadable, _audioRec, _videoRec, _rtcRec, _heartbeat, _dialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  window.conversationHeartbeatFrequency = 3 * 1000;
  var _default = _exports.default = Ember.Controller.extend(_conversable.default, _enmodal.default, _discussable.default, _editable.default, _uploadable.default, _audioRec.default, _videoRec.default, _rtcRec.default, {
    userprofileService: Ember.inject.service('userprofile'),
    kalturaService: Ember.inject.service('kaltura-upload'),
    conversationNew: Ember.inject.controller('classroom.lessons.conversation-new'),
    filteredList: Ember.computed.alias('conversationNew.filteredList'),
    discussions: Ember.inject.controller(),
    dashboard: Ember.inject.controller(),
    submission: Ember.inject.controller('classroom.lessons.submission'),
    // loadHeartbeatData uses this
    classroom: Ember.inject.controller(),
    // required by DiscussableMixin
    discussion: Ember.inject.controller('classroom.lessons.discussion'),
    // required by DiscussableMixin
    newDiscussion: Ember.inject.controller('classroom.lessons.discussion-new'),
    // required by DiscussableMixin
    lessons: Ember.inject.controller('classroom.lessons'),
    // required by UploadableMixin
    init(...args) {
      this._super(...args);
    },
    currentConversationId: Ember.computed.reads('model.id'),
    inlineInbox: Ember.computed('model', 'target.currentRouteName', function () {
      let currentRoute = this.get('target.currentRouteName');
      return currentRoute === "dashboard";
    }),
    scrollAndUpdateLocalConversation: function (conversation) {
      Ember.run.later(function () {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.existing-conversation .modal-content').animate({
          scrollTop: Ember.$('.existing-conversation .modal-content').prop('scrollHeight')
        }, 1000);
      }, 500);
      conversation.set('last_authored_message_at', new Date());
      conversation.set('last_message_at', new Date());
      conversation.set('message_count', conversation.get('message_count') + 1);
    },
    sortedMessages: Ember.computed.sort('model.messages', ['model.messages.[]'], (a, b) => {
      let id_a = parseInt(a.get('id'), 10),
        id_b = parseInt(b.get('id'), 10);
      return id_a < id_b ? -1 : 1;
    }),
    conversationModalContentMarginTop: Ember.computed('model.{participants.[],participantRelationships.[]}', function () {
      /* eslint-disable-next-line ember/no-get */
      if (this.get('model.participants.length') > 2) {
        return 0;
      }
      /* eslint-disable-next-line ember/no-get */
      let participantRelationships = this.get('model.participantRelationships.length');
      return participantRelationships * 26;
    }),
    sharedEnrollments: Ember.computed('model.{participants.[],participantRelationships.@each.section}', 'session.user.sectionCodes.[]', function () {
      let conversation = this.model,
        retval = [],
        /* eslint-disable-next-line ember/no-get */
        currentUser = this.get('session.user'),
        sectionCodes = currentUser.get('sectionCodes');
      if (conversation) {
        if (conversation.get('participants.length') > 2) {
          return [];
        }
        let participantRelationships = conversation.get('participantRelationships') || [];
        participantRelationships.forEach(participantRelationship => {
          if (!sectionCodes) {
            return;
          }
          sectionCodes.forEach(sectionCode => {
            if (sectionCode.sectionId === participantRelationship.section) {
              retval.push({
                sectionId: sectionCode.sectionId,
                relationship: participantRelationship.relationship,
                courseTitle: sectionCode.courseTitle,
                sectionLabel: sectionCode.sectionLabel
              });
            }
          });
        });
      }
      return retval;
    }),
    actions: {
      addFile: function (file) {
        if (file.type.includes('video')) {
          let encoding_video = {
            name: file.name,
            percent_uploaded: 0
          };
          this.encoding_videos.pushObject(encoding_video);
          let fileIndex = this.encoding_videos.length - 1;
          this.kalturaUploadVideo(file, async () => {
            Ember.debug('Unable to upload video to Kaltura. Uploading to S3...');
            await (0, _dialog.default)('Video embedding unsuccessful; this file will be available for download only.', ['OK']);
            this.encoding_videos.removeObject(encoding_video);
            file.ignoreDownloadOnlyPrompt = true;
            this.send('addValidFile', file);
          }, fileIndex, URL.createObjectURL(file), encoding_video);
        } else if (this.mimeTypes.indexOf(file.type) === -1) {
          this.send('addValidFile', file);
        } else {
          this.send('addInvalidFile', file);
        }
      },
      deleteAttachment: function (file) {
        this.send('deleteFile', file);
      },
      renameAttachment: function (attachment, newName) {
        if (attachment.file?.isUrl) {
          this.kalturaService.kalturaRenameVideo(attachment.uploaded_id, newName);
          Ember.set(attachment, 'name', newName);
        } else {
          Ember.set(attachment, 'name', newName);
          this.store.findRecord('attachment', attachment.uploaded_id).then(loadedAttachment => {
            loadedAttachment.set('name', newName);
            loadedAttachment.save();
          });
        }
      },
      closeModals: function () {
        this._super();
        if (this.target.currentRouteName === "dashboard") {
          this.dashboard.set('messagePanelOn', false);
        }
        return;
      },
      startPrivateConversationHeartbeat: function () {
        window.runPMHeartbeat = () => {
          const convo_id = this.currentConversationId,
            messages = this.sortedMessages;
          if (!convo_id || !messages) {
            return;
          }
          const since_id = messages[messages.length - 1]?.id || 0;
          (0, _heartbeat.default)([`/interface/conversations/${convo_id}/conversation-messages/since/${since_id}`], this).always(() => {
            // When the modal is open, 'currentConversationId' is always set on the
            // conversation controller. When the modal is closed, this value is cleared out.
            // Only heartbeat if there is something stored in this value. - JRW
            Ember.run.later(this, window.runPMHeartbeat, window.conversationHeartbeatFrequency);
          });
        };
        Ember.run.later(this, window.runPMHeartbeat, window.conversationHeartbeatFrequency);
      },
      loadMoreMessages: function (defer) {
        // call "convoController.send('loadMoreMessages')" in console to test
        window.convoController = this;

        /* eslint-disable-next-line ember/no-get */
        let loaded = this.get('session.loadedMessages') || [];

        /* eslint-disable-next-line ember/no-get */
        if (loaded.indexOf(this.get('model.id')) >= 0) {
          if (defer) {
            defer.resolve();
          }
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('.loading-prv').removeClass('active');
          return;
        }

        /* eslint-disable-next-line ember/no-get */
        let messages = this.get('model.messages');
        if (!messages) {
          if (defer) {
            defer.resolve();
          }
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('.loading-prv').removeClass('active');
          return;
        }
        let num_loaded = messages.get('length'),
          /* eslint-disable-next-line ember/no-get */
          user_id = this.get('session.user.id'),
          args = '';
        if (num_loaded > 0) {
          let min_id;
          for (let i = 0; i < num_loaded; i++) {
            let id = parseInt(messages.objectAt(i).get('id'), 10);
            if (!min_id || id < min_id) {
              min_id = id;
            }
          }
          args = `?page=${min_id}`;
        }
        setTimeout(() => {
          /* eslint-disable-next-line ember/no-jquery, ember/no-get */
          Ember.$.get(`/interface/conversations/${this.get('model.id')}/conversation-messages${args}`).then(data => {
            if (!data || !data.conversation_message || data.conversation_message.length === 0) {
              /* eslint-disable-next-line ember/no-get */
              loaded.push(this.get('model.id'));
              this.set('session.loadedMessages', loaded);
            } else {
              for (let m of data.conversation_message) {
                let auth_id = Number(m.author_id);
                if (auth_id === Number(user_id)) {
                  m.belongsToSelf = true;
                }
              }
              this.store.pushPayload(data);
            }
            if (defer) {
              defer.resolve();
            }

            /* eslint-disable-next-line ember/no-jquery, ember/no-incorrect-calls-with-inline-anonymous-functions */
            Ember.run.scheduleOnce(() => {
              Ember.$('.loading-prv').removeClass('active');
            });
          });
        }, 250);
      },
      scrollToBottom: function () {
        /* eslint-disable-next-line ember/no-incorrect-calls-with-inline-anonymous-functions */
        Ember.run.scheduleOnce('afterRender', this, function () {
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('.existing-conversation .modal-content').animate({
            scrollTop: Ember.$('.existing-conversation .modal-content').prop('scrollHeight')
          }, 500);
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('.modal-content').on('scrollstop', tgt => {
            if (tgt.target.scrollTop === 0 && this.nxtScroll) {
              /* eslint-disable-next-line ember/no-jquery */
              Ember.$('.loading-prv').addClass('active');
              this.send('loadMoreMessages');
            } else if (!this.nxtScroll) {
              this.set('nxtScroll', true);
            }
          });
        });
      },
      autoComplete: function (param) {
        this.conversationNew.send('autoComplete', param);
      },
      choose: function (recipient) {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$.ajax({
          type: 'POST',
          /* eslint-disable-next-line ember/no-get */
          url: '/interface/conversations/' + this.get('model.id') + '/addRecipient/' + recipient.id,
          success: () => {
            this.model.reload();
            Ember.debug('Recipient Added sucessfully');
          },
          error: error => {
            Ember.debug('Unable to add recipient. Something went wrong: ');
            Ember.debug(error);
          }
        });
        this.set('adding_member', false);
      },
      addUserToggle: function () {
        if (this.adding_member) {
          this.set('adding_member', false);
        } else {
          this.set('adding_member', true);
        }
      },
      toggleArchived: function () {
        let convo = this.model;
        let isArchived = convo.get('archived');
        let un = isArchived ? 'un' : '';
        let dialogMessage = 'Are you sure you wish to ' + un + 'archive this conversation? To undo this action later, click the folder icon again.';
        (0, _dialog.default)(dialogMessage, ['Yes', 'No']).then(selection => {
          if (selection === 'Yes') {
            convo.set('workflow_state', isArchived ? 'active' : 'archived');
            convo.save();
          }
        });
      },
      updateConversation: function () {
        var message = this.parseEmbed(this.bodyInput);

        /* eslint-disable-next-line ember/no-jquery */
        if (Ember.$.trim(message.replace(/&nbsp;/g, ' ').replace(/<!---->/g, ' ')).length === 0) {
          return;
        }
        message = message.replace('<!---->', '');
        message = message.trim();

        // Add any video embeds
        message = message + this.videoEmbedString;
        this.set('uploadInProgress', true);
        let current_conversation = this.model,
          conversationPartners = current_conversation.get('conversationPartners');

        // Uploading attachments
        this.set('uploadInProgress', true);

        // The first message is saved along with the original conversation
        if (current_conversation.get('isNew')) {
          current_conversation.set('lastActivity', new Date());
          current_conversation.set('new_message_body', message);
          if (!this.working && this.file_ids.length > 0) {
            current_conversation.set('attachments', this.file_ids);
          }
          current_conversation.save().then(savedConversation => {
            this.set('bodyInput', '');
            /* eslint-disable-next-line ember/no-jquery */
            Ember.$('.rte-editor-input').html('');
            this.send('clearAllFiles');
            this.set('uploadInProgress', false);
            savedConversation.set('last_authored_message', message);
          }, err => {
            Ember.debug(err, 'Create new conversation to ' + conversationPartners.id);
            this.set('uploadInProgress', false);
            window.alert('Unable to post message. Please try again.');
          }).then(() => {
            this.transitionToRoute('classroom.lessons.conversation', current_conversation.get('id'));
          });
        } else {
          this.store.nestResources('conversation-message', [{
            'conversation': current_conversation.get('id')
          }]);
          let new_conversation_message = current_conversation.store.createRecord('conversation_message');
          new_conversation_message.set('body', message);
          new_conversation_message.set('createdAt', new Date());
          new_conversation_message.set('belongsToSelf', true);
          new_conversation_message.set('conversation', current_conversation.get('id'));
          new_conversation_message.set('conversationPartners', conversationPartners);
          if (!this.working && this.file_ids.length > 0) {
            new_conversation_message.set('attachments', this.file_ids);
          }
          new_conversation_message.save().then(() => {
            this.set('bodyInput', '');
            /* eslint-disable-next-line ember/no-jquery */
            Ember.$('.rte-editor-input').html('');
            this.send('clearAllFiles');
            this.set('uploadInProgress', false);
            current_conversation.set('last_authored_message', message);
            current_conversation.set('last_message', message);
            current_conversation.set('lastActivity', new Date());
          }, err => {
            Ember.debug({
              Location: 'Post conversation reply',
              Status: err.status,
              Message: err.message,
              Error: err.responseText
            });
            this.set('uploadInProgress', false);
            window.alert('Unable to post message. Please try again.');
          }).then(() => {
            this.scrollAndUpdateLocalConversation(current_conversation);
          });
        }

        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.pop-attachment-drawer').addClass('hidden');
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.floating-modal.active').removeClass('drawer-open');
      },
      deleteUnsentConversation: function () {
        let current_conversation = this.model;
        if (current_conversation && current_conversation.get('isNew')) {
          current_conversation.destroyRecord();
        }
      },
      destroyEditor: function () {
        //This editorDestroyed logic is necessary because this method gets called twice,
        //once by 'closeModals', and the other time by 'destroyer'
        if (!this.editorDestroyed) {
          this._super();
          this.send('deleteUnsentConversation');

          // Ensure that the heartbeat stops
          this.set('model', null);
          this.editorDestroyed = true;
        }
      }
    }
  });
});