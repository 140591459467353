define("bocce/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Oyc7NQ+K",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],[\"banner-drawer\"],null]],[2,\"\\n\\n\"],[6,[37,1],[[32,0,[\"session\",\"hasAdBlock\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"adblock-warning\",[],[[\"@session\"],[[32,0,[\"session\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[1,[30,[36,0],[[30,[36,2],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"component\",\"if\",\"-outlet\"]}",
    "meta": {
      "moduleName": "bocce/templates/application.hbs"
    }
  });
});