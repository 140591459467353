define("bocce/helpers/morethanzero", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // app/helpers/morethanzero.js
  // Checks if the array has elements
  var _default = _exports.default = Ember.Helper.helper(function (item) {
    if (!item || !item[0] || !item[0].get) {
      return false;
    }
    return item[0].get('length') > 0;
  });
});