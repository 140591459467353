define("bocce/templates/components/quiz-questions/fill-in-multiple-blanks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "foDbzJs1",
    "block": "{\"symbols\":[],\"statements\":[[2,[34,0]]],\"hasEval\":false,\"upvars\":[\"answersHtml\"]}",
    "meta": {
      "moduleName": "bocce/templates/components/quiz-questions/fill-in-multiple-blanks.hbs"
    }
  });
});