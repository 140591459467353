define("bocce/models/user", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // app/models/user.js
  var _default = _exports.default = _model.default.extend({
    name: (0, _model.attr)('string'),
    short_name: (0, _model.attr)('string'),
    sortable_name: (0, _model.attr)('string'),
    image_url: (0, _model.attr)('string'),
    isCurrentUser: (0, _model.attr)('boolean'),
    teaching: (0, _model.hasMany)('section', {
      inverse: 'teachers',
      async: false
    }),
    loginDays: (0, _model.attr)(),
    bookmarks: (0, _model.hasMany)('bookmark', {
      async: false
    }),
    allBookmarkContentTagIds: (0, _model.attr)(),
    enrolled: (0, _model.hasMany)('enrollment', {
      async: false
    }),
    sectionCodes: (0, _model.attr)(),
    profile: (0, _model.attr)(),
    profile_updated: (0, _model.attr)('boolean'),
    lastviewed: (0, _model.attr)(),
    webexid: (0, _model.attr)(),
    zoomid: (0, _model.attr)(),
    email: (0, _model.attr)(),
    eventSchedulerTemplates: (0, _model.attr)(),
    notes: (0, _model.attr)(),
    staff: (0, _model.attr)('boolean'),
    admin: (0, _model.attr)('boolean'),
    acsUserId: (0, _model.attr)('string'),
    sfUserId: (0, _model.attr)('string'),
    calendarHash: (0, _model.attr)('string'),
    accommodation: (0, _model.attr)('string'),
    hideGradebookConfirmations: (0, _model.attr)('boolean'),
    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    isEnrolled: Ember.computed(function () {
      var enrolled = this.enrolled.get('content') || false;
      return enrolled.length > 0 ? true : false;
    }),
    whenPresent: (0, _model.attr)('date'),
    isPresent: (0, _model.attr)('boolean'),
    quizzesProgress: (0, _model.attr)(),
    hasAccommodation: Ember.computed('accommodation', function () {
      return this.accommodation && this.accommodation !== '';
    }),
    isDegreeStudent: Ember.computed('enrolled', function () {
      // check the user enrollment data to determine if the student
      // is degree or not
      let enrollmentData = this.enrolled,
        degree;
      if (enrollmentData && enrollmentData.length) {
        degree = enrollmentData.length !== 0 ? enrollmentData.objectAt(0).get('degree') : null;
      }
      return degree && degree !== '' ? true : false;
    }),
    hasZoomId: Ember.computed('zoomid', function () {
      if (this.zoomid !== 'not found') {
        return true;
      } else {
        return false;
      }
    }),
    enrolled_late: Ember.computed('name', function () {
      let user = session.get('section.enrollments').findBy('user.id', this.get('id'));

      //If the user is not in this course
      if (!user) {
        return false;
      }
      let enrollDate = user.get('created_at');
      let termStart = session.get('section.course.term.startsOn');

      // If either date is not found for some reason, return false
      if (!enrollDate || !termStart) {
        return false;
      }

      // Convert both dates to date objects
      enrollDate = new Date(enrollDate);
      termStart = new Date(termStart);

      // If either date is not a valid date, return false
      if (isNaN(enrollDate.getDate()) || isNaN(termStart.getDate())) {
        return false;
      }

      // We only need to compare days...
      enrollDate.setHours(0, 0, 0, 0);
      termStart.setHours(0, 0, 0, 0);
      let returnLate = false;
      if (enrollDate > termStart) {
        // Return date as mm/dd/yy
        returnLate = enrollDate.toLocaleDateString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: '2-digit'
        });
      }
      return returnLate;
    })
    /*
      Uncomment to test online indicator
      isPresent: computed(function () {
      return Math.random() >= 0.5;
    })*/
  });
});