define("bocce/components/quiz-scenarios/quiz-all-graded", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class QuizSingleGradedResults extends Ember.Component {
    didInsertElement() {
      Ember.$(() => {
        Ember.$(this.element).find('.quiz-question-wrapper').last().css('border-bottom', '1px solid var(--gray-light)');
        if (!this.quizzes.quiz.introduction) {
          Ember.$(this.element).find('.question-index').first().css('border-top', 'none');
        }
      });
      this.quizzes.element = this.element;
    }
  }
  _exports.default = QuizSingleGradedResults;
});