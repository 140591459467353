define("bocce/templates/components/quiz-stats/types/file-upload-question-statistics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "8j5cLlnH",
    "block": "{\"symbols\":[\"@statistics\"],\"statements\":[[8,\"quiz-stats/question-statistics-manual\",[],[[\"@statistics\"],[[32,1]]],null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "bocce/templates/components/quiz-stats/types/file-upload-question-statistics.hbs"
    }
  });
});