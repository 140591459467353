define("bocce/mixins/interactions/simple_slideshow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function SimpleSlideshow(el, data) {
    data = convertToJSON(el[0]);
    this.$el = el; // grab :first because this interaction can have many nested interactions
    //this.data = data;
    this.$container = this.$el.find('.interaction_content:first'); // add autosizing class. this will be removed later if the
    // slide show contains Interaction Components

    this.$container.addClass('autosize');
    this.currentIndex = -1;
    this.data = data.initial_json;
    this.addNavAndStage();
    this.cacheEls();
    this.initButtons();
  }
  function convertToJSON(element) {
    const jsonData = [];
    element.querySelectorAll("table tr td").forEach(slideData => {
      let title = slideData.children[0].innerHTML;
      let img_path = slideData.querySelector("img").src;
      let description = slideData.children[2].innerText;
      let alt_text = slideData.querySelector("img").alt_text || "";
      jsonData.push({
        title,
        img_path,
        description,
        alt_text
      });
    });
    return {
      "slide_id": element.id,
      "initial_json": jsonData
    };
  }
  SimpleSlideshow.prototype = {
    init: function () {
      // render template
      for (let i = 0; i < this.data.length; i++) {
        const obj = this.data[i];
        let str = this.addTemplate(obj, 'stage_id_' + i);
        this.$container.find(".stage").append(str);
      }
      return this.nextSlide();
    },
    // getData: function getData($el, $container, $stage) {
    //   var divs = $el.find('.interaction_data:first > div');
    //   var data = [];
    //   /* eslint-disable-next-line ember/no-jquery */

    //   $(divs).each(function () {
    //     var $item, isTable, isInteraction;
    //     /* eslint-disable-next-line ember/no-jquery */

    //     $item = $(this);
    //     isTable = $item.hasClass('table-component');
    //     isInteraction = $item.find('.interaction_content').length > 0;

    //     if (isTable) {
    //       // pull out all td's from the table
    //       $item.find('td').each(function () {
    //         /* eslint-disable-next-line ember/no-jquery */
    //         var $td = $(this);
    //         data.push(new TableRowContent($td, $stage));
    //       });
    //     } else if (isInteraction) {
    //       data.push(new InteractionComponent($item, $stage)); // Simple Slideshows with interactions need to be manually sized

    //       $container.removeClass('autosize');
    //     } else {
    //       throw 'Unsupported Content! This interaction accepts only table components and interaction components';
    //     }
    //   });
    //   return data;
    // },
    addTemplate: function (data, id) {
      var html, template;
      html = '<div style="display:none;" id="' + id + '">' + '<h2 class="body" tabindex="-1">' + data.title + '</h2>' + '<div  class="component image-component float-none clear-none booted"><img class="" tabindex="-1" src="' + data.img_path + '"  title="" alt="' + data.alt_text + '"></div>' + '<p class="body  last" tabindex="-1">' + data.description + '</p>' + '</div>';
      return html;
    },
    addNavAndStage: function () {
      const nav = '<div class="nav">\n' + '<button class="prev" tabindex="25" aria-label="Previous"><i class="fas fa-arrow-circle-left"></i></button>\n' + '<div class="count"></div>\n' + '<button class="next" tabindex="25" aria-label="Next"><i class="fas fa-arrow-circle-right"></i></button>\n' + '</div>';
      const stage = '<div class="stage scrollbar-mobile"></div>';
      this.$container.append(nav);
      this.$container.append(stage);
    },
    cacheEls: function () {
      var indexing = "";
      this.$stage = this.$el.find('.stage:first');
      this.$count = this.$el.find('.count:first');
      for (let i = 0; i < this.data.length; i++) {
        indexing += '<p class="nav cur_' + (i + 1) + '" tabindex="25" data="' + i + '">' + (i + 1) + '</p>';
      }
      this.$count.html(indexing);
    },
    initButtons: function () {
      var $prevBtn, $nextBtn;
      let slideshow = this;
      $prevBtn = this.$el.find('.prev');
      $nextBtn = this.$el.find('.next');

      // use proxy to bind correct 'this' to handlers

      /* eslint-disable-next-line ember/no-jquery */

      $prevBtn.click(Ember.$.proxy(this.prevSlide, this));
      /* eslint-disable-next-line ember/no-jquery */

      $nextBtn.click(Ember.$.proxy(this.nextSlide, this));
      this.$el.find('.count').find(".nav").off("click").on("click", function () {
        //console.log($(this).attr("data"));
        slideshow.clickSlide(parseInt(Ember.$(this).attr("data")));
      });
    },
    clickSlide: function (inx) {
      this.currentIndex = inx;
      this.loadSlide(this.currentIndex);
    },
    prevSlide: function prevSlide(e) {
      if (e) {
        e.preventDefault();
      }
      this.currentIndex--;
      //console.log($(this).attr("tabindex"));
      if (this.currentIndex < 0) {
        this.currentIndex = this.data.length - 1;
      }
      //$('.next,.prev').attr("tabindex",26+ this.currentIndex)
      this.loadSlide(this.currentIndex);
    },
    nextSlide: function (e) {
      if (e) {
        e.preventDefault();
      }
      this.currentIndex++;
      if (this.currentIndex >= this.data.length) {
        this.currentIndex = 0;
      }
      //$('.next,.prev').attr("tabindex",26+ this.currentIndex)
      this.loadSlide(this.currentIndex);
    },
    updateSlideCount: function (index, total) {
      var current;
      current = index + 1;
      this.$count.find(".nav").removeClass("active");
      this.$count.find(".cur_" + current).addClass("active");
    },
    loadSlide: function (index) {
      var slide = this.data[index];
      //console.log(slide.stage);
      // this.$stage.find('video, audio').get().forEach(function (e) {
      //   // find media elements in the slide and stop them
      //   e.pause();
      // });
      this.$el.find('.stage.scrollbar-mobile').children().hide();
      //$('.stage.scrollbar-mobile').html(slide.stage);
      this.$el.find("#stage_id_" + index).show();
      this.updateSlideCount(index, this.data.length);
      Ember.$('.last').focusout(function () {
        //$('.next,.prev').attr("tabindex",26+index)
        Ember.$('.prev').focus();
      });
    }
  };
  var _default = _exports.default = SimpleSlideshow;
});