define("bocce/mirage/factories/course", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    code(i) {
      return `course-${i}`;
    },
    afterCreate(course, server) {
      course.update({
        sections: server.createList('section', 1),
        lessons: server.createList('lesson', 1)
      });
    }
  });
});