define("bocce/mixins/av-players", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable-next-line ember/no-new-mixins */
  var _default = _exports.default = Ember.Mixin.create({
    session: Ember.inject.service(),
    //Keep track of the currently playing audio so that playback can be tracked with a progress bar.
    playingAudio: {
      id: '',
      item: null
    },
    //Keep track of the currently playing video so that playback can be tracked with a progress bar.
    playingVideo: {
      id: '',
      item: null,
      player: null
    },
    ticking: false,
    startAV: function () {
      this.session.currentAbortControllers.forEach(listener => listener.abort());
      this.session.clearAbortControllers();
      this.mainPanel = document.querySelector('.main-panel');
      this.avPlayerOnClickListener = e => {
        if (e.target.classList.contains('track-handle') || e.target.classList.contains('audio-asset')) {
          this.playAudioFromOb(e);
        } else if (e.target.classList.contains('video-asset')) {
          this.playVideoFromOb(e);
        } else if (e.target.classList.contains('kaltura-asset')) {
          this.playKalturaFromOb(e);
        }
        let compPath = e.composedPath();
        let hasVideoPlayer = this.playingVideo.player ? compPath.includes(this.playingVideo.player) : false;
        let hasAudioPlayer = this.audioPlayer ? compPath.includes(this.audioPlayer) : false;

        // If the evt target path does not contain the video player or audio player, disable the keyboard shortcuts
        if (hasVideoPlayer) {
          this.videoUnfocus = false;
        } else if (hasAudioPlayer) {
          this.videoUnfocus = false;
        } else {
          this.videoUnfocus = true;
        }
      };
      document.addEventListener('click', this.avPlayerOnClickListener);

      // Check if document already has a click event listener
      if (!this.mainPanel.hasAttribute('data-click-listener')) {
        this.mainPanel.setAttribute('data-click-listener', true);
        this.audio = document.querySelector('.audio-player .player');
        this.audioSeek = document.querySelector('.audio-player .audio-controls #player-seek');
        this.audioVolume = document.querySelector('.audio-player #player-vol');
        this.audioTimeCode = document.querySelector('.audio-player .audio-timecode');
        this.audioControls = document.querySelectorAll('.audio-controls > i:not(.fa-repeat), .audio-controls > label:not(.fa-repeat)');
        this.audioControlsPlayPause = document.querySelectorAll('.audio-controls > i.fa-play, .audio-controls > i.fa-pause');
        this.audioRepeatBtn = document.querySelector('.audio-controls > .fa-repeat');
        this.audioPlayer = document.querySelector('.audio-player');
        this.audioPlayerLesson = document.querySelector('.audio-player .topic-title');
        if (Ember.$.isMobile) {
          document.querySelector('.video-player .fa-window-restore').classList.add('ipad-control');
        } else {
          document.removeEventListener('keydown', this.keydown.bind(this));

          // When user focuses out of the text input, enable the keyboard shortcuts again
          document.addEventListener('focusin', e => {
            // If there's neither a video or audio player active, ignore
            if (!this.playingVideo.player && !this.audioPlayer) {
              this.videoUnfocus = true;
              return;
            }
            // If focused element path contains the video player, enable the keyboard shortcuts
            let compPath = e.composedPath();
            if (this.playingVideo.player && compPath.includes(this.playingVideo.player) || this.audioPlayer && compPath.includes(this.audioPlayer)) {
              this.videoUnfocus = false;
            } else {
              this.videoUnfocus = true;
            }
          });
        }
        this.audioSeek.addEventListener('input', this.seekAudioAction.bind(this));
        this.audioVolume.addEventListener('input', this.audioVolumeAction.bind(this));
        document.querySelector('.ap-close').addEventListener('click', this.audioClose.bind(this));
        this.timeupdateEventHandler = this.timeupdateEventHandler ?? this.updateAudioSeek;
        this.endedEventHandler = this.endedEventHandler ?? this.audioEnded;
        this.pauseEventHandler = this.pauseEventHandler ?? this.audioPaused;
        this.playingEventHandler = this.playingEventHandler ?? this.audioIsPlaying;
        this.audio.addEventListener('timeupdate', this.timeupdateEventHandler.bind(this));
        this.audio.addEventListener('ended', this.endedEventHandler.bind(this));
        this.audio.addEventListener('pause', this.pauseEventHandler.bind(this));
        this.audio.addEventListener('playing', this.playingEventHandler.bind(this));
        this.audioRepeatBtn.addEventListener('click', this.toggleRepeatAudioAction.bind(this));
        this.audioControlsPlayPause.forEach(btn => {
          btn.addEventListener('click', this.playPauseAudioAction.bind(this));
        });
      }
    },
    keydown(e) {
      // IF user is typing in a text field, don't do anything
      if (this.videoUnfocus) {
        return;
      }
      let item;
      // Check for currently playing audio or video
      if (this.playingAudio.item) {
        item = this.playingAudio.item;
      } else if (this.playingVideo) {
        item = this.playingVideo.item;
      } else {
        return;
      }

      // Switch on key code
      switch (e.keyCode) {
        case 32:
          // space - play/pause
          e.preventDefault();
          if (this.playingAudio.item) {
            this.playPauseAudioAction(item);
          } else if (this.playingVideo) {
            this.videoPlayPauseAction(item);
          }
          break;
        case 37:
          // left arrow - rewind 5 seconds
          e.preventDefault();
          if (this.playingAudio.item) {
            this.seekAudioAction(item, -5);
          } else if (this.playingVideo) {
            this.videoSeekEvent(item, -5);
          }
          break;
        case 39:
          // right arrow - forward 5 seconds
          e.preventDefault();
          if (this.playingAudio.item) {
            this.seekAudioAction(item, 5);
          } else if (this.playingVideo) {
            this.videoSeekEvent(item, 5);
          }
          break;
        case 38:
          // up arrow - increase volume
          e.preventDefault();
          if (this.playingAudio.item) {
            this.audioVolumeAction(item, 0.1);
          } else if (this.playingVideo) {
            this.videoVolumeAction(item, 0.1);
          }
          break;
        case 40:
          // down arrow - decrease volume
          e.preventDefault();
          if (this.playingAudio.item) {
            this.audioVolumeAction(item, -0.1);
          } else if (this.playingVideo) {
            this.videoVolumeAction(item, -0.1);
          }
          break;
        case 70:
          // f  - toggle fullscreen
          e.preventDefault();
          if (this.playingVideo) {
            this.toggleFullScreenAction(item);
          }
          break;
        case 67:
          // c - toggle captions
          e.preventDefault();
          if (this.playingVideo) {
            this.toggleCCAction(item);
          }
          break;
        case 77:
          // m - toggle mute
          e.preventDefault();
          if (this.playingAudio.item) {
            this.toggleAudioMuteAction(item);
          } else if (this.playingVideo) {
            this.toggleVideoVolumeAction(item);
          }
          break;
        case 74:
          // j - rewind 10 seconds
          e.preventDefault();
          if (this.playingVideo) {
            this.videoSeekEvent(item, 10);
          } else if (this.playingAudio.item) {
            this.seekAudioAction(item, 10);
          }
          break;
        case 73:
          // i - forward 10 seconds
          e.preventDefault();
          if (this.playingVideo) {
            this.videoSeekEvent(item, -10);
          } else if (this.playingAudio.item) {
            this.seekAudioAction(item, -10);
          }
          break;
        case 190:
          // . - Increase playback speed
          e.preventDefault();
          if (this.playingVideo) {
            this.changeVideoSpeedAction(item, 1);
          }
          break;
        case 188:
          // , - Decrease playback speed
          e.preventDefault();
          if (this.playingVideo) {
            this.changeVideoSpeedAction(item, -1);
          }
          break;
        case 221:
          // ] - Jump to next CC cue
          e.preventDefault();
          if (this.playingVideo) {
            this.jumpCueAction(item);
          }
          break;
        case 219:
          // [ - Jump to previous CC cue
          e.preventDefault();
          if (this.playingVideo) {
            this.jumpCueAction(item, true);
          }
          break;
        // no default
      }
    },
    //Audio Player
    audioClose: function () {
      //Remove the playing audio's progress bar
      if (this.playingAudio.id && this.playingAudio.item && this.playingAudio.item.target) {
        this.playingAudio.item.target.closest('#' + this.playingAudio.id).style['background-image'] = '';
        this.playingAudio.id = '';
        this.playingAudio.item = null;
      }
      this.audioPlayer.classList.remove('active');
      this.audioPlayer.querySelectorAll('audio').forEach(function (audio) {
        audio.pause();
      });
    },
    seekAudioAction: function (item, time) {
      let length = this.audio ? this.audio.duration : 0;
      if (time) {
        this.audio.currentTime = this.audio.currentTime + time;
      } else if (length) {
        this.audio.currentTime = parseInt(item.target.value) / 1000 * length;
      }
    },
    audioVolumeAction: function (item, vol) {
      if (vol) {
        let newVol = this.audio.volume + vol;
        if (newVol > 1) {
          newVol = 1;
        } else if (newVol < 0) {
          newVol = 0;
        }
        this.audio.volume = newVol;
        // Adjust the volume slider
        this.audioVolume.value = this.audio.volume * 100;
        return;
      }
      this.audio.volume = parseInt(item.target.value) / 100;
    },
    toggleAudioMuteAction: function () {
      if (this.audio.muted) {
        this.audio.muted = false;
        this.audioVolume.value = this.audio.volume * 100;
      } else {
        this.audio.muted = true;
        this.audioVolume.value = 0;
      }
    },
    updateAudioSeek: function (item) {
      let time = item.target.currentTime,
        minutes = Math.floor(time / 60),
        seconds = Math.floor(time) % 60,
        curTime;
      seconds = seconds < 10 ? '0' + seconds.toString() : seconds.toString();
      minutes = minutes < 10 ? '0' + minutes.toString() : minutes.toString();
      curTime = minutes + ':' + seconds;
      const audioPercentComplete = Math.round(1000 * (time / item.target.duration)) / 10;

      //Scale is 0 to 1000
      this.audioSeek.value = audioPercentComplete * 10;

      //Update the playing audio's progress bar.`
      if (this.playingAudio.id && this.playingAudio.item && this.playingAudio.item.target) {
        this.playingAudio.item.target.closest('#' + this.playingAudio.id).style['background-image'] = 'linear-gradient(to right, #00bcd4 ' + audioPercentComplete + '%, #fff ' + audioPercentComplete + '%)';
      }
      this.audioTimeCode.setAttribute('time_code', curTime);
    },
    playPauseAudioAction: function (pause) {
      if (!pause || this.audio.paused) {
        document.querySelectorAll('audio.public_audio_player').forEach(function (ob) {
          ob.pause();
        });

        // This is for Safari -- Safari freaks out if there's a play command without a promise
        let promise = this.audio.play();
        if (promise !== undefined) {
          promise.catch(() => {
            // Auto-play was prevented
          }).then(() => {
            // Auto-play started
          });
        }
      } else {
        this.audio.pause();
      }
    },
    toggleRepeatAudioAction: function () {
      if (this.audioRepeatBtn.classList.contains('active')) {
        this.audioRepeatBtn.classList.remove('active');
        this.audio.loop = false;
      } else {
        this.audio.loop = true;
        this.audioRepeatBtn.classList.add('active');
      }
    },
    checkDom: function () {
      // Check to make sure the DOM player objects are correctly defined
      if (this.audio && this.audioSeek && this.audioVolume && this.audioTimeCode && this.audioControls && this.audioControlsPlayPause && this.audioRepeatBtn && this.audioPlayer && this.audioPlayerLesson) {
        return;
      }

      // If any are missing, define them again
      this.mainPanel = document.querySelector('.main-panel');
      this.audio = document.querySelector('.audio-player .player');
      this.audioSeek = document.querySelector('.audio-player .audio-controls #player-seek');
      this.audioVolume = document.querySelector('.audio-player #player-vol');
      this.audioTimeCode = document.querySelector('.audio-player .audio-timecode');
      this.audioControls = document.querySelectorAll('.audio-controls > i:not(.fa-repeat), .audio-controls > label:not(.fa-repeat)');
      this.audioControlsPlayPause = document.querySelectorAll('.audio-controls > i.fa-play, .audio-controls > i.fa-pause');
      this.audioRepeatBtn = document.querySelector('.audio-controls > .fa-repeat');
      this.audioPlayer = document.querySelector('.audio-player');
      this.audioPlayerLesson = document.querySelector('.audio-player .topic-title');
    },
    playAudioFromOb: function (item) {
      this.checkDom();
      let tgt = item.target.attributes.source || item.target.parentNode.attributes.source,
        title = item.target.attributes.title,
        next = item.target.attributes.next,
        prev = item.target.attributes.prev,
        prevBtn = this.audioPlayer.querySelector('.prevBtn'),
        nextBtn = this.audioPlayer.querySelector('.nextBtn'),
        relocate;
      this.playingVideo = {
        id: '',
        item: null,
        player: null
      };
      this.videoUnfocus = false;

      //Clear the previously played audio's progress bar.
      if (this.playingAudio.id && this.playingAudio.item && this.playingAudio.item.target) {
        this.playingAudio.item.target.closest('#' + this.playingAudio.id).style['background-image'] = '';
      }
      if (!this.keyDownListener && !Ember.$.isMobile) {
        this.keyDownListener = true;
        let controller = new AbortController();
        document.addEventListener('keydown', this.keydown.bind(this), {
          signal: controller.signal
        });
        this.session.addAbortController(controller);
      }

      //Set the id for the playing audio.
      //The html attribute 'data-playback-tracking-id' is added to the parsed topic body's html from canvas.
      this.playingAudio.id = item.target.attributes['data-playback-tracking-id'] ? item.target.attributes['data-playback-tracking-id'].value : false;
      this.playingAudio.item = item;
      title = title ? title.value : document.querySelector('.lesson-content').previousSibling.nodeValue;
      let kCont = document.getElementById('kalturaContainer');
      if (kCont.sendNotification) {
        kCont.sendNotification('pause');
      }
      prevBtn.classList.add('inactive');
      nextBtn.classList.add('inactive');
      this.closeVideoPlayer();
      if (prev) {
        prevBtn.classList.remove('inactive');
        prevBtn.setAttribute('for', prev.value);
      }
      if (next) {
        nextBtn.classList.remove('inactive');
        nextBtn.setAttribute('for', next.value);
      }
      let activeVideo = document.querySelector('.video-player.active');
      if (activeVideo) {
        activeVideo.classList.remove('active');
      }
      const previousPlaybackRate = this.audio.playbackRate;
      this.audio.attributes.src.value = tgt.value;
      this.audio.load();
      this.audio.playbackRate = previousPlaybackRate;
      let promise = this.audio.play();
      if (promise !== undefined) {
        promise.catch(() => {
          // Auto-play was prevented
        }).then(() => {
          // Auto-play started
        });
      }
      this.audioPlayer.classList.add('active');
      this.audioPlayerLesson.setAttribute('title', title);
      relocate = item.target.closest('.audio-asset');
      if (relocate) {
        relocate = relocate.getAttribute('id');
      } else {
        relocate = item.target.closest('.audio_multi').getAttribute('id');
      }
      this.audioPlayer.setAttribute('relocate', relocate);
    },
    audioEnded: function (event) {
      let player = document.getElementById('publicPlayer');
      //  Activate 1 (play button)
      this.audioControls[1].classList.remove('inactive');
      //  Deactivate 2 (pause button)
      this.audioControls[2].classList.add('inactive');

      //  If 3 (Next button) is active, click it
      if (!this.audioControls[3].classList.contains('inactive')) {
        this.audioControls[3].click();
      } else if (player.loop && player.currentTime > Math.floor(player.duration - 1) && event.eventPhase === 2) {
        player.currentTime = 0;
        let promise = player.play();
        if (promise !== undefined) {
          promise.catch(() => {
            // Auto-play was prevented
          }).then(() => {
            // Auto-play started
          });
        }
      }
    },
    audioPaused: function () {
      //  Activate 1 (play button)
      this.audioControls[1].classList.remove('inactive');
      //  Deactivate 2 (pause button)
      this.audioControls[2].classList.add('inactive');
    },
    audioIsPlaying: function () {
      // Activate 2 (pause button)
      this.audioControls[2].classList.remove('inactive');
      // Deactivate 1 (play button)
      this.audioControls[1].classList.add('inactive');
    },
    closeAudioPlayer: function () {
      if (this.playingAudio.id && this.playingAudio.item && this.playingAudio.item.target) {
        this.playingAudio.item.target.closest('#' + this.playingAudio.id).style['background-image'] = '';
      }
      this.audio.pause();
      this.audioPlayer.classList.remove('active');
    },
    //Video Player
    playVideoFromOb: function (item) {
      // If the button clicked contains the fa-window-restore class, just maximitize the video player.
      if (item.target.classList.contains('fa-window-restore') || !!item.target.querySelector('.fa-window-restore')) {
        this.minmaxVideoPlayer(item, false);
        return;
      }
      this.checkDom();
      let rect = item.target.parentElement.lastElementChild.getBoundingClientRect(),
        cprnt = item.target.tagName.toLowerCase() === 'button' ? item.target : false;
      cprnt = !cprnt && (item.target.tagName.toLowerCase() === 'img' || item.target.tagName.toLowerCase() === 'i') ? item.target.parentNode : item.target;
      this.closeVideoPlayer(this);
      this.closeAudioPlayer();
      this.videoTranscriptItems = [];
      if (this.videoPlayerObserver) {
        this.videoPlayerObserver.disconnect();
      }
      if (!this.keyDownListener && !Ember.$.isMobile) {
        this.keyDownListener = true;
        let controller = new AbortController();
        document.addEventListener('keydown', this.keydown.bind(this), {
          signal: controller.signal
        });
        this.session.addAbortController(controller);
      }
      this.playingAudio.id = '';
      this.playingAudio.item = null;
      this.playingVideo = {
        id: '',
        item: null,
        player: null
      };
      let inf = cprnt.querySelector('i');
      let video_player;
      inf.classList.remove('fa-play');
      inf.classList.add('fa-spinner-third');
      inf.classList.add('fa-spin');
      video_player = document.querySelector('.video-player:not(#kalturaPlayer):not(.standalone)').cloneNode(true);
      video_player.classList.add('standalone');
      video_player.style.width = rect.width + 'px';
      video_player.style.height = rect.height + 'px';
      item.target.closest('.video-asset').parentElement.prepend(video_player);
      video_player.querySelector('.play-button').focus();
      this.playingVideo.id = item.target.attributes['data-playback-tracking-id'] ? item.target.attributes['data-playback-tracking-id'].value : false;
      this.playingVideo.item = item;
      this.playingVideo.player = video_player;

      // Disable right click on video player
      video_player.addEventListener('contextmenu', function (e) {
        e.preventDefault();
      });
      let video = video_player.querySelector('video');
      if (cprnt.attributes.noFlavors) {
        video_player.classList.add('noFlavors');
      } else {
        // Start a timer. IF the video doesn't load in 5 seconds, bump it down to 480p
        this.videoResTimer = setTimeout(() => {
          this.changeVideoResAction(false, '480p', video_player);
        }, 8000);
      }
      if (cprnt.attributes.uncompressed) {
        video_player.classList.add('uncompressed');
      }
      function brokenCCAlert(videoID, error) {
        //Turn video id into a url-encoded string
        let videoIDString = encodeURIComponent(videoID);
        let errorString = encodeURIComponent(error);
        Ember.$.ajax({
          type: 'POST',
          /* eslint-disable-next-line ember/no-get */
          url: `/interface/system/broken-cc?vidURL=${videoIDString}&vidError=${errorString}`,
          success: () => {
            console.log('BCC Alert sent');
          },
          error: error => {
            console.log('BCC Alert failed!');
            console.log(error);
          }
        });
      }
      function brokenVideoAlert(videoID, error) {
        let videoIDString = encodeURIComponent(videoID);
        let errorString = encodeURIComponent(error);
        // eslint-disable-next-line ember/no-jquery
        Ember.$.ajax({
          type: 'POST',
          url: `/interface/system/broken-video?vidURL=${videoIDString}&vidError=${errorString}`,
          success: () => {
            console.log('Broken Video Alert sent');
          },
          error: error => {
            console.log('Broken Video Alert failed!');
            console.log(error);
          }
        });
      }

      // Convert track from SRT to VTT
      function srt2vtt(responseText, context) {
        // Return if no CC data
        if (responseText.length === 0) {
          return false;
        }

        // Convert the srt to vtt format if necessary
        let isVTT = responseText.indexOf('WEBVTT') > -1;
        let vtt = isVTT ? responseText : 'WEBVTT\n\n';

        // remove all instances of \r\n and replace with \n -- also replace all single \r with \n
        // Carriage returns are not allowed in WebVTT files
        responseText = responseText.replace(/\r\n/g, '\n').replace(/\r/g, '\n');
        let srt_lines = responseText.split('\n');
        let transcriptRaw = [];

        // If the file is VTT, remove the first 2 lines from the SRT_lines array
        // That's extra guff we don't need
        if (isVTT) {
          srt_lines.splice(0, 2);
        }
        let srt_lines_length = srt_lines.length;

        // Split the CC file apart into lines and time codes for transcript rendering
        for (let i = 0; i < srt_lines_length; i++) {
          let srt_line = srt_lines[i];
          if (srt_line.indexOf('-->') > -1 && i < srt_lines_length - 1) {
            // Remove the last item in the transcriptRaw array if it's empty
            // This is to prevent empty lines from showing up in the transcript
            if (transcriptRaw.length > 0 && (!transcriptRaw[transcriptRaw.length - 1].text || transcriptRaw[transcriptRaw.length - 1].text === '')) {
              transcriptRaw.pop();
            }
            if (!isVTT) {
              srt_line = srt_line.replaceAll(',', '.');
              if (srt_line.split('.')[1].length < 3) {
                srt_line = srt_line + '0';
              }
              // Add line styling for captions here
              srt_line += 'align:middle line:84%';
              vtt += srt_line + '\n';
            }
            let convertedTime = srt_line.split(' --> ');
            // If the previous item in the array doesn't have text, delete it
            if (transcriptRaw.length > 0 && !transcriptRaw[transcriptRaw.length - 1].text) {
              transcriptRaw.pop();
            }
            // If there's a time code, convert to seconds
            if (convertedTime.length > 1) {
              convertedTime = convertedTime[0];
              convertedTime = convertedTime.split(':');
              // Convert HH:MM:SS.MMM to seconds
              convertedTime = parseInt(convertedTime[0]) * 3600 + parseInt(convertedTime[1]) * 60 + parseInt(convertedTime[2].split('.')[0]) + parseInt(convertedTime[2].split('.')[1]) / 1000;
              transcriptRaw.push({
                time: convertedTime
              });
            }
          } else if (srt_line.indexOf('-->') === -1 && srt_line.length > 0 && isNaN(srt_line)) {
            if (transcriptRaw.length > 0 && !transcriptRaw[transcriptRaw.length - 1].text) {
              transcriptRaw[transcriptRaw.length - 1].text = srt_line + '\n';
            } else {
              transcriptRaw[transcriptRaw.length - 1].text += srt_line + '\n';
            }
            if (!isVTT) {
              vtt += srt_line + '\n';
            }
          }
        }

        // If transcriptRaw is not empty, create an HTML paragraph element for each item in the array
        if (transcriptRaw.length > 0) {
          let transcript = document.createElement('p');
          transcript.classList.add('transcript');
          for (let i = 0; i < transcriptRaw.length; i++) {
            let transcriptItem = document.createElement('span');
            transcriptItem.classList.add('transcriptItem');
            transcriptItem.setAttribute('data-time', transcriptRaw[i].time);
            transcriptItem.innerHTML = transcriptRaw[i].text;
            transcript.appendChild(transcriptItem);
          }
          // Add a show/hide button to the end of the transcript
          let transcriptShowHide = document.createElement('button');
          transcriptShowHide.classList.add('transcriptShowHide');
          transcriptShowHide.innerHTML = 'Show Transcript';
          transcript.appendChild(transcriptShowHide);
          // Add the transcript to the video player
          video_player.after(transcript);

          // Add listener for transcript show/hide
          transcriptShowHide.addEventListener('click', () => {
            if (transcript.classList.contains('active')) {
              transcript.classList.remove('active');
              transcriptShowHide.innerHTML = 'Show Transcript';
            } else {
              transcript.classList.add('active');
              transcriptShowHide.innerHTML = 'Hide Transcript';
            }
          });

          // Add listeners for transcript items and chane the video time when clicked
          context.videoTranscriptItems = transcript.querySelectorAll('.transcriptItem');
          for (let i = 0; i < context.videoTranscriptItems.length; i++) {
            context.videoTranscriptItems[i].addEventListener('click', () => {
              video.currentTime = parseFloat(context.videoTranscriptItems[i].getAttribute('data-time')) + 0.01;
            });
          }
        }

        // turn vtt into blob
        let blob = new Blob([vtt], {
          type: 'text/vtt'
        });

        // create a url for the blob
        return window.URL.createObjectURL(blob);
      }

      // Add listener for video player play button
      video.addEventListener('playing', this.videoPlayBtn.bind(this));
      // Add listener for video player pause button
      video.addEventListener('pause', this.videoPauseBtn.bind(this));
      // Add listener for video player ended event
      video.addEventListener('ended', this.videoEnded.bind(this));
      // Add listener for video player timeupdate event
      video.addEventListener('timeupdate', this.updateVideoTime.bind(this));

      // Add listener for help button
      video_player.querySelector('.help-button-open').addEventListener('click', evt => {
        evt.preventDefault();
        video_player.querySelector('.video-player-help').classList.add('active');
      });
      video_player.querySelector('.help-button-close').addEventListener('click', evt => {
        evt.preventDefault();
        video_player.querySelector('.video-player-help').classList.remove('active');
      });
      video_player.querySelector('.minmax-button').addEventListener('click', this.minmaxVideoPlayer.bind(this));
      video_player.querySelector('.video-close-button').addEventListener('click', this.closeVideoPlayer.bind(this));
      video_player.querySelectorAll('.play-button').forEach(button => {
        button.addEventListener('click', this.playVideoAction.bind(this));
      });
      video_player.querySelectorAll('.pause-button').forEach(button => {
        button.addEventListener('click', this.pauseVideoAction.bind(this));
      });
      video_player.querySelectorAll('.video-rewind-button').forEach(button => {
        button.addEventListener('click', this.rewindVideoAction.bind(this));
      });
      video_player.querySelectorAll('.video-forward-button').forEach(button => {
        button.addEventListener('click', this.forwardVideoAction.bind(this));
      });
      video_player.querySelector('.volume-button-mute').addEventListener('click', this.toggleVideoVolumeAction);
      video_player.querySelector('.video-speed').addEventListener('click', this.changeVideoSpeedAction);
      video_player.querySelector('.video-resolution').addEventListener('click', this.changeVideoResAction);
      video_player.querySelector('.video-loop-toggle').addEventListener('click', this.toggleLoopAction);
      video_player.querySelector('.video-cc-toggle').addEventListener('click', this.toggleCCAction);
      video_player.querySelector('.video-fullscreen').addEventListener('click', this.toggleFullScreenAction);
      let video_seek_slider = video_player.querySelector('.play-slider-input');
      // Add listener for video player seek event
      video_seek_slider.addEventListener('input', this.videoSeekEvent);
      let video_volume_slider = video_player.querySelector('.volume-slider-input');
      // Add listener for video player volume event
      video_volume_slider.addEventListener('input', this.videoVolumeAction);

      // Go through all buttons and controls inside video_player and add an aria-controls attribute with a randomly generated ID like video_player_1
      // This helps screenreaders solve controls and content relationships
      let video_player_controls = video_player.querySelectorAll('button, input, select');
      let video_player_id = 'video_player_' + Math.floor(Math.random() * 1000000);
      for (let i = 0; i < video_player_controls.length; i++) {
        video_player_controls[i].setAttribute('aria-controls', video_player_id);
      }
      let startVideo = () => {
        document.querySelectorAll('.video-asset i').forEach(icon => {
          icon.classList.remove('fa-window-restore');
          icon.classList.remove('paused');
          icon.classList.add('fa-play');
        });
        video.load();

        // Add the randomly generated ID to the video player
        video.setAttribute('id', video_player_id);
        let promise = video.play();
        if (promise !== undefined) {
          promise.catch(() => {
            // cancel the resolution fallback timer
            if (this.videoResTimer) {
              clearTimeout(this.videoResTimer);
            }
            // Auto-play was prevented
            inf.classList.add('fa-wind-window-restore');
            inf.classList.remove('fa-spinner-third');
            inf.classList.remove('fa-spin');
            inf.classList.remove('paused');
            video_player.classList.remove('hidden');
            this.videoUnfocus = false;
          }).then(() => {
            // cancel the resolution fallback timer
            clearTimeout(this.videoResTimer);
            // Auto-play started
            inf.classList.add('fa-window-restore');
            inf.classList.remove('fa-spinner-third');
            inf.classList.remove('fa-spin');
            inf.classList.remove('paused');
            video_player.classList.remove('hidden');
            this.videoUnfocus = false;
          });
        }
      };

      /**
       * @typedef Status
       * @property {string} description
       * @property {string} indicator "none" means no error. Else "major" or "minor"
       *
       * @typedef StatusData
       * @property {Status}
       */

      /**
       * Get the status of Kaltura's API
       * @returns {Promise<StatusData>}
       */
      const kalturaStatus = async () => {
        return (await fetch("https://status.kaltura.com/api/v2/status.json")).json();
      };
      let source = video.getElementsByTagName('source')[0];
      if (cprnt.attributes.source.value !== this.get('currentVideo') || cprnt.attributes.source.value !== source.getAttribute('src')) {
        let flavor = session.kaltura_flavors[session.currentVideoRes],
          tracks = video.getElementsByTagName('track'),
          poster = cprnt.attributes.poster.value,
          kaltura_session = session.get('kaltura_session'),
          track_url = !cprnt.attributes.kaltura ? cprnt.attributes.track.value : `https://www.kaltura.com/api_v3/service/caption_captionasset/action/serveByEntryId?entryId=${cprnt.attributes.source.value}&ks=${kaltura_session}`,
          source_url = !cprnt.attributes.kaltura ? cprnt.attributes.source.value : `https://cdnapisec.kaltura.com/p/2588802/sp/0/playManifest/entryId/${cprnt.attributes.source.value}/format/url/protocol/https/flavorParamId/${flavor}/video.mp4`;
        source.addEventListener('error', () => {
          let error_background = video_player.querySelector(".player-error-background");
          kalturaStatus().then(data => {
            let indicator = data.status.indicator;
            if (indicator !== "none") {
              // notify slack channel
              brokenVideoAlert(cprnt.attributes.source.value, data.status.description);
              inf.classList.add('fa-wind-window-restore');
              inf.classList.remove('fa-spinner-third');
              inf.classList.remove('fa-spin');
              inf.classList.remove('paused');
              video_player.classList.remove('hidden');
              error_background.classList.remove('hidden');
            }

            // create timeout for 5 minutes, then check again
            setTimeout(() => {
              kalturaStatus().then(data => {
                if (data.status.indicator === "none") {
                  error_background.classList.add('hidden');
                  startVideo();
                }
              });
            }, 300000);
          });
        });
        this.set('currentVideo', source_url);
        source.setAttribute('src', source_url);

        // Set video poster
        if (poster && poster !== '') {
          video.setAttribute('poster', poster);
        }

        // PRM: We can't just reuse the existing track node -- Chrome, at least,
        // doesn't reload the files when src changes
        if (tracks.length > 0) {
          video.removeChild(tracks[0]);
        }

        // Fetch track data from the track URL
        // Convert the track data from SRT to VTT format
        // Add the VTT track to the video player
        if (track_url && track_url !== '' && track_url.indexOf('undefined') === -1) {
          let xhr = new XMLHttpRequest();
          xhr.open('GET', track_url, true);
          xhr.onload = () => {
            if (xhr.status === 200) {
              // Try to convert the track data from SRT to VTT format -- catch any errors
              let vtt;
              try {
                vtt = srt2vtt(xhr.responseText, this);
              } catch (e) {
                brokenCCAlert(cprnt.attributes.source.value, e);
              }
              if (vtt) {
                let vtt_track = document.createElement('track');
                vtt_track.setAttribute('src', vtt);
                vtt_track.setAttribute('kind', 'subtitles');
                vtt_track.setAttribute('srclang', 'en');
                vtt_track.setAttribute('label', 'English');
                video.appendChild(vtt_track);
              }
            } else {
              console.log('Error loading CC track');
              video_player.classList.add('noCC');
            }
            startVideo();
          };
          xhr.send();
        } else {
          video_player.classList.add('noCC');
          startVideo();
        }
      }
      video_player.classList.remove('minified');
      video_player.classList.add('active');

      // Don't perform video movement on iPhone -- that goes straight to full screen
      if (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPod/i)) {
        // iPhones prefer to play video with their own controls (and in full-screen only)
        // so we need to hide the video player controls and show the native controls
        video_player.classList.add('small-mobile');
        video.controls = true;
      } else {
        // Clicking the video should toggle play/pause
        video.addEventListener('click', () => {
          this.videoUnfocus = false;
          if (video.paused) {
            video.play();
          } else {
            video.pause();
          }
        });
        if (!cprnt.attributes.standalone) {
          // Create the intersection observer
          let options = {
            root: document.querySelector('.main-panel'),
            rootMargin: "0px",
            threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1]
          };
          this.videoPlayerObserver = new IntersectionObserver(this.videoPlayerObserverCallback.bind(this), options);
          this.videoPlayerObserver.observe(video_player);
        }
        video_player.attributes.playsInline = true;
      }
    },
    closeVideoPlayer: function () {
      function closeVideo(video, context, destroy) {
        if (video) {
          let video_player = video.closest('.video-player');
          // Unload the video player
          video.pause();
          video.firstElementChild.attributes.getNamedItem('src').value = '';
          video.load();
          context.set('currentVideo', false);
          if (context.videoPlayerObserver) {
            context.videoPlayerObserver.disconnect();
          }
          if (destroy) {
            let transcript = document.querySelector('.transcript');
            if (transcript) {
              transcript.remove();
            }
            let video_seek_slider = video_player.querySelector('.play-slider-input');
            video_seek_slider.removeEventListener('change', context.videoSeekEvent);
            let video_volume_slider = video_player.querySelector('.volume-slider-input');
            video_volume_slider.removeEventListener('change', context.videoVolumeAction);
          }
        }
      }

      // Destroy any standalone players
      document.querySelectorAll('.video-player.standalone').forEach(function (player) {
        let video = player.querySelector('video');
        closeVideo(video, this, true);
        player.remove();
      }.bind(this));

      // Minimize the floating player
      document.querySelectorAll('.video-player.active').forEach(function (player) {
        player.classList.remove('active');
        player.classList.add('minified');
        let video = player.querySelector('video');
        closeVideo(video, this, false);
      }.bind(this));

      // Reset play cue buttons
      document.querySelectorAll('.video-asset > i').forEach(function (icon) {
        icon.classList.remove('fa-window-restore');
        icon.classList.remove('paused');
        icon.classList.add('fa-play');
      });

      // Pause any kaltura players
      let kCont = document.getElementById('kalturaContainer');
      if (kCont.sendNotification) {
        kCont.sendNotification('pause');
      }
    },
    updateVideoTime: function (item) {
      let video_player = item.target.closest('.video-player').querySelector('video');
      let video_time_code = item.target.closest('.video-player').querySelector('.video-time-code-current');
      let currentVideoTime = video_player.currentTime;
      if (video_time_code) {
        video_time_code.innerHTML = this.formatTime(currentVideoTime);
      }
      let video_scrubber = item.target.closest('.video-player').querySelector('.play-slider-input');
      if (video_scrubber) {
        video_scrubber.value = currentVideoTime / video_player.duration * 1000;
        // Set aria-valuetext in "X minutes Y seconds of Z minutes W seconds" format
        video_scrubber.setAttribute('aria-valuetext', this.formatTime(currentVideoTime) + ' of ' + this.formatTime(video_player.duration));
        let currentTranscriptLine;
        for (let i = 0; i < this.videoTranscriptItems.length; i++) {
          let transcriptItem = this.videoTranscriptItems[i];
          let transcriptTime = transcriptItem.getAttribute('data-time');
          transcriptTime = parseFloat(transcriptTime);
          transcriptItem.classList.remove('current');
          if (transcriptTime <= currentVideoTime) {
            currentTranscriptLine = i;
          }
        }
        if (currentTranscriptLine || currentTranscriptLine === 0) {
          this.videoTranscriptItems[currentTranscriptLine].classList.add('current');
        }
      }
    },
    formatTime: function (time) {
      // Format time as hh:mm:ss
      let h = Math.floor(time / 3600),
        m = Math.floor(time % 3600 / 60),
        s = Math.floor(time % 3600 % 60);
      return (h > 0 ? h + ":" + (m < 10 ? "0" : "") : "") + m + ":" + (s < 10 ? "0" : "") + s;
    },
    changeVideoSpeedAction: function (item, speed) {
      if (speed) {
        let video_player = item.target.closest('.video-player') || item.target.parentElement.querySelector('.video-player');
        let video = video_player.querySelector('video');
        let speed_selector = video_player.querySelector('.video-speed-selector');
        let current_speed = speed_selector.querySelector('.current');
        let playback_speed = current_speed.attributes['speed'].value;
        if (speed === -1) {
          // Find option one ahead of current_speed
          let next_speed = current_speed.nextElementSibling;
          if (next_speed) {
            current_speed.classList.remove('current');
            next_speed.classList.add('current');
          }
        } else if (speed === 1) {
          // Find option one behind current_speed
          let prev_speed = current_speed.previousElementSibling;
          if (prev_speed) {
            current_speed.classList.remove('current');
            prev_speed.classList.add('current');
          }
        }

        // Get the current speed
        current_speed = speed_selector.querySelector('.current').attributes['speed'].value;
        if (current_speed !== playback_speed) {
          // Update the speed
          let player = this.playingVideo.item.target.parentElement.querySelector('.video-player');
          if (!player) {
            player = this.playingVideo.item.target.closest('.video-player');
          }
          let video = player.querySelector('video');
          video.playbackRate = current_speed;
        }
        return;
      }
      let video_player = item.target.closest('.video-player');
      let video = video_player.querySelector('video');
      if (!video_player) {
        return;
      }
      speed = parseFloat(item.target.attributes.speed.value);
      video_player.querySelectorAll('.video-speed-selector .option').forEach(function (option) {
        option.classList.remove('current');
      });
      item.target.classList.add('current');
      video.playbackRate = speed;
    },
    changeVideoResAction: function (item, manualRes, videoPlayer) {
      if (manualRes) {
        this.set('currentVideoRes', manualRes);
        // get the resolution button that matches the manualRes
        item = {
          target: videoPlayer.querySelector(`.video-resolution-selector #video-resolution-${manualRes}`)
        };
        // Check if the res selected is the same as the current res
        if (item.target.classList.contains('current')) {
          return;
        }
        videoPlayer.classList.add('hidden');
      }

      // Change the video resolution by swapping out the source, referencing session.kaltura_flavors
      let videoRes = item.target.getAttribute('resolution');
      session.currentVideoRes = videoRes;
      localStorage.setItem('bocceVideoRes', videoRes);
      item.target.parentElement.querySelectorAll('.option').forEach(function (option) {
        option.classList.remove('current');
      });
      item.target.classList.add('current');
      let video = item.target.closest('.video-player').querySelector('video');
      // get current time of video
      let currentTime = video.currentTime;
      // get current volume of video
      let volume = video.volume;
      // get current playback rate of video
      let playbackRate = video.playbackRate;
      // get current muted state of video
      let muted = video.muted;
      // Pause the video
      video.pause();

      // Pull video source url from source HTML element child of video player
      let videoSource = video.querySelector('source');
      // Get the video source url
      let videoSourceUrl = videoSource.getAttribute('src');
      let video_source_flavor = videoSourceUrl ? videoSourceUrl.split('/flavorParamId/')[1].split('/')[0] : null;
      if (!video_source_flavor) {
        console.log('no video source flavor');
        return;
      }
      let new_video_source = videoSourceUrl.replace(video_source_flavor, session.kaltura_flavors[videoRes]);
      video.setAttribute('src', new_video_source);
      // reload video with new source
      video.load();
      // set current time of video to previous time
      video.currentTime = currentTime;
      // set current volume of video to previous volume
      video.volume = volume;
      // set current playback rate of video to previous playback rate
      video.playbackRate = playbackRate;
      // set current muted state of video to previous muted state
      video.muted = muted;
      // play video
      let promise = video.play();
      if (promise !== undefined) {
        promise.catch(() => {
          // Auto-play was prevented
          if (videoPlayer) {
            videoPlayer.classList.remove('hidden');
          }
        }).then(() => {
          // Auto-play started
          if (videoPlayer) {
            videoPlayer.classList.remove('hidden');
          }
        });
      }
    },
    toggleCCAction: function (item) {
      let video_player = item.target.closest('.video-player') || item.target.parentElement.querySelector('.video-player');
      let ccButton = video_player.querySelector('.video-cc-toggle');
      let video = video_player.querySelector('video');
      if (!video) {
        return;
      }
      if (video.textTracks.length > 0) {
        video.textTracks[0].mode = video.textTracks[0].mode === 'showing' ? 'hidden' : 'showing';
        ccButton.classList.toggle('active');
      }
    },
    jumpCueAction: function (item, prev) {
      let video_player = item.target.closest('.video-player') || item.target.parentElement.querySelector('.video-player');
      let video = video_player.querySelector('video');
      if (!video) {
        return;
      }
      let cues = this.videoTranscriptItems;
      if (!cues || cues.length === 0) {
        return;
      }
      let currentTime = video.currentTime;
      let cueIndex = Ember.$(cues).filter('.current').index();
      if (prev) {
        cueIndex--;
      } else {
        cueIndex++;
      }
      if (cueIndex < 0) {
        cueIndex = 0;
      } else if (cueIndex >= cues.length - 1) {
        cueIndex = cues.length - 1;
      }
      let timeAttr = cues[cueIndex].attributes['data-time'];
      let time = timeAttr ? timeAttr.value : 0;
      video.currentTime = time;
    },
    toggleFullScreenAction: function (item, exitFullScreen) {
      // check if video is in fullscreen mode
      if (document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
        document.querySelectorAll('.video-player').forEach(function (videoPlayer) {
          videoPlayer.classList.remove('fullscreen');
        });
        return;
      }
      if (exitFullScreen) {
        return;
      }
      let video_player = item.target.closest('.video-player') || item.target.parentElement.querySelector('.video-player');
      if (!video_player) {
        return;
      }
      if (video_player.requestFullscreen) {
        video_player.requestFullscreen();
      } else if (video_player.mozRequestFullScreen) {
        video_player.mozRequestFullScreen();
      } else if (video_player.webkitRequestFullscreen) {
        video_player.webkitRequestFullscreen();
      } else if (video_player.msRequestFullscreen) {
        video_player.msRequestFullscreen();
      } else if (video_player.webkitEnterFullscreen) {
        video_player.webkitEnterFullscreen();
      } else if (video_player.enterFullscreen) {
        video_player.enterFullscreen();
      }
      document.querySelectorAll('.video-player').forEach(function (videoPlayer) {
        videoPlayer.classList.add('fullscreen');
      });
    },
    videoEnded: function (item) {
      // If the video ended, reset all the video player elements
      let video = item.target;
      if (!video) {
        return;
      }
      video.load();
      // A little hack to clear captions that get stuck on the screen at the end of the video when video.load() is called
      this.toggleCCAction(item);
      this.toggleCCAction(item);
    },
    //Kaltura Video Player
    playKalturaFromOb: function (item) {
      this.checkDom();
      let rect = item.target.parentElement.lastElementChild.getBoundingClientRect(),
        mainRect = document.querySelector('.main-panel').getBoundingClientRect(),
        cprnt = item.target[0].tagName.toLowerCase() === 'button' ? item.target : false,
        cpent = !cprnt && (item.target[0].tagName.toLowerCase() === 'img' || item.target[0].tagName.toLowerCase() === 'i') ? item.target.parentElement : item.target;
      cprnt = !cprnt && (item.target[0].tagName.toLowerCase() === 'img' || item.target[0].tagName.toLowerCase() === 'i') ? item.target.parentNode : item.target;

      //call closevideoplayer. Pass current context
      this.closeVideoPlayer();
      this.closeAudioPlayer();
      let inf = cprnt.querySelector('i');
      let video = document.querySelector('#kalturaPlayer');
      let vidWidth = rect.width + 'px';
      let vidHeight = rect.height + 'px';
      video.style.top = item.target.parentElement.offsetTop + 'px';
      video.style.height = vidHeight;
      video.style.left = rect.left - mainRect.left + 'px';
      video.style.width = vidWidth;
      this.audioPlayer.removeClass('active');
      document.querySelector('#kalturaPlayer').classList.remove('minified');
      if (inf.classList.contains('fa-window-restore')) {
        inf.classList.remove('fa-window-restore');
        this.minmaxVideoPlayer(document.querySelector('#kalturaPlayer'), false);
        return;
      }
      document.querySelectorAll('.video-asset .fa-window-restore, .kaltura-asset .fa-window-restore').forEach(function (item) {
        item.classList.remove('fa-window-restore');
        item.classList.remove('paused');
        item.classList.add('fa-play');
      });
      inf.classList.add('fa-window-restore');
      inf.classList.remove('fa-play');
      inf.classList.remove('paused');

      // Don't perform video movement on iPhone -- that goes straight to full screen
      if (!navigator.userAgent.match(/iPhone/i) && !navigator.userAgent.match(/iPod/i)) {
        video.classList.remove('minified');
        video.classList.add('active');

        // Create the intersection observer
        let options = {
          root: null,
          rootMargin: "0px",
          threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1]
        };
        this.videoPlayerObserver = new IntersectionObserver(this.videoPlayerObserverCallback.bind(this), options);
        this.videoPlayerObserver.observe(video);
        this.mainPanel.addEventListener('resize', () => {
          let rect = cpent.getBoundingClientRect(),
            mainRect = document.querySelector('.main-panel').getBoundingClientRect();
          video.style.top = cpent.offsetTop + 'px';
          video.style.height = rect.height + 'px';
          video.style.left = rect.left - mainRect.left + 'px';
          video.style.width = rect.width + 'px';
        });
      }
      if (!item.target.classList.contains('paused')) {
        let kCont = document.getElementById('kalturaContainer');
        let source_id = cprnt.attributes.source.value;
        let account = cprnt.attributes.account.value;
        let player_id = cprnt.attributes.player_id.value;
        let player_multi = !!cprnt.attributes.multi;
        if (kCont.sendNotification) {
          kCont.sendNotification('changeMedia', {
            'entryId': source_id
          });
        } else {
          try {
            if (player_multi) {
              kWidget.embed({
                targetId: 'kalturaContainer',
                flashvars: {
                  'autoPlay': true,
                  'autoMute': false,
                  'playlistAPI.kpl0Id': source_id
                },
                wid: '_' + account,
                uiconf_id: player_id
              });
            } else {
              kWidget.embed({
                targetId: 'kalturaContainer',
                flashvars: {
                  'autoPlay': true,
                  'autoMute': false
                },
                wid: '_' + account,
                uiconf_id: player_id,
                entry_id: source_id
              });
            }
            kWidget.addReadyCallback(playerId => {
              let kdp = document.getElementById(playerId);
              kdp.kBind('playerPaused', data => {
                this.videoPlayBtn(data, true);
              });
              kdp.kBind('playerPlayed', data => {
                this.videoPauseBtn(data, true);
              });
              kdp.kBind('doPlay', function () {
                kdp.sendNotification('changeVolume', 1);
                kdp.kUnbind('doPlay');
              });
            });
          } catch (e) {
            debug(e.message);
          }
        }
      }
    },
    videoPlayerObserverCallback: function (entries) {
      entries.forEach(entry => {
        if (!entry.isIntersecting && !entry.target.classList.contains('minified') && !this.get('minmaxing')) {
          // Check if video player is inside any hidden element parents
          let hidden = false;
          let parent = entry.target.parentElement;
          while (parent) {
            // if parent has a hidden class or is hidden inline, then we don't want to minimize
            if (parent.classList.contains('hidden') || parent.style.display === 'none') {
              hidden = true;
              break;
            } else if (parent.classList.contains('interaction_content')) {
              // If parent is an interaction, pause the video
              this.pauseVideoAction(entry);
              hidden = true;
              break;
            }
            parent = parent.parentElement;
          }
          if (hidden) {
            return;
          }
          this.minmaxVideoPlayer(entry.target, true);
        } else if (this.get('minmaxing')) {
          this.set('minmaxing', false);
        }
      });
    },
    minmaxVideoPlayer: function (item, min) {
      let video_player = item.classList ? item : item.target.closest('.video-player') || item.target.parentElement.querySelector('.video-player');
      let isFullScreen = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement;
      if (video_player.classList.contains('minified') && !min && !isFullScreen) {
        this.set('minmaxing', true);
        video_player.classList.remove('minified');
        // Scroll video into view
        video_player.scrollIntoView(true);
        this.videoUnfocus = false;
      } else if (!video_player.classList.contains('minified') && !isFullScreen) {
        if (!video_player.classList.contains('paused')) {
          video_player.classList.add('minified');
        }
      }
    },
    videoPlayBtn: function (item, kaltura) {
      let video_player = item.target.closest('.video-player') || item.target.previousSibling.closest('.video-player');
      let video = video_player.querySelector('video');
      if (video_player) {
        video_player.classList.remove('paused');
        video_player.classList.add('playing');
      }
      if (kaltura) {
        let videoAssetPause = video_player.closest('.video_component, .video-component') ? [video_player.closest('.video_component, .video-component').querySelector('.kaltura-asset .fa-play')] : document.querySelectorAll('.kaltura-asset .fa-play');
        if (videoAssetPause && !!videoAssetPause[0]) {
          videoAssetPause.forEach(pause => {
            pause.classList.remove('fa-play');
            pause.classList.add('fa-window-restore');
            pause.classList.remove('paused');
          });
        }
      } else {
        let videoAssetPause = video_player.closest('.video_component, .video-component') ? [video_player.closest('.video_component, .video-component').querySelector('.video-asset .fa-play')] : document.querySelectorAll('.video-asset .fa-play');
        if (videoAssetPause && !!videoAssetPause[0]) {
          videoAssetPause.forEach(pause => {
            pause.classList.remove('fa-play');
            pause.classList.add('fa-window-restore');
            pause.classList.remove('paused');
          });
        }

        // set video-time-code-total to the duration of the video formatted as hh:mm:ss
        let video_time_code_total = video_player.querySelector('.video-time-code-total');
        video_time_code_total.innerHTML = this.formatTime(video.duration);
      }
    },
    videoPauseBtn: function (item, kaltura) {
      let video_player = item.target.closest('.video-player') || item.target.previousSibling.closest('.video-player');
      if (video_player) {
        video_player.classList.add('paused');
        video_player.classList.remove('playing');
      }
      if (kaltura) {
        let videoAssetPause = video_player.closest('.video_component, .video-component') ? [video_player.closest('.video_component, .video-component').querySelector('.kaltura-asset .fa-window-restore')] : document.querySelectorAll('.video-asset .fa-window-restore');
        if (videoAssetPause && !!videoAssetPause[0]) {
          videoAssetPause.forEach(pause => {
            pause.classList.add('fa-play');
            pause.classList.remove('fa-window-restore');
            pause.classList.add('paused');
          });
        }
      } else {
        let videoAssetPause = video_player.closest('.video_component, .video-component') ? [video_player.closest('.video_component, .video-component').querySelector('.video-asset .fa-window-restore')] : document.querySelectorAll('.video-asset .fa-window-restore');
        if (videoAssetPause && !!videoAssetPause[0]) {
          videoAssetPause.forEach(pause => {
            pause.classList.add('fa-play');
            pause.classList.remove('fa-window-restore');
            pause.classList.add('paused');
          });
        }
      }
    },
    rewindVideoAction: function (item) {
      // Rewind the video by 5 seconds
      let video_player = item.target.closest('.video-player') || item.target.parentElement.querySelector('.video-player');
      let video = video_player.querySelector('video');
      if (!video) {
        return;
      }
      video.currentTime -= 5;
    },
    forwardVideoAction: function (item) {
      // Fast-forward the video by 5 seconds
      let video_player = item.target.closest('.video-player') || item.target.parentElement.querySelector('.video-player');
      let video = video_player.querySelector('video');
      if (!video) {
        return;
      }
      video.currentTime += 10;
    },
    videoVolumeAction: function (item, volume) {
      let video_player = item.target.closest('.video-player') || item.target.parentElement.querySelector('.video-player');
      if (!video_player) {
        return;
      }
      let video = video_player.querySelector('video');
      if (!volume) {
        video.volume = item.target.value / 100;
      } else {
        let newVolume = video.volume + volume;
        if (newVolume > 1) {
          newVolume = 1;
        } else if (newVolume < 0) {
          newVolume = 0;
        }
        video.volume = newVolume;
        // Adjust the volume slider
        let volume_slider = video_player.querySelector('.volume-slider-input');
        volume_slider.value = video.volume * 100;
      }
      let volume_button = video_player.querySelector('.volume-button-mute');
      if (video.volume === 0) {
        volume_button.classList.add('fa-volume-off');
        volume_button.classList.remove('fa-volume');
        video.muted = true;
      } else {
        volume_button.classList.remove('fa-volume-off');
        volume_button.classList.add('fa-volume');
        video.muted = false;
      }
    },
    toggleVideoVolumeAction: function (item) {
      let video_player = item.target.closest('.video-player') || item.target.parentElement.querySelector('.video-player');
      let video = video_player.querySelector('video');
      if (!video) {
        return;
      }
      let mute_button = video_player.querySelector('.volume-button-mute');
      if (video.muted === true || video.volume === 0) {
        mute_button.classList.remove('fa-volume-off');
        mute_button.classList.add('fa-volume');
        video.muted = false;
      } else {
        mute_button.classList.remove('fa-volume');
        mute_button.classList.add('fa-volume-off');
        video.muted = true;
      }
    },
    playVideoAction: function (item) {
      let kCont = document.getElementById('kalturaContainer');
      if (kCont.sendNotification) {
        kCont.sendNotification('play');
      } else {
        let video_player = item.target.closest('.video-player') || item.target.parentElement.querySelector('.video-player');
        let video = video_player.querySelector('video');
        let promise = video.play();
        if (promise !== undefined) {
          promise.catch(() => {
            // Auto-play was prevented
          }).then(() => {
            // Auto-play started
          });
        }
      }
    },
    pauseVideoAction: function (item) {
      let kCont = document.getElementById('kalturaContainer');
      if (kCont.sendNotification) {
        kCont.sendNotification('pause');
      } else {
        let video_player = item.target.closest('.video-player') || item.target.parentElement.querySelector('.video-player');
        let video = video_player.querySelector('video');
        video.pause();
      }
    },
    videoPlayPauseAction: function (item) {
      let video_player = item.target.closest('.video-player') || item.target.parentElement.querySelector('.video-player');
      if (video_player) {
        if (video_player.classList.contains('paused')) {
          this.playVideoAction(item);
        } else {
          this.pauseVideoAction(item);
        }
      }
    },
    toggleLoopAction: function (item) {
      let video_player = item.target.closest('.video-player') || item.target.parentElement.querySelector('.video-player');
      let video = video_player.querySelector('video');
      if (!video) {
        return;
      }
      if (video.loop) {
        video.loop = false;
        item.target.classList.remove('active');
      } else {
        video.loop = true;
        item.target.classList.add('active');
      }
    },
    videoSeekEvent: function (item, time) {
      let video_player = item.target.closest('.video-player') || item.target.parentElement.querySelector('.video-player');
      let video = video_player.querySelector('video');
      if (time) {
        video.currentTime = video.currentTime + time;
        return;
      }
      video.currentTime = item.target.value * video.duration / 1000;
    }
  });
});