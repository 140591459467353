define("bocce/mixins/helpguide", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable-next-line ember/no-new-mixins */
  var _default = _exports.default = Ember.Mixin.create({
    isMobile: Ember.computed(function () {
      /* eslint-disable-next-line ember/no-jquery */
      return Ember.$.isMobile;
    }),
    actions: {
      startInstructionSet: function (instructionType) {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.instruction-set').removeClass('active');
        if (event.target.classList.contains('done')) {
          event.target.classList.remove('done');
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('.classroom').attr('class', 'classroom active');
          return;
        }
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('#instruction-' + instructionType).addClass('active');
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.classroom').addClass('helpguide-' + instructionType + '-1');
        event.target.classList.add('done');
      },
      completeInstruction: function (instructionType, step, subStep) {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.classroom').attr('class', 'classroom active');
        if (event.target.classList.contains('done')) {
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$(event.target).removeClass('done');
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$(event.target).nextAll().removeClass('done');
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('.classroom').addClass('helpguide-' + instructionType + '-' + (step - 1));
          return;
        }
        if (subStep) {
          step = step + '-' + subStep;
        }

        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.classroom').addClass('helpguide-' + instructionType + '-' + step);
        event.target.classList.add('done');
      },
      resetInstructionSet: function () {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.classroom').attr('class', 'classroom active');
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.help-guide-container .done').removeClass('done');
      },
      closeHelpGuide: function () {
        this.set('helpGuideOpen', false);
      },
      minMaxHelpGuide: function () {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.help-guide-container').toggleClass('minimized');
      }
    }
  });
});