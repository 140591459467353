define("bocce/controllers/classroom/lessons/conversation-new", ["exports", "bocce/mixins/discussable", "bocce/mixins/enmodal", "bocce/mixins/conversable", "bocce/mixins/editable", "bocce/mixins/uploadable", "bocce/mixins/audio-rec", "bocce/mixins/video-rec", "bocce/mixins/rtc-rec"], function (_exports, _discussable, _enmodal, _conversable, _editable, _uploadable, _audioRec, _videoRec, _rtcRec) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  window.conversationHeartbeatFrequency = 3 * 1000;
  var _default = _exports.default = Ember.Controller.extend(_conversable.default, _enmodal.default, _discussable.default, _editable.default, _uploadable.default, _audioRec.default, _videoRec.default, _rtcRec.default, {
    init(...args) {
      this._super(...args);
      this.recipients = this.recipients || [];
    },
    conversation: Ember.inject.controller('classroom.lessons.conversation'),
    conversations: Ember.inject.controller(),
    userprofileService: Ember.inject.service('userprofile'),
    discussions: Ember.inject.controller(),
    // required by EditableMixin
    classroom: Ember.inject.controller(),
    // required by DiscussableMixin and EnmodalMixin
    discussion: Ember.inject.controller('classroom.lessons.discussion'),
    // required by DiscussableMixin
    newDiscussion: Ember.inject.controller('classroom.lessons.discussion-new'),
    // required by DiscussableMixin
    lessons: Ember.inject.controller('classroom.lessons'),
    // required by UploadableMixin

    scrollAndUpdateLocalConversation: function (conversation) {
      Ember.run.later(function () {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.existing-conversation .modal-content').animate({
          scrollTop: Ember.$('.existing-conversation .modal-content').prop('scrollHeight')
        }, 1000);
      }, 500);
      conversation.set('last_authored_message_at', new Date());
      conversation.set('last_message_at', new Date());
      conversation.set('message_count', conversation.get('message_count') + 1);
    },
    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    conversationModalContentMarginTop: Ember.computed('model', function () {
      /* eslint-disable-next-line ember/no-get */
      let participantRelationships = this.get('model.participantRelationships');
      return participantRelationships * 26;
    }),
    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    sharedEnrollments: Ember.computed('model', function () {
      let conversation = this.model,
        retval = [],
        /* eslint-disable-next-line ember/no-get */
        currentUser = this.get('session.user'),
        sectionCodes = currentUser.get('sectionCodes');
      if (conversation) {
        let participantRelationships = conversation.get('participantRelationships') || [];
        participantRelationships.forEach(participantRelationship => {
          if (!sectionCodes) {
            return;
          }
          sectionCodes.forEach(sectionCode => {
            if (sectionCode.sectionId === participantRelationship.section) {
              retval.push({
                sectionId: sectionCode.sectionId,
                relationship: participantRelationship.relationship,
                courseTitle: sectionCode.courseTitle,
                sectionLabel: sectionCode.sectionLabel
              });
            }
          });
        });
      }
      return retval;
    }),
    privateConversationHeartbeat: function () {
      let store = this.store,
        heartbeat = () => {
          /* eslint-disable-next-line ember/no-get */
          let currentConversationId = this.get('model.id');
          store.nestResources('conversation-message', [{
            'conversation': currentConversationId
          }]);

          // Load in conversation messages
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$.get('/interface/conversations/' + currentConversationId).then(resolvedData => {
            let data = resolvedData,
              loaded_messages = store.peekAll('conversation_message'),
              ids = loaded_messages.map(e => e.id),
              to_push = [];
            for (let msg of data.conversation_message) {
              if (ids.indexOf(msg.id.toString()) === -1) {
                to_push.push(msg);
              }
            }
            if (to_push.length > 0) {
              store.pushPayload({
                'conversation_message': to_push
              });
              Ember.run.later(function () {
                /* eslint-disable-next-line ember/no-jquery */
                Ember.$('.existing-conversation .modal-content').animate({
                  scrollTop: Ember.$('.existing-conversation .modal-content').prop('scrollHeight')
                }, 1000);
              }, 500);
            }
          }).always(() => {
            // When the modal is open, 'currentConversationId' is always set on the
            // conversation controller. When the modal is closed, this value is cleared out.
            // Only heartbeat if there is something stored in this value. - JRW
            if (this.currentConversationId) {
              Ember.run.later(this, heartbeat, window.conversationHeartbeatFrequency);
            }
          });
        };
      window.runPMHeartbeat = heartbeat;
      Ember.run.later(this, heartbeat, window.conversationHeartbeatFrequency);
    },
    actions: {
      autoComplete: function (param) {
        param = param.trim();
        if (param !== '' && param.length > 2) {
          let handle = this.autoCompleteTimeoutHandle;
          if (handle) {
            Ember.run.cancel(handle);
          }

          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('.recipient-filter-list').removeClass('hidden');
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('.recipient-filter-list .loading-graphic').removeClass('hidden');
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('.recipient-filter-list .no-results').addClass('hidden');
          this.set('filteredList', []);
          var that = this;

          // Wait a moment before we spam the server with searches for "jam", "jame", and "james"
          // all at once
          handle = Ember.run.later(() => {
            param = param.toLowerCase();
            param = encodeURIComponent(param);

            /* eslint-disable-next-line ember/no-jquery */
            Ember.$.ajax({
              type: 'get',
              param: param,
              url: '/interface/user-search/' + param,
              success: function (results) {
                let premd = that.store.peekAll('conversation').filter(conversation => {
                  let matching_conversations = conversation.get('participants').filter(user => {
                    if (typeof this.param !== 'string') {
                      return false;
                    }
                    let lc = this.param.toLowerCase();
                    return user.get('name').toLowerCase().indexOf(lc) > -1;
                  });
                  return matching_conversations > 0;
                });

                /* eslint-disable-next-line ember/no-jquery */
                Ember.$('.recipient-filter-list .loading-graphic').addClass('hidden');
                let res = results.length < 8 ? results : results.slice(0, 7);
                res = premd.concat(res);
                that.set('filteredList', res);
                if (results.length === 0) {
                  /* eslint-disable-next-line ember/no-jquery */
                  Ember.$('.recipient-filter-list .no-results').removeClass('hidden');
                }
              },
              error: function (error) {
                Ember.debug('Error in conversation recipient list.');
                Ember.debug(error);
              }
            });
          }, 333);
          this.set('autoCompleteTimeoutHandle', handle);
        } else {
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('.recipient-filter-list').addClass('hidden');
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('.recipient-filter-list .loading-graphic').addClass('hidden');
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('.recipient-filter-list .no-results').addClass('hidden');
          this.set('filteredList', []);
        }
      },
      // This choose action represents a user picking a
      // recipient from the autocomplete list above the conversations list
      startConversation: function (recipient) {
        this.set('conversation.uploadInProgress', true);
        let recipArray = [],
          recipURL = '';
        if (recipient) {
          recipArray = [recipient];
        }
        this.recipients.forEach(function (ob) {
          recipArray.push(ob.id);
        });
        recipURL += recipArray.join('-');
        this.set('recipients', []);

        // Check if a conversation with the user already exists
        let conversations = this.store.peekAll('conversation'),
          existing = conversations.find(function (c) {
            let ob = c.get('conversationPartners');
            if (ob.get('length') > 0) {
              ob = ob.toArray();
            }
            if (!Array.isArray(ob) || !Array.isArray(recipArray) || recipArray.length !== ob.length) {
              return false;
            }
            let arr1 = ob.concat().sort(),
              arr2 = recipArray.concat().sort(),
              i;
            for (i = 0; i < arr1.length; i++) {
              if (parseInt(arr1[i].get('id')) !== parseInt(arr2[i])) {
                return false;
              }
            }
            return true;
          });
        if (existing) {
          if (existing.get('archived')) {
            existing.set('workflow_state', 'active');
            existing.save();
          }
          this.send('viewConversation', existing.id);
        } else {
          this.send('createConversation', recipURL);
        }
        this.set('conversation.uploadInProgress', false);
      },
      choose: function (recipient) {
        this.recipients.pushObject(recipient);
      }
    }
  });
});