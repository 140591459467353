define("bocce/templates/components/quiz/statistics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "N6H2Y0SR",
    "block": "{\"symbols\":[\"stats\",\"qstats\",\"answer\",\"user_id\",\"@quiz\"],\"statements\":[[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,5,[\"quiz_statistics\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,1,[\"question_statistics\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[12],[2,\"---\"],[1,[32,2,[\"question_text\"]]],[2,\"---\"],[13],[10,\"br\"],[12],[13],[10,\"br\"],[12],[13],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,2,[\"answers\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[32,3,[\"text\"]]],[10,\"br\"],[12],[13],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,3,[\"user_ids\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[1,[32,4]],[2,\"\\n\"]],\"parameters\":[4]}]]],[2,\"            \"],[10,\"br\"],[12],[13],[10,\"br\"],[12],[13],[2,\"\\n\"]],\"parameters\":[3]}]]]],\"parameters\":[2]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "bocce/templates/components/quiz/statistics.hbs"
    }
  });
});