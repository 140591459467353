define("bocce/helpers/heartbeat", ["exports", "bocce/js/store-util"], function (_exports, _storeUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = loadHeartbeatData;
  function loadHeartbeatData(routes, ctl, view = null) {
    const keys = ['attachment', 'discussion', 'response', 'submission', 'conversation', 'conversation_message', 'event', 'comment', 'user'];
    const deleble_types = ['comment'];
    const store = ctl.get('store');
    const user_id = parseInt(ctl.session.get('user.id'), 10);
    const router = ctl.get('router');
    /* eslint-disable-next-line ember/no-jquery */
    let promises = routes.map(url => Ember.$.get(url)),
      course_hb_index = -1,
      full_reload_p = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].match(/courses\/.*\/heartbeat/)) {
        course_hb_index = i;
      }
      if (routes[i].indexOf('heartbeat') === -1) {
        full_reload_p = true;
      }
    }

    /* eslint-disable-next-line ember/no-jquery */
    return Ember.$.when.apply(Ember.$, promises).done(function (...data) {
      // Data is an array of array of the parameters that the done function
      // would normally get.  data[nn][0] is the actual data we're looking
      // for from each hit.

      if (routes.length === 1) {
        data = [data];
      }
      for (let i = 0; i < data.length; i++) {
        if (data[i][2].status === 500) {
          Ember.debug('Heartbeat request returned 500 error, disabling');
          window.heartbeatFrequencyInMilliseconds = 0;
          return;
        }
      }
      if (view && course_hb_index >= 0) {
        let course_hb_data = data[course_hb_index][0];
        ctl.set('session.section.adviceCards', course_hb_data.adviceCards);
        ctl.set('session.runningChatMeetingKey', course_hb_data?.meeting_details?.meeting_key);
        ctl.set('session.runningChatMeetingDetails', course_hb_data.meeting_details);
        ctl.set('session.anyChatsRunning', ctl.get('session.runningChatMeetingKey'));
        ctl.set('session.canMergeChats', !!course_hb_data.other_section_meeting_key);
        if (course_hb_data.meeting_details) {
          delete course_hb_data.meeting_details;
        }
        ctl.set('session.globalReadOnly', !!course_hb_data.readonly);
        if (course_hb_data.readonly) {
          delete course_hb_data.readonly;
        }
        if (course_hb_data.banner && course_hb_data.banner.serial) {
          let ban = course_hb_data.banner;
          if (session.last_banner_serial !== ban.serial) {
            view.send('notify', ban.banner, ban.dismissable, ban.modal_type || null, ban.modal_id || null, 100, ctl.get('session'), false, ban.link, ban.serial, "banner", ban.dismiss_condition_id);
            session.last_banner_serial = ban.serial;
          }
          delete course_hb_data.banner;
        } else if (ctl.get('session.notification') && session.last_banner_serial) {
          //TODO: This 'else if' might not even be used...
          view.send('dismissNotif', ctl.get('session'));
          session.last_banner_serial = null;
        }
        if (course_hb_data.activity) {
          let activity = course_hb_data.activity;
          let fiveMinutesAgo = moment().subtract(5, 'minutes');
          for (let uid in activity) {
            let user = store.peekRecord('user', uid);
            if (user) {
              user.set('whenPresent', activity[uid]);
              if (activity[uid]) {
                let isPresent = new Date(activity[uid]) > fiveMinutesAgo;
                user.set('isPresent', isPresent);
              }
            }
          }
        }
      }
      for (let key of keys) {
        let objs = getData(data, key);

        // Use .peekAll instead of find to avoid a REST hit
        let loaded_objs = store.peekAll(key);
        let ids = loaded_objs.map(function (e) {
          return '' + e.id;
        });
        let to_push = [],
          interface_ids = [];
        for (let j = 0; j < objs.length; j++) {
          if (ids.indexOf('' + objs[j].id) === -1) {
            // TODO: make my_submission a computed property instead of computing it
            // in the inteface's toEmber function.
            if (key === 'submission') {
              let isSubmissionOwner = parseInt(objs[j].user) === user_id,
                grade = objs[j].grade;
              objs[j].my_submission = isSubmissionOwner;
              if (isSubmissionOwner) {
                store.findRecord('assignment', objs[j].assignment).then(assignment => {
                  assignment.set('currentUserSubmissionGrade', grade);
                });
              }
            } else if (key === 'comment') {
              let submissionCtl = ctl.get('submission');
              let open_id = submissionCtl.get('openAssignmentView.id');

              // If the comment is one we didn't make ourselves and we're not looking at
              // the submission right now, set it to unread
              if (objs[j].user !== user_id && open_id !== objs[j].submission) {
                let submission = store.peekRecord('submission', objs[j].submission);
                if (submission) {
                  submission.set('read', false);
                  submission.set('workflow_state', 'unread');
                }
              }
            }
            // If the item's id doesn't exist in the store, add the item
            // to the to_push array.
            to_push.push(objs[j]);
          } else if (objs[j].is_deleted || objs[j].is_updated) {
            // If object remarked as deleted or updated, push too

            to_push.push(objs[j]);
          }
          interface_ids.push(objs[j].id);
        }

        // If this is a deleble type, and we're doing a full reload,
        // delete any objects that weren't included in the data dump
        if (full_reload_p && deleble_types.indexOf(key) >= 0) {
          loaded_objs.filter(obj => interface_ids.indexOf(obj.id) === -1).forEach(obj => {
            Ember.debug('Removing orphan ' + key + ' ' + obj.id);
            store.unloadRecord(obj);
          });
        }
        if (to_push.length > 0) {
          Ember.debug('Pushing ' + to_push.length + ' new ' + key);
          Ember.debug(to_push.map(function (e) {
            return e.id;
          }));

          // Uncomment to bring back growler
          /*
          growler
            .attr('message', messages[i])
            .removeClass('growl')
            .addClass('growl');
          */
          let payload = {};
          payload[key] = to_push;
          store.pushPayload(key, payload);
          if (key === 'response') {
            for (let resp of to_push) {
              let disc = store.peekRecord('discussion', resp.discussion);
              if (disc) {
                let unread_count = disc.get('unread_count');
                disc.set('unread_count', unread_count + 1);
              }
            }
          }
          if (key === 'conversation_message') {
            for (let conversation_message of to_push) {
              let conversation = store.peekRecord('conversation', conversation_message.conversation);
              if (conversation) {
                conversation.set('workflow_state', 'unread');
                conversation.set('last_message', conversation_message.body);
              }
            }
          }

          // JRW: Sometimes announcements come back as already read.
          if (key === 'discussion') {
            for (let discussion of to_push) {
              let loadedDiscussion = store.peekRecord('discussion', discussion.id);
              if (loadedDiscussion.get('is_announcement')) {
                loadedDiscussion.set('read', false);
              }
            }
          }
        }
      }
      doDeletes(store, router, data);
      // Pop the ongoing event modal up once per session
      let meeting_details = ctl.get('session.runningChatMeetingDetails'),
        shown = sessionStorage.getItem('popupShownForMeetingId'),
        event_id = meeting_details?.event_id || meeting_details?.id || false,
        willTransition;
      willTransition = event_id && shown != event_id;
      if (willTransition) {
        sessionStorage.setItem('popupShownForMeetingId', event_id);
        router.transitionTo('classroom.lessons.event', event_id);
      }
    });
  }
  function getData(data, key) {
    let objs = [];
    data.map(d => {
      if (d[0][key] && d[0][key].length > 0) {
        objs = objs.concat(d[0][key]);
      }
    });
    return objs;
  }
  function doDeletes(store, router, data) {
    let deletes = getData(data, 'deletes');
    for (let d of deletes) {
      if (d.type && d.id) {
        let typeMatch;

        //These deletes come back from heartbeat in the form of <type>_attachment:id. 
        //The type is the type of attachment that was deleted (discussion, submission, etc) and the id is for the model
        //that had that attachment.
        if (typeMatch = d.type.match(/^\w+(?=_attachment)/)) {
          let type = typeMatch[0];
          const typeSplit = d.type.split(':');
          if (typeSplit.length >= 2) {
            const id = typeSplit[1];
            const record = store.peekRecord(type, id);
            if (record) {
              let currentAttachmentIds = record.get('attachments');
              if (currentAttachmentIds) {
                record.set('attachments', currentAttachmentIds.filter(a => {
                  //'a' might be an on object if the item was added by heartbeat within the same session
                  if (a && a.id) {
                    return a.id != d.id;
                  } else {
                    return a != d.id;
                  }
                }));
              }
            }
          }
        } else {
          let loaded_objs = store.peekAll(d.type);
          let ids = loaded_objs.map(function (e) {
            if (d.type == 'response') {
              return +e.id;
            } else {
              return e.id;
            }
          });

          //Delete the record if it exists
          if (ids.indexOf(d.id) >= 0) {
            const r = store.peekRecord(d.type, d.id);
            if (r) {
              (0, _storeUtil.pushDeletion)(store, d.type, d.id);
              const currentRoute = router.currentRoute;

              //Exit out of the discussion if it is currently being viewed.
              if (currentRoute && currentRoute.params && currentRoute.params['discussion_id']) {
                if (currentRoute.params['discussion_id'] == d.id) {
                  router.transitionTo('classroom.lessons');
                }
              }
            }
          }
        }
      }
    }
  }
});