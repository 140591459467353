define("bocce/templates/components/quiz-scenarios/quiz-intercept", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Yk8WyzoL",
    "block": "{\"symbols\":[\"@quizzes\"],\"statements\":[[6,[37,1],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"    You have no time left in your quiz.\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[32,1,[\"startingQuiz\"]],[32,1,[\"gradingQuiz\"]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"quiz-intercept-spinner\"],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"fas fa-spinner fa-spin fa-fw\"],[12],[13],[2,\" Please wait...\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[11,\"button\"],[16,0,[31,[\"button quiz-intercept-submit-button \",[30,[36,1],[[30,[36,0],[[32,1,[\"startingQuiz\"]],[32,1,[\"gradingQuiz\"]]],null],\"disabled\"],null]]]],[4,[38,3],[\"click\",[30,[36,2],[[32,0,[\"submitButtonCallback\"]]],null]],null],[12],[1,[32,0,[\"submitButtonText\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"or\",\"if\",\"fn\",\"on\",\"noTimeLeft\"]}",
    "meta": {
      "moduleName": "bocce/templates/components/quiz-scenarios/quiz-intercept.hbs"
    }
  });
});