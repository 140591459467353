define("bocce/templates/components/quiz-stats/question-statistics-multiple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "+3ocPcyR",
    "block": "{\"symbols\":[\"blank\",\"@statistics\",\"@quizzes\"],\"statements\":[[2,\"\\n\"],[10,\"div\"],[14,0,\"question-statistics-multiple\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"question-stats-question-text\"],[12],[2,[32,0,[\"questionText\"]]],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"question-statistics-multiple-blank-stats\"],[14,5,\"visibility:hidden; position: fixed\"],[12],[2,\"\\n\\n\"],[2,\"        \"],[8,\"quiz-stats/question-statistics-single\",[],[[\"@statistics\",\"@answers\",\"@quizzes\",\"@answersString\",\"@showHeading\"],[[32,2],[32,0,[\"answers\"]],[32,3],[32,0,[\"answersString\"]],true]],null],[2,\"\\n    \"],[13],[2,\"\\n\"],[6,[37,4],[[32,0,[\"statistics\",\"responses\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"blanks\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"div\"],[12],[13],[10,\"span\"],[14,5,\"font-weight: bold\"],[12],[1,[32,1]],[13],[2,\" - \\n            \"],[11,\"button\"],[4,[38,1],[[32,0],\"showUsers\",[30,[36,0],[[30,[36,0],[[32,0,[\"blankPercentages\"]],[32,1]],null],\"usernames\"],null]],null],[12],[2,\"\\n                \"],[1,[30,[36,0],[[30,[36,0],[[32,0,[\"blankPercentages\"]],[32,1]],null],\"correct\"],null]],[2,\"%\\n            \"],[13],[2,\"\\n             correct\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"div\"],[14,5,\"font-style: italic\"],[12],[2,\"no responses\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"array-item\",\"action\",\"-track-array\",\"each\",\"if\"]}",
    "meta": {
      "moduleName": "bocce/templates/components/quiz-stats/question-statistics-multiple.hbs"
    }
  });
});