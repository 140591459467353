define("bocce/templates/interactions/audiocomments/comment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "i7NZwCtO",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"comment-title\"],[12],[1,[34,0]],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"comment-body\"],[12],[2,[34,1]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"label\",\"comment\"]}",
    "meta": {
      "moduleName": "bocce/templates/interactions/audiocomments/comment.hbs"
    }
  });
});