define("bocce/adapters/application", ["exports", "@ember-data/adapter/rest", "ember-inflector"], function (_exports, _rest, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // app/adapters/application.js

  let adapter = _rest.default.extend({
    namespace: 'interface',
    init(...args) {
      this._super(...args);
      this.nestedResources = this.nestedResources || {};
      if (window.location.host == 'intro.online.berklee.edu') {
        this.host = window.location.protocol === 'https:' ? 'https://' + window.location.host : 'http://berklee-bocce-static.s3-website-us-east-1.amazonaws.com';
      }
    },
    updateId: function (record, data) {
      var oldId = Ember.get(record, 'id');
      let id = data.id;
      if (id == null || id === '') {
        id = null;
      } else {
        id = id + '';
      }
      if (oldId && oldId !== id) {
        this.typeMapFor(record.constructor).idToRecord[oldId] = record;
      } else {
        this.typeMapFor(record.constructor).idToRecord[id] = record;
      }
      Ember.set(record, 'id', id);
    },
    nestResources: function (type, resources) {
      let r = this.nestedResources || {};
      if (!Array.isArray(resources)) {
        resources = [resources];
      }
      if (r[type] && r[type].ids && r[type].ids.length === resources.length) {
        let match = true;
        for (let i = 0, l = resources.length; i < l; i++) {
          let old = r[type].ids[i],
            key = Object.keys(old)[0];
          if (old[key] !== resources[i][key]) {
            match = false;
            break;
          }
        }
        if (match) {
          return this;
        }
      }
      let reloaded = [];

      // Don't need to reload if it's the first go-round .. right?
      if (!r[type]) {
        reloaded.push('all');
      }
      r[type] = {
        ids: resources,
        reloaded: []
      };
      this.set('nestedResources', r);
      return this;
    },
    /*
    hasReloadedType(type, id) {
      return true;
      let nestings = this.get('nestedResources'),
          nesting = nestings[type];
       if ( ! nesting ) {
        return true;
      }
       return nesting.reloaded.indexOf(id) >= 0;
    },
     markReloaded(type, id) {
      let nestings = this.get('nestedResources'),
          t = nestings[type];
       t.reloaded.push(id);
      this.set('nestedResources', nestings);
    },
     // PRM: New logic.  Anything that's nested within a course or a section needs to
    // be reloaded when the course changes.  This keeps track of what it's already
    // reloaded, so we (hopefully) only hit the API once per object per change.
    shouldReloadRecord: function(store, snapshot) {
      if ( this.hasReloadedType(snapshot.modelName, 'all') ||
           this.hasReloadedType(snapshot.modelName, snapshot.id) ) {
        return false;
      }
       debug("reloading " + snapshot.modelName + ".id = " + snapshot.id);
      this.markReloaded(snapshot.modelName, snapshot.id);
       return true;
    },
     shouldReloadAll: function(store, snapshot) {
      let type = snapshot.type.modelName;
       if ( this.hasReloadedType(type, 'all') ) {
        return false;
      }
       debug("reloading all " + type);
      this.markReloaded(type, 'all');
       return true;
    },
    */

    buildURL: function (type, id, snapshot) {
      var nestings = this.nestedResources[type] || {},
        resources = nestings.ids || [],
        parts = [this.host],
        i,
        resource,
        key,
        joined;
      if (this.namespace) {
        parts.push(this.namespace);
      }
      for (i = 0; i < resources.length; i++) {
        resource = resources[i];
        for (key in resource) {
          if (!Object.prototype.hasOwnProperty.call(resource, key)) {
            continue;
          }
          parts.push(_emberInflector.default.inflector.pluralize(key));
          parts.push(resource[key]);
        }
      }
      parts.push(_emberInflector.default.inflector.pluralize(type), id);
      joined = parts.join('/');
      if (window.location.host == 'intro.online.berklee.edu') {
        // On intro, we don't want to use normal interface URLs.  Add a
        // slash at the end to ensure that we get the correct item, then
        // an index.html to avoid an extra redirect.
        if (joined.substr(-1) !== '/') {
          joined += '/';
        }
        joined += 'index.html';
      }
      if (snapshot && snapshot.adapterOptions) {
        let params = [];
        for (let key in snapshot.adapterOptions) {
          params.push(key + '=' + encodeURIComponent(snapshot.adapterOptions[key]));
        }
        if (params.length > 0) {
          joined += '?' + params.join('&');
        }
      }
      return joined;
    },
    // NK: TEMPORARY FIX TO STOP THE MADNESS AAAAA
    // Background reloading: never do it (for now. we want to turn this back on later).
    shouldBackgroundReloadRecord() {
      return false;
    },
    shouldBackgroundReloadAll() {
      return false;
    },
    // store.findAll should always do a fetch.
    shouldReloadAll() {
      return true;
    }
  });
  if (window.location.host == 'intro.online.berklee.edu') {
    adapter = adapter.extend({
      createRecord: function (store, type, snapshot) {
        snapshot.id = new Date().getTime();
        return this.updateRecord(store, type, snapshot);
      },
      updateRecord: function (store, type, snapshot) {
        let data = this.serialize(snapshot, {
          includeId: true
        });
        return new Promise(resolve => {
          let retval = {};
          retval[snapshot.modelName] = data;
          Ember.run(null, resolve, retval);
        });
      }
    });
  }
  var _default = _exports.default = adapter;
});