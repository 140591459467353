define("bocce/helpers/file-extension", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.fileExtension = fileExtension;
  // Take a filename and return it's file extension
  function fileExtension([filename]) {
    if (!filename) {
      return;
    }
    let splitFilename = filename.split('.');
    if (splitFilename.length == 1) {
      return;
    }
    return '.' + splitFilename[splitFilename.length - 1] || '';
  }
  var _default = _exports.default = Ember.Helper.helper(fileExtension);
});