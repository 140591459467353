define("bocce/routes/classroom/lessons/no-submissions", ["exports", "bocce/routes/classroom/lessons/modal-base"], function (_exports, _modalBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _modalBase.default.extend({
    // NK: temp fix. 'openAssignment' should be renamed 'model' on the
    // controller so we can use Ember's default setupController hook.
    setupController(controller, model) {
      controller.set('openAssignment', model);
    }
  });
});