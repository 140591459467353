define("bocce/models/gradebook", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // app/models/gradebook.js
  var _default = _exports.default = _model.default.extend({
    sort: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    short_name: (0, _model.attr)('string'),
    sortable_name: (0, _model.attr)('string'),
    sis_user_id: (0, _model.attr)('string'),
    sis_import_id: (0, _model.attr)('string'),
    integration_id: (0, _model.attr)('string'),
    login_id: (0, _model.attr)('string'),
    avatar_url: (0, _model.attr)('string'),
    columns: (0, _model.attr)(),
    enrolled_late: Ember.computed('name', function () {
      let enrollmentObj = session.get('section.enrollments').findBy('user.id', this.get('id'));
      let enrollDate = enrollmentObj ? enrollmentObj.get('enrolledAt') : false;
      let termStart = session.get('section.course.term.startsOn');

      // If either date is not found for some reason, return false
      if (!enrollDate || !termStart) {
        return false;
      }

      // Convert both dates to date objects
      enrollDate = new Date(enrollDate);
      termStart = new Date(termStart);

      // If either date is not a valid date, return false
      if (isNaN(enrollDate.getDate()) || isNaN(termStart.getDate())) {
        return false;
      }

      // We only need to compare days...
      enrollDate.setHours(0, 0, 0, 0);
      termStart.setHours(0, 0, 0, 0);
      let returnLate = false;
      if (enrollDate > termStart) {
        // Return date as mm/dd/yy
        returnLate = enrollDate.toLocaleDateString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: '2-digit'
        });
      }
      return returnLate;
    })
  });
});