define("bocce/templates/components/quiz-stats/types/essay-question-statistics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "AOAeTVsH",
    "block": "{\"symbols\":[\"@statistics\"],\"statements\":[[8,\"quiz-stats/question-statistics-manual\",[],[[\"@statistics\"],[[32,1]]],null],[2,\"\\n\"],[6,[37,2],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[11,\"button\"],[24,0,\"button question-stats-essay-responses\"],[4,[38,0],[[32,0],\"getEssayResponses\"],null],[12],[2,\"\\n\"],[6,[37,2],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"            \"],[10,\"i\"],[14,0,\"fas fa-spinner fa-spin\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"            Download Essays\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    no responses\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"downloadingEssays\",\"if\",\"hasEssays\"]}",
    "meta": {
      "moduleName": "bocce/templates/components/quiz-stats/types/essay-question-statistics.hbs"
    }
  });
});