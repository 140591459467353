define("bocce/components/quiz-stats/question-statistics-single", ["exports", "bocce/utilities/dialog", "bocce/mixins/boot"], function (_exports, _dialog, _boot) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const QuestionStatisticsSingle2 = Ember.Component.extend(_boot.default, {});

  //Used for multiple choice, multiple answer, fill in the blank, and true/false.

  //this.statistics gets passed in to the component. Origin is canvas quiz stats api.
  let QuestionStatisticsSingle = _exports.default = (_dec = Ember._tracked, _dec2 = Ember._action, (_class = class QuestionStatisticsSingle extends QuestionStatisticsSingle2 {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "answersTracked", _descriptor, this);
    }
    didInsertElement() {
      this.boot_area($(this.element).find(`.bootable-area`), true, true, true, false, true);
    }

    //Triggers the stats getter to recalculate and thus the UI to update with new stats.
    //So hacky, but it was the only way I could get this to re-render when the 'answers' arg changed
    //as in the case of fill-in-multiple-blanks or multiple-dropdowns
    willRender() {
      this.answersTracked = this.answers;
    }
    get stats() {
      let stats = {};
      let scores = this.quizzes.quiz.scores;
      stats.answerStats = {};
      for (const answer of this.answersTracked) {
        if (answer.correct) {
          //First, we run the calculation, then we convert it to a float, then we ensure we only have two decimal places, finally, 
          //we strip off trailing zeros with the '+' at the front of this calculation. For example, the '+' makes sure that '100.00' gets stored 
          //in the string as '100', '100.10' as '100.1', etc.
          stats.percentageCorrect = +parseFloat(answer.user_ids.length / this.statistics.responses * 100).toFixed(2);
        }
        if (!stats.answerStats[answer.id]) {
          stats.answerStats[answer.id] = {};
        }
        stats.answerStats[answer.id].correct = answer.correct;
        stats.answerStats[answer.id].text = answer.text;
        stats.answerStats[answer.id].responsePercentage = `${+parseFloat(answer.user_ids.length / this.statistics.responses * 100).toFixed(2)}%`;
        stats.answerStats[answer.id].users = answer.user_ids.map(ui => {
          return `${scores[ui].name}`;
        });
      }
      return stats;
    }
    showUsers(users) {
      let usersHtml = `<table class="question-stats-usernames">
                        <tbody>`;
      for (let user of users.sort()) {
        usersHtml += `<tr>
                       <td>${user}</td>
                     </tr>`;
      }
      usersHtml += '</tbody></table>';
      (0, _dialog.default)('', ['OK'], usersHtml, 0);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "answersTracked", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "showUsers", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "showUsers"), _class.prototype)), _class));
});