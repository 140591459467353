define("bocce/helpers/decode", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.decode = decode;
  _exports.default = void 0;
  // app/helpers/decode.js
  // Decode URI-style properties
  var _default = _exports.default = Ember.Helper.helper(function (dec) {
    let item = dec.join(' ');
    return decode(item);
  });
  function decode(stringToDecode) {
    if (!stringToDecode) {
      return stringToDecode;
    }
    let retval = '';
    try {
      retval = decodeURIComponent(stringToDecode);
    } catch (err) {
      retval = unescape(stringToDecode);
    }
    return retval;
  }
});