define("bocce/components/quiz/previous-questions", ["exports", "lodash.isequal"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let PreviousQuestions = _exports.default = (_dec = Ember._action, (_class = class PreviousQuestions extends Ember.Component {
    didInsertElement() {
      var maxWidth = 0;
      $('.previous-questions .previous-question-index').each(function (index, element) {
        var curWidth = $(element).width();
        if (maxWidth < curWidth) {
          maxWidth = curWidth;
        }
      });

      // Set all buttons to the max width
      $('.previous-questions .previous-question-index').width(maxWidth);
    }
    get dropdownDisabled() {
      return this.quizzes.gradingQuiz || this.quizzes.uploadingQuestion;
    }
    get questionData() {
      return this.quizzes.questions.map((question, index) => {
        let text = `Question ${index + 1}`;
        if (this.quizzes.hasScore) {
          /**
           * Possible values for question.correct: 
           *  undefined: manually graded question without a score
           *  defined: manually graded question with a score
           *  partial: partially right answer, ex: multiple fill in the blanks 
           *          where one of the blanks is right and the other is wrong
           *  correct/incorrect: fully right or wrong answer, ex: multiple choice, multiple dropdowns
           */

          if (question.correct === 'undefined') {
            text += ` - pending`;
          } else if (question.correct === 'defined' || question.correct === 'partial') {
            text += ` - ${question.points_received} / ${question.points}`;
          } else {
            text += ` - ${question.correct}`;
          }
        } else if ((0, _lodash.default)(question.given_answer, question.default_answer) && index <= this.quizzes.maxQuestionIndexUsed && index != this.quizzes.questionIndex) {
          text += ` - unanswered`;
        }
        let disabled = this.quizzes.hasScore ? false : index > this.quizzes.maxQuestionIndexUsed;
        return {
          question,
          disabled,
          text
        };
      });
    }
    setQuestionIndex(event) {
      const index = +event.target.value;
      this.quizzes.setQuestionIndex(index);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "setQuestionIndex", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "setQuestionIndex"), _class.prototype)), _class));
});