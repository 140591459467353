define("bocce/helpers/two-decimals", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.helper(function (val) {
    if (!val || val[0] == null) {
      return 'n/a';
    }

    // round val[0] to two decimal places
    return Math.round(val[0] * 100) / 100;
  });
});