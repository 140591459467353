define("bocce/templates/components/quiz/finish-quiz", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "bwibZ0+S",
    "block": "{\"symbols\":[\"@disabled\",\"@quizzes\"],\"statements\":[[11,\"button\"],[16,0,[31,[\"finish-quiz question-next-or-finish \",[30,[36,0],[[32,1],\"disabled\"],null]]]],[16,\"disabled\",[32,1]],[24,4,\"button\"],[4,[38,2],[\"click\",[30,[36,1],[[32,2,[\"submitQuiz\"]]],null]],null],[12],[2,\"\\n\"],[6,[37,0],[[32,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"            \"],[10,\"i\"],[14,0,\"fas fa-spinner fa-spin fa-fw\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"            Finish Quiz\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"fn\",\"on\"]}",
    "meta": {
      "moduleName": "bocce/templates/components/quiz/finish-quiz.hbs"
    }
  });
});