define("bocce/templates/components/buttons/async-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "kJzDsxo2",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,null],[2,\"\\n\"],[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[14,0,\"loading-spinner\"],[12],[10,\"i\"],[14,0,\"fas fa-spinner fa-spin\"],[14,\"aria-hidden\",\"true\"],[12],[13],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"_working\",\"if\"]}",
    "meta": {
      "moduleName": "bocce/templates/components/buttons/async-button.hbs"
    }
  });
});