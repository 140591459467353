define("bocce/models/conversation", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //app/models/conversation
  var _default = _exports.default = _model.default.extend({
    participants: (0, _model.hasMany)('user', {
      async: true
    }),
    last_message: (0, _model.attr)('string'),
    section: (0, _model.belongsTo)('section', {
      async: true
    }),
    last_message_at: (0, _model.attr)('date'),
    last_authored_message: (0, _model.attr)('string'),
    last_authored_message_at: (0, _model.attr)('date'),
    message_count: (0, _model.attr)(),
    starred: (0, _model.attr)('boolean'),
    group_conversation: (0, _model.attr)('boolean'),
    messages: (0, _model.hasMany)('conversation_message', {
      async: true
    }),
    context_name: (0, _model.attr)('string'),
    conversationPartners: (0, _model.hasMany)('user', {
      async: true
    }),
    topConversationPartners: Ember.computed('conversationPartners', function () {
      return this.conversationPartners.slice(0, 3);
    }),
    currentUser: (0, _model.belongsTo)('user', {
      async: true
    }),
    recipients: (0, _model.attr)(),
    new_message_body: (0, _model.attr)(),
    private: (0, _model.attr)('boolean'),
    attachments: (0, _model.attr)(),
    workflow_state: (0, _model.attr)(),
    participantRelationships: (0, _model.attr)(),
    lastActivity: (0, _model.attr)('date'),
    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    lastActivityFormatted: Ember.computed('last_message_at', 'last_authored_message_at', function () {
      return moment(this.lastActivity).tz('America/New_York').format('MM/DD/YY hh:mmA z');
    }),
    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    last_authored_message_at_formatted: Ember.computed(function () {
      let retval = this.last_authored_message_at;
      if (!retval) {
        retval = this.last_message_at;
      }
      return moment(retval).tz('America/New_York').format('MM/DD/YY hh:mm a');
    }),
    lastActivityFormattedBR: Ember.computed('last_message_at', 'last_authored_message_at', function () {
      return moment(this.lastActivity).tz('America/New_York').format('MMM D<br>h:mm A');
    }),
    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    last_authored_message_at_formattedBR: Ember.computed(function () {
      let retval = this.last_authored_message_at;
      if (!retval) {
        retval = this.last_message_at;
      }
      return moment(retval).tz('America/New_York').format('MMM D<br>h:mm A');
    }),
    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    last_authored_message_at_timestamp: Ember.computed(function () {
      let retval = this.last_authored_message_at;
      if (!retval) {
        retval = this.last_message_at;
      }
      return moment(retval).tz('America/New_York').format('x');
    }),
    last_message_formatted: Ember.computed('last_message', 'last_authored_message', function () {
      let retval = this.last_message ? this.last_message : this.last_authored_message,
        maxLength = 200;
      if (!retval) {
        return '';
      } else {
        // Remove all HTML tags from string
        retval = retval.replace(/<[^>]*>/g, '');
        if (retval.length < maxLength) {
          return retval;
        }
        retval = retval.substr(0, maxLength);
        //Re-trim if we are in the middle of a word - JRW
        retval = retval.substr(0, Math.min(retval.length, retval.lastIndexOf(' ')));
        return retval;
      }
    }),
    read: Ember.computed('workflow_state', function () {
      if (this.workflow_state === 'read') {
        return true;
      }
      return false;
    }),
    archived: Ember.computed.equal('workflow_state', 'archived'),
    active: Ember.computed.not('archived')
  });
});