define("bocce/components/interactions/guitar/main", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    didInsertElement() {
      console.log('guitar main!');
    }
  });
});