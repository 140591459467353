define("bocce/components/recipient-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    filter: null,
    filteredList: null,
    recipientId: null,
    currentListPosition: null,
    // The reason there is a keyUp function AND a keyPress function is because
    // keyUp recognized the arrow keys but not the enter key. And keyPress recognizes
    // the enter key and not the arrow keys. At least that's how it was on my macbook
    // air work computer - JRW.
    keyUp: function (keyEvent) {
      if (keyEvent.key === 'ArrowUp' || keyEvent.key === 'ArrowDown') {
        /* eslint-disable-next-line ember/no-jquery */
        var listLength = Ember.$('.recipient-filter-list li').length;
        var currentListPosition = this.currentListPosition;
        if (keyEvent.key === 'ArrowUp') {
          if (currentListPosition === 0 || currentListPosition === null) {
            this.set('currentListPosition', listLength - 1);
          } else {
            this.set('currentListPosition', this.currentListPosition - 1);
          }
        } else {
          // it's the ArrowDown
          if (currentListPosition === listLength - 1) {
            // we're at the end, go back to the beginning
            this.set('currentListPosition', 0);
          } else {
            if (currentListPosition >= 0 && currentListPosition !== null) {
              this.set('currentListPosition', this.currentListPosition + 1);
            } else {
              this.set('currentListPosition', 0);
            }
          }
        }

        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.recipient-filter-list li').eq(this.currentListPosition).addClass('active');
      } else {
        // A new word has been autocompleted, set the list position
        // to zero.
        this.set('currentListPosition', null);
      }
    },
    keyPress: function (keyEvent) {
      if (keyEvent.which === 13) {
        // 13 is the enter key
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.recipient-filter-list li').eq(this.currentListPosition).click();
      }
    },
    actions: {
      autoComplete: function () {
        if (!this.filter) {
          this.set('filteredList', []);
          this.set('recipientId', null);
        }
        this.autoComplete(this.filter);
      },
      removeRecipient: function (recipient_id) {
        var recipients = this.recipients;
        recipients.removeObject(recipients.findBy('id', recipient_id));
      },
      focusRI: function () {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.recipient-filter-input').focus();
      },
      choose: function (recipient) {
        if (recipient.modelName === 'conversation') {
          this.viewConversation(recipient.id);
          return;
        }
        /* eslint-disable-next-line ember/no-get */
        if (!this.get('recipients.isFulfilled')) {
          this.recipients.pushObject(recipient);
        } else {
          this.choose(recipient);
        }
        this.set('filteredList', []);
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.recipient-filter-input').val('').focus();
        this._super();
      }
    }
  });
});