define("bocce/mixins/support/util", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports._createForOfIteratorHelper = _createForOfIteratorHelper;
  _exports.any = any;
  _exports.bindAll = bindAll;
  _exports.encodeImageFileAsURL = encodeImageFileAsURL;
  _exports.max = max;
  _exports.min = min;
  _exports.mixin = mixin;
  _exports.randomString = randomString;
  _exports.shuffle = shuffle;
  _exports.sortAsc = sortAsc;
  _exports.sortDesc = sortDesc;
  _exports.unique = unique;
  function shuffle(arr) {
    let l = arr.length;
    for (let i = l - 1; i >= 1; i--) {
      let n = Math.floor(Math.random() * i),
        tmp = arr[n];
      arr[n] = arr[i];
      arr[i] = tmp;
    }
  }
  function any(arr, func) {
    // This could be arr.filter(func).length > 0, but looping lets us short circuit
    let l = arr.length;
    for (let i = 0; i < l; i++) {
      if (func(arr[i])) {
        return true;
      }
    }
    return false;
  }
  function unique(arr) {
    return Array.from(new Set(arr));
  }
  function sortAsc(arr) {
    return arr.sort((a, b) => a > b ? 1 : -1);
  }
  function sortDesc(arr) {
    return arr.sort((a, b) => a > b ? -1 : 1);
  }
  function max(arr) {
    return sortDesc(arr)[0];
  }
  function min(arr) {
    return sortAsc(arr)[0];
  }
  function bindAll(obj, fields = false) {
    for (let fn in obj) {
      if (typeof obj[fn] !== 'function' || fields && fields.indexOf(fn) === -1) {
        continue;
      }
      obj[fn] = obj[fn].bind(obj);
    }
  }
  function mixin(dest, src, no_overwrites) {
    for (let k in src) {
      if (!dest[k] || !no_overwrites) {
        dest[k] = src[k];
      }
    }
  }
  function encodeImageFileAsURL(element) {
    let file = element.files[0];
    return new Promise(function (resolve, reject) {
      var fr = new FileReader();
      fr.onload = () => {
        resolve(fr.result);
        // console.log(fr.result);
      };
      fr.onerror = reject;
      fr.readAsDataURL(file);
    });
  }
  function randomString(length) {
    let text = '';
    let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }
  function _createForOfIteratorHelper(o, allowArrayLike) {
    var it;
    if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) {
      if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") {
        if (it) o = it;
        var i = 0;
        var F = function F() {};
        return {
          s: F,
          n: function n() {
            if (i >= o.length) return {
              done: true
            };
            return {
              done: false,
              value: o[i++]
            };
          },
          e: function e(_e) {
            throw _e;
          },
          f: F
        };
      }
      throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
    }
    var normalCompletion = true,
      didErr = false,
      err;
    return {
      s: function s() {
        it = o[Symbol.iterator]();
      },
      n: function n() {
        var step = it.next();
        normalCompletion = step.done;
        return step;
      },
      e: function e(_e2) {
        didErr = true;
        err = _e2;
      },
      f: function f() {
        try {
          if (!normalCompletion && it.return != null) it.return();
        } finally {
          if (didErr) throw err;
        }
      }
    };
  }
  function _unsupportedIterableToArray(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _arrayLikeToArray(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(o);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
  }
  function _arrayLikeToArray(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for (var i = 0, arr2 = new Array(len); i < len; i++) {
      arr2[i] = arr[i];
    }
    return arr2;
  }
});