define("bocce/models/quiz", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // app/models/quiz.js
  var _default = _exports.default = _model.default.extend({
    section: (0, _model.belongsTo)('section'),
    title: (0, _model.attr)('string'),
    search_id: (0, _model.attr)(),
    introduction: (0, _model.attr)('string'),
    due_at: (0, _model.attr)('date'),
    is_graded: (0, _model.attr)('boolean'),
    allowed_attempts: (0, _model.attr)('number'),
    presentation: (0, _model.attr)('string'),
    shuffle: (0, _model.attr)('boolean'),
    cant_go_back: (0, _model.attr)('boolean'),
    is_quiz: true,
    time_limit_minutes: (0, _model.attr)('number'),
    attempts: (0, _model.hasMany)('attempt'),
    quiz_statistics: (0, _model.belongsTo)('quiz_statistic', {
      async: false
    }),
    scores: (0, _model.attr)(),
    //The components and config that will be used to render the quiz stats. Origin
    //of quiz stats data is canvas quiz stats api.
    quiz_statistics_components: Ember.computed('quiz_statistics', function () {
      let components = [];
      for (const questionStats of this.get('quiz_statistics.question_statistics').toArray()) {
        const type = questionStats.question_type.replaceAll('_', '-');
        components.push({
          path: `quiz-stats/types/${type}-statistics`,
          statistics: questionStats,
          type,
          id: questionStats.id
        });
      }
      return components;
    }),
    multiple_choices: (0, _model.hasMany)('multiple_choice', {
      async: false
    }),
    true_falses: (0, _model.hasMany)('true_false', {
      async: false
    }),
    short_answers: (0, _model.hasMany)('short_answer', {
      async: false
    }),
    multiple_answers: (0, _model.hasMany)('multiple_answer', {
      async: false
    }),
    multiple_dropdowns: (0, _model.hasMany)('multiple_dropdown', {
      async: false
    }),
    essays: (0, _model.hasMany)('essay', {
      async: false
    }),
    file_uploads: (0, _model.hasMany)('file_upload', {
      async: false
    }),
    fill_in_multiple_blanks: (0, _model.hasMany)('fill_in_multiple_blank', {
      async: false
    }),
    quiz_questions: (0, _model.hasMany)('quiz_question', {
      async: false
    }),
    /* eslint-disable-next-line ember/require-return-from-computed */
    formattedDate: Ember.computed('due_at', function () {
      if (this.due_at) {
        var d = this.due_at;
        if (!d) {
          return '';
        }
        return moment(d).tz('America/New_York').calendar();
      }
    }),
    formattedDueDate: Ember.computed.alias('formattedDate'),
    dueToday: Ember.computed('due_at', function () {
      var d = this.due_at;
      if (d && moment(d).isSame(Date(), 'day')) {
        return true;
      }
      return false;
    }),
    todo: Ember.computed.equal('attempts.length', 0),
    sortDate: Ember.computed.alias('due_at')
  });
});