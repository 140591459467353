define("bocce/models/question_statistic", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // app/models/quiz_question.js
  var _default = _exports.default = _model.default.extend({
    question_type: (0, _model.attr)('string'),
    question_text: (0, _model.attr)('string'),
    position: (0, _model.attr)('number'),
    responses: (0, _model.attr)('number'),
    answer_sets: (0, _model.attr)(),
    answers: (0, _model.attr)(),
    answered_student_count: (0, _model.attr)()
  });
});