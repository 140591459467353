define("bocce/components/classroom/header/section-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: 'span',
    classNames: ['header-course-section'],
    features: Ember.inject.service(),
    showWeek: Ember.computed('features.staticContent', 'hideWeek', function () {
      /* eslint-disable-next-line ember/no-get */
      return !this.get('features.staticContent') && !this.get('hideWeek');
    }),
    _sectionLabel: Ember.computed.or('sectionLabel', 'section.number'),
    _termLabel: Ember.computed.or('termLabel', 'term.name')
  });
});