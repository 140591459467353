define("bocce/services/features", ["exports", "ember-feature-flags/services/features", "bocce/config/environment"], function (_exports, _features, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _features.default.extend({
    config: _environment.default,
    init() {
      this._super(...arguments);
      if (_environment.default.featureFlags) {
        this.setup(_environment.default.featureFlags);
      }
    }
  });
});