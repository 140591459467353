define("bocce/services/session", ["exports", "bocce/config/environment", "ember-data"], function (_exports, _environment, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function is_in_range(ranges, course_id) {
    if (!ranges) {
      return false;
    }
    course_id = parseInt(course_id, 10);
    ranges = ranges.split(',');
    for (let range of ranges) {
      if (range.indexOf('-') === -1) {
        if (parseInt(range, 10) === course_id) {
          return true;
        }
        continue;
      }
      let [min, max] = range.split('-');
      min = parseInt(min, 10);
      if (max) {
        max = parseInt(max, 10);
        if (course_id >= min && course_id <= max) {
          return true;
        }
      } else {
        if (course_id >= min) {
          return true;
        }
      }
    }
    return false;
  }
  var _default = _exports.default = Ember.Service.extend({
    init() {
      this._super(...arguments);
      this.set('currentAbortControllers', Ember.A([]));
    },
    features: Ember.inject.service(),
    isStaticContent: Ember.computed('features.staticContent', function () {
      return this.features.isEnabled('staticContent');
    }),
    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    portrait: Ember.computed.reads('user.image_url'),
    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    course: Ember.computed.reads('section.course'),
    termID: Ember.computed.reads('course.term.id'),
    termName: Ember.computed.reads('course.term.name'),
    isLiveWorkshop: Ember.computed.reads('course.isLiveWorkshop'),
    lessonId: -1,
    itemId: -1,
    last_banner_serial: undefined,
    isBCM: Ember.computed.alias('course.term.isBCM'),
    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    isInstructor: Ember.computed('user', 'user.teaching.[]', 'section', function () {
      /* eslint-disable-next-line ember/no-get */
      let section_id = this.get('section.id');
      if (!section_id) {
        return false;
      }

      /* eslint-disable-next-line ember/no-get */
      let teaching = this.get('user.teaching');
      /* eslint-disable-next-line ember/no-get */
      return teaching && teaching.filter(s => section_id === s.get('id')).get('length') > 0;
    }),
    teachingUser: Ember.computed('section', function () {
      let section = this.get('section');
      return section.get('teachers').get('firstObject');
    }),
    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    isShowcaseInstructor: Ember.computed('user', 'user.teaching.[]', 'section', function () {
      /* eslint-disable-next-line ember/no-get */
      let instructors = this.get('course.showcaseInstructors') || [];

      /* eslint-disable-next-line ember/no-get */
      return instructors.indexOf(parseInt(this.get('user.id'))) >= 0;
    }),
    isObserver: Ember.computed('section.role', function () {
      let sec = this.section;

      /* eslint-disable-next-line ember/no-get */
      return sec.get('role') === 'observer';
    }),
    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    isStudent: Ember.computed('user', 'user.enrolled.[]', 'section', function () {
      /* eslint-disable-next-line ember/no-get */
      let section_id = this.get('section.id');
      if (!section_id) {
        return false;
      }

      /* eslint-disable-next-line ember/no-get */
      let enrollments = this.get('user.enrolled');
      if (!enrollments || enrollments.length == 0) {
        return false;
      }
      return enrollments

      /* eslint-disable-next-line ember/no-get */.filter(e => section_id === e.get('section.id') && e.get('role') === 'student')
      /* eslint-disable-next-line ember/no-get */.get('length') > 0;
    }),
    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    isStaff: Ember.computed.reads('user.staff'),
    isAdmin: Ember.computed.reads('user.admin'),
    gradeLock: Ember.computed.reads('course.isGradeLock'),
    // The flavors live here: https://kmc.kaltura.com/index.php/kmcng/settings/transcoding/profile/10823552/flavors
    // If you change them in your Kaltura setup, change them here too. But you shouldn't ever need to.
    kaltura_flavors: {
      "1080p": 487091,
      "720p": 487071,
      "480p": 487051,
      "Uncompressed": 0
    },
    currentVideoRes: Ember.computed('videoRes', function () {
      let videoRes = localStorage.getItem('bocceVideoRes');
      if (typeof videoRes === 'undefined' || videoRes === null) {
        // get user's connection speed, if the navigator.connection.downlink is supported
        // Use downlink speed to determine video resolution
        // If navigator.connection.downlink is not supported, use a default value
        let downlink = navigator.connection ? navigator.connection.downlink : false;
        if (downlink) {
          if (downlink >= 4) {
            videoRes = '1080p';
          } else if (downlink >= 2) {
            videoRes = '720p';
          } else {
            videoRes = '480p';
          }
        } else {
          videoRes = '1080p';
        }
      }
      return videoRes;
    }),
    gradeLockAt: Ember.computed.reads('course.gradeLockDate'),
    courseCodeWithoutSection: Ember.computed('course.code', function () {
      const courseCodeWithSection = this.get('course.code');
      let indexOfDot = courseCodeWithSection.indexOf('.');
      return courseCodeWithSection.substring(0, indexOfDot > -1 ? indexOfDot : courseCodeWithSection.length);
    }),
    lateGradingPolicy: Ember.computed('course.lateGradingPolicy', function () {
      return this.get('course.lateGradingPolicy') || '';
    }),
    bookmarks: Ember.computed('user.bookmarks', function () {
      return this.get('user.bookmarks') || [];
    }),
    allBookmarkContentTagIds: Ember.computed('user.allBookmarkContentTagIds', function () {
      return this.get('user.allBookmarkContentTagIds') || [];
    }),
    gradebookWeights: Ember.computed('course.gradebook_weights', function () {
      return this.get('course.gradebook_weights') ?? [];
    }),
    readOnly: Ember.computed.or('globalReadOnly', 'course.isReadOnly'),
    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    isEnabled: Ember.computed('course.id', function () {
      let retval = {},
        /* eslint-disable-next-line ember/no-get */
        cid = this.get('course.id'),
        /* eslint-disable-next-line ember/no-get */
        courseCodeRaw = this.get('course.code'),
        courseCode = courseCodeRaw ? courseCodeRaw.split('.')[0] : false;
      for (let feature in _environment.default.APP.courseFeatures) {
        let featureContents = _environment.default.APP.courseFeatures[feature];
        if (typeof featureContents === 'object') {
          retval[feature] = _environment.default.APP.courseFeatures.aFNonCredit.indexOf(courseCode) > -1;
        } else {
          retval[feature] = is_in_range(_environment.default.APP.courseFeatures[feature], cid);
        }
      }
      return retval;
    }),
    allowStudentChats: Ember.computed('course.id', function () {
      /* eslint-disable-next-line ember/no-get */
      let courseId = this.get('course.id');
      return is_in_range(_environment.default.APP.courseFeatures.coursesAllowStudentChats, courseId);
    }),
    allowOldGradebook: Ember.computed('user.id', function () {
      /* eslint-disable-next-line ember/no-get */
      let userId = this.get('user.id');
      return is_in_range(_environment.default.APP.courseFeatures.useOldGradebook, userId);
    }),
    hasZoomId: Ember.computed('user.hasZoomId', function () {
      /* eslint-disable-next-line ember/no-get */
      let hasZoomId = this.get('user.hasZoomId');
      return hasZoomId;
    }),
    isSimplifiedTerm: Ember.computed('course.term', function () {
      let termName = this.get('course.term.name'),
        simplifiedTermKeywords = _environment.default.APP.courseFeatures.simplifiedTermKeywords || [],
        isSimplifiedTerm = false;
      for (let simplifiedTermKeyword of simplifiedTermKeywords) {
        if (termName.includes(simplifiedTermKeyword)) {
          isSimplifiedTerm = true;
        }
      }
      return isSimplifiedTerm;
    }),
    isLimitedStudentTerm: Ember.computed('course.term', function () {
      let termName = this.get('course.term.name'),
        limitedStudentTermKeywords = _environment.default.APP.courseFeatures.limitedStudentTermsKeywords || [],
        isLimitedStudentTerm = false;
      if (!termName) {
        return isLimitedStudentTerm;
      }
      if (this.get("isAdmin") || this.get('isStaff') || this.get('isInstructor')) {
        return isLimitedStudentTerm;
      }
      for (let limitedStudentTermKeyword of limitedStudentTermKeywords) {
        if (termName.toLowerCase().includes(limitedStudentTermKeyword.toLowerCase())) {
          isLimitedStudentTerm = true;
        }
      }
      return isLimitedStudentTerm;
    }),
    noPiano: Ember.computed('course.code', function () {
      /* eslint-disable-next-line ember/no-get */
      let courseCodeRaw = this.get('course.code');
      let courseCode = courseCodeRaw ? courseCodeRaw.split('.')[0] : false;
      return _environment.default.APP.courseFeatures.noPiano.indexOf(courseCode) > -1;
    }),
    aFNonCredit: Ember.computed('course.code', function () {
      /* eslint-disable-next-line ember/no-get */
      let courseCodeRaw = this.get('course.code');
      let courseCode = courseCodeRaw ? courseCodeRaw.split('.')[0] : false;
      return _environment.default.APP.courseFeatures.aFNonCredit.indexOf(courseCode) > -1;
    }),
    currentAbortControllers: null,
    addAbortController(controller) {
      this.currentAbortControllers.pushObject(controller);
    },
    clearAbortControllers() {
      this.currentAbortControllers.clear();
    }
  });
});