define("bocce/templates/components/quiz/answer-feedback", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "KTsRE1nx",
    "block": "{\"symbols\":[\"@question\",\"@quiz\",\"@onCloseModal\"],\"statements\":[[10,\"div\"],[14,0,\"question-checker\"],[15,1,[31,[\"question-\",[32,1,[\"id\"]],\"-modal\"]]],[12],[2,\"\\n    \"],[11,\"label\"],[24,0,\"close-question-modal\"],[16,\"for\",[31,[\"quiz-\",[32,2,[\"id\"]],\"-question-\",[32,1,[\"id\"]],\"-checker\"]]],[4,[38,1],[\"click\",[30,[36,0],[[32,3]],null]],null],[12],[10,\"i\"],[14,0,\"far fa-times-circle\"],[14,\"aria-hidden\",\"true\"],[12],[13],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"question-loading\"],[12],[10,\"i\"],[14,0,\"fas fa-spinner fa-spin fa-3x fa-fw\"],[12],[13],[2,\" Checking answer. Please wait...\"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"question-correct bootable-area\"],[12],[6,[37,2],[[32,1,[\"correct_feedback\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,[32,1,[\"correct_feedback\"]]]],\"parameters\":[]},{\"statements\":[[2,\"Correct!\"]],\"parameters\":[]}]]],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"question-wrong bootable-area\"],[12],[6,[37,2],[[32,1,[\"wrong_feedback\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,[32,1,[\"wrong_feedback\"]]]],\"parameters\":[]},{\"statements\":[[2,\"Incorrect\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"if\"]}",
    "meta": {
      "moduleName": "bocce/templates/components/quiz/answer-feedback.hbs"
    }
  });
});