define("bocce/components/buttons/async-button", ["exports", "bocce/components/buttons/button-generic"], function (_exports, _buttonGeneric) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
  
  buttons/async-button component
  
  Creates a button that plays nicely with async actions. Expects the following parameters:
    * promiseAction - an action that returns a Promise
    * thenAction - an action to call on the Promise resolution. promiseAction will pass its resolved value to thenAction
    * active (optional) - a property to activate/deactivate the button. Button is active when truthy, inactive when
        falsey. When active is not provided, the button defaults to active.
  */
  var _default = _exports.default = _buttonGeneric.default.extend({
    init(...args) {
      this._super(...args);
      // Default to active when no active condition is supplied.
      this.active = typeof this.active !== 'undefined' ? this.active : true;
    },
    attributeBindings: ['ariaLabel:aria-label', 'ariaDisabled:aria-disabled'],
    classNames: ['button', 'async'],
    classNameBindings: ['_ready:active', '_working:working'],
    ariaLabel: Ember.computed('activeLabel', 'inactiveLabel', '_working', '_ready', function () {
      if (this._ready) {
        return this.activeLabel;
      } else {
        if (this._working) {
          return 'loading';
        } else {
          return this.inactiveLabel;
        }
      }
    }),
    ariaDisabled: Ember.computed.not('_ready'),
    _working: false,
    _ready: Ember.computed('_working', 'active', function () {
      return !this._working && this.active;
    }),
    click() {
      if (this._ready) {
        this.set('_working', true);
        this.promiseAction().then(resolved_val => {
          this.set('_working', false);
          if (typeof this.thenAction === 'function') {
            this.thenAction(resolved_val);
          }
        });
      }
    }
  });
});