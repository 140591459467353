define("bocce/models/gradebook_weight", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //app/models/gradebook_weight.js
  //A single weight for the gradebook policy.
  //ex: 75% for assignments. 75 is the weight and 'assignments' is the name.
  var _default = _exports.default = _model.default.extend({
    name: (0, _model.attr)('string'),
    weight: (0, _model.attr)('number')
  });
});