define("bocce/templates/components/quiz-questions/essay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "oToI7Rpx",
    "block": "{\"symbols\":[\"@question\"],\"statements\":[[10,\"textarea\"],[15,\"oninput\",[30,[36,0],[[32,0],\"setAnswer\"],null]],[14,0,\"quiz-question-choice\"],[15,\"question-id\",[31,[[32,1,[\"id\"]]]]],[15,1,[31,[\"quiz-\",[34,1,[\"id\"]],\"-question-\",[32,1,[\"id\"]],\"-textarea\"]]],[15,3,[31,[\"quiz-\",[34,1,[\"id\"]],\"-question-\",[32,1,[\"id\"]],\"-choices\"]]],[15,2,[31,[[32,1,[\"given_answer\"]]]]],[12],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"quiz\"]}",
    "meta": {
      "moduleName": "bocce/templates/components/quiz-questions/essay.hbs"
    }
  });
});