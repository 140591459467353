define("bocce/controllers/classroom/lessons/survey", ["exports", "bocce/mixins/enmodal", "bocce/mixins/notify"], function (_exports, _enmodal, _notify) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable-next-line ember/no-mixins */
  var _default = _exports.default = Ember.Controller.extend(_enmodal.default, _notify.default, {
    queryParams: ['nudge', 'concluded'],
    nudge: false,
    concluded: false,
    classroom: Ember.inject.controller(),
    // required by EnmodalMixin

    actions: {
      display: async function () {
        /* eslint-disable-next-line ember/no-get */
        let course_id = this.get('session.course.id');
        let response = await fetch(`/interface/survey/${course_id}`);
        let data = await response.json();
        let form = document.getElementById('survey_init');
        form.innerHTML = '';
        for (let key in data) {
          if (data[key] !== null) {
            let html = `<input type="hidden" name="${key}" value="${data[key]}" />`;
            form.insertAdjacentHTML('beforeend', html);
          }
        }
        form.submit();
      }
    }
  });
});