define("bocce/helpers/trim", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.helper(function (item) {
    if (!item || !item[0] || typeof item[0] !== 'string') {
      return false;
    }
    return item[0].trim();
  });
});