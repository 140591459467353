define("bocce/models/term", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // app/models/term.js
  var _default = _exports.default = _model.default.extend({
    name: (0, _model.attr)('string'),
    startsOn: (0, _model.attr)('date'),
    endsOn: (0, _model.attr)('date'),
    courses: (0, _model.hasMany)('course', {
      async: false
    }),
    weeksSinceStart: Ember.computed('startsOn', function () {
      var start = this.startsOn,
        now = new Date(),
        week;
      if (!start) {
        return 1;
      }
      week = Math.ceil((now - start) / (7 * 24 * 3600 * 1000));
      return week;
    }),
    week: Ember.computed('weeksSinceStart', function () {
      let week = this.weeksSinceStart;
      if (week < 0) {
        week = 0;
      }
      if (week > 12) {
        week = 12;
      }
      return week;
    }),
    // is this term for BCM (campus) courses?
    isBCM: Ember.computed('name', function () {
      let name = this.name;
      // returns true for anything starting with "Campus", and any of the following initials: BCM, BCB, BSP
      return /^B(C[MB]|SP)-/.test(name) || /^Campus/.test(name);
    })
  });
});