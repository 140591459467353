define("bocce/components/kaltura-player", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    kalturaEmbed: function () {
      if (window.kWidget) {
        let kalturaRecording = this.kalturaRecording;
        let kEmbedObject = {
          targetId: this.elementId,
          flashvars: {
            'autoPlay': false
          },
          wid: '_2588802',
          uiconf_id: 44413892,
          entry_id: kalturaRecording.kalturaId
        };
        let embedResult = window.kWidget.embed(kEmbedObject);
        (true && !(embedResult !== false) && Ember.assert('kWidget.embed is called on an existing DOM element', embedResult !== false));
      }
    },
    didRender() {
      this.kalturaEmbed();
    }
  });
});