define("bocce/utilities/timer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function timer(duration, interval, callback, eventHandlers) {
    var timer = duration,
      minutes,
      seconds;
    const _interval = setInterval(function () {
      minutes = parseInt(timer / 60, 10);
      seconds = parseInt(timer % 60, 10);
      callback(minutes, seconds);
      if (Array.isArray(eventHandlers) && eventHandlers.length) for (let eventHandler of eventHandlers) {
        if (timer <= eventHandler.time && !eventHandler.executed) {
          eventHandler.callback(minutes, seconds);
          eventHandler.executed = true;
        }
      }
      timer = timer - Math.floor(interval / 1000);
      if (timer < 0) {
        clearInterval(_interval);
      }
    }, interval);
    return _interval;
  }
  var _default = _exports.default = timer;
});