define("bocce/templates/interactions/textlineandtextselecthybrid/main", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "6KAZSdug",
    "block": "{\"symbols\":[\"question\",\"answerSet\",\"answer\"],\"statements\":[[6,[37,1],[[30,[36,0],[[30,[36,0],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"question\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"text-line unanswered\"],[12],[2,[32,1,[\"question\"]]],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"answers fr\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,1,[\"answerSets\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"select\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,2,[\"answers\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"option\"],[12],[1,[32,3]],[13],[2,\"\\n\"]],\"parameters\":[3]}]]],[2,\"        \"],[13],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"clear\"],[12],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[10,\"div\"],[14,0,\"buttons\"],[12],[2,\"\\n  \"],[10,\"a\"],[14,6,\"#\"],[14,0,\"checkAnswers button\"],[12],[2,\"Check Answers\"],[13],[2,\"\\n  \"],[10,\"a\"],[14,6,\"#\"],[14,0,\"reset button\"],[12],[2,\"Reset Quiz\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"feedback\"],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"message\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"final-text\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\",\"questions\"]}",
    "meta": {
      "moduleName": "bocce/templates/interactions/textlineandtextselecthybrid/main.hbs"
    }
  });
});