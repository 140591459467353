define("bocce/templates/classroom/lessons/student-event-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "9pJA38lr",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,\"role\",\"dialog\"],[14,\"aria-modal\",\"true\"],[14,\"aria-labelledby\",\"event-title-label\"],[14,\"aria-describedby\",\"event-title-label\"],[14,0,\"floating-modal new-event new-student-event active\"],[14,1,\"new-event\"],[12],[2,\"\\n  \"],[11,\"button\"],[24,0,\"close-button\"],[24,\"aria-label\",\"Close current modal\"],[4,[38,0],[[32,0],\"closeModals\"],[[\"bubbles\"],[false]]],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fas fa-times\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"h2\"],[14,0,\"event-start-label\"],[12],[2,\"Give your session a title:\"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"event-title-container\"],[12],[2,\"\\n\"],[2,\"    \"],[8,\"input\",[[24,0,\"new-student-event-title\"],[24,\"placeholder\",\"Title\"]],[[\"@value\"],[[34,1]]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[11,\"button\"],[24,0,\"start-chat-btn\"],[24,\"aria-label\",\"Start session\"],[4,[38,0],[[32,0],\"startStudentChat\"],[[\"on\"],[\"mouseUp\"]]],[12],[2,\"Start Session Now\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[8,\"modals/dark-backdrop\",[],[[\"@action\"],[[30,[36,0],[[32,0],\"closeModals\"],null]]],null]],\"hasEval\":false,\"upvars\":[\"action\",\"titleInput\"]}",
    "meta": {
      "moduleName": "bocce/templates/classroom/lessons/student-event-new.hbs"
    }
  });
});