define("bocce/components/lessons/event-new-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class EventNewContentComponent extends Ember.Component {
    didInsertElement() {
      const stickyHeader = document.getElementById("new-event-sticky-header");
      const headerOffsetHeight = stickyHeader ? stickyHeader.offsetHeight : 42;

      //Scroll the newly created element to a visually pleasing spot on the screen.
      $('#new-event').animate({
        scrollTop: this.element.offsetTop - headerOffsetHeight
      }, 1250);
    }
  }
  _exports.default = EventNewContentComponent;
  ;
});