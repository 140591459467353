define("bocce/components/grade-student-list/list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: 'li',
    classNames: ['clearfix'],
    classNameBindings: ['graded', 'my_submission', 'current', 'unread'],
    attributeBindings: ['getRole:role', 'getAriaLabel:aria-label'],
    // returns a default value if it's not provided by the template
    getRole: Ember.computed('role', function () {
      if (this.role) {
        return this.role;
      } else {
        return 'button';
      }
    }),
    getAriaLabel: Ember.computed('submission.user.short_name', 'ariaLabel', function () {
      let ariaLabel = this.ariaLabel;
      if (ariaLabel) {
        return ariaLabel;
      } else {
        /* eslint-disable-next-line ember/no-get */
        let name = this.get('submission.user.short_name');
        if (name) {
          return `Assignment submission belonging to ${name}`;
        } else {
          return 'Assignment submission';
        }
      }
    }),
    graded: Ember.computed('submission.grade', function () {
      /* eslint-disable-next-line ember/no-get */
      return Boolean(this.get('submission.grade'));
    }),
    my_submission: Ember.computed.reads('submission.my_submission'),
    current: Ember.computed('submission.id', 'currentSubmissionId', function () {
      /* eslint-disable-next-line ember/no-get */
      return this.get('submission.id') === this.currentSubmissionId;
    }),
    unread: Ember.computed.not('submission.read'),
    // triggers clickAction on click
    click() {
      this.clickAction();
    }
  });
});