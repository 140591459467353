define("bocce/templates/components/embeded-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "OOLBCpoO",
    "block": "{\"symbols\":[\"embed\"],\"statements\":[[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,0],[[32,1,[\"bocceSourced\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[2,[32,1,[\"content\"]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"a\"],[15,6,[31,[[32,1,[\"url\"]]]]],[12],[2,\"\\n      \"],[10,\"img\"],[14,0,\"embeded-image\"],[14,\"alt\",\"Embedded image\"],[15,\"src\",[31,[[32,1,[\"imageUrl\"]]]]],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"div\"],[14,0,\"embed-seperator\"],[12],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"embeds\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "bocce/templates/components/embeded-content.hbs"
    }
  });
});