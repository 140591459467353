define("bocce/templates/conversation-reply-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "FKMAFHX5",
    "block": "{\"symbols\":[],\"statements\":[[8,\"drag-and-drop\",[],[[\"@files\",\"@store\",\"@controller\"],[[34,0],[34,1],[32,0]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,\"role\",\"region\"],[14,\"aria-label\",\"Conversation Reply Text Editor\"],[14,0,\"conversation-reply-box\"],[12],[2,\"\\n    \"],[10,\"div\"],[15,0,[31,[\"reply-container reply-quick \",[30,[36,3],[[35,2],\"active-editor\"],null]]]],[12],[2,\"\\n      \"],[8,\"rte-input\",[],[[\"@userInput\",\"@updateArchive\"],[[34,4],[30,[36,5],[[32,0],\"updateLocalDocs\"],null]]],null],[2,\"\\n      \"],[19,\"drafts\",[]],[2,\"\\n      \"],[8,\"attachments-container\",[],[[\"@attachmentFiles\",\"@deleteAttachment\",\"@encodingVideos\",\"@renameAttachment\"],[[32,0,[\"attachmentsToContainer\"]],[30,[36,5],[[32,0],\"deleteAttachment\"],null],[32,0,[\"encodingVideosToContainer\"]],[30,[36,5],[[32,0],\"renameAttachment\"],null]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[2,\"\\n      \"],[10,\"div\"],[14,0,\"button-container\"],[12],[2,\"\\n        \"],[11,\"button\"],[24,0,\"btn\"],[4,[38,5],[[32,0],\"openAttachmentDrawer\"],null],[12],[2,\"\\n          \"],[10,\"i\"],[14,0,\"fas fa-paperclip\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\" Attach\\n        \"],[13],[2,\"\\n        \"],[11,\"button\"],[24,0,\"btn btn-black\"],[16,\"disabled\",[30,[36,7],[[35,6],true,null],null]],[4,[38,5],[[32,0],\"updateConversation\",true],null],[12],[2,\"\\n          \"],[10,\"i\"],[14,0,\"fas fa-paper-plane\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\" Post\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[8,\"modals/attachments-modal\",[],[[\"@addFile\"],[[30,[36,5],[[32,0],\"addFile\"],null]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":true,\"upvars\":[\"files\",\"store\",\"inEditor\",\"if\",\"bodyInput\",\"action\",\"postable\",\"unless\"]}",
    "meta": {
      "moduleName": "bocce/templates/conversation-reply-box.hbs"
    }
  });
});