define("bocce/helpers/safehtml", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.safehtml = safehtml;
  function safehtml([input] /*, hash*/) {
    return Ember.String.htmlSafe(input);
  }
  var _default = _exports.default = Ember.Helper.helper(safehtml);
});