define("bocce/templates/components/quiz-stats/types/multiple-choice-question-statistics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "9KeRBCbU",
    "block": "{\"symbols\":[\"@statistics\",\"@quizzes\"],\"statements\":[[8,\"quiz-stats/question-statistics-single\",[],[[\"@statistics\",\"@answers\",\"@quizzes\",\"@questionText\"],[[32,1],[32,1,[\"answers\"]],[32,2],[32,1,[\"question_text\"]]]],null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "bocce/templates/components/quiz-stats/types/multiple-choice-question-statistics.hbs"
    }
  });
});