define("bocce/mixins/audio-rec", ["exports", "bocce/mixins/legacy-attachment-manager"], function (_exports, _legacyAttachmentManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-new-mixins */
  var _default = _exports.default = Ember.Mixin.create(_legacyAttachmentManager.default, {
    recorder: false,
    panel: false,
    echoCancellationEnabled: Ember.computed(function () {
      return localStorage.getItem('bocceEchoCancellation') === 'true';
    }),
    isChrome: Ember.computed(function () {
      return navigator.userAgent.toLowerCase().indexOf('chrome') > -1;
    }),
    noRec: Ember.computed(function () {
      // Check if getUserMedia is available. Otherwise, kill mic.
      navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia;
      return !navigator.getUserMedia;
    }),
    startUserMedia: function (stream) {
      this.set('recorder', new Recorder(stream));
      Ember.debug('Recorder initialised.');
    },
    createDownloadLink: function () {
      let panel = this.panel;
      this.recorder.exportMP3(function (blob, mp3Name, context) {
        let file = new File([blob], mp3Name, {
          type: 'audio/mpeg3'
        });
        if (context.routeName) {
          //If context ISN'T a controller...
          context = context.get('controller');
        }
        panel.attributes.class.value = 'audio-rec-panel active stopped';
        context.send('addValidFile', file);
        context.send('toggleAudioRecPanel');
      }, this);
    },
    actions: {
      toggleAudioRecPanel: function (close) {
        let panel;
        this.set('panel', document.querySelector('.floating-modal.active .audio-rec-panel'));
        this.set('timeCode', document.querySelector('.floating-modal.active .audio-rec-panel .time-code'));
        panel = this.panel;
        if (!!close || panel.classList.contains('active') || this.noRec) {
          panel.attributes.class.value = 'audio-rec-panel standby';
          return false;
        }
        if (panel.classList.contains('active') || !!close && !!this.currStream) {
          this.recorder.destroy();
          this.set('recorder', false);
        } else {
          panel.attributes.class.value = 'audio-rec-panel standby active';
          this.send('toggleCameraPanel', true);
          try {
            // WebKit nonsense
            window.AudioContext = window.AudioContext || window.webkitAudioContext;
            window.URL = window.URL || window.webkitURL;
            Ember.debug('Audio context set up.');
            Ember.debug('navigator.getUserMedia ' + (navigator.getUserMedia ? 'available.' : 'not present!'));
          } catch (e) {
            Ember.debug('No web audio support in this browser!');
          }
        }
      },
      audioRecRecord: function () {
        let constraints = {
          audio: true
        };
        if (!(localStorage.getItem('bocceEchoCancellation') === 'true')) {
          constraints.audio = {
            echoCancellation: false,
            mozAutoGainControl: false,
            mozNoiseSuppression: false,
            googEchoCancellation: false,
            googAutoGainControl: false,
            googNoiseSuppression: false,
            googHighpassFilter: false
          };
        }
        navigator.mediaDevices.getUserMedia(constraints).then(stream => {
          this.startUserMedia(stream);
          let timeCode = this.timeCode,
            rec = this.recorder.record(function (time) {
              timeCode.setAttribute('time_code', time);
            });
          if (rec) {
            this.panel.attributes.class.value = 'audio-rec-panel active recording';
          }
        }).catch(error => {
          Ember.debug('No live audio input: ' + e);
          this.panel.attributes.class.value = 'audio-rec-panel permission';
        });
      },
      audioRecStop: function () {
        let rec = this.recorder.stop();
        if (rec) {
          // create MP3 download link using audio data blob
          this.createDownloadLink();
          this.panel.attributes.class.value = 'audio-rec-panel active encoding';
        }
      },
      echoCancellationToggleAudio: function () {
        let echoCancellation = localStorage.getItem('bocceEchoCancellation') === 'true';
        if (echoCancellation) {
          localStorage.setItem('bocceEchoCancellation', false);
          this.set('echoCancellationEnabled', false);
        } else {
          localStorage.setItem('bocceEchoCancellation', true);
          this.set('echoCancellationEnabled', true);
        }
      }
    }
  });
});