define("bocce/templates/components/quiz-admin/delete-latest-attempt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "VF5cJ6mD",
    "block": "{\"symbols\":[],\"statements\":[[8,\"quiz-admin/operation\",[],[[\"@copy\",\"@noConfig\",\"@operationOnAction\"],[\"Delete all quiz attempts\",true,[32,0,[\"onAction\"]]]],null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "bocce/templates/components/quiz-admin/delete-latest-attempt.hbs"
    }
  });
});