define("bocce/components/modals/attachments/attachments-upload-file", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ModalsAttachmentsAttachmentsUploadFileComponent = _exports.default = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, (_class = class ModalsAttachmentsAttachmentsUploadFileComponent extends _component.default {
    constructor(owner, args) {
      super(owner, args);
      _initializerDefineProperty(this, "uploadedFiles", _descriptor, this);
      _initializerDefineProperty(this, "hasUploaded", _descriptor2, this);
      this.uploadedFiles = [];
      this.hasUploaded = false;
    }
    uploadFile(event) {
      this.hasUploaded = true;
      this.uploadedFiles = [...this.uploadedFiles, ...event.target.files];
    }
    uploadMoreFiles() {
      this.hasUploaded = false;
    }
    attachToPost() {
      this.uploadedFiles.forEach(file => {
        this.args.addFile(file);
      });
      this.uploadedFiles = [];
      this.hasUploaded = false;
      this.args.closeModal();
    }
    willDestroy() {
      super.willDestroy();
      this.uploadedFiles = [];
      this.hasUploaded = false;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "uploadedFiles", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "hasUploaded", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "uploadFile", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "uploadFile"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "uploadMoreFiles", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "uploadMoreFiles"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "attachToPost", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "attachToPost"), _class.prototype)), _class));
});