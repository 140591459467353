define("bocce/mixins/interactions/javascript", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* jshint unused: false */

  function _createForOfIteratorHelper(o, allowArrayLike) {
    var it;
    if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) {
      if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") {
        if (it) o = it;
        var i = 0;
        var F = function F() {};
        return {
          s: F,
          n: function n() {
            if (i >= o.length) return {
              done: true
            };
            return {
              done: false,
              value: o[i++]
            };
          },
          e: function e(_e) {
            throw _e;
          },
          f: F
        };
      }
      throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
    }
    var normalCompletion = true,
      didErr = false,
      err;
    return {
      s: function s() {
        it = o[Symbol.iterator]();
      },
      n: function n() {
        var step = it.next();
        normalCompletion = step.done;
        return step;
      },
      e: function e(_e2) {
        didErr = true;
        err = _e2;
      },
      f: function f() {
        try {
          if (!normalCompletion && it.return != null) it.return();
        } finally {
          if (didErr) throw err;
        }
      }
    };
  }
  function _unsupportedIterableToArray(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _arrayLikeToArray(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(o);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
  }
  function _arrayLikeToArray(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for (var i = 0, arr2 = new Array(len); i < len; i++) {
      arr2[i] = arr[i];
    }
    return arr2;
  }
  function _typeof(obj) {
    "@babel/helpers - typeof";

    if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
      _typeof = function _typeof(obj) {
        return typeof obj;
      };
    } else {
      _typeof = function _typeof(obj) {
        return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
      };
    }
    return _typeof(obj);
  }

  /* jshint unused: false */
  function Javascript($el, data) {
    this.$el = $el;
    this.data = data.config;
    this.data.runnable = !!parseInt(this.data.runnable, 10);
    this.data.line_numbers = !!parseInt(this.data.line_numbers, 10);
    this.data.editable = !!parseInt(this.data.editable, 10);
    this.content = this.$el.find('.interaction_content');
    this.height = this.content.css('min-height');
    this.content.css('width', '').css('min-height', '');
  }
  Javascript.prototype = {
    format_code: function format_code() {
      var retval = this.data.code;
      if (this.data.line_numbers && !this.data.editable) {
        var parts = this.data.code.split('\n'),
          lines = parts.length,
          digits = ('' + lines).length;
        retval = [];
        for (var i = 0; i < parts.length; i++) {
          var part = parts[i],
            num = i + 1,
            nd = ('' + num).length;
          if (nd < digits) {
            for (; nd < digits; nd++) {
              num = '0' + num;
            }
          }
          retval.push(num + '| ' + part);
        }
        retval = retval.join('\n');
      }
      return retval.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
    },
    init: function init() {
      var html = '<div class="code_runner"><div class="language">' + this.data.language + '</div>';
      if (this.data.sandbox) {
        html += "<div class=\"sandbox\">".concat(this.data.sandbox, "</div>");
      }
      if (this.data.editable) {
        html += '<div><label><textarea tabindex="25" class="code" wrap="hard" style="width: 95%; height: ' + this.height + ';">' + this.format_code() + '</textarea></label></div>';
      } else {
        html += '<div class="code">' + this.format_code() + '</div>';
      }
      if (this.data.runnable) {
        html += '<button class="run_code" tabindex="25">Run!</button>' + '<div class="output" tabindex="25"></div>';
      }
      html += '</div>';
      this.content.html(html);
      this.run_button = this.content.find('button.run_code');
      this.output_area = this.content.find('div.output');
      this.code_area = this.content.find('textarea');
      this.sandbox = this.content.find('.sandbox');
      this.run_button.click(this.run.bind(this));
    },
    run: function run() {
      var code,
        that = this;
      if (this.data.editable) {
        code = this.code_area.val();
      } else {
        code = this.data.code;
      }
      code = code.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&');
      var funcs = code.match(/function ([a-z0-9_]+)[^a-z0-9_]/ig);
      if (funcs && funcs.length > 0) {
        funcs = funcs.map(function (fn) {
          return fn.match(/function ([a-z0-9_]+)[^a-z0-9_]/i)[1];
        });
        funcs.forEach(function (fn) {
          code = code.replace('function ' + fn, 'function __orig_' + fn);
          code = 'function ' + fn + '() {guard(\'' + fn + '\'); return __orig_' + fn + '.apply(null, arguments);}' + code;
        });
      }
      var guard_counts = {},
        guard; //eslint-disable-line no-unused-vars
      // eslint-disable-next-line no-unused-vars

      guard = function guard(fn) {
        if (!guard_counts[fn]) {
          guard_counts[fn] = 0;
        }
        guard_counts[fn]++;
        if (guard_counts[fn] >= 100) {
          throw 'Recursion safety net: ' + fn + ' called more than 100 times';
        }
      };
      var print = function print(val) {
        if (val instanceof Set) {
          return 'Set: ' + Array.from(val).join(', ');
        }
        if (val instanceof Map) {
          return 'Map: ' + Array.from(val).map(function (x) {
            return x.join(' => ');
          }).join(', ');
        }
        if (Array.isArray(val)) {
          return '[' + val.map(print).join(', ') + ']';
        }
        if (_typeof(val) === 'object' && Object.keys(val).length > 0) {
          var retval = 'Object';
          if (_typeof(val) !== 'object') {
            retval += ' ' + _typeof(val);
          }
          return retval + ' { ' + Object.keys(val).map(function (k) {
            return k + ' => ' + print(val[k]);
          }).join(', ') + ' }';
        }
        return val.toString();
      };
      this.output_area.html('');
      try {
        // Run it in a closure to avoid polluting the global space
        (function (code) {
          var window = {},
            //eslint-disable-line no-unused-vars
            $output_area = that.output_area,
            //eslint-disable-line no-unused-vars
            $sandbox = that.sandbox,
            //eslint-disable-line no-unused-vars
            sandbox = $sandbox[0]; //eslint-disable-line no-unused-vars

          var console = {
            //eslint-disable-line no-unused-vars
            log: function log() {
              var _iterator = _createForOfIteratorHelper(arguments),
                _step;
              try {
                for (_iterator.s(); !(_step = _iterator.n()).done;) {
                  var val = _step.value;
                  that.output_area[0].innerHTML += print(val) + '\n';
                }
              } catch (err) {
                _iterator.e(err);
              } finally {
                _iterator.f();
              }
            }
          };
          eval(code);
        })(code);
      } catch (err) {
        this.output_area[0].innerHTML += err.toString() + '\n';
      }
    }
  };
  var _default = _exports.default = Javascript;
});