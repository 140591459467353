define("bocce/mixins/showcase", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function getShowcaseContents(discId) {
    let showcaseContents = '';
    // if this is a discussion, get the discussion reply content.
    // Otherwise, it's an assignment, so get the assignment content.
    // NK todo: it would be great if we could do this in a more Ember-y
    // way instead of using jQuery.
    if (discId) {
      /* eslint-disable-next-line ember/no-jquery */
      showcaseContents += Ember.$(`#discussion-response-${discId} .discussion-info`).html();
      /* eslint-disable-next-line ember/no-jquery */
      showcaseContents += Ember.$(`#discussion-response-${discId} .discussion-reply`).html();
    } else {
      // get the html content from each child of the submission body
      // EXCEPT for the (hidden) late-assignment-marker.
      /* eslint-disable-next-line ember/no-jquery */
      Ember.$('.submission-body > *').not('.late-assignment-marker').each(function () {
        showcaseContents += this.outerHTML;
      });
    }

    // Strip out content that we don't want included in the showcase page
    /* eslint-disable-next-line ember/no-jquery */
    let contents_node = Ember.$(`<div>${showcaseContents}</div>`);
    contents_node.find('.submission-grade').remove();
    contents_node.find('.audio-asset').remove();
    showcaseContents = contents_node.html();

    // remove user icons because we REALLY don't want to share those:
    showcaseContents = showcaseContents.replace(/fa-(universal-access|graduation-cap)/, '');
    return showcaseContents;
  }

  /* eslint-disable-next-line ember/no-new-mixins */
  var _default = _exports.default = Ember.Mixin.create({
    readyToShowcase: Ember.computed('showcaseComment', 'showcaseWeek', 'showcaseTitle', function () {
      var ret = this.showcaseComment,
        til = this.showcaseTitle,
        sw = this.showcaseWeek;
      return !ret || ret === '' || !til || til === '' || !sw || sw === '' ? false : true;
    }),
    showcaseTitle: Ember.computed('showcaseOpen', function () {
      /* eslint-disable-next-line ember/no-jquery */
      var title = Ember.$('.floating-modal .modal-title').text().trim();

      /* eslint-disable-next-line ember/no-jquery */
      title = title === '' ? Ember.$('.floating-modal .discussion-title').text().trim() : title;

      // get the student's name and submission info from the modal author
      // name div. Trim whitespace and remove inner newlines/strings of
      // spaces
      /* eslint-disable-next-line ember/no-jquery */
      return title + ' - Posted By ' + Ember.$('.floating-modal .author-name:first')
      /* eslint-disable-next-line ember/no-jquery */.text().trim().replace(Ember.$('.floating-modal .author-name > *').text().trim(), '').replace(/([\n\r\t]+| {2,})/g, '');
    }),
    actions: {
      toggleShowcase: function (id) {
        var showcaseId = this.showcaseOpen;
        if (showcaseId) {
          if (showcaseId === id) {
            this.set('showcaseOpen', false);
          } else {
            this.set('showcaseOpen', id);
          }
        } else {
          this.set('showcaseOpen', id);
        }
        this.set('showcaseComment', '');
        this.send('changeShowcaseWeek', '');
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.showcase-wrapper').removeClass('current');
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('#discussion-response-' + id + ' .showcase-wrapper').addClass('current');

        // scroll the showcase item into view
        if (this.showcaseOpen) {
          /* eslint-disable-next-line ember/no-incorrect-calls-with-inline-anonymous-functions */
          Ember.run.scheduleOnce('afterRender', this, () => {
            let showcaseBody = document.getElementsByClassName('showcase-body').item(0);
            if (showcaseBody) {
              showcaseBody.scrollIntoView({
                behavior: 'smooth'
              });
            }
          });
        }
      },
      changeShowcaseWeek: function (value) {
        this.set('showcaseWeek', value);
      },
      postShowcase: function (discId, showcId) {
        var showcaseComment = this.showcaseComment,
          showcaseTitle = this.showcaseTitle,
          showcaseContents = getShowcaseContents(discId),
          showcaseWeek = this.showcaseWeek,
          that = this,
          str;

        /* eslint-disable-next-line ember/no-get */
        showcaseContents = '<div class="showcase-intro">' + showcaseComment + '<br><br>-' + this.get('user.name') + '</div><hr>' + showcaseContents;

        /* eslint-disable-next-line ember/no-jquery */
        showcId = showcId || Ember.$('.floating-modal.active').attr('mid');

        /* eslint-disable-next-line ember/no-jquery */
        showcaseContents = showcaseContents ? showcaseContents : Ember.$('.discussion-starter').html();
        str = {
          title: showcaseTitle,
          body: showcaseContents,
          /* eslint-disable-next-line ember/no-get */
          section_id: this.get('section.id'),
          lesson_number: showcaseWeek,
          showcase_type: discId ? 'discussion' : 'assignment',
          showcase_id: showcId
        };
        str = JSON.stringify(str);

        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('body').addClass('please-wait');

        /* eslint-disable-next-line ember/no-jquery */
        Ember.$.ajax({
          context: this,
          type: 'post',
          url: '/interface/showcase/add',
          data: str,
          success: function (ret) {
            var uri = '/#/' + ret.course + '/' + ret.course + '/' + ret.section + '/' + ret.lesson + '/' + ret.url,
              win;
            Ember.debug('Showcase Created');
            /* eslint-disable-next-line ember/no-jquery */
            Ember.$('body').removeClass('please-wait');
            that.send('toggleShowcase', discId);
            let sec = this.section;
            sec.set('showcase_course_id', ret.course);
            sec.set('showcase_section_id', ret.section);
            win = window.open(uri, '_blank');
            if (win) {
              //Browser has allowed it to be opened
              win.focus();
            } else {
              //Browser has blocked it
              alert('Please allow popups for this website.');
            }
          },
          error: function () {
            /* eslint-disable-next-line ember/no-jquery */
            Ember.$('body').removeClass('please-wait');
            Ember.debug('Failed to create showcase');
          }
        });
      }
    }
  });
});