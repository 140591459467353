define("bocce/helpers/total", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // app/helpers/total.js
  // Return total number of elements in array
  var _default = _exports.default = Ember.Helper.helper(function (item) {
    if (!item || !item[0] || !item[0].get) {
      return false;
    }
    return item[0].get('length');
  });
});