define("bocce/mixins/interactions/video_mixer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function VideoMixer($el) {
    this.$el = $el;
    this.$audio = this.$el.find('audio');
    this.$stage = this.$el.find('.interaction_content');
    this.$players = this.initJPlayers();

    //The audio is not supposed to preload by default
    //This is the only case where it should
    this.$audio.each(function () {
      this.preload = 'auto';
      this.load();
    });

    /* eslint-disable-next-line ember/no-jquery */
    this.$el.bind('playersloaded', Ember.$.proxy(this.init, this));
  }
  VideoMixer.prototype = {
    init: function () {
      this.addTemplate();
      this.addMixer();
      this.$el.find('.loading').fadeOut();
      this.$el.find('.VideoMixer').attr('style', '');
      this.$el.find('.VideoMixer video').attr('preload', 'auto');
    },
    addTemplate: function () {
      var template = '<div class="mixer"><div class="faders"></div></div>';
      this.$stage.append(template);
    },
    addMixer: function () {
      var faderTemplate = '<div class="fader_container"><div class="fader"></div><div class="fader_volume"></div><div class="fader_label"></div></div>';

      /* eslint-disable-next-line ember/no-jquery */
      this.$players.each(Ember.$.proxy(function (i, player) {
        var data, $faders, $faderEl, $volume, plAudio;
        data = this.getDataForFader(i);
        $faders = this.$stage.find('.faders');
        /* eslint-disable-next-line ember/no-jquery */
        $faderEl = Ember.$(faderTemplate).appendTo($faders);
        $volume = $faderEl.find('.fader_volume').text(data.volume + '%');
        $faderEl.find('.fader_label').text(data.label);
        $faderEl.find('.fader').slider({
          orientation: 'vertical',
          min: 0,
          max: 100,
          value: data.volume || 100,
          slide: function (e, ui) {
            var plAudio;
            $volume.text(ui.value + '%');
            /* eslint-disable-next-line ember/no-jquery */
            plAudio = Ember.$(player).closest('audio');
            if (plAudio.length > 0) {
              plAudio[0].volume = ui.value / 100;
            } else {
              /* eslint-disable-next-line ember/no-jquery */
              plAudio = Ember.$(player);
              plAudio[0].volume = ui.value / 100;
            }
          }
        });

        // set initial player volume
        /* eslint-disable-next-line ember/no-jquery */
        plAudio = Ember.$(player).closest('audio');
        if (plAudio.length > 0) {
          plAudio.volume = 0.5;
        } else {
          /* eslint-disable-next-line ember/no-jquery */
          plAudio = Ember.$(player);
          plAudio[0].volume = 0.5;
        }
      }, this));
    },
    // return data from the interaction's data table (in editor)
    getDataForFader: function (num) {
      var $table, $row, $cells;
      $table = this.$el.find('table');
      // row is num+1 to account for table header row
      /* eslint-disable-next-line ember/no-jquery */
      $row = Ember.$($table.find('tr')[num + 1]);
      $cells = $row.find('td');
      return {
        /* eslint-disable-next-line ember/no-jquery */
        label: Ember.$($cells[1]).text(),
        /* eslint-disable-next-line ember/no-jquery */
        volume: parseInt(Ember.$($cells[2]).text())
      };
    },
    initJPlayers: function () {
      var $videoComponent, $audioComponents, $players;
      /**
      * The interaction data area should contain one video jplayer and multiple audio jplayers.
      *
      * Using jplayer selector and working up to closest .component to grab video and audio.
      * Current '.quicktime-component' and '.flash-component' classes don't really describe the
      * content and could change at some point in the future.
      **/
      $videoComponent = this.$el.find('video');
      $audioComponents = this.$el.find('audio');

      /* eslint-disable-next-line ember/no-jquery */
      var $videoContainer = Ember.$('<div class="video-mixer-container"></div>');
      $videoContainer.append($videoComponent);
      this.$stage.prepend($videoContainer, $audioComponents);

      /* eslint-disable-next-line ember/no-jquery */
      Ember.$('<div>').text('Loading...').addClass('loading').appendTo(this.$stage);

      // move audio components off screen
      $audioComponents.hide();

      /* eslint-disable-next-line ember/no-jquery */
      $players = Ember.$($videoComponent).add($audioComponents);

      /* eslint-disable-next-line ember/no-jquery */
      $videoComponent.bind('canplay', Ember.$.proxy(this.onJPlayerReady, this));
      return $players;
    },
    onJPlayerReady: function (e) {
      // unbind default event assigned by cms to disable 'pauseOthers'
      /* eslint-disable-next-line ember/no-jquery */
      Ember.$(e.target).bind('playing', Ember.$.proxy(this.playAll, this))
      /* eslint-disable-next-line ember/no-jquery */.bind('pause', Ember.$.proxy(this.pauseAll, this))
      /* eslint-disable-next-line ember/no-jquery */.bind('seeked', Ember.$.proxy(this.onSeek, this))
      /* eslint-disable-next-line ember/no-jquery */.bind('loadstart', Ember.$.proxy(this.onJPlayerLoadProgress, this));
    },
    onJPlayerLoadProgress: function () {
      //As soon as player is ready, open up the controls
      this.$el.trigger('playersloaded');
    },
    playAll: function () {
      this.$audio.each(function () {
        this.play();
      });
    },
    pauseAll: function () {
      this.$audio.each(function () {
        this.pause();
      });
    },
    stopAll: function () {
      this.$audio.each(function () {
        this.pause();
        this.currentTime = 0;
      });
    },
    onSeek: function (e) {
      var curTime = e.target.currentTime;
      this.$audio.each(function () {
        this.currentTime = curTime;
      });
    }
  };
  var _default = _exports.default = VideoMixer;
});