define("bocce/helpers/morethan", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.morethan = morethan;
  // app/helpers/morethan.js
  // Check if first provided number is more than the second
  // Example: If number of assignments is more than 0
  // {{#if (morethan assignments.length '0')}}
  function morethan(inp) {
    var first = inp[0],
      second = inp[1];
    if (!first || !second) {
      return false;
    }
    return Number(first) > Number(second);
  }
  var _default = _exports.default = Ember.Helper.helper(morethan);
});