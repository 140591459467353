define("bocce/components/av-player", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let AvPlayerComponent = _exports.default = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, (_class = class AvPlayerComponent extends _component.default {
    constructor(owner, args) {
      super(owner, args);
      _initializerDefineProperty(this, "currentTime", _descriptor, this);
      _initializerDefineProperty(this, "duration", _descriptor2, this);
      _initializerDefineProperty(this, "playing", _descriptor3, this);
      _initializerDefineProperty(this, "thumbPosition", _descriptor4, this);
      _initializerDefineProperty(this, "muted", _descriptor5, this);
      _initializerDefineProperty(this, "playerElement", _descriptor6, this);
      this.currentTime = 0;
      this.duration = 0;
      this.playing = false;
      this.thumbPosition = 0;
      this.muted = false;
    }
    get currentTimeFormatted() {
      return this.formatTime(this.currentTime);
    }
    get durationFormatted() {
      return this.formatTime(this.duration);
    }
    get linearGradient() {
      let thumbPercent = this.thumbPosition / 10;
      // a gap appears between the slider thumb and background that is biggest at 0% and 100% and not visible at 50%,
      // so adjust the background to fill this gap between 0% and 2% depending on the thumb's proximity to the middle
      let percentDifference = -1 / 25 * thumbPercent + 2;
      let finalPercent = thumbPercent + percentDifference;
      return `linear-gradient(to right, var(--color-base-05) 0%, var(--color-base-05) ${finalPercent}%, var(--color-base-50) ${finalPercent}%, var(--color-base-50) 100%`;
    }

    /**
     * Formats the given time in seconds to a string in a mm:ss format
     * @param timeInSeconds the time to be formatted in seconds
     * @returns {string}
     */
    formatTime(timeInSeconds) {
      return `${Math.floor(timeInSeconds / 60)}:${Math.floor(timeInSeconds % 60).toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
      })}`;
    }
    setupPlayer(element) {
      this.playerElement = element;
    }
    stopPlaying() {
      this.playing = false;
      this.playerElement.pause();
    }
    togglePlaying() {
      this.playing = !this.playing;
      if (!this.playing) {
        this.playerElement.pause();
      } else {
        this.playerElement.play();
      }
    }
    toggleMuted() {
      this.muted = !this.muted;
    }
    seek(event, time) {
      if (time) {
        this.currentTime = this.currentTime + time;
      } else {
        this.currentTime = event.target.value * this.duration / 1000;
      }
      this.playerElement.currentTime = this.currentTime;
    }
    updateTime(event) {
      this.currentTime = event.target.currentTime;
      if (this.duration == 0) {
        return;
      }
      this.thumbPosition = this.currentTime / this.duration * 1000;
    }
    updateDuration(event) {
      this.duration = event.target.duration;
    }
    changeThumbPosition(event) {
      this.thumbPosition = event.target.value;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentTime", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "duration", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "playing", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "thumbPosition", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "muted", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "playerElement", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setupPlayer", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "setupPlayer"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "stopPlaying", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "stopPlaying"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "togglePlaying", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "togglePlaying"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleMuted", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "toggleMuted"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "seek", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "seek"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateTime", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "updateTime"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateDuration", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "updateDuration"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeThumbPosition", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "changeThumbPosition"), _class.prototype)), _class));
});