define("bocce/mixins/interactions/hot_spot_quiz", ["exports", "bocce/mixins/support/util", "bocce/mixins/interactions/abstract_quiz"], function (_exports, util, _abstract_quiz) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function HotSpotQuiz($el, data) {
    if (typeof data.initial_json == "string") {
      data.initial_json = JSON.parse(data.initial_json || '[]');
    }
    data = convertToJSON($el[0], data);
    for (let i = 0; i < data.initial_json.length; i++) {
      const initialJsonObj = data.initial_json[i];
      initialJsonObj.hotSpots = [];
      for (let i = 0; i < initialJsonObj.hotSpotsAccessibility.length; i++) {
        const hotspot = initialJsonObj.hotSpotsAccessibility[i];
        if (hotspot.dummyBlankElement) {} else {
          initialJsonObj.hotSpots.push(hotspot);
        }
      }
    }
    util.mixin(this, new _abstract_quiz.default($el, data, 'hot_spot_quiz'));

    // add methods from overrides to overwrite abstract methods
    util.mixin(this, this.overrides);

    // bind 'this' to all methods
    util.bindAll(this);

    // listeners for method extension
    this.el.bind(this.SHOW_QUESTION, this.onShowQuestion);
    this.el.bind(this.CORRECT, this.onAnswerCorrect);
    this.el.bind(this.INCORRECT, this.onAnswerIncorrect);
    this.el.bind(this.QUIZ_COMPLETE, this.onQuizComplete);
  }
  function convertToJSON(element, data) {
    data.initial_json.forEach(question => {
      question.hotSpotsAccessibility = question.hotSpots;
    });
    return data;
  }
  HotSpotQuiz.prototype = {
    init: function () {
      this.images = this.data.assets.images;
      return this.showQuestion();
    },
    checkClick: function (e) {
      e.preventDefault();

      /* eslint-disable-next-line ember/no-jquery */
      var ofs = Ember.$(e.currentTarget).offset();
      var clickX = e.pageX - ofs.left;
      var clickY = e.pageY - ofs.top;

      // remove any existing incorrect hot spots
      this.container.find('.incorrect-hot-spot').remove();
      // merge all hotspots into one object
      var hotSpots = this.currentQuestion.hotSpots;
      // tack on incorrectHotSpots, if they exist
      hotSpots = hotSpots.concat(this.currentQuestion.incorrectHotSpots || []);

      // see if click is within a defined rect
      var that = this;
      var correct = util.any(hotSpots, function (hotSpot) {
        // first check X
        if (clickX >= hotSpot.left && clickX <= hotSpot.left + hotSpot.width) {
          // then check Y
          if (clickY >= hotSpot.top && clickY <= hotSpot.top + hotSpot.height) {
            that.revealHotSpot(hotSpot);
            return true;
          }
        }
      });
      if (!correct) {
        this.answerIncorrect();
      }
      return false;
    },
    revealHotSpot: function (selectedHotSpot) {
      var hotSpots;
      //this.el.find('.feedback').hide()

      // only incorrect hot spots have feedback
      if (selectedHotSpot.feedback) {
        this.createRect(selectedHotSpot, 'incorrect-hot-spot');
        this.showFeedback(unescape(selectedHotSpot.feedback));
      } else {
        hotSpots = this.currentQuestion.hotSpots;
        this.createRect(selectedHotSpot, 'hot-spot');
        // toss out the selected rectangle
        hotSpots.splice(hotSpots.indexOf(selectedHotSpot), 1);
        this.answerCorrect(hotSpots.length);
      }
    },
    createRect: function (rect, hotSpotClass) {
      /* eslint-disable-next-line ember/no-jquery */
      var el = Ember.$('<div>').addClass(hotSpotClass);
      el.width(rect.width);
      el.height(rect.height);
      el.css('top', rect.top);
      el.css('left', rect.left);
      this.container.find('.image-area').append(el);
    },
    data: {},
    onAnswerCorrect: function (e, opts) {
      var numRemaining = opts.numRemaining;
      if (numRemaining > 0) {
        this.showFeedback('<span class="correct">Correct!</span> ' + numRemaining + ' ' + this.pluralize('answer', numRemaining) + ' remaining.');
      } else {
        this.el.find('.hot-spot-focus').attr("tabindex", "-1");
        this.allAnswersCorrect();
        this.container.find('.image-area').unbind('click');
      }
    },
    pluralize: function (singularStr, num) {
      if (num !== 1) {
        singularStr = singularStr + 's';
      }
      return singularStr;
    },
    onAnswerIncorrect: function () {
      this.showFeedback('<span class="incorrect">Incorrect</span>, try again!', true);
    },
    allAnswersCorrect: function () {
      var continueBtn = this.el.find('.continue-btn');
      continueBtn.click(this.nextQuestion);

      // call to super to retrieve question
      var question = this.getCurrentQuestion();
      var feedback = unescape(question.feedback);
      // convert json string undefined to actual js undefined
      if (feedback === 'undefined') {
        feedback = undefined;
      }
      this.showFeedback(feedback ? feedback : 'Nice job!');
      continueBtn.removeClass('hidden');
    },
    onShowQuestion: function () {
      // set the image path since we can't include paths in handlebars templates
      var imageArea = this.container.find('.image-area:first');
      var image = imageArea.find('img')[0];
      var currQueObj = this.getCurrentQuestion();
      image.src = this.images[currQueObj.imageIndex];
      var imageCss = currQueObj.imageCss;
      if (imageCss) {
        for (const style in imageCss) {
          Ember.$(image).css(style, imageCss[style]);
        }
      }
      // update question count
      var $count = this.el.find('.question-count');
      $count.text(this.currentIndex + 1 + ' of ' + this.json.length);
      this.container.find(".audio_player").off("click").on("click", function () {
        var that = this;
        var audio_player = Ember.$(this).find("audio").get(0);
        if (audio_player.paused) {
          audio_player.play();
          Ember.$(this).addClass("fa-pause");
          Ember.$(this).removeClass("fa-play");
        } else {
          audio_player.pause();
          Ember.$(this).addClass("fa-play");
          Ember.$(this).removeClass("fa-pause");
        }
        Ember.$(this).find("audio").on('ended', function () {
          Ember.$(that).addClass("fa-play");
          Ember.$(that).removeClass("fa-pause");
        });
        return false;
      });
      imageArea.click(this.checkClick);
    },
    showFeedback: function (message, shouldFade) {
      var feedBack = this.el.find('.feedback');
      feedBack.attr("tabindex", this.el.find(".question").attr("tabindex"));
      feedBack.show();
      feedBack.stop(true).css('opacity', '1').html(message);
      feedBack.focus();
      if (shouldFade) {
        feedBack.fadeOut(2000);
      }
    },
    onQuizComplete: function () {
      var replay = this.el.find('.try-again-btn');
      replay.removeClass('hidden');
      replay.click(this.resetQuiz);
    },
    overrides: {}
  };
  var _default = _exports.default = HotSpotQuiz;
});