define("bocce/components/drag-and-drop", ["exports", "bocce/mixins/editable", "bocce/mixins/audio-rec", "bocce/mixins/video-rec", "bocce/mixins/rtc-rec", "bocce/mixins/uploadable", "bocce/mixins/discussable", "bocce/mixins/enmodal", "bocce/mixins/kaltura-upload", "bocce/mixins/legacy-attachment-manager", "bocce/utilities/dialog"], function (_exports, _editable, _audioRec, _videoRec, _rtcRec, _uploadable, _discussable, _enmodal, _kalturaUpload, _legacyAttachmentManager, _dialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  var _default = _exports.default = Ember.Component.extend(_legacyAttachmentManager.default, _editable.default, _audioRec.default, _videoRec.default, _rtcRec.default, _uploadable.default, _discussable.default, _enmodal.default, _kalturaUpload.default, {
    userprofileService: Ember.inject.service('userprofile'),
    didInsertElement: function () {
      this.boundAppropriateAttachment = this.appropriateAttachment.bind(this);
    },
    willDestroyElement: function () {
      Ember.$('.discussionAttachment').off('change', this.boundAppropriateAttachment);
    },
    //The attachments are included as a partial. This partial is removed from the dom when editing a post. So, we
    //have to re-add the event handler upon each render to ensure that the event gets added back to the element
    //when the attachments partial is shown again (i.e: didRender gets triggered.)
    didRender() {
      /* eslint-disable-next-line ember/no-jquery */
      Ember.$('.discussionAttachment').off('change', this.boundAppropriateAttachment);
      /* eslint-disable-next-line ember/no-jquery */
      Ember.$('.discussionAttachment').on('change', this.boundAppropriateAttachment);
    },
    appropriateAttachment: function (e) {
      var files = e.target.files,
        len = files.length,
        controller = this.controller,
        mimeTypes = controller.mimeTypes,
        i;
      if (mimeTypes) {
        controller.set('processInProgress', true);
        for (i = 0; i < len; i++) {
          if (files[i].type.indexOf('video') > -1) {
            let encoding_video = {
              name: files[i].name,
              percent_uploaded: 0
            };
            this.encoding_videos.pushObject(encoding_video);
            let fileIndex = this.encoding_videos.length - 1;
            this.kalturaUploadVideo(files[i], async () => {
              await (0, _dialog.default)('Video embedding unsuccessful; this file will be available for download only.', ['OK']);
              this.encoding_videos.removeObject(encoding_video);
              files[i].ignoreDownloadOnlyPrompt = true;
              controller.send('addValidFile', files[i]);
            }, fileIndex);
          } else if (mimeTypes.indexOf(files[i].type) === -1) {
            controller.send('addValidFile', files[i]);
          } else {
            controller.send('addInvalidFile', files[i]);
          }
        }
      }

      // Purge file input
      e.target.value = '';
    }
  });
});