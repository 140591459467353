define("bocce/components/lessons/advice-card-viewer", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let AdviceCardViewer = _exports.default = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, (_class = class AdviceCardViewer extends _component.default {
    constructor(owner, args) {
      super(owner, args);
      _initializerDefineProperty(this, "adviceCards", _descriptor, this);
      _initializerDefineProperty(this, "currentIndex", _descriptor2, this);
      _initializerDefineProperty(this, "currentLikes", _descriptor3, this);
      _initializerDefineProperty(this, "currentCardLiked", _descriptor4, this);
      _initializerDefineProperty(this, "currentCardHidden", _descriptor5, this);
      let header = document.querySelector('[aria-labelledby="advice-cards-header"]') || document.querySelector('[aria-labelledby="advice-cards-intro-header"]') || document.querySelector('[aria-labelledby="advice-cards-lesson-1-header"]');
      try {
        header.style.background = '#F4F6F8';
        document.querySelector('[topic_title="Peer to Peer Support: Advice Cards"]').style.background = '#F4F6F8';
      } catch (e) {
        console.log(e);
      }
      let course_id = window.session.get('course.course_id');
      fetch(`/interface/advice_cards/${course_id}`, {
        method: 'GET'
      }).then(response => {
        // Make sure to check if the response is OK before proceeding
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        // Use the .json() method to parse the response body as JSON
        return response.json();
      }).then(data => {
        // 'data' is the JSON object that was parsed from the response body
        this.adviceCards = Ember.A(data);
      }).catch(error => {
        // Handle any errors that occurred during the fetch
        console.error('There was a problem with your fetch operation:', error);
      });
    }
    get currentCard() {
      if (this.adviceCards?.length) {
        this.currentLikes = this.adviceCards[this.currentIndex].num_likes;
        this.currentCardLiked = this.adviceCards[this.currentIndex].liked;
        this.currentCardHidden = this.adviceCards[this.currentIndex].is_hidden;
        return this.adviceCards[this.currentIndex];
      }
      return false;
    }
    async toggleHide() {
      let requestType = this.currentCard.is_hidden ? 'unhide' : 'hide';
      let response = await fetch(`/interface/advice_cards/${window.session.course.get('course_id')}/${this.currentCard.id}/${requestType}`, {
        method: "PATCH"
      });
      if (!response.ok) {
        throw response.status;
      }
      this.currentCard.is_hidden = !this.currentCard.is_hidden;
      this.currentCardHidden = !this.currentCardHidden;
      return response.json();
    }
    async toggleLike() {
      let response;
      if (this.currentCard.liked) {
        response = await fetch(`/interface/advice_cards/${window.session.course.get('course_id')}/${this.currentCard.id}/unlike`, {
          method: "DELETE"
        });
      } else {
        response = await fetch(`/interface/advice_cards/${window.session.course.get('course_id')}/${this.currentCard.id}/like`, {
          method: "PUT"
        });
      }
      if (!response.ok) {
        throw response.status;
      }
      if (this.currentCard.liked) {
        this.currentLikes--;
        this.currentCard.num_likes--;
      } else {
        this.currentLikes++;
        this.currentCard.num_likes++;
      }
      this.currentCard.liked = !this.currentCard.liked;
      this.currentCardLiked = !this.currentCardLiked;
      return response.json();
    }
    nextSlide() {
      this.currentIndex++;
      if (this.currentIndex >= this.adviceCards.length) {
        this.currentIndex = 0;
      }
      this.logInteraction();
    }
    prevSlide() {
      this.currentIndex--;
      if (this.currentIndex < 0) {
        this.currentIndex = this.adviceCards.length - 1;
      }
      this.logInteraction();
    }
    async logInteraction() {
      fetch(`/interface/advice_cards/${window.session.course.get('course_id')}/interaction`, {
        method: 'PUT'
      }).catch(error => {
        // Handle any errors that occurred during the fetch
        console.error('There was a problem with your fetch operation:', error);
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "adviceCards", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentIndex", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "currentLikes", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "currentCardLiked", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "currentCardHidden", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "toggleHide", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "toggleHide"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleLike", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "toggleLike"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "nextSlide", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "nextSlide"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "prevSlide", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "prevSlide"), _class.prototype)), _class));
});