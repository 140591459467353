define("bocce/helpers/file-name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.fileName = fileName;
  // app/helpers/file-name.js
  // Take a filename and return it's file name without the extension
  function fileName([filename]) {
    if (!filename) {
      return;
    }
    let split = filename.split('.');
    let extension = '.' + split[split.length - 1];
    return filename.replace(extension, '');
  }
  var _default = _exports.default = Ember.Helper.helper(fileName);
});