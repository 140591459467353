define("bocce/controllers/classroom/lessons/admin/quizzes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    quizUsers: null,
    selectedQuizId: "",
    selectedQuiz: null,
    selectedUser: null,
    result: null,
    page: 1,
    init(...args) {
      this._super(...args);
      this.set('operations', ['quiz-admin/unlock-for-next-attempt'

      //commented out for now. When putting this back, consider restricting it to a subset of users. 
      //Also, rename this to 'delete-all-attempts'...'quiz-admin/delete-latest-attempt'
      ]);
    },
    loadQuizUsers: async function (quizId) {
      let path = `/interface/sections/${this.get('session.section.id')}/quizzes/${quizId}/submission_users`,
        data = await $.get(path);
      this.set('quizUsers', data.quiz_submission_users.users);
    },
    actions: {
      selectQuiz: function (quizId) {
        this.set('selectedQuizId', quizId);
        this.loadQuizUsers(quizId);
        let thisQuiz = this.store.findRecord('quiz', quizId);
        this.set('selectedQuiz', thisQuiz);
        this.set('selectedUser', null);
      },
      expandOperations: async function (userId) {
        let user = await this.store.findRecord('user', userId);
        user.set('quizAttemptMade', this.get('quizUsers').find(u => u.id == userId).attempt_made);

        //Hacky, but this is the best way I could find to get the quiz operations to re-render when the selected user changes.
        this.set('selectedUser', null);
        Ember.run.schedule('afterRender', () => {
          this.set('selectedUser', user);
        });
      }
    }
  });
});