define("bocce/controllers/announcement", ["exports", "bocce/mixins/audio-rec", "bocce/mixins/editable", "bocce/mixins/discussable", "bocce/mixins/video-rec", "bocce/mixins/rtc-rec", "bocce/mixins/enmodal", "bocce/mixins/notify", "bocce/mixins/prefs"], function (_exports, _audioRec, _editable, _discussable, _videoRec, _rtcRec, _enmodal, _notify, _prefs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-observers */
  var _default = _exports.default = Ember.Controller.extend(_audioRec.default, _editable.default, _discussable.default, _enmodal.default, _videoRec.default, _rtcRec.default, _prefs.default, _notify.default, {
    init(...args) {
      this._super(...args);
      this.sortProperties = this.sortProperties || ['lastResponse.date:desc'];
    },
    discussions: Ember.inject.controller(),
    // required by EditableMixin
    classroom: Ember.inject.controller(),
    // required by DiscussableMixin and EnmodalMixin
    discussion: Ember.inject.controller('classroom.lessons.discussion'),
    // required by DiscussableMixin
    newDiscussion: Ember.inject.controller('classroom.lessons.discussion-new'),
    // required by DiscussableMixin
    sorted_model: Ember.computed.sort('model', 'sortProperties'),
    userProfileService: Ember.inject.service('userprofile'),
    announcements: Ember.computed.filterBy('sorted_model', 'is_announcement', true),
    /* eslint-disable-next-line ember/no-observers */
    contentObserver: Ember.observer('model.content.isLoaded', function () {
      let c = this.model;

      // content.content.isLoaded tells us when the array is fully loaded
      if (!(c = this.model) || !(c = c.get('content')) || !c.get('isLoaded') || this.announcementInitialLoad) {
        return;
      }
      let anns = this.announcements.sortBy('date'),
        anlen = anns.get('length');
      if (anlen === 0) {
        this.set('noAnnouncements', true);
      } else {
        for (let i = 0; i < anlen; i++) {
          let ann = anns.objectAt(i);
          if (!ann.get('read')) {
            this.send('notify', ann.get('title'), true, 'discussion', ann.id);
          }
        }
      }

      /* eslint-disable-next-line ember/no-get */
      if (this.get('session.user.profile.can_showcase')) {
        this.send('togglePrefPanel');
      }

      /* eslint-disable-next-line ember/no-get */
      if (this.get('session.user.profile.font_size')) {
        /* eslint-disable-next-line ember/no-get */
        this.send('adjustFont', this.get('session.user.profile.font_size'));
      }
      this.set('announcementInitialLoad', true);
    })
  });
});