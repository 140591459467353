define("bocce/components/lessons/event-new-template-manager", ["exports", "bocce/utilities/dialog", "bocce/config/environment"], function (_exports, _dialog, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    didInsertElement() {
      //Move this template to the 'template-manager-root' div, which is in 'event-new.hbs'. The design 
      //was much easier with this pattern.
      $(this.element).appendTo('#template-manager-root');
    },
    changeTemplate(index) {
      this.onTemplateChange(index);
      this.hideTemplateManager();
    },
    actions: {
      useTemplate(index) {
        const self = this;
        if (!this.contentNotDirty) {
          //User has made changes without saving, confirm they want to change templates. 
          (0, _dialog.default)(_environment.default.APP.text.confirmChangeTemplate, ['Yes', 'No']).then(choice => {
            if (choice === 'No') {
              return false;
            }
            self.changeTemplate(index);
          });
        } else {
          self.changeTemplate(index);
        }
      }
    }
  });
});