define("bocce/models/attempt", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // app/models/attempt.js
  var _default = _exports.default = _model.default.extend({
    quiz: (0, _model.attr)(),
    user: (0, _model.attr)(),
    number: (0, _model.attr)(),
    left: (0, _model.attr)(),
    started_at: (0, _model.attr)(),
    end_at: (0, _model.attr)(),
    score: (0, _model.attr)(),
    possible: (0, _model.attr)(),
    token: (0, _model.attr)(),
    state: (0, _model.attr)(),
    answers: (0, _model.attr)(),
    attachments: (0, _model.attr)(),
    action: (0, _model.attr)()
  });
});