define("bocce/controllers/classroom/lessons/admin/live", ["exports", "bocce/utilities/dialog"], function (_exports, _dialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    meetingKeyRequest: async function (event, meetingKey, action) {
      let eventId = event.get('id');
      /* eslint-disable-next-line ember/no-get */
      let path = `/interface/courses/${this.get('session.course.id')}/events/${eventId}/${action}/${meetingKey}`,
        currentMeetingKeys = event.get('meetingKeys') || [];
      let response = await fetch(path, {
        method: 'POST'
      });
      let responseBody = await response.json();
      switch (responseBody) {
        case 'added':
          currentMeetingKeys.push(meetingKey);
          event.set('meetingKeys', currentMeetingKeys);
          break;
        case 'deleted':
          {
            let index = currentMeetingKeys.indexOf(meetingKey);
            if (index > -1) {
              currentMeetingKeys.splice(index, 1);
              event.set('meetingKeys', currentMeetingKeys);
            }
            break;
          }
        default:
          Ember.debug(`Error making meeting key request: ${action} ID: ${eventId} meetingKey: ${meetingKey}`);
          break;
      }
    },
    actions: {
      toggleRemoveMeetingKeyConfirm: function (meetingKey) {
        let selector = `.event-delete-${meetingKey}-confirm`;
        let element = document.querySelector(selector);
        if (element) {
          element.classList.toggle('hidden');
        }
      },
      addMeetingKey: function (event) {
        (0, _dialog.default)('Add Meeting Key', ['Submit', 'Cancel'], `Enter the nine digit meeting key to add to "${event.get('title')}" ID: ${event.get('id')}`, true).then(meetingKey => {
          if (meetingKey.length < 9 || meetingKey.length > 12) {
            alert('Meeting Key must contain between 9 and 12 integers');
            return;
          }
          if (!/^\d+$/.test(meetingKey)) {
            alert('Meeting Key must only contain numbers.');
            return;
          }
          this.meetingKeyRequest(event, meetingKey, 'addMeetingKey');
        });
      },
      removeMeetingKey: function (event, meetingKey) {
        this.send('toggleRemoveMeetingKeyConfirm', meetingKey);
        this.meetingKeyRequest(event, meetingKey, 'deleteMeetingKey');
      },
      refreshZoomUser: async function (user) {
        let path = `/interface/zoom/refresh_user/${user.email}/${user.id}`,
          response = await fetch(path);
        let zoomID = await response.text();
        $('.reset-user-results').text(`Success: Zoom ID Set to ${zoomID}, please refresh`);
      }
    }
  });
});