define("bocce/mixins/interactions/guitar", ["exports", "bocce/mixins/support/render-template", "bocce/mixins/support/util"], function (_exports, _renderTemplate, util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function Guitar($el, data) {
    // bind all methods to this
    util.bindAll(this);
    var id = $el.attr('id'),
      quiz = {
        oneKeyEntry: true,
        revealCorrectAnswerAfter: data.config.revealCorrectAnswerAfter,
        // Number of tries before 'show answer' appears (false for never)
        noteLabels: data.config.noteLabels === 'true',
        // Label notes?
        selectedKeys: [],
        questions: []
      };

    // class vars
    this.$el = $el;
    this.$el.find('.interaction_content').removeAttr('style');
    this.$interactionData = this.$el.find('.interaction_data');
    this.$containerEl = this.$el.find('.interaction_content');
    this.$el.find('tr').each(function (num, row) {
      if (parseInt(num) === 0) {
        return; // skip the header row
      }

      /* eslint-disable-next-line ember/no-jquery */
      var $cells = Ember.$(row).find('td'),
        questionContent = $cells[0].innerHTML,
        answerRaw = $cells[1].innerHTML,
        feedbackRight = $cells[2].textContent,
        feedbackWrong = $cells[3].textContent,
        orderMatters,
        previewInput,
        answer,
        checked;

      // Check for bold
      if (answerRaw.indexOf('</strong>') > -1) {
        orderMatters = true;
      } else {
        orderMatters = false;
      }

      // Check for italic
      if (answerRaw.indexOf('</em>') > -1) {
        previewInput = 'arpeggio';
      } else {
        previewInput = 'chord';
      }

      // Strip out tags for and arrayify answer
      /* eslint-disable-next-line ember/no-jquery */
      answerRaw = Ember.$('<div/>').html(answerRaw).text().replace(new RegExp(' ', 'g'), '');
      answer = answerRaw.split(',');
      if (num === 1) {
        checked = 'checked="true"';
      } else {
        checked = '';
      }

      // Collect everything into a quiz question object literal
      quiz.questions.push({
        id: num,
        checked: checked,
        questionContent: questionContent,
        feedbackWrong: feedbackWrong,
        feedbackRight: feedbackRight,
        orderMatters: orderMatters,
        previewInput: previewInput,
        triesLeft: data.config.revealCorrectAnswerAfter,
        isChord: true,
        // Leave true
        answer: answer
      });
      Ember.debug('Question ' + num + ': ' + $cells[0].innerHTML);
      Ember.debug('Answer ' + num + ': ' + $cells[1].innerHTML);
    });
    quiz.total = quiz.questions.length || 0;
    quiz.guitar_interaction_id = id;
    this.context = quiz;
    this.answers = 'HEX,BX,GX,DX,AX,LEX';
  }
  Guitar.prototype = {
    init: function () {
      // add UI
      this.pauseAtTime = null;
      return (0, _renderTemplate.default)('guitar', 'main', this.context).then(content => {
        this.$containerEl.append(content);
        this.initListeners();
      });
    },
    initListeners: function () {
      var that = this,
        /* eslint-disable-next-line ember/no-jquery */
        $interaction = Ember.$('#guitar-interaction-container' + this.context.guitar_interaction_id);
      $interaction.find('.play-button').on('click', function () {
        var strings = ['high-e-string', 'b-string', 'g-string', 'd-string', 'a-string', 'low-e-string'],
          aud = $interaction.find('audio'),
          src = [];
        strings.forEach(function (str) {
          var tSrc = $interaction.find('.' + str + ' .note-selector:checked:not(".nX")').attr('note') || '';
          src.push(tSrc);
        });
        aud.each(function (aud) {
          if (src[aud] !== '') {
            this.src = 'https://intro.online.berklee.edu/audio/guitar/' + src[aud] + '.mp3';
            this.load();
          }
        });
        aud.each(function () {
          if (this.src) {
            this.play();
          }
        });
      });

      /*Scroll STOP*/
      $interaction.find('.guitar-container').on('scrollstop', function () {
        var frets = $interaction.find('.fret'),
          fretLength = [98, 196, 294, 382, 470, 558, 637, 716, 793, 870, 947, 1015, 1083, 1151, 1219, 1287, 1350, 1413, 1476, 1539, 1602],
          fretArrLength = fretLength.length,
          scLeft = this.scrollLeft,
          i;
        for (i = 1; i < fretArrLength; i++) {
          if (scLeft > fretLength[i]) {
            frets.removeClass('ov');
            frets[i - 1].classList.add('ov');
          }
        }
      });
      $interaction.find('.note-selector').on('click', function () {
        that.answers = $interaction.find('.note-selector:not(.nX):checked').map(function () {
          return this.attributes['fret'].value;
        }).get().toString();
      });
      $interaction.find('.question-checker-handle').on('click', function () {
        if (this.attributes['answer'].value === that.answers) {
          that.context.questions[this.attributes.number.value - 1].triesLeft = 0;
          window.alert('Correct!');
        } else {
          that.context.questions[this.attributes.number.value - 1].triesLeft -= 1;
          window.alert('Incorrect!');
        }
      });
      $interaction.find('.check-question-button').on('click', function () {
        var val = this.attributes['answer'].value.split(','),
          left = parseInt(that.context.questions[this.attributes.number.value - 1].triesLeft),
          i;
        if (left <= 0) {
          $interaction.find('input').next().removeClass('peek');
          for (i = 0; i < val.length; i++) {
            $interaction.find('input[fret=' + val[i] + ']').next().addClass('peek');
          }
        } else if (left === 1) {
          window.alert('Please try to answer the question 1 more time!');
        } else {
          window.alert('Please try to answer the question ' + left + ' more times!');
        }
      });
      $interaction.find('.clear-button, .question-selector').on('click', function () {
        var aud = $interaction.find('audio');
        $interaction.find('.note-selector.nX').prop('checked', true);
        $interaction.find('input').next().removeClass('peek');
        that.answers = '';

        // unload audio
        aud.each(function () {
          this.src = '';
          this.load();
        });
      });
    }
  };
  var _default = _exports.default = Guitar;
});