define("bocce/components/side-panel/panel-list-item/discussion-icon", ["exports", "bocce/components/side-panel/panel-list-item/icon"], function (_exports, _icon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _icon.default.extend({
    session: Ember.inject.service(),
    iconClass: Ember.computed('item.i_submitted', 'session.isInstructor', function () {
      /* eslint-disable-next-line ember/no-get */
      if (!this.get('session.isInstructor') && this.get('item.i_submitted')) {
        return 'fas fa-check';
      } else {
        return 'fas fa-users';
      }
    })
  });
});