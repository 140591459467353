define("bocce/components/lessons/topic-body", ["exports", "bocce/mixins/boot", "bocce/mixins/quiz"], function (_exports, _boot, _quiz) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  var _default = _exports.default = Ember.Component.extend(_quiz.default, _boot.default, {
    bookmarks: Ember.inject.service(),
    userprofileService: Ember.inject.service('userprofile'),
    didInsertElement() {
      // run boot function on this topic's element
      this.bootContent();
      this.addLinkTargets();
      this.modifyBookmarks();
    },
    bootContent() {
      /* eslint-disable-next-line ember/no-jquery */
      let node = Ember.$(this.element).find('.bootable-area');
      if (node && node.length) {
        // Boot each bootable-area node found in this component
        let promises = [];
        /* eslint-disable-next-line ember/no-jquery */
        node.each((i, n) => promises.push(this.boot_area(Ember.$(n))));
        return Promise.all(promises);
      } else {
        return Promise.resolve(null);
      }
    },
    addLinkTargets() {
      // TODO-FIXME:
      // This is ugly. Is there a way to do this in the CMS?
      // Give every link a target="_blank" property
      // This will ensure all links open in new tab/window
      /* eslint-disable-next-line ember/no-jquery */
      Ember.$(this.element).find('a[href]:not(.ember-view)').attr('target', '_blank');
    },
    modifyBookmarks() {
      const lesson = this.lessons.get('model.lesson');
      const lesson_id = lesson.get('id');
      const lesson_name = lesson.get('title');
      Ember.$(`.bookmark-add.${this.index}`).on('click', () => {
        this.bookmarksService.addBookmark(this.item.db_id, this.item.id, this.item.title, this.topicbody.body, lesson_id, lesson_name);
      });
    },
    templateForType: Ember.computed('item.type', function () {
      let templates = {
        'assignment': 'assignment-contents',
        'discussion': 'discussion-contents',
        'quiz': 'quiz'
      };
      /* eslint-disable-next-line ember/no-get */
      let type = this.get('item.type').toLowerCase();
      return templates[type] || null;
    }),
    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    discussioncontents: Ember.computed('item', 'data', function () {
      /* eslint-disable-next-line ember/no-get */
      let type = this.get('item.type').toLowerCase();
      if (type === 'discussion') {
        return this.data;
      }
      return null;
    }),
    discussionLastReply: Ember.computed('discussioncontents', async function () {
      let discussion = this.discussioncontents;
      if (discussion) {
        let responses = await discussion.get('responses');
        let latestResponse = responses.content ? responses.content[0] : null;
        return latestResponse;
      }
      return null;
    }),
    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    assignmentcontents: Ember.computed('item', 'data', function () {
      /* eslint-disable-next-line ember/no-get */
      let type = this.get('item.type').toLowerCase();
      if (type === 'assignment') {
        return this.data;
      }
      return null;
    }),
    topicbody: Ember.computed('templateForType', 'data', function () {
      if (this.templateForType === null) {
        return this.data;
      }
      return null;
    }),
    actions: {
      sendParentAction(action, ...args) {
        this[action](...args);
      },
      viewModal() {
        this.viewModal(...arguments);
      }
    }
  });
});