define("bocce/mirage/scenarios/test-assignment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default(server) {
    let user = server.create('user', {
      lastviewed: {}
    });
    server.create('session', {
      user
    });
    let course = server.create('course');
    let section = course.sections.models[0];
    server.create('assignment', {
      course,
      section
    });
  }
});