define("bocce/templates/components/discussion/reply-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "wj+O9C/V",
    "block": "{\"symbols\":[],\"statements\":[[10,\"li\"],[14,0,\"response is-child response-trigger\"],[12],[2,\"\\n    \"],[11,\"button\"],[24,0,\"response-reply\"],[24,\"aria-label\",\"Reply to this post\"],[4,[38,3],[[32,0],\"reply\",[35,2],[35,1],[35,0]],null],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fas fa-reply\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\" Reply\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"parentName\",\"parentText\",\"parentID\",\"action\"]}",
    "meta": {
      "moduleName": "bocce/templates/components/discussion/reply-button.hbs"
    }
  });
});