define("bocce/controllers/classroom/lessons/student-event-new", ["exports", "bocce/mixins/enmodal", "bocce/mixins/webex"], function (_exports, _enmodal, _webex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  var _default = _exports.default = Ember.Controller.extend(_enmodal.default, _webex.default, {
    titleInput: ''
  });
});