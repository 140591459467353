define("bocce/utilities/logout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function logout() {
    let body = document.querySelector('body'),
      form = document.createElement('form');
    form.setAttribute('action', '/logout');
    form.setAttribute('method', 'POST');

    // Destroy GainSight Cookie
    document.cookie = 'apt.sid=; Domain=.berklee.edu; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT';
    form.innerHTML = '<input type="hidden" name="_method" value="delete">';
    body.appendChild(form);
    form.submit();
  }
  var _default = _exports.default = logout;
});