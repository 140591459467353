define("bocce/routes/classroom/lessons/assignments", ["exports", "bocce/routes/classroom/lessons/modal-base"], function (_exports, _modalBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
  
  Assignments route
  
  Right now, this is only redirects the user to the correct submission (or lack
  thereof). This is here so that we can more cleanly link to an assignment using
  {{link-to}} components without needing to know the user's submission info. This
  is important in components such as side-panel/panel-list-item
  
  The logic is a duplicate of the treatAssignment action on the Discussable mixin.
  
  */
  var _default = _exports.default = _modalBase.default.extend({
    session: Ember.inject.service(),
    beforeModel() {
      // ensure that we are looking for the assignment in the correct section
      // TODO: NK: Write Ember Adapters that will handle this logic for us
      this.store.nestResources('assignment', /* eslint-disable-next-line ember/no-get */
      [{
        section: this.get('session.section.id')
      }]);
    },
    // Using the default model hook, which uses the assignment_id from the URL
    redirect(model) {
      // Special case first, so that we don't have to deal with async logic
      // unless it's necessary
      /* eslint-disable-next-line ember/no-get */
      let isTeacherOrObserver = this.get('session.isInstructor') || /* eslint-disable-next-line ember/no-get */
      this.get('session.isObserver');
      /* eslint-disable-next-line ember/no-get */
      if (!isTeacherOrObserver && this.get('session.course.isReadOnly')) {
        let submission = model.get('currentUserSubmission');
        if (submission) {
          return this.replaceWith('classroom.lessons.submission', submission);
        } else {
          return this.replaceWith('classroom.lessons.submission-new', model.get('id'));
        }
      }
      this.store.nestResources('submission', [/* eslint-disable-next-line ember/no-get */
      {
        section: this.get('session.section.id')
      }, {
        assignment: model.get('id')
      }]);
      return this.store.findAll('submission', {
        reload: true
      }).then(submissions => {
        // Make sure we're ONLY looking at submissions for this assignment.
        // TODO: NK: As-is, this is not testable, because in non-production
        //       builds Ember will throw an assertion error. We should move to
        //       using the unique ID from canvas for identifying submissions,
        //       rather than the human-friendly assignmentID_userID formula.
        submissions = submissions.filter(s => {
          return s.get('assignment.id') === model.get('id');
        });
        let submission;
        if (isTeacherOrObserver) {
          submission = submissions.toArray()[0];
        } else {
          submission = submissions.find(s => {
            return s.get('my_submission');
          });
        }
        if (submission) {
          this.replaceWith('classroom.lessons.submission', submission);
        } else {
          let route = isTeacherOrObserver ? 'classroom.lessons.no-submissions' : 'classroom.lessons.submission-new';
          this.replaceWith(route, model.get('id'));
        }
      });
    }
  });
});