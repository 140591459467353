define("bocce/templates/interactions/flashcards/main", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "dEYf7/bd",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"card\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,1,\"time\"],[12],[13],[2,\"\\n  \"],[10,\"div\"],[14,1,\"count\"],[12],[1,[34,0]],[13],[2,\"\\n  \"],[10,\"div\"],[14,1,\"question\"],[12],[2,[34,1]],[13],[2,\"\\n  \"],[10,\"div\"],[14,1,\"answer\"],[14,0,\"hidden\"],[12],[2,[34,2]],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,1,\"buttons\"],[12],[2,\"\\n  \"],[10,\"a\"],[14,6,\"#\"],[14,1,\"showAnswerBtn\"],[14,0,\"button blue\"],[12],[2,\"Show Answer\"],[13],[2,\"\\n  \"],[10,\"a\"],[14,6,\"#\"],[14,1,\"shuffleBtn\"],[14,0,\"button blue hidden\"],[12],[2,\"Shuffle Remaining\"],[13],[2,\"\\n  \"],[10,\"a\"],[14,6,\"#\"],[14,1,\"nextBtn\"],[14,0,\"button blue hidden\"],[12],[2,\"Next Question\"],[13],[2,\"\\n  \"],[10,\"a\"],[14,6,\"#\"],[14,1,\"gotItBtn\"],[14,0,\"button green hidden\"],[14,\"title\",\"Remove the card from the deck\"],[12],[10,\"i\"],[14,0,\"fas fa-check-circle\"],[12],[13],[2,\" Got It!\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"count\",\"questionHtml\",\"answerHtml\"]}",
    "meta": {
      "moduleName": "bocce/templates/interactions/flashcards/main.hbs"
    }
  });
});