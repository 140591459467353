define("bocce/routes/classroom/lessons/modal-base", ["exports", "bocce/mixins/routable"], function (_exports, _routable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable-next-line ember/no-mixins */
  var _default = _exports.default = Ember.Route.extend(_routable.default, {
    beforeModel() {
      delete localStorage.localDocRunner;
    },
    // Cleanup code for modals
    resetController() {
      // Clean out autoSave
      let fl;
      try {
        fl = JSON.parse(localStorage.localDocs);
        delete fl['Auto Save'];
      } catch (err) {
        fl = {};
      }
      localStorage.localDocs = JSON.stringify(fl);

      /* eslint-disable-next-line ember/no-jquery */
      Ember.$('a').removeAttr('tabindex');
      /* eslint-disable-next-line ember/no-jquery */
      Ember.$('button').removeAttr('disabled');
    },
    renderTemplate() {
      // get the default template name via the routeName property.
      // The simpler way to do this would be to use this.templateName,
      // but because this is a superclass it's undefined here and only
      // accessible from the subclass.
      let template = this.routeName;
      this.render(template, {
        into: 'classroom',
        outlet: 'modals'
      });

      /* eslint-disable-next-line ember/no-jquery */
      Ember.$('a').attr('tabindex', '-1');
      // NK: Including .bocce in the selector below prevents tests from aborting.
      // I'm not sure why the test framework aborts, but making sure that we only
      // change buttons within the BOCCE body seems to appease QUnit.
      /* eslint-disable-next-line ember/no-jquery */
      Ember.$('.bocce button').not('.adblock-warning button, .user-profile button, .floating-modal *, .popup-container *, .video-player *, .help-guide-container button, .pref-dialog.active.hcode button').attr('disabled', 'true');

      /* eslint-disable-next-line ember/no-jquery */
      Ember.$('.floating-modal a, .popup-container a').removeAttr('tabindex');

      /* eslint-disable-next-line ember/no-jquery */
      Ember.$('.floating-modal [tabindex="0"]').focus();
    }
  });
});