define("bocce/models/multiple_dropdown", ["exports", "bocce/models/quiz_question", "lodash.isequal"], function (_exports, _quiz_question, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class MultipleDropdown extends _quiz_question.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "default_answer", {});
      _defineProperty(this, "doCheckAnswer", true);
    }
    isAnswerCorrect() {
      return super.isAnswerCorrect((given, correct) => {
        return (0, _lodash.default)(given, correct);
      });
    }
    async onNextOrFinish() {
      if (this.quizzes.isTimedQuiz && this.answerUnsaved) {
        await this.updateCanvas();
      }
      this.answerUnsaved = false;
    }
    get textFormatted() {
      return null;
    }
    addEventListeners() {
      if (!this.quizzes.hasScore) {
        for (let blankId in this.optionAnswers) {
          let answers = this.optionAnswers[blankId];
          if (answers.length > 0) {
            $(`#question-${this.get('id')}-${blankId}`).off('change.multidropdown').on('change.multidropdown', event => {
              let givenAnswer = this.get('given_answer');
              if (event.target.value) {
                givenAnswer[blankId] = +event.target.value;
              } else if (givenAnswer[blankId]) {
                delete givenAnswer[blankId];
              }
              Ember.set(this, 'given_answer', {
                ...givenAnswer
              });
            });
          }
        }
      }
    }
  }
  _exports.default = MultipleDropdown;
});