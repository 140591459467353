define("bocce/components/attachments/attached-file", ["exports", "@glimmer/component", "bocce/helpers/file-extension", "bocce/utilities/dialog"], function (_exports, _component, _fileExtension, _dialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let AttachmentsAttachedFileComponent = _exports.default = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember.inject.service, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, (_class = class AttachmentsAttachedFileComponent extends _component.default {
    constructor(owner, args) {
      super(owner, args);
      _initializerDefineProperty(this, "renaming", _descriptor, this);
      _initializerDefineProperty(this, "renameInput", _descriptor2, this);
      _initializerDefineProperty(this, "store", _descriptor3, this);
      this.renaming = false;
      if (this.fileName && this.fileName.includes(".")) {
        this.renameInput = this.fileName.split(".")[0];
      } else if (this.fileName && this.fileName.length > 0) {
        this.renameInput = this.fileName;
      } else {
        this.renameInput = "";
      }
    }
    get progress() {
      if (this.args.attachment.progress) {
        return this.args.attachment.progress;
      }
      return 0;
    }
    get fileName() {
      return this.args.name || this.args.attachment.name || this.args.attachment.file.name;
    }
    get fileExtension() {
      return (0, _fileExtension.fileExtension)([this.fileName]);
    }
    get uploading() {
      if (this.args.uploading) {
        return this.args.uploading;
      }
      return false;
    }
    get uploaded() {
      if (this.args.attachment?.uploaded) {
        return this.args.attachment.uploaded;
      }
      return false;
    }
    get fileUrl() {
      return this.args.attachment.blobURL || URL.createObjectURL(this.args.attachment.file);
    }
    get attachmentType() {
      if (this.args.attachment.file?.type?.includes('audio')) {
        return "microphone";
      } else if (this.args.attachment.file?.type?.includes('video')) {
        return "video";
      } else {
        return "paperclip";
      }
    }
    deleteAttachment() {
      let dialogMessage = 'Are you sure you wish to delete this attachment?';
      (0, _dialog.default)(dialogMessage, ['Yes', 'No']).then(selection => {
        if (selection === 'Yes') {
          this.args.deleteAttachment(this.args.attachment.file);
        }
      });
    }
    showRename() {
      this.renaming = true;
    }
    confirmRename() {
      if (!this.renameInput || this.renameInput.includes('.')) {
        alert('Invalid file name, must not contain "."');
        return;
      }
      let newName = this.fileExtension ? this.renameInput + this.fileExtension : this.renameInput;
      this.args.renameAttachment(this.args.attachment, newName, this.args.index);
      this.renaming = false;
    }
    cancelRename() {
      this.renaming = false;
    }
    retryAttachment() {
      this.args.uploadSingleFile(this.args.attachment.file);
    }
    playPreviewAudio(url) {
      let audioPlayer = document.getElementById('main-audio-player');
      let audioPlayerHTML = audioPlayer.querySelector('audio');
      audioPlayer.classList.add('active');
      audioPlayerHTML.src = url;
      audioPlayerHTML.play();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "renaming", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "renameInput", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "deleteAttachment", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "deleteAttachment"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showRename", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "showRename"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "confirmRename", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "confirmRename"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancelRename", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "cancelRename"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "retryAttachment", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "retryAttachment"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "playPreviewAudio", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "playPreviewAudio"), _class.prototype)), _class));
});