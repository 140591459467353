define("bocce/mixins/interactions/timed_writing", ["exports", "bocce/mixins/support/util", "bocce/mixins/support/local-storage"], function (_exports, util, _localStorage2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function TimedWriting($el, data) {
    this.$el = $el; // $stage is where all of the action happens
    this.data = data;
    this.$stage = this.$el.find('.interaction_content');
    this.id = $el[0].id;
    this.timer = {}; // bind 'this' from this object to all methods
    this.initialJSON = data.initial_json;
    (0, util.bindAll)(this);
  }
  TimedWriting.SECONDS = 'seconds';
  TimedWriting.SECONDS_REMAINING = 'seconds-remaining';
  TimedWriting.COMPLETED = 'completed';
  TimedWriting.RENDERED = 'rendered';
  TimedWriting.prototype = {
    init: function init() {
      var _this2 = this;
      var dataTable, tabs;
      dataTable = this.$el.find('.interaction_data table')[0];
      tabs = this.createTabsFromTable(dataTable);
      console.log(tabs.tabs.length);
      var tablist = "",
        tabcontents = "";
      for (let i = 0; i < tabs.tabs.length; i++) {
        console.log(tabs.tabs[i]);
        tablist += '<li>' + tabs.tabs[i].linkHtml + '</li>';
        if (tabs.tabs[i].isSummaryTab) {
          tabcontents += '<div id="' + tabs.tabs[i].id + '">' + '<div class="summary-container"></div>' + '</div>';
        } else {
          tabcontents += '<div id="' + tabs.tabs[i].id + '">' + '<div class="question-container">' + tabs.tabs[i].question + '<!---->' + '</div>' + '<div class="feedback hidden"></div>' + '<div class="buttons-row cf">' + '<button class="start-timer">Start</button>' + '<button class="reset">Reset</button>' + ' <div class="time-container">Time: <span class="time"></span></div>' + '</div>' + '<div>' + tabs.tabs[i].textareaHtml + '</div>' + '</div>';
        }
      }

      //return (0, _renderTemplate.default)('timed_writing', 'main', tabs).then(function (content) {
      var $content = '<div class="tabs">' + '<ul>' + tablist + '</ul>' + tabcontents + '</div>'; //= $(_this2.initialJSON);

      _this2.addTemplateTo(_this2.$stage, tabs, $content);
      _this2.checkAutosize();
      _this2.initTabs();
      _this2.loadData(); // save data when user leaves the page

      /* eslint-disable-next-line ember/no-jquery */

      Ember.$(window).on('unload', Ember.$.proxy(_this2.saveData, _this2)); // fake a click to the first tab to kick things off

      _this2.selectFirstTab();
      //});
    },
    addTemplateTo: function addTemplateTo($parentEl, tabs, content) {
      $parentEl.append(content); // let listening tab classes know when the UI has been rendered

      /* eslint-disable-next-line ember/no-jquery */

      Ember.$(document).trigger(TimedWriting.RENDERED); // cache some newly rendered, commonly used els

      this.$timeContainer = this.$stage.find('.time-container');
      this.$time = this.$stage.find('.time');
      this.$startBtn = this.$stage.find('.start-timer').click(this.startTimer);
      this.$resetBtn = this.$stage.find('.reset').click(this.resetTab);
    },
    checkAutosize: function checkAutosize() {
      // autosize by default, don't if user adds no-autosize class
      if (!this.$stage.hasClass('no-autosize')) {
        this.$stage.addClass('autosize');
      }
    },
    createTabsFromTable: function createTabsFromTable(tableEl) {
      var tabs = [],
        _this = this;
      /* eslint-disable-next-line ember/no-jquery */

      Ember.$(tableEl).find('tr').each(function (i) {
        var id; // first row is for column headers

        if (i === 0) {
          return;
        } // each tab must have a unique ID

        id = _this.id + '-tab-' + i; // TabFactory returns the proper tab type (regular or summary)
        // pass tr (this) to Tab constructor

        tabs.push(tabFactory(this, id));
      });
      return {
        'tabs': tabs
      };
    },
    initTabs: function initTabs() {
      this.$stage.find('.tabs').tabs({
        activate: this.tabSelected
      });
    },
    selectFirstTab: function selectFirstTab() {
      this.$currentTab = this.$stage.find('.tabs li a:first');
      this.displayCurrentTab();
    },
    loadData: function loadData() {
      var data, savedID, tabSelector, $tab, savedText, textareaSelector, $textArea;
      data = _localStorage2.default.getData(this.id);
      if (!data) {
        return;
      }
      for (savedID in data) {
        tabSelector = '.tabs a[href="#' + savedID + '"]';
        /* eslint-disable-next-line ember/no-jquery */
        $tab = Ember.$(this.$stage.find(tabSelector)); // flag the tab as completed

        $tab.data(TimedWriting.COMPLETED, true); // add the saved text into the correct textarea

        savedText = data[savedID];
        textareaSelector = '#' + savedID + ' textarea';
        $textArea = this.$stage.find(textareaSelector);
        $textArea.val(savedText).removeAttr('disabled').attr('readonly', 'true');
      }
    },
    tabSelected: function tabSelected(e, target) {
      /* eslint-disable-next-line ember/no-jquery */
      this.$currentTab = Ember.$(target.newTab).find('a:first');
      this.$stage.find('.bottom-row').show();
      this.displayCurrentTab();
    },
    displayCurrentTab: function displayCurrentTab() {
      if (this.$currentTab.hasClass('default-tab')) {
        this.defaultTabSelected();
      } else {
        this.summaryTabSelected();
      }
    },
    defaultTabSelected: function defaultTabSelected() {
      var timeRemaining;
      this.get$Textarea().attr('readonly', 'true');
      timeRemaining = this.$currentTab.data(TimedWriting.SECONDS_REMAINING);
      this.$time.text(secondsToClockTime(timeRemaining));
      this.$timeContainer.show();
      if (this.$currentTab.data(TimedWriting.COMPLETED)) {
        this.$startBtn.hide();
        this.setFeedback('You have already completed this.');
      } else {
        this.$startBtn.show();
      }
      // un-timed tabs...
      if (timeRemaining === undefined) {
        this.$timeContainer.hide();
      }
      clearInterval(this.timer);
    },
    summaryTabSelected: function () {
      var summaryTab = this.$currentTab.data('summaryTab');
      this.$stage.find('.summary-container').html(summaryTab.createSummary(this.$stage)); // don't show buttons on summary tab
      this.$stage.find('.summary-container').append("<button class='export'>Export</button>");
      let filename = "summary_text_" + this.data.slide_id;
      this.$stage.find('.summary-container').find(".export").off("click").on("click", function () {
        const regex = /\n/ig,
          regex1 = /\t/ig;
        var userInput = Ember.$(this).parent().text().replaceAll(regex1, '').replace("Export", "");
        //var userInput = $(this).parent().text();

        var blob = new Blob([userInput], {
          type: "text/plain;charset=utf-8"
        });
        saveAs(blob, filename + ".txt");
      });
      this.$stage.find('.bottom-row').hide();
    },
    startTimer: function () {
      var seconds;
      this.$startBtn.hide(); // make textarea editable and put the cursor inside of it

      this.get$Textarea().removeAttr('disabled').removeAttr('readonly').removeAttr('placeholder').focus();
      seconds = this.$currentTab.data(TimedWriting.SECONDS); // un-timed question...

      if (seconds === undefined) {
        this.$currentTab.data(TimedWriting.COMPLETED, true);
        return;
      }
      this.timer = setInterval(this.onTimeUpdate, 1000); // setInterval isn't called for 1 second, so trigger
      // it once manually so the update is immediate

      this.onTimeUpdate();
    },
    onTimeUpdate: function () {
      var timeRemaining;
      timeRemaining = this.$currentTab.data(TimedWriting.SECONDS_REMAINING);
      timeRemaining = parseInt(timeRemaining);
      timeRemaining--;
      this.$currentTab.data(TimedWriting.SECONDS_REMAINING, timeRemaining);
      this.$time.text(secondsToClockTime(timeRemaining));
      if (timeRemaining <= 0) {
        this.timerComplete();
      }
    },
    timerComplete: function () {
      var $tabs, currentIndex, numTabs;
      clearInterval(this.timer); // we want the user to be able to copy and paste, but not type

      this.get$Textarea().attr('readonly', 'true');
      $tabs = this.$stage.find('.tabs');
      currentIndex = $tabs.tabs('option', 'selected');
      numTabs = $tabs.find('ul li').length;
      if (currentIndex < numTabs - 1) {
        this.setFeedback('Time\'s up. Click the next tab to continue.');
      } else {
        this.setFeedback('Time\'s up.');
      }
      this.$currentTab.data(TimedWriting.COMPLETED, true);
    },
    resetTab: function () {
      var $textArea, time; // reset the time-remaining to the original duration

      this.$currentTab.data(TimedWriting.SECONDS_REMAINING, this.$currentTab.data(TimedWriting.SECONDS));
      $textArea = this.get$Textarea();
      $textArea.val('').attr({
        'placeholder': 'Click "Start" to begin typing...',
        'disabled': 'disabled'
      }).removeAttr('readonly').blur();
      clearInterval(this.timer);
      time = secondsToClockTime(this.$currentTab.data(TimedWriting.SECONDS));
      this.$time.text(time);
      this.setFeedback('', true);
      this.$currentTab.data(TimedWriting.COMPLETED, false);
      this.$startBtn.show();
    },
    get$Textarea: function () {
      var $textarea = this.$stage.find(this.getTabID()).find('textarea');
      return $textarea;
    },
    setFeedback: function (msg, hide) {
      /* eslint-disable-next-line ember/no-jquery */
      var $feedback = Ember.$(this.getTabID()).find('.feedback').removeClass('hidden').html(msg);
      if (hide) {
        $feedback.addClass('hidden');
      }
    },
    getTabID: function () {
      return this.$currentTab.attr('href').replace(/^.*#/, '#');
    },
    saveData: function () {
      var data, panels, $panel, panelID, text, hasTextArea;
      data = {};
      panels = this.$el.find('.ui-tabs-panel');
      panels.each(function () {
        /* eslint-disable-next-line ember/no-jquery */
        $panel = Ember.$(this);
        panelID = $panel.attr('id');
        text = $panel.find('textarea').val();
        hasTextArea = $panel.find('textarea').length > 0;
        if (hasTextArea && text.length) {
          data[panelID] = text;
        }
      });
      _localStorage.saveData(this.id, data);
    }
  };
  function clockTimeToSeconds(time) {
    var splitTime, mins, secs;
    splitTime = time.split(':');
    mins = parseInt(splitTime[0]);
    secs = parseInt(splitTime[1]);
    return mins * 60 + secs;
  }
  function secondsToClockTime(totalSecs) {
    var mins, secs;
    mins = Math.floor(totalSecs / 60);
    secs = totalSecs % 60; // zero pad seconds

    secs = secs < 10 ? '0' + secs : secs;
    return mins + ':' + secs;
  }
  function tabFactory(tableRow, id) {
    var cell,
      cellText,
      isSummaryTab = false;
    /* eslint-disable-next-line ember/no-jquery */

    cell = Ember.$(tableRow).find('td')[1];
    /* eslint-disable-next-line ember/no-jquery */

    cellText = Ember.$.trim(Ember.$(cell).html()); // check to see if cell contains summary tokens

    isSummaryTab = cellText.match(/{[QAT]*(?:HtmlQ)*[0-9]+}/) === null ? false : true;
    if (isSummaryTab) {
      return new SummaryTab(tableRow, id);
    }
    return new Tab(tableRow, id);
  }
  function Tab(tableRow, id) {
    // expected table columns: tab label, question, time

    /* eslint-disable-next-line ember/no-jquery */
    var $cells = Ember.$(tableRow).find('td');
    this.id = id;
    this.question_node = '';
    this.question = $cells[1].innerHTML; // the cms parses href attrs and textarea tags in manifests
    // causing strange results, so build the els outside of the template

    this.linkHtml = this.createLinkHtml($cells, id);
    this.textareaHtml = this.createTextareaHtml();
  }
  Tab.prototype = {
    createLinkHtml: function createLinkHtml($cells, id) {
      var tabLabel, seconds;
      /* eslint-disable-next-line ember/no-jquery */

      tabLabel = Ember.$.trim(Ember.$($cells[0]).text());
      /* eslint-disable-next-line ember/no-jquery */

      seconds = Ember.$.trim(Ember.$($cells[2]).text()); // user can leave the time field blank
      // to create an "un-timed" Tab

      if (seconds.length) {
        seconds = clockTimeToSeconds(seconds);
      } else {
        seconds = undefined;
      }
      var href = '#' + id; // The minimal ember app needs to run anywhere, so it's left to assume it
      // serves /.  As a result, our anchor tag gets altered and jquery-ui thinks
      // it needs to load /#tab-name via XHR.

      //if (_environment.default.cms) {
      // href = location.pathname + location.search + href;
      //}
      ///* eslint-disable-next-line ember/no-jquery */

      return Ember.$('<div>').append( /* eslint-disable-next-line ember/no-jquery */
      Ember.$('<a>').attr({
        'href': href,
        'data-seconds': seconds,
        'data-seconds-remaining': seconds
      }).addClass('default-tab').html(tabLabel)).html();
    },
    createTextareaHtml: function createTextareaHtml() {
      /* eslint-disable-next-line ember/no-jquery */
      return Ember.$('<div>').append( /* eslint-disable-next-line ember/no-jquery */
      Ember.$('<textarea>').attr({
        'placeholder': 'Click "Start" to begin typing...',
        'disabled': 'disabled'
      })).html();
    }
  };
  function SummaryTab(tableRow, id) {
    // expected table columns: tab label, question, time

    /* eslint-disable-next-line ember/no-jquery */
    var $cells = Ember.$(tableRow).find('td');
    this.id = id;
    /* eslint-disable-next-line ember/no-jquery */

    this.content = Ember.$.trim(Ember.$($cells[1]).html());
    this.linkHtml = this.createLinkHtml($cells, id);
    this.isSummaryTab = true; // listen for UI to be rendered so handlers can be attached

    /* eslint-disable-next-line ember/no-jquery */

    Ember.$(document).bind(TimedWriting.RENDERED, this.onRendered.bind(this));
    (0, util.bindAll)(this);
  }
  SummaryTab.prototype = {
    linkHref: function linkHref(id) {
      var href = '#' + id;

      //if (_environment.default.cms) {
      // href = location.pathname + location.search + href;
      //}

      return href;
    },
    createLinkHtml: function createLinkHtml($cells, id) {
      /* eslint-disable-next-line ember/no-jquery */
      var tabLabel = Ember.$.trim(Ember.$($cells[0]).text());
      /* eslint-disable-next-line ember/no-jquery */

      return Ember.$('<div>').append( /* eslint-disable-next-line ember/no-jquery */
      Ember.$('<a>').attr({
        'href': this.linkHref(id)
      }).addClass('summary-tab').html(tabLabel)).html();
    },
    // pin a ref to this instance to the tab el
    onRendered: function onRendered() {
      /* eslint-disable-next-line ember/no-jquery */
      var $tabLink = Ember.$('a[href="' + this.linkHref(this.id) + '"]');
      //debugger
      $tabLink.data('summaryTab', this);
    },
    // scrape the current tabs and assemble a summary
    // based on user specified tokens:
    //
    // - {T#}     = Tab label from tab #
    // - {Q#}     = Question from tab #
    // - {HtmlQ#} = Question from tab # (full html)
    // - {A#}     = Answer from tab #
    //
    createSummary: function createSummary($stage) {
      var summary; // make copy of content string to preserve

      summary = this.content + ''; // set current stage to allow multiple Timed Writings on the same page

      this.$stage = $stage; // replace tab tokens

      summary = summary.replace(/{(T)[0-9]+}/g, this.scrapeTabLabel); // replace question tokens

      summary = summary.replace(/{(Q)[0-9]+}/g, this.scrapeQuestion); // replace html question tokens

      summary = summary.replace(/{(?:HtmlQ)[0-9]+}/g, this.scrapeHtmlQuestion); // replace answer tokens

      summary = summary.replace(/{(A)[0-9]+}/g, this.scrapeAnswer);
      return summary;
    },
    scrapeTabLabel: function scrapeTabLabel(token) {
      return this.scrape(token, '.default-tab', 'text');
    },
    scrapeQuestion: function scrapeQuestion(token) {
      return this.scrape(token, '.question-container', 'text');
    },
    scrapeHtmlQuestion: function scrapeHtmlQuestion(token) {
      return this.scrape(token, '.question-container', 'html');
    },
    scrapeAnswer: function scrapeAnswer(token) {
      return this.scrape(token, 'textarea', 'val');
    },
    scrape: function scrape(token, selector, func) {
      var index, el;
      index = this.getIndexFromToken(token);
      el = this.$stage.find(selector)[index];
      /* eslint-disable-next-line ember/no-jquery */

      return Ember.$(el)[func]();
    },
    getIndexFromToken: function getIndexFromToken(token) {
      // decrement user specified numbers by 1 to get array index
      return parseInt(token.match(/[0-9]+/)[0] - 1);
    }
  };
  var _default = _exports.default = TimedWriting;
});