define("bocce/components/banner-drawer", ["exports", "bocce/mixins/enmodal", "bocce/mixins/notify", "bocce/utilities/dialog"], function (_exports, _enmodal, _notify, _dialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  var _default = _exports.default = Ember.Component.extend(_enmodal.default, _notify.default, {
    init(...args) {
      this._super(...args);
      const hashSplit = window.location.hash.split('/');
      let course = null;
      if (hashSplit.length > 1) {
        course = hashSplit[1];
        if (+course) {
          Ember.$.get(`/interface/banners/user/active/-1/${course}`).then(ban => {
            if (ban.id && ban.message) {
              let modal_type = ban.dismiss_condition == "survey" ? "survey" : null;
              let modal_id = ban.dismiss_condition == "survey" ? "course" : null;
              this.actions.notify(ban.message, ban.is_dismissable, modal_type, modal_id, 100, this.session, false, ban.link, ban.id, "banner", ban.dismiss_condition_id);
              this.session.last_banner_serial = ban.id;
            }
          });
        }
      }
    },
    banners: Ember.computed.reads('session.banners'),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    actions: {
      toggleDrawer: function () {
        if (!this.drawerActive) {
          this.set('drawerActive', true);
          this.set('session.notification', false);
        } else {
          this.set('drawerActive', false);
        }
      },
      dismissDashboardNotif: function (id) {
        this.send('dismissNotif', id);
        if (Ember.$.isMobile) {
          (0, _dialog.default)("You can find it later in the user menu.");
        } else {
          (0, _dialog.default)("You can find it later in the user menu or by clicking the logo in the top left corner.");
        }
      },
      //Dismiss banner by index
      dismissNotif: function (id) {
        // ID is not the banner id, but the index of the banner in the array
        // the banner id is the 'serial' property
        let singleBanner = this.session.banners[id],
          dismissable = singleBanner.dismissable,
          banner_id = singleBanner.id,
          course_id = this.get('session.course.id'),
          term_id = this.get('session.termID');
        if (dismissable) {
          /* eslint-disable-next-line ember/no-get */
          let banners = this.get('session.banners');

          // Mark announcement as read
          if (banners[id].modal_type === 'discussion') {
            this.store.findRecord('discussion', banners[id].modal_id).then(model => {
              model.set('read', true);
              if (model.get('hasUnreadResponses')) {
                model.set('markReadThrough', model.get('lastResponseId'));
              }
              model.save();
            });
          }

          // Dismiss the banner for the particular user on the back end
          // show error if failure.
          let dismissRequest = Ember.$.get(`/interface/banners/${banner_id}/dismiss/${term_id}/${course_id}`);

          // Remove from banner drawer
          banners.splice(id, 1);
          this.set('session.banners', banners);
          this.notifyPropertyChange('banners');
        }
      },
      viewNotif: function (index) {
        let banner = this.session.banners[index];
        if (banner.reload) {
          location.reload(true);
          return;
        }

        // Remove from banner drawer
        let banners = this.session.banners;
        banners.splice(index, 1);
        this.set('session.banners', banners);
        this.notifyPropertyChange('banners');

        // Close drawer
        this.set('drawerActive', false);
        if (banner.link) {
          window.open(banner.link, '_blank');
        } else if (banner.modal_id) {
          this.send('viewModal', banner.modal_type, banner.modal_id);
        } else {
          (0, _dialog.default)(banner.message);
        }
      }
    }
  });
});