define("bocce/mixins/enmodal", ["exports", "bocce/mixins/editable", "bocce/utilities/dialog"], function (_exports, _editable, _dialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-new-mixins */
  var _default = _exports.default = Ember.Mixin.create(_editable.default, {
    actions: {
      newTypeModal: function (type) {
        this.transitionToRoute(`classroom.lessons.${type}-new`);
      },
      viewModal: function (type, modal) {
        this.transitionToRoute(`classroom.lessons.${type}`, modal);
      },
      closeModals: function (posting, editorHasContent) {
        let dialogPromise;
        editorHasContent = editorHasContent || this.editorHasContent;
        if (!posting && editorHasContent) {
          const message = 'Are you sure you want to close this window? All unsaved work will be lost.';
          dialogPromise = (0, _dialog.default)(message, ['Yes', 'No']);
        } else {
          dialogPromise = Promise.resolve('Yes');
        }
        dialogPromise.then(selection => {
          if (selection === 'Yes') {
            if (this.files) {
              this.send('clearAllFiles');
            }
            if (this.actions && this.actions.teardown) {
              this.send('teardown');
            }
            this.send('deleteArchivedText', {
              'name': 'Auto Save'
            }, true);
            if (this.target.currentRouteName !== "dashboard") {
              /* eslint-disable-next-line ember/no-jquery */
              Ember.$('.side-panel').removeClass('on-modal');
            }
            this.send('destroyEditor');
            if (this.target.currentRouteName !== "dashboard") {
              this.transitionToRoute('classroom.lessons');
            }
          }
        });
      },
      viewAssignmentInLesson: function (id) {
        var that = this;
        this.store.findRecord('item', id).then(function (items) {
          that.transitionToRoute('classroom.lessons', items.get('lesson').id, id);
        });
      }
    }
  });
});