define("bocce/routes/classroom/lessons", ["exports", "bocce/mixins/discussable", "bocce/mixins/lesson-redirect"], function (_exports, _discussable, _lessonRedirect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  var _default = _exports.default = Ember.Route.extend(_discussable.default, _lessonRedirect.default, {
    router: Ember.inject.service(),
    scrollThrough: false,
    shouldRenderLoading: true,
    mainPanelHalfHeight: false,
    discussionsLoaded: false,
    beforeModel: function (transition) {
      // Look and see if we have the pages for this lesson already loaded.
      // If not, make a reload call to /courses/NN/lessons/MM?all=1 to
      // get all the data in a single go.
      let params = transition.to.find(ri => ri.name === 'classroom.lessons').params;
      let surveyIncomplete = this.session.get('courseConcludedNoSurvey');
      if (surveyIncomplete) {
        this.transitionTo('classroom.lessons.survey', {
          queryParams: {
            concluded: true
          }
        });
      }
      if (parseInt(params.lesson_id) === -1) {
        // if lesson_id is -1, get the most recent lesson the user viewed
        return this.getRedirectLessonInfo().then(i => {
          params.lesson_id = i.lesson_id;
          params.item_id = i.item_id;
        });
      }
      let lesson = this.store.peekRecord('lesson', params.lesson_id),
        needs_reload_p = true;
      if (!lesson) {
        // If we didn't find the lesson, that could mean that we haven't
        // loaded the data at all yet, or that lesson doesn't exist.
        let all_lessons = this.store.peekAll('lesson');

        /* eslint-disable-next-line ember/no-get */
        if (all_lessons.get('length') > 0) {
          lesson = all_lessons.objectAt(0);
          /* eslint-disable-next-line ember/no-get */
          params.lesson_id = lesson.get('id');
        }
      } else {
        /* eslint-disable-next-line ember/no-get */
        let item = lesson.get('items').objectAt(0);
        if (item) {
          /* eslint-disable-next-line ember/no-get */
          needs_reload_p = !this.store.peekRecord(item.get('type').toLowerCase(), item.get('id'));

          /* eslint-disable-next-line ember/no-get */
          Ember.debug('Checking ' + item.get('type').toLowerCase(), item.get('id'), needs_reload_p);
        }
      }
      if (needs_reload_p) {
        Ember.debug('Reloading lesson ' + params.lesson_id);
        return this.store.findRecord('lesson', params.lesson_id, {
          adapterOptions: {
            all: 1
          },
          reload: true
        });
      }
    },
    model(params) {
      let lesson_id = params.lesson_id;
      let item_id = params.item_id;
      let lessonPromise = this.store.findRecord('lesson', lesson_id);
      let course_id = this.paramsFor('classroom').course_id;
      let itemPromise = lessonPromise.then(lesson => {
        this.store.nestResources('item', [{
          course: course_id
        }, {
          lesson: lesson_id
        }]);

        // The item should already be loaded at this point, since this
        // follows the lesson promise
        let item = this.store.peekRecord('item', item_id);
        if (!item) {
          /* eslint-disable-next-line ember/no-get */
          return lesson.get('items').objectAt(0);
        }
        return item;
      });
      this.updateLessonInfo(lesson_id, item_id);
      let topicsPromise = lessonPromise.then(lesson => {
        /* eslint-disable-next-line ember/no-get */
        return Promise.all(lesson.get('items').map(item => {
          /* eslint-disable-next-line ember/no-get */
          let type = item.get('type').toLowerCase();
          /* eslint-disable-next-line ember/no-get */
          let id = item.get('id');
          return this.store.findRecord(type, id);
        }));
      });
      return Ember.RSVP.hash({
        course: this.store.findRecord('course', course_id),
        lesson: lessonPromise,
        item: itemPromise,
        topics: topicsPromise,
        session: {
          panel: {
            activity: true
          }
        }
      });
    },
    afterModel: function () {
      var that = this,
        st = this.scrollThrough;
      const itemId = this.paramsFor('classroom.lessons').item_id;
      if (itemId === '0') {
        /* eslint-disable-next-line ember/no-get */
        this.modelFor('classroom.lessons').lesson.get('items').then(function (items) {
          /* eslint-disable-next-line ember/no-get */
          var firstItem = items.get('currentState')[0];
          if (firstItem) {
            that.transitionTo('classroom.lessons', 0, firstItem.id);
          }
        });
      }
      if (st) {
        this.set('scrollThrough', false);
      } else {
        if (document.documentElement.clientWidth < 1034) {
          let classroom = document.querySelector('.classroom');
          let defaultPanel = this.get('session.panel');
          if (defaultPanel !== 'syllabus' && itemId != 'peer-to-peer-support-advice-cards') {
            if (classroom) {
              classroom.classList.add('active');
            }
          } else {
            if (classroom) {
              classroom.classList.remove('active');
            }
          }
        }
      }
      if (this.get('session.panel') === 'chat') {
        this.controllerFor('classroom').loadEvents();
      }
      this.controllerFor('classroom.lessons').set('interactionsBooted', true);
      let position_sidepanel = () => {
        let $n,
          $sp,
          dest,
          retry_p = false;
        if (!st) {
          /* eslint-disable-next-line ember/no-jquery */
          $sp = Ember.$('.side-panel');
          let activeLink = $sp.find('a.active');
          if (activeLink.length > 0) {
            dest = activeLink.position().top - 100;
            if (dest < 0) {
              dest = 0;
              retry_p = true;
            }
          }
          let visibleActiveLink = $sp.find('a.active:in-viewport');
          if (visibleActiveLink.length === 0 && activeLink.length > 0) {
            $sp.scrollTop(activeLink.position().top - $sp.height() + 100 + $sp.scrollTop());
          }
          let contentNode = this.controller.getItemNode();
          if (contentNode.length > 0) {
            /* eslint-disable-next-line ember/no-jquery */
            Ember.$('.main-panel').scrollTop(0).scrollTop(contentNode.position().top - 100);
          }
        } else {
          /* eslint-disable-next-line ember/no-jquery */
          $n = Ember.$('.side-panel a.active:in-viewport');
          if ($n.length === 0 || $n.position().top < 50) {
            /* eslint-disable-next-line ember/no-jquery */
            $sp = Ember.$('.side-panel');
            /* eslint-disable-next-line ember/no-jquery */
            $n = Ember.$('.side-panel a.active');
            if ($n.length > 0) {
              $sp.scrollTop($n.position().top - $sp.height() + 100 + $sp.scrollTop());
            }
          }
        }

        // Sometimes while loading all LinkTo components have the .active class. Putting this class on the syllabus
        // lets us keep the syllabus items from all being red while still loading lesson content.
        this.set('session.lessonLoaded', true);

        // If we're still loading, wait a second and try again
        /* eslint-disable-next-line ember/no-jquery */
        if (retry_p && Ember.$('.loading-mask').is(':visible')) {
          Ember.run.later(this, position_sidepanel, 1000);
        }
      };
      Ember.run.scheduleOnce('afterRender', this, position_sidepanel);
    },
    renderTemplate() {
      this.render();
      let discussionsController = this.controllerFor('discussions'),
        assignments = this.store.findAll('assignment');
      discussionsController.set('loadedAssignments', assignments);
      this.set('discussionsLoaded', true);
    },
    updateLessonInfo(lessonId, itemId) {
      this.session.setProperties({
        lessonId,
        itemId
      });
    },
    actions: {
      updatePath: function () {
        /* eslint-disable-next-line ember/no-jquery */
        var allInViewport = Ember.$('li.lesson-content:in-viewport'),
          /* eslint-disable-next-line ember/no-jquery */
          $reset = Ember.$('.main-panel.reset'),
          content = this.currentModel.item.id,
          inViewport,
          new_id,
          contentNode;
        inViewport = allInViewport.eq(1);
        if (!this.mainPanelHalfHeight) {
          /* eslint-disable-next-line ember/no-jquery */
          this.set('mainPanelHalfHeight', Ember.$('.main-panel').height() / 2);
        }
        if (inViewport.length === 0 || inViewport.position().top >= this.mainPanelHalfHeight) {
          inViewport = allInViewport.first();
        }
        if (inViewport) {
          new_id = inViewport.attr('id');
          let itemChanged = new_id !== content;
          let hasReset = $reset.length > 0;

          /* eslint-disable-next-line ember/no-get */
          let currentRouteName = this.get('router.currentRouteName');
          let regex = /^classroom\.lessons(\.index)?$/;
          let onLessons = regex.test(currentRouteName);

          /* eslint-disable-next-line ember/no-jquery */
          let loadingMask = Ember.$('.visible .loading-mask').length > 0;
          if ((itemChanged || hasReset) && onLessons && !loadingMask) {
            if ($reset.length === 0) {
              this.set('scrollThrough', true);
              this.set('shouldRenderLoading', false);
              this.transitionTo('classroom.lessons', new_id);
            } else {
              /* eslint-disable-next-line ember/no-jquery */
              Ember.$('.main-panel.reset').removeClass('reset');
              /* eslint-disable-next-line ember/no-jquery */
              contentNode = Ember.$('#' + content);
              if (contentNode.length > 0) {
                /* eslint-disable-next-line ember/no-jquery */
                Ember.$('.main-panel').scrollTop(0).scrollTop(contentNode.position().top - 100);
              }
            }
          }

          /* eslint-disable-next-line ember/no-jquery */
          if (!Ember.$.isMobile) {
            /* eslint-disable-next-line ember/no-jquery */
            this.controller.boot_area(Ember.$('#' + new_id), false, true, true, true, true);
          }

          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('#' + new_id).find('img').not('.img_booted').each(function (key, img_node) {
            if (img_node.attributes['data-src']) {
              img_node.setAttribute('src', img_node.attributes['data-src'].value);
              img_node.classList.add('img_booted');
            }
          });
        }
      },
      resetHeight: function () {
        var content, contentNode;
        // Reset the current half-height marker
        // Also make sure scroll-spy is caught up

        if (this.mainPanelHalfHeight) {
          /* eslint-disable-next-line ember/no-jquery */
          this.set('mainPanelHalfHeight', Ember.$('.main-panel').height() / 2);
        }

        // Only mess with scroll-spy if user isn't scrolling
        if (!this.amScrolling) {
          content = this.paramsFor('classroom.lessons').item_id;
          content = content.replace('=', '\\=');
          /* eslint-disable-next-line ember/no-jquery */
          contentNode = Ember.$('#' + content);
          if (contentNode.length > 0) {
            /* eslint-disable-next-line ember/no-jquery */
            Ember.$('.main-panel').scrollTop(0).scrollTop(contentNode.position().top - 10);
          }
        }
      },
      bookmarkTopic: function () {
        var itemFromModel = this.currentModel.item,
          bookmarked = !itemFromModel.bookmarked;
        Ember.set(itemFromModel, 'bookmarked', bookmarked);
      },
      completeTopic: function () {
        var itemFromModel = this.currentModel.item,
          completed = !itemFromModel.completed;
        Ember.set(itemFromModel, 'completed', completed);
      },
      willTransition: function () {
        // Clean up on transition between lessons
        if (this.shouldRenderLoading) {
          //Clean up video and audio player
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('audio').each(function () {
            this.pause();
          });
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('video').each(function () {
            this.pause();
          });
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('.audio-player').removeClass('active');
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('.video-player').removeClass('active').addClass('minified');
        }
      },
      loading(transition) {
        Ember.debug(`LessonsRoute.shouldRenderLoading: ${this.shouldRenderLoading}`);
        if (!this.shouldRenderLoading) {
          transition.then(() => {
            this.set('shouldRenderLoading', true);
          });
        }
        // When loading returns false, the loading template does not
        // render. This lets the transition take place (updating the URL
        // and link-to active states) without disrupting the user
        // scroll/interaction with the lesson
        return this.shouldRenderLoading;
      }
    }
  });
});