define("bocce/models/file_upload", ["exports", "bocce/models/quiz_question", "bocce/helpers/upload"], function (_exports, _quiz_question, _upload) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let FileUpload = _exports.default = (_dec = Ember.inject.service, (_class = class FileUpload extends _quiz_question.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "session", _descriptor, this);
      _defineProperty(this, "default_answer", 0);
    }
    async onNextOrFinish() {
      if (this.quizzes.isTimedQuiz && this.answerUnsaved) {
        this.quizzes.uploadingQuestion = true;
        await this.setGivenAnswer();
        await this.updateCanvas();
      }
      this.answerUnsaved = false;
    }
    async setGivenAnswer() {
      const answerFileId = await this.getAnswerFileId();
      if (answerFileId) {
        this.set('given_answer', answerFileId);
      }
    }
    async getAnswer() {
      if (this.quizzes.isTimedQuiz) {
        return this.given_answer;
      } else {
        const answerFileId = await this.getAnswerFileId();
        return answerFileId ?? this.default_answer;
      }
    }
    async getAnswerFileId() {
      try {
        const file = this.get('given_answer');
        if (file) {
          return await (0, _upload.default)(file, this.session.get('user.id'), this.quizzes.store, 'quizzes', false, null, `/api/v1/courses/${this.session.get('course.id')}/quizzes/${this.quizzes.quiz.search_id}/submissions/self/files`);
        } else {
          return null;
        }
      } catch (e) {
        return null;
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  ;
});