define("bocce/mixins/discussable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable-next-line ember/no-new-mixins */
  var _default = _exports.default = Ember.Mixin.create({
    activityMessage: Ember.computed('message', function () {
      var txt = this;
      return txt;
    }),
    postTime: Ember.computed('date', function () {
      var retVal = moment(this.date).tz('America/New_York').format('MMMM Do, h:mmA') + ' ET';
      return retVal;
    }),
    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    postAuthor: Ember.computed('message', function () {
      var retVal = this.user;
      return retVal;
    }),
    // Dead code?
    // Track whether or not a model is set
    // Set up/break down modal accordingly
    /* eslint-disable-next-line ember/no-observers */
    toggleModal: Ember.observer('model', 'activeNewDiscussion', 'classroom.activeNewDiscussion', function () {
      var mod = this.model || {},
        /* eslint-disable-next-line ember/no-get */
        and = this.activeNewDiscussion || this.get('classroom.activeNewDiscussion');
      let setupCameraTag = () => {
        if (window.CameraTag) {
          window.CameraTag.setup();
        }
      };

      // TODO: EUGENE - Remove this for Winter 25. This is strictly to fix old-style Tidal embeds
      let convertTidalEmbed = src => {
        // Extract parameters from src URL
        const urlParams = new URLSearchParams(src.split('?')[1]);
        const type = urlParams.get('type');
        const id = urlParams.get('id');

        // Define mapping between type parameters and full words
        const typeMapping = {
          t: 'tracks',
          v: 'videos',
          p: 'playlists',
          a: 'albums'
        };

        // If type is not recognized or id is not available, return null
        if (!type || !id) {
          return null;
        }

        // If type is recognized, construct new URL
        if (typeMapping[type]) {
          const newURL = `https://embed.tidal.com/${typeMapping[type]}/${id}`;
          return newURL;
        } else {
          return null; // Return null if type is not recognized
        }
      };
      if (and) {
        if (and === 'discussion') {
          and = 'conversation';
        }
        this.set('discussion.inEditor', true);

        /* eslint-disable-next-line ember/no-incorrect-calls-with-inline-anonymous-functions */
        Ember.run.scheduleOnce('afterRender', this, function () {
          setupCameraTag();
          let tidalEmbeds = Ember.$('.floating-modal iframe[src*="embed.tidal.com/player"]');
          tidalEmbeds.each((index, el) => {
            let src = Ember.$(el).attr('src');
            let newSrc = convertTidalEmbed(src);
            if (newSrc) {
              Ember.$(el).attr('src', newSrc);
            }
          });
        });
      } else if (mod.id) {
        // There is a "read" status for conversations but disabling this
        // for conversations for now. - JRW
        if (mod.constructor.modelName !== 'conversation') {
          this.mark_read(mod);
          setupCameraTag();

          /* eslint-disable-next-line ember/no-incorrect-calls-with-inline-anonymous-functions */
          Ember.run.scheduleOnce('afterRender', this, function () {
            /* eslint-disable-next-line ember/no-jquery */
            Ember.$('.floating-modal video').attr('preload', 'none');
            let tidalEmbeds = Ember.$('.floating-modal iframe[src*="embed.tidal.com/player"]');
            tidalEmbeds.each((index, el) => {
              let src = Ember.$(el).attr('src');
              let newSrc = convertTidalEmbed(src);
              if (newSrc) {
                Ember.$(el).attr('src', newSrc);
              }
            });
          });
        }
      } else {
        this.set('discussion.inEditor', false);
        this.set('discussion.isLesson', false);
        this.set('discussion.bodyInput', '');
        this.set('bodyInput', '');
        this.set('titleInput', '');
      }
      this.discussion.send('clearAllFiles');
    }),
    mark_read: function (model) {
      model.set('read', true);
      if (model.get('hasUnreadResponses')) {
        model.set('markReadThrough', model.get('lastResponseId'));
      }
      model.save();
    },
    actions: {
      treatAssignment: function (id) {
        var that = this;
        this.store.findRecord('assignment', id).then(function (assignment) {
          var submission;
          if (that.session.get('isInstructor') || that.session.get('isObserver')) {
            submission = assignment.get('submissions.content.currentState');
            if (submission.length > 0) {
              submission = submission[0].id;
            } else {
              submission = false;
            }
            if (submission) {
              that.send('viewModal', 'submission', submission);
            } else {
              that.send('viewModal', 'no-submissions', assignment.id);
            }
          } else {
            if (that.session.get('course.isReadOnly')) {
              submission = assignment.get('currentUserSubmission');
              if (submission) {
                that.send('viewModal', 'submission', submission);
              } else {
                that.send('viewModal', 'submission-new', assignment.id);
              }
            } else {
              that.store.nestResources('submission', [{
                section: that.session.section.id
              }, {
                assignment: assignment.id
              }]);
              that.store.findAll('submission', {
                reload: true
              }).then(submissions => {
                submission = submissions.find(s => {
                  let mine = s.get('my_submission');
                  let correct_assignment = s.get('assignment.id') === assignment.id;
                  return mine && correct_assignment;
                });
                if (submission) {
                  that.send('viewModal', 'submission', submission.id);
                } else {
                  that.send('viewModal', 'submission-new', assignment.id);
                }
              }, () => {
                that.send('viewModal', 'submission-new', assignment.id);
              });
            }
          }
        });
      },
      subscribe: function () {
        /* eslint-disable-next-line ember/no-get */
        var model = this.get('discussion.model');
        model.set('subscribed', !model.get('subscribed'));
        model.save();
      }
    }
  });
});