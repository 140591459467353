define("bocce/templates/components/user-portrait", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "my4w+zca",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[35,0,[\"image_url\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"img\"],[15,\"alt\",[31,[\"User avatar of \",[34,0,[\"name\"]]]]],[15,\"src\",[31,[[34,0,[\"image_url\"]]]]],[14,\"onerror\",\"this.src='/images/avatar.png'\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[35,0,[\"avatar_url\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"img\"],[15,\"alt\",[31,[\"User avatar of \",[34,0,[\"name\"]]]]],[15,\"src\",[31,[[34,0,[\"avatar_url\"]]]]],[14,\"onerror\",\"this.src='/images/avatar.png'\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"svg\"],[14,\"width\",\"100\"],[14,\"height\",\"100\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[12],[2,\"\\n        \"],[10,\"rect\"],[14,\"width\",\"100%\"],[14,\"height\",\"100%\"],[14,\"fill\",\"#b4b4b4\"],[12],[13],[2,\" \"],[3,\" Background \"],[2,\"\\n        \"],[10,\"text\"],[14,\"x\",\"50%\"],[14,\"y\",\"50%\"],[14,\"alignment-baseline\",\"central\"],[14,\"text-anchor\",\"middle\"],[14,\"font-family\",\"Arial\"],[14,\"font-size\",\"30\"],[14,\"fill\",\"#ffffff\"],[12],[2,\"\\n          \"],[1,[34,1]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"img\"],[15,\"alt\",[31,[\"No avatar available for \",[34,0,[\"name\"]]]]],[14,\"src\",\"front_end/css/style-assets/no-avatar.gif\"],[15,\"alt\",[31,[\"No user avatar available for \",[34,0,[\"name\"]]]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[8,\"user-dot\",[],[[\"@user\"],[[34,0]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"user\",\"initials\",\"svgPlaceHolder\",\"if\"]}",
    "meta": {
      "moduleName": "bocce/templates/components/user-portrait.hbs"
    }
  });
});