define("bocce/templates/classroom/lessons/conversation-new-with", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "hsJ1ET+x",
    "block": "{\"symbols\":[],\"statements\":[[3,\" reuse the conversation template \"],[2,\"\\n\"],[19,\"classroom/lessons/conversation\",[]]],\"hasEval\":true,\"upvars\":[]}",
    "meta": {
      "moduleName": "bocce/templates/classroom/lessons/conversation-new-with.hbs"
    }
  });
});