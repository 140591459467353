define("bocce/controllers/classroom/lessons", ["exports", "ember-data", "bocce/mixins/editable", "bocce/mixins/assignments", "bocce/mixins/uploadable", "bocce/mixins/audio-rec", "bocce/mixins/video-rec", "bocce/mixins/rtc-rec", "bocce/mixins/enmodal", "bocce/mixins/boot", "bocce/mixins/interactions/piano", "bocce/utilities/dialog", "bocce/mixins/notify"], function (_exports, _emberData, _editable, _assignments, _uploadable, _audioRec, _videoRec, _rtcRec, _enmodal, _boot, _piano, _dialog, _notify) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable-next-line ember/use-ember-data-rfc-395-imports */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-observers */
  /* eslint-disable-next-line ember/no-mixins */
  var _default = _exports.default = Ember.Controller.extend(_notify.default, _editable.default, _assignments.default, _uploadable.default, _audioRec.default, _videoRec.default, _rtcRec.default, _boot.default, _enmodal.default, {
    classroom: Ember.inject.controller(),
    discussions: Ember.inject.controller(),
    // required by EditableMixin
    lessons: Ember.inject.controller('classroom.lessons'),
    // required by UploadableMixin
    submission: Ember.inject.controller('classroom.lessons.submission'),
    // loadHeartbeatData uses this

    heartbeat: Ember.inject.service(),
    features: Ember.inject.service(),
    bookmarks: Ember.inject.service(),
    router: Ember.inject.service(),
    bookmarksService: Ember.computed.alias('bookmarks'),
    userprofileService: Ember.inject.service('userprofile'),
    topicsQueue: false,
    // Temporary function that we can call to start the misc. boot functions. Everything called from this should get
    // refactored; right now it mostly works, but is really hard to understand what does what and what relies on what.
    // Also, not all of it works.
    getThingsRolling() {
      this.afterBoot();
      this.heartbeat.begin(this);
    },
    getItemNode() {
      /* eslint-disable-next-line ember/no-get */
      let itemId = `#${this.get('model.item.cssString')}`;
      /* eslint-disable-next-line ember/no-jquery */
      let itemNode = Ember.$(itemId);
      return itemNode;
    },
    afterBoot() {
      // Open up syllabus view on first load...
      /* eslint-disable-next-line ember/no-jquery */
      if (Ember.$('.classroom').hasClass('loading')) {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.classroom').removeClass('loading').addClass('active');
      }
      /* eslint-disable-next-line ember/no-jquery */
      Ember.$('.main-panel').addClass('no-scroll');
      /* eslint-disable-next-line ember/no-jquery */
      Ember.$('.main-panel-container').off('resize');

      // Create a play listener for any videos with a "post-video-embed" class -- if the play fails, we'll replace the
      // source with a placeholder video and try again every 20 seconds until the video is fixed
      document.addEventListener('error', function (event) {
        // Check if the event target is a video source inside a .video-container
        if (event.target.matches('.video-container video source')) {
          let video = event.target.closest('.video-container').querySelector('video');
          let source = event.target;
          let oldSrc = source.getAttribute('src');
          let placeholderSrc = '/front_end/images/video_processing_placeholder.mp4';

          // If the video is not the placeholder video, replace the source with the placeholder video and try again
          if (oldSrc !== placeholderSrc) {
            source.setAttribute('src', placeholderSrc);
            video.load();
            video.play();
          }
          function checkVideoStatus() {
            let cacheBustSRC = oldSrc + '?cacheBust=' + new Date().getTime();
            Ember.$.ajax({
              url: cacheBustSRC,
              type: 'HEAD',
              cache: false,
              crossDomain: true,
              success: function (response, textStatus, xhr) {
                // If the video is fixed, clear the interval and play the video
                // Check for OK and 302
                if (xhr.status === 200 || xhr.status === 302) {
                  let wasPlaying = !video.paused;
                  // Set video source back to the original source
                  source.setAttribute('src', cacheBustSRC);
                  video.load();
                  if (wasPlaying) {
                    video.play();
                  }
                  clearInterval(interval);
                } else {
                  console.log("Uploaded video is still processing...");
                }
              },
              error: function (xhr, textStatus, errorThrown) {
                console.log("Uploaded video is still processing...");
              }
            });
          }
          let interval = setInterval(checkVideoStatus, 20000);
        }
      }, true);
      setTimeout(() => {
        this.afterAfterBoot();
      }, 500);
    },
    afterAfterBoot() {
      /* eslint-disable-next-line ember/no-jquery */
      Ember.$('.main-panel').removeClass('no-scroll');
      let current_item = this.getItemNode();
      if (current_item && current_item.length) {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.main-panel').scrollTop(0).scrollTop(current_item.position().top - 100);
      }

      /* eslint-disable-next-line ember/no-jquery */
      if (Ember.$('#bocce-piano .pianoContainer').length === 0) {
        this.setupPiano();
      }
    },
    setupPiano() {
      // Set up piano
      let config = {
        'keyWidth': 30,
        'noteLabels': 'true',
        'middleC': 'true',
        'hideKey': false,
        'hotKeys': true,
        'placementId': 'bocce-piano',
        'noKeys': false,
        'keyDirs': {}
      };
      for (let i = 0; i < 85; i++) {
        config['keyDirs']['key_' + i] = 'https://intro.online.berklee.edu/audio/piano/key_' + i + '.mp3';
      }
      window.piano = new _piano.default(config);
      window.piano.run();
    },
    nextLesson: Ember.computed('classroom.nextLessonMap', 'model.lesson.id', function () {
      return _emberData.default.PromiseObject.create({
        promise: new Promise(resolve => {
          /* eslint-disable-next-line ember/no-get */
          this.get('classroom.nextLessonMap').then(map => {
            /* eslint-disable-next-line ember/no-get */
            let nextLessonId = parseInt(this.get('model.lesson.id')) + 1;
            resolve(map[nextLessonId]);
          });
        })
      });
    }),
    showNextLesson: Ember.computed('nextLesson', 'features.staticContent', function () {
      return _emberData.default.PromiseObject.create({
        promise: new Promise(resolve => {
          /* eslint-disable-next-line ember/no-get */
          this.get('nextLesson').then(nextLesson => {
            resolve(Boolean(!this.get('features.staticContent') && nextLesson));
          });
        })
      });
    }),
    // this is sort of the equivalent to the modalId. It is needed because
    // there needs to be a parent for the response text and the lesson
    // can have an in page response
    replyContainerId: Ember.computed(function () {
      return 'in-page-discussion';
    }),
    nextLessonAvail: Ember.computed('nextLesson', function () {
      var retRes = false,
        nxt = this.nextLesson;
      if (nxt) {
        retRes = true;
      }
      return retRes;
    }),
    nextLessonLocked: Ember.computed('nextLesson', function () {
      return _emberData.default.PromiseObject.create({
        promise: new Promise(resolve => {
          this.nextLesson.then(next => {
            resolve(next && next.get('locked'));
          });
        })
      });
    }),
    lastLesson: Ember.computed('classroom.lastLesson', 'model.lesson.id', function () {
      var retRes = false,
        /* eslint-disable-next-line ember/no-get */
        last = this.get('classroom.lastLesson');
      if (last && this.model.lesson.get('id') === last.id) {
        retRes = true;
      }
      return retRes;
    }),
    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    discussionModel: Ember.computed.reads('model.discussion'),
    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    unlocks_at_formatted: Ember.computed('nextLesson', function () {
      /* eslint-disable-next-line ember/no-get */
      return moment(this.get('nextLesson.unlocks_at')).tz('America/New_York').format('MMM Do');
    }),
    /* eslint-disable-next-line ember/no-observers */
    currentPathChanged: Ember.observer('model.currentPath', function () {
      var current_user = this.session.get('user');

      /* eslint-disable-next-line ember/no-get */
      if (!this.get('model.item')) {
        return;
      }
      this.model.course.set('lastLesson', this.model.lesson);
      this.model.course.set('lastItem', this.model.item);

      // Mark the item as viewed in canvas.
      // Re-save this, even if it's already read, so we can track activity.
      if (!this.session.get('course.isReadOnly')) {
        this.model.item.set('read', true);
        this.model.item.save();
      }
      current_user.then(user => {
        let lv = user.get('lastviewed');
        lv[this.model.course.get('course_id')] = {
          lesson: this.model.lesson.get('id'),
          item_id: this.model.item.get('id')
        };
        user.set('lastviewed', lv);
        user.save();
      });
    }),
    lessonsController: Ember.computed(function () {
      return this;
    }),
    actions: {
      nextLesson: async function (curLesson) {
        if (!(await this.nextLessonLocked)) {
          this.classroom.send('nextLesson', curLesson);
        }
      },
      showcaseDeleteItem: function (item) {
        (0, _dialog.default)('Are you sure you want to delete this lesson from the showcase? This action cannot be undone.', ['Yes, delete it.', 'Cancel']).then(selection => {
          if (selection === 'Yes, delete it.') {
            let lesson = item.get('lesson');
            let destroyLesson = lesson.get('items').get('length') === 1 || false;
            item.destroyRecord();
            if (destroyLesson) {
              lesson.destroyRecord();
            }
          }
        });
      },
      showcaseEditItem: function () {
        this.set('editingLessonTitle', true);
      },
      cancelEdit: function () {
        this.set('editingLessonTitle', false);
      },
      showcaseSaveItemTitle: function (item) {
        /* eslint-disable-next-line ember/no-jquery */
        let newTitle = Ember.$(`.lesson-edit-input-${item.id}`).val();
        item.set('title', newTitle);
        item.set('showcaseEdit', true);
        item.save();
        this.set('editingLessonTitle', false);
      },
      updateShortcutsDrawer(title) {
        this.classroom.set('shortcutsDrawerLabel', title);
      },
      submitAdviceCard() {
        this.set('session.section.adviceCards.iSubmitted', true);
      }
    }
  });
});