define("bocce/models/discussion", ["exports", "@ember-data/model", "bocce/mixins/discussable", "bocce/mixins/attachments-mixin"], function (_exports, _model, _discussable, _attachmentsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // app/models/discussion.js
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  var _default = _exports.default = _model.default.extend(_discussable.default, _attachmentsMixin.default, {
    db_id: (0, _model.attr)('number'),
    user: (0, _model.belongsTo)('user', {
      async: true
    }),
    course: (0, _model.belongsTo)('course', {
      async: true
    }),
    section: (0, _model.belongsTo)('section', {
      async: true
    }),
    title: (0, _model.attr)('string'),
    message: (0, _model.attr)('string'),
    date: (0, _model.attr)('date'),
    responses: (0, _model.hasMany)('response', {
      async: true
    }),
    // embededContent: DS.belongsTo('embededContent', {async: true}),
    expandedResponses: null,
    preExisting: false,
    locked: (0, _model.attr)('boolean'),
    is_announcement: (0, _model.attr)('boolean'),
    is_required: (0, _model.attr)('boolean'),
    is_admin_announcement: (0, _model.attr)('boolean'),
    admin_announcement_term: (0, _model.attr)('number'),
    is_discussion: (0, _model.attr)('boolean'),
    attachments: (0, _model.attr)(),
    subscribed: (0, _model.attr)('boolean'),
    i_submitted: (0, _model.attr)('boolean'),
    due_at: (0, _model.attr)(),
    read: (0, _model.attr)('boolean'),
    unread_count: (0, _model.attr)('number'),
    activity_type: (0, _model.attr)('string'),
    dueWithinOneWeek: (0, _model.attr)('boolean'),
    dueWithinTwoWeeks: (0, _model.attr)('boolean'),
    showcase: (0, _model.attr)(),
    poll_id: (0, _model.attr)(),
    poll_raw: (0, _model.attr)(),
    instructor_ids: (0, _model.attr)(),
    // Last "read" response id
    markReadThrough: (0, _model.attr)('number'),
    /* eslint-disable-next-line ember/require-return-from-computed, ember/require-computed-property-dependencies */
    formattedDate: Ember.computed('date', function () {
      if (this.is_required) {
        var d = this.date;
        if (!d) {
          return '';
        }
        return moment(d).tz('America/New_York').calendar();
      }
    }),
    /* eslint-disable-next-line ember/require-return-from-computed, ember/require-computed-property-dependencies */
    formattedDiscussionDueDate: Ember.computed(function () {
      if (this.due_at) {
        var d = this.due_at;
        if (!d) {
          return '';
        }
        return moment(d).tz('America/New_York').calendar();
      }
    }),
    // Creating this computed property so that assignments and discussions both
    // have the same formatted due date property
    formattedDueDate: Ember.computed.alias('formattedDiscussionDueDate'),
    dueToday: Ember.computed('due_at', function () {
      var d = this.due_at;
      if (d && moment(d).isSame(Date(), 'day')) {
        return true;
      }
      return false;
    }),
    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    hasUnreadResponses: Ember.computed('responses.@each.{read,length}', 'unread_count', 'read', 'markReadThrough', 'lastResponseId', function () {
      if (!this.read) {
        return true;
      }
      if (this.markReadThrough === this.lastResponseId) {
        return false;
      }
      if (this.mkRead) {
        return false;
      }
      return this.responses.filterBy('read', false).get('length') > 0;
    }),
    unread: Ember.computed.alias('hasUnreadResponses'),
    lastResponseId: Ember.computed('responses', function () {
      var last_id = this.responses.map(function (r) {
        return r.get('id');
      }).reduce(function (max_id, id) {
        id = parseInt(id, 10);
        if (id > max_id) {
          return id;
        }
        return max_id;
      }, -1);
      if (last_id > -1) {
        return last_id;
      }
      return null;
    }),
    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    lastResponse: Ember.computed('responses', function () {
      var retVal = this;
      /* eslint-disable-next-line ember/no-get */
      if (this.get('responses.length') > 0) {
        retVal = this.responses.slice(-1)[0];
      }
      return retVal;
    }),
    otherResponders: Ember.computed('responses', 'responses.length', function () {
      var retVal = [],
        responses = this.responses,
        respLength = responses.get('length');
      if (respLength > 0) {
        responses.forEach(function (response) {
          if (retVal.indexOf(response.get('user').get('content')) === -1) {
            retVal.push(response.get('user').get('content'));
          }
        });
      }
      return retVal;
    }),
    respClname: Ember.computed('expandedResponses', function () {
      var expanded = this.expandedResponses,
        cname = 'responses';
      if (expanded) {
        cname += '-expanded';
      }
      return cname;
    }),
    listId: Ember.computed('id', function () {
      return 'disc-elem-' + this.id;
    }),
    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    msgIsTooBig: Ember.computed.gt('message.length', 200),
    modalId: Ember.computed('id', function () {
      return 'disc-modal-' + this.id;
    }),
    textAreaId: Ember.computed('id', function () {
      return 'text-area-' + this.id;
    }),
    refModalId: Ember.computed('modalId', function () {
      return '#' + this.modalId;
    }),
    responseCount: Ember.computed('responses', function () {
      return this.responses.get('length');
    }),
    hasResponses: Ember.computed.gt('responseCount', 0),
    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    respContainerId: Ember.computed(function () {
      var id = this.id;
      if (!id) {
        id = 'no-id';
      }
      return 'resp-container-' + id.toString();
    }),
    hasMultipleResponses: Ember.computed.gt('responseCount', 1),
    responseCountStr: Ember.computed('responseCount', function () {
      return this.responseCount.toString();
    }),
    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    viewResponsesMsg: Ember.computed('responseCountStr', function () {
      var rspCount = this.responseCount,
        retVal;

      // we always show one so subtract that
      rspCount -= 1;
      if (rspCount > 0) {
        retVal = 'View ' + rspCount.toString() + ' Previous Response';
        if (rspCount > 1) {
          retVal = retVal + 's';
        }
      }
      return retVal;
    }),
    idSlug: Ember.computed.reads('id'),
    isNotAnnouncement: Ember.computed.not('is_announcement'),
    isNotRequired: Ember.computed.not('is_required'),
    isConversation: Ember.computed.and('isNotAnnouncement', 'isNotRequired'),
    isUnlocked: Ember.computed.not('locked'),
    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    sortDate: Ember.computed('lastResponse.date', 'due_at', function () {
      let date;
      if (this.lastResponse === this) {
        date = this.due_at || this.date;
      } else {
        /* eslint-disable-next-line ember/no-get */
        date = this.get('lastResponse.date');
      }
      return new Date(date);
    }),
    todo: Ember.computed.not('i_submitted')
  });
});