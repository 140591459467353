define("bocce/templates/interactions/hotspotquiz/quizcomplete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "WWIjo26x",
    "block": "{\"symbols\":[],\"statements\":[[10,\"a\"],[14,6,\"#\"],[14,0,\"try-again-btn hidden\"],[12],[2,\"Quiz Complete. Try again?\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "bocce/templates/interactions/hotspotquiz/quizcomplete.hbs"
    }
  });
});