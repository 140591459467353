define("bocce/components/quiz/quiz-overview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class QuizOverview extends Ember.Component {
    //@service quizzes;

    init(...args) {
      super.init(...args);
    }
  }
  _exports.default = QuizOverview;
});