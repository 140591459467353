define("bocce/components/quiz-questions/multiple-dropdowns", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    didInsertElement() {
      this.question.addEventListeners();
    },
    answersHtml: Ember.computed(function () {
      let text = this.question.get('text');
      const givenAnswer = {
        ...this.question.get('given_answer')
      };
      for (let blankId in this.question.optionAnswers) {
        let answers = this.question.optionAnswers[blankId];
        let givenOptionAnswer = givenAnswer ? givenAnswer[blankId] ?? null : null;
        if (answers.length > 0) {
          let optionsHtml = `<select class="quiz-question-dropdown" id="question-${this.question.get('id')}-${blankId}">`;
          if (this.quizzes.isScoredTimedQuizWithRemainingAttempts) {
            let answer = answers.find(a => a.id == givenOptionAnswer);
            if (answer) {
              optionsHtml += `<option>${answer.text}</option>`;
            } else {
              optionsHtml += `<option> ----- </option>`;
            }
          } else {
            optionsHtml += `<option value=""> ----- </option>`;
            for (let answer of answers) {
              optionsHtml += `<option id="question-${this.question.get('id')}-${blankId}-${answer.id}" value="${answer.id}" 
                                ${givenOptionAnswer === answer.id ? 'selected' : ''}>${answer.text}
                              </option>`;
            }
          }
          optionsHtml += "</select>";
          text = text.replace(`[${blankId}]`, optionsHtml);
        }
      }
      return text;
    })
  });
});