define("bocce/models/lobby", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // app/models/lesson.js
  var _default = _exports.default = _model.default.extend({
    role: (0, _model.attr)('string'),
    course_id: (0, _model.attr)('number'),
    user_id: (0, _model.attr)('number'),
    title: (0, _model.attr)('string'),
    section: (0, _model.attr)('string'),
    term: (0, _model.attr)('string'),
    week: (0, _model.attr)('string'),
    url: (0, _model.attr)('string'),
    current: (0, _model.attr)('boolean'),
    old: (0, _model.attr)('boolean'),
    start_at: (0, _model.attr)('date')
  });
});