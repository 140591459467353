define("bocce/templates/components/buttons/delete-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Lm631Mru",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"i\"],[14,0,\"fas fa-trash-alt\"],[12],[13],[2,\" \"],[18,1,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "bocce/templates/components/buttons/delete-button.hbs"
    }
  });
});