define("bocce/mixins/interactions/content_container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function ContentContainer($el) {
    this.$el = $el;
    this.$stage = this.$el.find('.interaction_content');
    this.$data = [];
    this.index = -1;
  }
  ContentContainer.prototype = {
    init: function () {
      this.createDataFromTable();

      // Delegate all events in case interactions are added before/after
      // this Content Container is instantiated.
      let listener = this.$el.parents('.lesson-topic');

      /* eslint-disable-next-line ember/no-jquery */
      listener.on('CC:ShowContent', '.interaction_component', Ember.$.proxy(this.onShowContent, this));
      /* eslint-disable-next-line ember/no-jquery */
      listener.on('CC:ClearContent', '.interaction_component', Ember.$.proxy(this.hideContent, this));
    },
    createDataFromTable: function () {
      var $rows = this.$el.find('.interaction_data table:first tr'),
        that = this;
      $rows.each(function () {
        /* eslint-disable-next-line ember/no-jquery */
        var $td = Ember.$(this).find('td');

        /* eslint-disable-next-line ember/no-jquery */
        that.$data.push(Ember.$('<div/>').append($td.children()));
      });
    },
    hideContent: function () {
      if (this.index >= 0) {
        this.$data[this.index].detach();
      }
      this.$stage.empty();
    },
    onShowContent: function (e, index) {
      this.hideContent();
      this.index = index;
      this.$stage.append(this.$data[index]);
    }
  };
  var _default = _exports.default = ContentContainer;
});