define("bocce/routes/classroom/lessons/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    renderTemplate() {
      this.render('classroom.lessons.loading', {
        into: 'classroom',
        outlet: 'modals'
      });
    }
  });
});