define("bocce/components/quiz-questions/fill-in-multiple-blanks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    didInsertElement() {
      this.question.addEventListeners();
    },
    answersHtml: Ember.computed(function () {
      let text = this.question.get('text');
      let givenAnswer = {
        ...this.question.get('given_answer')
      };
      for (let blankId of this.question.blankIds) {
        text = text.replace(`[${blankId}]`, `<input type="text" class="quiz-question-blank" id="question-${this.question.get('id')}-${blankId}" 
                                                        value="${givenAnswer ? givenAnswer[blankId] ?? '' : ''}" />`);
      }
      return text;
    })
  });
});