define("bocce/helpers/reply-count", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // app/helpers/reply-count.js
  // pluralize new replies or not
  var _default = _exports.default = Ember.Helper.helper(function (replies) {
    // We're calling this now with the number of replies, instead of the replies themselves
    let total = replies[0];
    if (total > 1) {
      return total + ' new replies';
    } else if (total === 1) {
      return '1 new reply';
    }
  });
});