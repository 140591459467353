define("bocce/routes/classroom/lessons/quiz", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    redirect(model) {
      let itemID = model.get('id');
      return this.store.findRecord('item', itemID).then(item => {
        let lessonID = item.get('lesson.id');
        if (!lessonID) {
          throw new Error(`Did not find lesson for item ${itemID}`);
        }
        this.replaceWith('classroom.lessons', lessonID, itemID);
      });
    }
  });
});