define("bocce/templates/components/quiz-questions/multiple-choice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "zrdvNnXj",
    "block": "{\"symbols\":[\"answer\",\"indexA\",\"@question\"],\"statements\":[[6,[37,10],[[30,[36,9],[[30,[36,9],[[32,3,[\"answers\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"input\"],[15,\"onclick\",[30,[36,0],[[32,0],\"setAnswer\",[32,1,[\"id\"]]],null]],[15,\"checked\",[30,[36,1],[[32,3,[\"given_answer\"]],[32,1,[\"id\"]]],null]],[15,0,[31,[\"question-choice-cb sr-only \",[30,[36,2],[[32,1,[\"correct\"]],\"correct\"],null]]]],[15,\"question-id\",[31,[[32,3,[\"id\"]]]]],[15,1,[31,[\"quiz-\",[34,3,[\"id\"]],\"-question-\",[32,3,[\"id\"]],\"-choice-\",[32,1,[\"id\"]]]]],[15,3,[31,[\"quiz-\",[34,3,[\"id\"]],\"-question-\",[32,3,[\"id\"]],\"-choices\"]]],[14,4,\"radio\"],[12],[13],[2,\"\\n      \"],[10,\"label\"],[14,0,\"quiz-question-choice bootable-area\"],[15,\"choice-letter\",[31,[[30,[36,4],[[32,2]],null]]]],[15,\"for\",[31,[\"quiz-\",[34,3,[\"id\"]],\"-question-\",[32,3,[\"id\"]],\"-choice-\",[32,1,[\"id\"]]]]],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"letter\"],[12],[1,[30,[36,4],[[32,2]],null]],[13],[2,\"\\n\"],[6,[37,8],[[30,[36,7],[[35,6,[\"isScoredTimedQuizWithRemainingAttempts\"]],[30,[36,5],[[30,[36,1],[[32,3,[\"given_answer\"]],[32,1,[\"id\"]]],null]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[2,[32,1,[\"text\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n\"]],\"parameters\":[1,2]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"same\",\"if\",\"quiz\",\"letter\",\"not\",\"quizzes\",\"and\",\"unless\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "bocce/templates/components/quiz-questions/multiple-choice.hbs"
    }
  });
});