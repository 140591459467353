define("bocce/components/side-panel/work", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    didInsertElement: function () {
      this.scrollCallback = () => {
        if (document.querySelector('.side-panel').scrollTop > 10) {
          $('.side-panel .panel-container.work-panel .late-grading-policy-cta-container').addClass('boxShadow');
        } else {
          $('.side-panel .panel-container.work-panel .late-grading-policy-cta-container').removeClass('boxShadow');
        }
      };
      document.querySelector('.side-panel').addEventListener('scroll', this.scrollCallback);
    },
    willDestroyElement: function () {
      document.querySelector('.side-panel').removeEventListener('scroll', this.scrollCallback);
    },
    classNames: ['activities', 'work'],
    assignmentsArray: Ember.computed('assignments', function () {
      return this.assignments || [];
    }),
    discussionsArray: Ember.computed('discussions', function () {
      return this.discussions || [];
    }),
    quizzesArray: Ember.computed('quizzes', function () {
      return this.quizzes || [];
    }),
    all: Ember.computed('quizzesArray.[]', 'assignmentsArray.@each.locked', 'discussionsArray.@each.locked', function () {
      let {
        quizzesArray,
        assignmentsArray,
        discussionsArray
      } = this;
      let all = [...quizzesArray.toArray(), ...assignmentsArray.toArray().filter(a => !a.get('locked')), ...discussionsArray.toArray().filter(a => !a.get('locked'))];
      all.sort((a, b) => {
        return new Date(b.get('sortDate')) - new Date(a.get('sortDate'));
      });
      return all;
    }),
    todo: Ember.computed('all.@each.todo', function () {
      let all = this.all;
      return all.filter(item => item.get('todo'));
    }),
    done: Ember.computed('all.@each.todo', function () {
      return this.all.filter(item => !item.get('todo'));
    }),
    actions: {
      setShowLateGradingPolicy(show) {
        this.set('showLateGradingPolicy', show);
      }
    }
  });
});