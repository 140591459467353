define("bocce/mixins/interactions/reveal_content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function RevealContent(el) {
    // TODO: remove this check once boot.js no longer has jquery
    if (el instanceof Ember.$) {
      el = el.get(0);
    }
    this.el = el;
    this.interactionData = el.querySelector('.interaction_data');
    this.container = el.querySelector('.interaction_content');
  }
  RevealContent.prototype = {
    init: function () {
      let hiddenContent = this.interactionData.querySelectorAll('td')[1];
      hiddenContent.remove();
      let hiddenContentDiv = document.createElement('div');
      hiddenContentDiv.append(hiddenContent);
      hiddenContentDiv.style.display = 'none';
      let initialContent = this.interactionData.querySelector('td:nth-of-type(1)');
      initialContent.remove();
      let initialContentDiv = document.createElement('div');
      initialContentDiv.classList.add('pointer');
      initialContentDiv.append(initialContent);
      let closeButton = document.createElement('button');
      closeButton.classList.add('close');
      closeButton.setAttribute('aria-label', 'Open');
      closeButton.innerHTML = '<i class="fas fa-plus" aria-hidden />';
      initialContentDiv.append(closeButton);
      this.container = this.container.parentNode.querySelector('.RevealContent');
      this.container.append(initialContentDiv);
      this.container.append(hiddenContentDiv);
      initialContentDiv.addEventListener('click', () => {
        if (hiddenContentDiv.style.display) {
          hiddenContentDiv.style.removeProperty('display');
        } else {
          hiddenContentDiv.style.display = 'none';
        }
        let aria_label = closeButton.getAttribute('aria-label');
        closeButton.classList.toggle('rotate');
        closeButton.setAttribute('aria-label', swapClose(aria_label));
        function swapClose(x) {
          return x === 'Close' ? 'Open' : 'Close';
        }
      });

      // prevent any links within initialContent from navigating anywhere

      for (let a of initialContentDiv.querySelectorAll('a')) {
        a.addEventListener('click', e => e.preventDefault());
      }
    }
  };
  var _default = _exports.default = RevealContent;
});