define("bocce/components/quiz-scenarios/quiz-single-graded-results", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class QuizSingleGradedResults extends Ember.Component {
    //@service quizzes;
  }
  _exports.default = QuizSingleGradedResults;
});