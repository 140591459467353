define("bocce/helpers/startswith", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.startswith = startswith;
  // app/helpers/startswith.js
  // Check if the first provided string begins with the second.
  // {{#if (startswith assignments.name 'Assignment 2')}}

  function startswith(inp) {
    var first = inp[0],
      second = inp[1];
    if (!first || !second) {
      return false;
    }
    return first.indexOf(second) === 0;
  }
  var _default = _exports.default = Ember.Helper.helper(startswith);
});