define("bocce/templates/components/quiz-scenarios/quiz-all-graded", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "9hZGVj5X",
    "block": "{\"symbols\":[\"question\",\"i\"],\"statements\":[[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,0,[\"quizzes\",\"questions\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"quiz-question-wrapper\"],[12],[2,\"\\n        \"],[8,\"quiz-questions/question\",[],[[\"@question\",\"@quiz\",\"@quizzes\",\"@index\",\"@questionComponent\"],[[32,1],[32,0,[\"quizzes\",\"quiz\"]],[32,0,[\"quizzes\"]],[32,2],[32,1,[\"component\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"\\n\"],[8,\"quiz/results\",[],[[\"@onRetry\",\"@quizzes\"],[[32,0,[\"quizzes\",\"retryQuiz\"]],[34,2]]],null]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\",\"quizzes\"]}",
    "meta": {
      "moduleName": "bocce/templates/components/quiz-scenarios/quiz-all-graded.hbs"
    }
  });
});