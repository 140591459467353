define("bocce/components/quiz-stats/types/essay-question-statistics", ["exports", "bocce/utilities/dialog", "bocce/js/utils"], function (_exports, _dialog, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let QuestionStatisticsSingle = _exports.default = (_dec = Ember._tracked, _dec2 = Ember._action, (_class = class QuestionStatisticsSingle extends Ember.Component {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "downloadingEssays", _descriptor, this);
    }
    get hasEssays() {
      return this.statistics.responses > 0;
    }
    async getEssayResponses() {
      try {
        this.downloadingEssays = true;
        let path = `/interface/sections/${session.section.id}/quizzes/${this.quizzes.quiz.id}/get_essay_responses/${this.statistics.id}`;
        let response = await fetch(path, {
          cache: "no-cache"
        });
        if (!response.ok) {
          throw new Error();
        }
        let blob = await response.blob();
        (0, _utils.saveFile)('essay.zip', 'application/zip', blob);
        this.downloadingEssays = false;
      } catch (e) {
        this.downloadingEssays = false;
        (0, _dialog.default)('No essays found.');
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "downloadingEssays", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "getEssayResponses", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "getEssayResponses"), _class.prototype)), _class));
});