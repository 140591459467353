define("bocce/mixins/interactions/rive", ["exports", "bocce/mixins/support/util", "bocce/mixins/support/render-template", "@rive-app/canvas"], function (_exports, util, _renderTemplate, _canvas) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var rive = _canvas;
  function RiveInteraction($el, data_raw) {
    this.$containerEl = $el.find('.interaction_content');
    this.data = {
      stateMachine: data_raw.config.statemachine,
      source: data_raw.config.src,
      autoplay: data_raw.config.autoplay || true,
      width: this.$containerEl.find('.interaction_content').css('width'),
      height: this.$containerEl.find('.interaction_content').css('height')
    };
  }
  RiveInteraction.prototype = {
    init() {
      return (0, _renderTemplate.default)('rive', 'main', this.data).then(content => {
        this.$containerEl.append(content);
        this.$canvasEl = this.$containerEl.find('canvas');

        // Set height and width of the canvas
        this.$canvasEl.attr('width', this.data.width);
        this.$canvasEl.attr('height', this.data.height);

        // Change canvas id to be unique
        this.$canvasEl.attr('id', this.$canvasEl.attr('id') + util.randomString(5));
        this.loadRive();
      });
    },
    loadRive() {
      const layout = new rive.Layout({
        fit: rive.Fit.Contain,
        alignment: rive.Alignment.Center
      });
      const r = new rive.Rive({
        canvas: this.$canvasEl[0],
        src: this.data.source,
        stateMachines: this.data.stateMachine,
        autoplay: this.data.autoplay,
        layout: layout,
        onLoad: () => {
          r.resizeDrawingSurfaceToCanvas();
        }
      });
      r.on(_canvas.EventType.RiveEvent, riveEvent => {
        console.log("riveEvent", riveEvent);
        const eventData = riveEvent.data;
        const eventProperties = eventData.properties;
        if (eventData.type === _canvas.RiveEventType.General) {
          console.log("Event name", eventData.name);
          // Added relevant metadata from the event
          console.log("Rating", eventProperties.rating);
          console.log("Message", eventProperties.message);
        } else if (eventData.type === _canvas.RiveEventType.OpenUrl) {
          console.log("Event name", eventData.name);
          window.open(eventData.url);
        }
      });
      this.rive = r;
    }
  };
  var _default = _exports.default = RiveInteraction;
});