define("bocce/controllers/classroom/lessons/no-submissions", ["exports", "bocce/mixins/editable", "bocce/mixins/assignments", "bocce/mixins/uploadable", "bocce/mixins/audio-rec", "bocce/mixins/video-rec", "bocce/mixins/rtc-rec", "bocce/mixins/enmodal", "bocce/mixins/boot", "bocce/mixins/discussable"], function (_exports, _editable, _assignments, _uploadable, _audioRec, _videoRec, _rtcRec, _enmodal, _boot, _discussable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  var _default = _exports.default = Ember.Controller.extend(_enmodal.default, _editable.default, _assignments.default, _uploadable.default, _audioRec.default, _videoRec.default, _rtcRec.default, _boot.default, _discussable.default, {
    userprofileService: Ember.inject.service('userprofile'),
    discussions: Ember.inject.controller(),
    // required by EditableMixin
    lessons: Ember.inject.controller('classroom.lessons'),
    // required by UploadableMixin
    classroom: Ember.inject.controller(),
    // required by UploadableMixin and EnmodalMixin
    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    assignments: Ember.computed(function () {
      return this.store.findAll('assignment');
    })
  });
});