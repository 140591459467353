define("bocce/app", ["exports", "@ember-data/store", "ember-resolver", "ember-load-initializers", "bocce/config/environment"], function (_exports, _store, _emberResolver, _emberLoadInitializers, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _emberResolver.default,
    init(...args) {
      this._super(...args);
      // this allows us to bind actions to paste and blur events:
      this.customEvents = this.customEvents || {
        paste: 'paste',
        blur: 'blur'
      };
    }
  });
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);

  // TODO (NK): unjank this. We really shouldn't need to extend Store. One
  // possible alternative is to create Adapters that are aware of the necessary
  // parent models and builds URLs automatically, instead of needing manual
  // nesting.
  _store.default.prototype.nestResources = function (type, resources) {
    return this.adapterFor(type).nestResources(type, resources);
  };
  moment.tz.add(['EST|EST|50|0|', 'America/New_York|EST EDT EWT EPT|50 40 40 40|01010101010101010101010101010101010101010101010102301010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010|-261t0 1nX0 11B0 1nX0 11B0 1qL0 1a10 11z0 1qN0 WL0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 WL0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 WL0 1qN0 11z0 1o10 11z0 RB0 8x40 iv0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 WL0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 s10 1Vz0 LB0 1BX0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|21e6']);
  moment.locale('en', {
    'calendar': {
      'lastDay': 'MMM D',
      'sameDay': 'h:mmA z',
      'nextDay': 'MMM D',
      'lastWeek': 'MMM D',
      'nextWeek': 'MMM D',
      'sameElse': 'MMM D'
    }
  });
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    /* eslint-disable-next-line ember/no-jquery */
    Ember.$.isMobile = true;
  } else {
    /* eslint-disable-next-line ember/no-jquery */
    Ember.$.isMobile = false;
  }
  if (navigator.userAgent.toLowerCase().indexOf('safari') !== -1) {
    if (navigator.userAgent.toLowerCase().indexOf('chrome') > -1) {
      /* eslint-disable-next-line ember/no-jquery */
      Ember.$.isSafari = false;
    } else {
      /* eslint-disable-next-line ember/no-jquery */
      Ember.$.isSafari = true;
    }
  }
  if (window.navigator.userAgent.indexOf('Edge') > -1) {
    /* eslint-disable-next-line ember/no-jquery */
    Ember.$.isEdge = true;
  }

  // JRW: Make the mouse position available in the window object in case the user
  // is using a browser that doesn't support window.event (Firefox)
  /* eslint-disable-next-line ember/no-jquery */
  Ember.$(document).mousemove(e => {
    window.mouseX = e.pageX;
    window.mouseY = e.pageY;
  }).mouseover();
  var _default = _exports.default = App;
});