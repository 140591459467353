define("bocce/controllers/classroom/lessons/admin/cache", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    quizUsers: [],
    selectedQuizUser: {},
    selectedQuiz: {},
    canFlushQuizCache: Ember.computed('selectedQuizUser', function () {
      if (Object.keys(this.get('selectedQuizUser')).length !== 0) {
        return true;
      }
      return false;
    }),
    sendFlushRequest: function (path, type = "course") {
      showElements('.floating-modal.admin .cache-management .status .in-progress');
      let basePath = "/interface/system/flush/";
      if (type === "quiz") {
        basePath = "/interface/";
      }
      return fetch(`${basePath}${path}`, {
        method: 'POST'
      }).then(response => response.json()).then(responseJSON => {
        this.flushResponseHandler(responseJSON);
      });
    },
    flushResponseHandler(retval) {
      hideElements('.floating-modal.admin .cache-management .status .in-progress');
      // Success
      if (retval === 'success') {
        // The cache call was successful
        showElements('.floating-modal.admin .cache-management .status .success');
        hideElements('.floating-modal.admin .cache-management .status .failure');
        // Refresh the browser
        setTimeout(() => {
          location.reload();
        }, 3000);
      } else {
        // Unsuccessful
        showElements('.floating-modal.admin .cache-management .status .failure');
        hideElements('.floating-modal.admin .cache-management .status .success');
      }
    },
    loadQuizUsers: async function (quizId) {
      let path = `/interface/sections/${this.get('session.section.id')}/quizzes/${quizId}/submission_users`,
        data = await $.get(path);
      if (data.quiz_submission_users.users) {
        let firstUserId = data.quiz_submission_users.users[0].id;
        this.setSelectedQuizUser(firstUserId);
        this.set('quizUsers', data.quiz_submission_users.users);
      }
    },
    setSelectedQuizUser: function (userId) {
      let thisUser = this.store.findRecord('user', userId);
      this.set('selectedQuizUser', thisUser);
    },
    setSelectedQuiz: function (quizId) {
      let thisQuiz = this.store.findRecord('quiz', quizId);
      this.set('selectedQuiz', thisQuiz);
    },
    actions: {
      toggleCacheClearConfirm: function (selector) {
        selector = `.${selector}`;
        let element = document.querySelector(selector);
        if (element) {
          element.classList.toggle('hidden');
        }
      },
      clearCache: function (type) {
        /* eslint-disable-next-line ember/no-get */
        let courseId = this.get('session.course.id'),
          /* eslint-disable-next-line ember/no-get */
          sectionId = this.get('session.section.id'),
          path;
        switch (type) {
          case 'lessons':
            path = `courses/${courseId}/modules`;
            break;
          case 'assignment':
            path = `courses/${courseId}/assignments`;
            break;
          case 'discussion':
            path = `courses/${courseId}/discussion_topics`;
            break;
          case 'events':
            path = `calendar_events?context_codes[]=course_${courseId}&all_events=true`;
            break;
          case 'enrollments':
            path = `sections/${sectionId}/enrollments`;
            break;
          case 'course':
            path = `courses/${courseId}`;
            break;
          case 'quiz':
            path = `quiz-admin/flush-context/${courseId}/${this.get('selectedQuiz.search_id')}/${this.get('selectedQuizUser.id')}`;
            break;
        }
        this.sendFlushRequest(path, type);
      },
      selectQuiz: async function (quizId) {
        $(".quizzes .quiz-options .quiz-users").removeClass("hidden");
        this.setSelectedQuiz(quizId);
        await this.loadQuizUsers(quizId);
      },
      selectQuizUser: function (userId) {
        this.setSelectedQuizUser(userId);
      }
    }
  });
  function hideElements(selector) {
    for (let element of document.querySelectorAll(selector)) {
      element.classList.add('hidden');
    }
  }
  function showElements(selector) {
    for (let element of document.querySelectorAll(selector)) {
      element.classList.remove('hidden');
    }
  }
});