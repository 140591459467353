define("bocce/mixins/rtc-rec", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable-next-line ember/no-new-mixins */
  var _default = _exports.default = Ember.Mixin.create({
    videoStatus: 'uninitiated',
    echoCancellationEnabled: Ember.computed(function () {
      return localStorage.getItem('bocceEchoCancellation') === 'true';
    }),
    presetResolution: false,
    captureCamera: function (resolution, callback) {
      let vidSettings = {};
      let audioSettings = {};
      if (resolution === 'hd') {
        vidSettings = {
          width: {
            min: 1280
          },
          height: {
            min: 720
          }
        };
      }

      // Check the local storage for a bocceEchoCancellation setting
      // If true, set the audio settings to echoCancellation: true
      if (!(localStorage.getItem('bocceEchoCancellation') === 'true')) {
        audioSettings = {
          echoCancellation: false,
          mozAutoGainControl: false,
          mozNoiseSuppression: false,
          googEchoCancellation: false,
          googAutoGainControl: false,
          googNoiseSuppression: false,
          googHighpassFilter: false
        };
      }
      vidSettings.deviceId = this.cameraDeviceId;
      audioSettings.deviceId = this.audioInputId;
      navigator.mediaDevices.getUserMedia({
        audio: audioSettings,
        video: vidSettings
      }).then(function (camera) {
        callback(camera);
      }).catch(function (error) {
        Ember.debug(error);
      });
    },
    stopRecordingCallback: function (context, blob) {
      /* eslint-disable-next-line ember/no-jquery */
      let video = Ember.$('.recorder-monitor')[0],
        recorder = window.recorder;

      // Forcibly assign webm mimetype to blob
      blob = blob.slice(0, blob.size, 'video/webm');
      this.set('blobURL', URL.createObjectURL(blob));
      window.MediaSource = window.MediaSource || window.WebKitMediaSource;
      let mediaSource = new MediaSource();
      mediaSource.addEventListener('sourceopen', event => {
        /* eslint-disable-next-line ember/no-jquery */
        let fileType = Ember.$.isSafari ? 'video/mp4; codecs="avc1.42E01E, mp4a.40.2"' : 'video/webm; codecs="vorbis,vp8"';
        event.target.addSourceBuffer(fileType);
      }, false);

      // Set up video preview
      video.removeAttribute('autoplay');
      video.src = video.srcObject = null;
      video.muted = false;
      video.volume = 1;
      video.controls = true;
      video.src = this.blobURL;

      // Set up video backup
      /* eslint-disable-next-line ember/no-jquery */
      Ember.$('#video-backup-link').attr('href', this.blobURL);
      this.set('vidBlob', blob);
      recorder.camera.stop();
      recorder.clearRecordedData();
      recorder = null;
      window.recorder = null;
    },
    actions: {
      toggleRTCPanel: function (exit) {
        this.set('videoStatus', 'uninitiated');
        this.set('presetResolution', false);

        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.recorder-monitor').addClass('hidden').attr('src', '');
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.video-backup-rec').attr('href', '');
        if (exit) {
          return false;
        }

        /* eslint-disable-next-line ember/no-jquery */
        let videoSelect = Ember.$('.video-input-selector')[0];
        /* eslint-disable-next-line ember/no-jquery */
        let audioInputSelect = Ember.$('.audio-input-selector')[0];

        // Clear out camera
        let recorder = window.recorder;
        if (recorder) {
          recorder.camera.stop();
          recorder.clearRecordedData();
          recorder = null;
          window.recorder = null;
        }
        if (videoSelect && audioInputSelect) {
          navigator.mediaDevices.enumerateDevices().then(deviceInfos => {
            for (let i = 0; i !== deviceInfos.length; ++i) {
              let deviceInfo = deviceInfos[i];
              let option = document.createElement('option');
              option.value = deviceInfo.deviceId;
              if (deviceInfo.kind === 'audioinput') {
                option.text = deviceInfo.label || 'Microphone ' + (audioInputSelect.length + 1);
                audioInputSelect.appendChild(option);
                if (!this.audioInputId) {
                  this.set('audioInputId', deviceInfo.deviceId);
                }
              } else if (deviceInfo.kind === 'videoinput') {
                option.text = deviceInfo.label || 'Camera ' + (videoSelect.length + 1);
                if (!this.cameraDeviceId) {
                  this.set('cameraDeviceId', deviceInfo.deviceId);
                }
                videoSelect.appendChild(option);
              }
            }
          });
        }

        /* eslint-disable-next-line ember/no-jquery */
        if (Ember.$('.floating-modal.active .audio-rec-panel').length > 0) {
          this.send('toggleAudioRecPanel', true);
        }
      },
      changeAudioInput: function (id) {
        this.set('audioInputId', id);
      },
      changeVideoInput: function (id) {
        this.set('cameraDeviceId', id);
      },
      enableCamera: function (resolution) {
        let presetResolution = this.presetResolution;
        if (presetResolution) {
          resolution = presetResolution;
        } else {
          this.set('presetResolution', resolution);
        }
        this.captureCamera(resolution, camera => {
          /* eslint-disable-next-line ember/no-jquery */
          let fileType = Ember.$.isSafari ? 'video/mp4' : 'video/webm';
          /* eslint-disable-next-line ember/no-jquery */
          let video = Ember.$('.recorder-monitor')[0];
          video.setAttribute('autoplay', '');
          video.classList.remove('hidden');
          video.muted = true;
          video.volume = 0;
          video.removeAttribute('controls');
          video.srcObject = camera;
          window.recorder = new MediaStreamRecorder(camera, {
            mimeType: fileType
          });
          this.set('videoStatus', 'ready');
          this.set('recordingCurrentTime', '00:00');

          // release camera on stopRecording
          window.recorder.camera = camera;
        });
      },
      startVideoRec: function () {
        let recorder = window.recorder;
        if (recorder) {
          this.set('videoStatus', 'recording');
          recorder.record();
        } else {
          Ember.debug('No recorder enabled. Refresh browser and try again');
        }
        this.set('recordingStartTime', moment().unix());
        window.kalturaRec = setInterval(() => {
          let timeCalc = moment().unix() - this.recordingStartTime;
          this.set('recordingCurrentTime', moment(timeCalc * 1000).format('mm:ss'));
        }, 1000);
      },
      stopVideoRec: function () {
        let recorder = window.recorder;
        if (recorder) {
          this.set('videoStatus', 'recorded');
          clearInterval(window.kalturaRec);
          this.set('recordingStartTime', false);
          this.set('recordingCurrentTime', '00:00');
          recorder.stop(blob => {
            this.stopRecordingCallback(this, blob);
          });
        } else {
          Ember.debug('No recorder enabled. Refresh browser and try again');
        }
      },
      attachVideoRec: function () {
        this.set('videoStatus', 'uploading');
        this.set('uploadPercentage', '0%');
        this.kalturaUploadVideo(this.vidBlob, message => {
          window.alert(message);
          this.set('videoStatus', 'recorded');
        }, false, this.blobURL);
      },
      displayVideoBackup: function (close) {
        this.set('showVideoBackup', !close);
      },
      echoCancellationToggleVideo: function () {
        var echoCancellation = localStorage.getItem('bocceEchoCancellation') === 'true';
        if (echoCancellation) {
          localStorage.setItem('bocceEchoCancellation', false);
          this.set('echoCancellationEnabled', false);
        } else {
          localStorage.setItem('bocceEchoCancellation', true);
          this.set('echoCancellationEnabled', true);
        }
      }
    }
  });
});