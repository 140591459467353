define("bocce/mixins/support/sound_mixer_core", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = SoundMixerCore;
  function SoundMixerCore(customConfig, $el) {
    this.customConfig = customConfig;
    this.$el = $el;
  }

  // Build and run a single sound mixer instance...
  SoundMixerCore.prototype.run = function () {
    var config = {},
      prop;

    // Load custom configurations
    if (this.customConfig) {
      for (prop in this.customConfig) {
        config[prop] = this.customConfig[prop];
      }
    }

    // Functions
    function buildMixer($el) {
      let longestAud = {
          duration: 0,
          key: 0
        },
        trackCount = config.mixer.tracks.length,
        // DOM Elements,
        container,
        // A (hopefully) random id for this page alone
        rnd = Math.floor(Math.random() * 1000000);

      /* eslint-disable-next-line ember/no-jquery */
      container = Ember.$('<div class="sound-mixer"></div>');
      /* eslint-disable-next-line ember/no-jquery */
      Ember.$('<div class="fader-container"> <div class="fader-label">MASTER</div> <input type="range" class="fader-slider fader-master" min="0" max="128" value="128" volume="1" actual_volume="1"> <div class="toggle mute"> <input type="checkbox" class="input-mute input-mute-master" id="input-mute-master-' + rnd + '"> <label for="input-mute-master-' + rnd + '" class="fas fa-volume-off"></label> </div> </div>').appendTo(container);
      for (let i = 0; i < trackCount; i++) {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('<div class="fader-container"> <audio src="' + config.mixer.tracks[i].trackPath + '"></audio> <div class="fader-label">' + config.mixer.tracks[i].trackName + '</div><div class="fader"><input type="range" class="fader-slider fader-' + i + '" min="0" max="128" value="128" volume="1"></div><div class="toggle solo"><input type="checkbox" class="input-solo input-solo-' + i + '" id="input-solo-' + i + '-' + rnd + '" key="' + i + '"><label for="input-solo-' + i + '-' + rnd + '" class="fas fa-headphones"></label></div><div class="toggle mute"><input type="checkbox" class="input-mute input-mute-' + i + '" id="input-mute-' + i + '-' + rnd + '"><label for="input-mute-' + i + '-' + rnd + '" class="fas fa-volume-off"></label></div></div>').appendTo(container);
      }

      /* eslint-disable-next-line ember/no-jquery */
      Ember.$('<div class="player-controls"><div class="loop-button toggle"><input type="checkbox" class="input input-loop" id="input-loop-' + rnd + '"><label for="input-loop-' + rnd + '" class="input-ploop"></label></div><div class="play-button"><input type="checkbox" class="input input-play" id="input-play-' + rnd + '"><label for="input-play-' + rnd + '" class="input-play-pause"></label></div><div class="seek"><input type="range" class="seek-slider" id="seek" value="0"></div><div class="time-stamp" timestamp="00:00"></div></div>').appendTo(container);
      $el.append(container);
      let faders = $el.find('.fader-slider:not(".fader-master")'),
        faderMaster = $el.find('.fader-slider.fader-master'),
        soloToggles = $el.find('.input-solo'),
        muteToggles = $el.find('.input-mute:not(".input-mute-master")'),
        muteMaster = $el.find('.input-mute-master'),
        playToggle = $el.find('.input-play'),
        loopToggle = $el.find('.input-loop'),
        timeStamp = $el.find('.time-stamp'),
        seek = $el.find('.seek-slider'),
        audio = $el.find('audio');

      // Determine which audio sample is longest
      // Use it for seeking/API events
      audio.each(function (key) {
        longestAud = audio[key].duration > longestAud.duration ? {
          duration: audio[key].duration,
          key: key
        } : longestAud;
      });

      // Audio Player - Functions

      // Update audio times when user seeks using the scrubber
      seek.on('change.fndtn.slider', item => {
        var ct, length;
        audio.each(function (key) {
          var aud = audio[key];
          length = aud.duration;
          if (length) {
            ct = parseInt(item.target.value) / 100 * length;
            if (ct <= aud.duration) {
              aud.currentTime = ct;
            }
          }
        });
      });

      // Toggle loop functionality on and off
      loopToggle.on('change', item => {
        if (item.target.checked) {
          playToggle.attr('loop', true);
        } else {
          playToggle.attr('loop', false);
        }
      });

      // Toggle audio between playing and paused
      playToggle.on('change', item => {
        if (item.target.checked) {
          audio.each(function (key) {
            audio[key].play();
          });
        } else {
          audio.each(function (key) {
            audio[key].pause();
          });
        }
      });

      // Audio Player - Events

      /* eslint-disable-next-line ember/no-jquery */
      Ember.$(audio[longestAud.key]).on('playing', () => playToggle.prop('checked', true)).on('ended', () => {
        playToggle.prop('checked', false);
        if (playToggle.attr('loop') === 'loop') {
          audio.each(function (key) {
            audio[key].currentTime = 0;
            audio[key].play();
          });
        }
      }).on('timeupdate', item => {
        let time = item.target.currentTime,
          minutes = Math.floor(time / 60),
          seconds = Math.floor(time) % 60;
        seconds = seconds < 10 ? '0' + seconds.toString() : seconds.toString();
        minutes = minutes < 10 ? '0' + minutes.toString() : minutes.toString();
        seek.val(Math.round(1000 * (time / item.target.duration)) / 10);
        timeStamp.attr('timestamp', minutes + ':' + seconds);
      });

      // Set volume of a single track
      faders.on('change', item => {
        var vol = item.target.value / item.target.max,
          masterVol = faderMaster.attr('actual_volume');
        item.target.closest('.fader-container').children[0].volume = vol * masterVol;
        item.target.attributes.volume.value = vol;
      });

      // Set master volume - impacts all tracks
      faderMaster.on('change', item => {
        var vol = item.target.value / item.target.max;
        item.target.attributes.volume.value = vol;
        item.target.attributes.actual_volume.value = vol;
        audio.each(function (key) {
          audio[key].volume = vol * faders[key].attributes.volume.value;
        });
      });

      // Mute single track
      muteToggles.on('change', item => {
        var vol = item.target.closest('.fader-container').children[2].children[0].getAttribute('volume'),
          masterVol = faderMaster.attr('actual_volume');
        if (item.target.checked) {
          item.target.closest('.fader-container').children[0].volume = 0;
        } else {
          item.target.closest('.fader-container').children[0].volume = vol * masterVol;
        }
      });

      // Mute all tracks but the one in question
      soloToggles.on('change', item => {
        var faderC = item.target.closest('.fader-container').children[2].children[0],
          soloing = false;
        if (item.target.checked) {
          faderC.setAttribute('solo', true);
        } else {
          faderC.setAttribute('solo', false);
        }
        soloToggles.each(function (key) {
          if (faders[key].attributes.solo && faders[key].attributes.solo.value === 'true') {
            soloing = true;
          }
        });
        faders.each(function (key) {
          var vol = faders[key].value / faders[key].max,
            masterVol = faderMaster.attr('actual_volume');
          if (faders[key].attributes.solo && faders[key].attributes.solo.value === 'true' || !soloing) {
            faders[key].closest('.fader-container').children[0].volume = vol * masterVol;
          } else {
            faders[key].closest('.fader-container').children[0].volume = 0;
          }
        });
      });

      // Mute all tracks
      muteMaster.on('change', item => {
        var masterVol = faderMaster.attr('actual_volume'),
          muteVol;
        if (item.target.checked) {
          item.target.closest('.fader-container').children[0].volume = muteVol = 0;
        } else {
          item.target.closest('.fader-container').children[0].volume = muteVol = masterVol;
        }
        audio.each(function (key) {
          var ak = audio[key],
            vol = ak.closest('.fader-container').children[2].children[0].getAttribute('volume');
          ak.closest('.fader-container').children[0].volume = vol * muteVol;
        });
      });
      return 'Mixer Built Sucessfully!';
    }
    buildMixer(this.$el);
  };

  // Destroy current mixer instance
  SoundMixerCore.prototype.destroy = function () {
    // Remove all placementId children, but keep the node.
    // Since the object wasn't the one that created it, it shouldn't do the destruction.
    while (this.$el.firstChild) {
      this.$el.removeChild(this.$el.firstChild);
    }
  };
});