define("bocce/routes/classroom/lessons/conversation-new-with", ["exports", "bocce/routes/classroom/lessons/modal-base"], function (_exports, _modalBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _modalBase.default.extend({
    model(params) {
      let user_ids = params.user_ids.toString().split('-');
      let conversations = this.store.findAll('conversation').then(conversations => {
        // filtered should be an array of conversations that match the
        // list of user ids to whom we want to send a group message
        let filtered = conversations.filter(item => {
          // if the length is different, we know it's not a match
          if (!Array.isArray(user_ids) || item.get('conversationPartners.length') !== user_ids.length) {
            return false;
          }

          // if the length is the same, check that each user id is present
          let partners = item.get('conversationPartners').toArray();
          let existing_ids = partners.map(c => c.id);
          let wanted_ids = user_ids.concat();
          for (let id of wanted_ids) {
            if (!existing_ids.includes(id)) {
              return false;
            }
          }
          return true;
        });
        return filtered;
      });
      let users = conversations.then(filtered => {
        if (filtered.get('length') > 0) {
          let id = filtered[0].get('id');
          this.transitionTo('classroom.lessons.conversation', id);
          return false;
        } else {
          let users = [];

          // make sure to include yourself in the conversation!
          /* eslint-disable-next-line ember/no-get */
          user_ids.push(this.get('session.user.id'));
          user_ids.forEach(id => {
            users.push(this.store.findRecord('user', id));
          });
          return Promise.all(users);
        }
      });
      return users.then(users => {
        if (users) {
          let group_conversation = user_ids.length > 2 ? true : false;
          let conversation = this.store.createRecord('conversation', {
            recipients: user_ids,
            private: !group_conversation,
            conversationPartners: users,
            group_conversation
          });
          return conversation;
        }
      });
    }
  });
});