define("bocce/controllers/gradebook", ["exports", "bocce/utilities/dialog"], function (_exports, _dialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    init(...args) {
      this._super(...args);
      this.filters = this.filters || ['All'];
    },
    classroom: Ember.inject.controller(),
    discussions: Ember.inject.controller(),
    userprofileService: Ember.inject.service('userprofile'),
    gradeCalculator: Ember.inject.service('grade-calculator'),
    ungradedAsZero: true,
    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    model: Ember.computed('classroom.gradebookOpen', 'discussions.unreadCountActivities', function () {
      return this.store.findAll('gradebook', {
        reload: true
      });
    }),
    assignmentHeaders: Ember.computed('model', 'model.content', function () {
      let headers = this.model.toArray()[0];
      if (headers) {
        for (let column of headers.columns) {
          if (column.muted) {
            column.muted = false;
          }
        }
      }
      return headers;
    }),
    assignmentUsers: Ember.computed('model', 'model.content', function () {
      return this.model.toArray().slice(1);
    }),
    grades: Ember.computed(function () {
      return ['-', 'A', 'A-', 'B+', 'B', 'B-', 'C+', 'C', 'C-', 'D', 'F', 'I'];
    }),
    passfail: Ember.computed('session.aFNonCredit', function () {
      let retval = [{
        display: '-',
        value: '-'
      }, {
        display: 'Pass',
        value: 'A'
      }, {
        display: 'Fail',
        value: 'F'
      }];

      /* eslint-disable-next-line ember/no-get */
      if (this.get('session.aFNonCredit')) {
        retval = [{
          display: '-',
          value: '-'
        }, {
          display: 'Pass',
          value: 'A'
        }, {
          display: 'Fail',
          value: 'F'
        }, {
          display: 'A',
          value: 'A'
        }, {
          display: 'A-',
          value: 'A-'
        }, {
          display: 'B+',
          value: 'B+'
        }, {
          display: 'B',
          value: 'B'
        }, {
          display: 'B-',
          value: 'B-'
        }, {
          display: 'C+',
          value: 'C+'
        }, {
          display: 'C',
          value: 'C'
        }, {
          display: 'C-',
          value: 'C-'
        }, {
          display: 'D',
          value: 'D'
        }, {
          display: 'F',
          value: 'F'
        }, {
          display: 'I',
          value: 'I'
        }];
      }
      return retval;
    }),
    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    gradesDueAt: Ember.computed(function () {
      /* eslint-disable-next-line ember/no-get */
      return moment(this.get('session.gradeLockAt')).format('MMMM DD');
    }),
    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    showGradesDueAt: Ember.computed('session.gradeLockAt', function () {
      /**
        * When interface receives a term update without a grade cutoff date, it is intended that there is no cutoff date. In this case, 
        * the date is set to the year 9999, which is a date that more than likely will never be reached. Javascript's date's .getYear() returns
        * the number of years since 1900. So, in checking equality to 9999 - 1900, we are checking if the year is 9999, and thus if there is a grade 
        * cutoff.
       */
      return this.get('session.gradeLockAt') && !(this.get('session.gradeLockAt').getYear() == 9999 - 1900);
    }),
    unmuteAllGrades: false,
    hideMutedGrades: false,
    /* eslint-disable-next-line ember/no-observers */
    calcGrades: Ember.observer('assignmentHeaders.@each.muted', 'ungradedAsZero', 'unmuteAllGrades', 'filters', function () {
      let usersPrp = this.assignmentUsers,
        unmuteAllGrades = this.unmuteAllGrades,
        ungradedAsZero = this.ungradedAsZero;
      for (let h = 0; h < usersPrp.length; h++) {
        let allUserAssignments = usersPrp[h].get('columns');
        let finalGrades = this.get('gradeCalculator').calculateFinalGrade(allUserAssignments, unmuteAllGrades, ungradedAsZero);

        // Assign total grades to each category (assignment type)
        for (let i = 0; i < finalGrades.categoryGrades.length; i++) {
          let indx = usersPrp[h].get('columns').findIndex(m => m && m.calcType && m.calcType.indexOf(finalGrades.categoryGrades[i].category) > -1);
          let scoreCalc = finalGrades.categoryGrades[i].score;
          let gradeCalc = finalGrades.categoryGrades[i].grade;
          this.set('assignmentUsers.' + h + '.columns.' + indx + '.score', scoreCalc);
          this.set('assignmentUsers.' + h + '.columns.' + indx + '.grade', gradeCalc);
        }

        // Assign total grades to the final grade columns
        let totalIndx = usersPrp[h].get('columns').findIndex(m => m && m.calcType === 'Total');
        this.set('assignmentUsers.' + h + '.columns.' + totalIndx + '.score', finalGrades.weightedScore);
        this.set('assignmentUsers.' + h + '.columns.' + totalIndx + '.grade', finalGrades.finalGrade);
      }
    }),
    actions: {
      weighNotYetDueGrades: function () {
        this.toggleProperty('unmuteAllGrades');
      },
      hideMuted: function () {
        this.toggleProperty('hideMutedGrades');
      },
      muteToggleAssignment: function (assignment_index) {
        let usersPrp = this.assignmentUsers;
        for (let i = 0; i < usersPrp.length; i++) {
          let isMuted = this.get('assignmentUsers.' + i + '.columns.' + assignment_index + '.muted');
          this.set('assignmentUsers.' + i + '.columns.' + assignment_index + '.muted', !isMuted);
        }
        let isMuted = this.get('assignmentHeaders.columns.' + assignment_index + '.muted');
        this.set('assignmentHeaders.columns.' + assignment_index + '.muted', !isMuted);
        this.calcGrades();
      },
      ungradedAsZeroToggle: function () {
        let asZero = this.ungradedAsZero;
        this.set('ungradedAsZero', !asZero);
      },
      gradeEditOn: function (column) {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.gradebook-container .content .tg-wrap table td#cell-' + column).addClass('editing');

        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.gradebook-container .content .tg-wrap table td#cell-' + column + ' input').focus();
      },
      gradeEditOff: function (column) {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.gradebook-container .content .tg-wrap table td#cell-' + column).removeClass('editing');
      },
      assignGrade: function (assignment_id, submission_id, student_id, score) {
        let usr = this.assignmentUsers,
          inx = usr.findIndex(function (m) {
            return m && m.id === student_id;
          }),
          indx = usr[inx].get('columns').findIndex(function (m) {
            return m && m.assignment_id === assignment_id;
          });
        if (!score || typeof score === 'object') {
          score = event.target.value;
        }
        usr[inx].set('columns.' + indx + '.score', score);
        this.set('assignmentUsers', usr);

        /* eslint-disable-next-line ember/no-get */
        this.store.nestResources('submission', [{
          section: this.get('session.section.id')
        }, {
          assignment: assignment_id
        }]);
        this.store.findRecord('submission', submission_id + '_' + student_id).then(sub => {
          sub.set('posted_grade', score);
          sub.save().then(() => {
            // PRM: I hate these confirmations _so_ much.
            /* eslint-disable-next-line ember/no-get */
            if (!this.get('session.user.hideGradebookConfirmations')) {
              (0, _dialog.default)('Your grade has been successfully submitted.');
            }
            this.calcGrades();
            Ember.notifyPropertyChange(this, 'assignmentUsers');
            /* eslint-disable-next-line ember/no-jquery */
            Ember.$('.gradebook-container .content .tg-wrap table td').removeClass('editing');
          }, err => {
            let prompt = 'Please try again in a few minutes.';
            if (err.errors && err.errors.length > 0) {
              let status = err.errors[0].status;
              if (status === '401') {
                prompt = 'You do not have permission to grade in this course. Please make sure you are enrolled as an instructor.';
              } else if (status === '501') {
                prompt = 'Something went wrong with the server. Please contact support.';
              } else if (status === '503') {
                prompt = 'Please try again in a few minutes.';
              } else {
                prompt = 'Please contact suppot with the following error: ' + err.errors[0].detail;
              }
            }
            (0, _dialog.default)('Unable to save your grade. ' + prompt);
          });
        });
      },
      assignFinalGrade: function (assignment_id, submission_id, student_id) {
        let grade = event.target.value;

        // Lock out grading after session ends
        /* eslint-disable-next-line ember/no-get */
        if (this.get('session.readOnly')) {
          (0, _dialog.default)('Final grades for this course are now locked. Please contact the registrar directly to modify any final student grades.');
          return;
        }
        let usr = this.assignmentUsers,
          inx = usr.findIndex(function (m) {
            return m && m.id === student_id;
          }),
          indx = usr[inx].get('columns').findIndex(function (m) {
            return m && m.assignment_id === assignment_id;
          });
        usr[inx].set('columns.' + indx + '.grade', grade);

        /* eslint-disable-next-line ember/no-get */
        this.store.nestResources('submission', [{
          section: this.get('session.section.id')
        }, {
          assignment: assignment_id
        }]);
        this.store.findRecord('submission', submission_id + '_' + student_id).then(sub => {
          sub.set('posted_grade', grade);
          sub.save().then(() => {
            (0, _dialog.default)('Your grade has been successfully submitted.');
            /* eslint-disable-next-line ember/no-jquery */
            Ember.$('.gradebook-container .content .tg-wrap table td').removeClass('editing');
            Ember.$.ajax({
              type: 'POST',
              url: '/interface/final-grade-conf',
              data: JSON.stringify({
                section_id: this.get('session.section.id'),
                term_name: this.get('session.course.term.name')
              }),
              success(data) {
                if (data.success) {
                  console.log('Grade Conf: ' + data.message);
                }
              },
              error(jqXHR, textStatus) {
                console.log('Error: ' + textStatus);
              }
            });
          }, err => {
            let prompt = 'Please try again in a few minutes.';
            if (err.errors && err.errors.length > 0) {
              let status = err.errors[0].status;
              if (status === '401') {
                prompt = 'You do not have permission to grade in this course. Please make sure you are enrolled as an instructor.';
              } else if (status === '501') {
                prompt = 'Something went wrong with the server. Please contact support.';
              } else if (status === '503') {
                prompt = 'Please try again in a few minutes.';
              } else {
                prompt = 'Please contact suppot with the following error: ' + err.errors[0].detail;
              }
            }
            (0, _dialog.default)('Unable to save your grade. ' + prompt);
          });
        });
      },
      generateCSV: function () {
        let csvArr = [],
          rowsTemp = [];

        /* eslint-disable-next-line ember/no-get */
        let headersPrp = this.get('assignmentHeaders.columns'),
          usersPrp = this.assignmentUsers;
        for (let i = 0; i < headersPrp.length; i++) {
          let text = headersPrp[i].name || headersPrp[i].title || headersPrp[i];
          //surround with couples of double quotes in case the header title has a comma in it
          rowsTemp.push('"' + text.replaceAll(/"/gm, '""') + '"');
        }
        csvArr.push(rowsTemp);
        for (let i = 0; i < usersPrp.length; i++) {
          rowsTemp = [];
          for (let j = 0; j < usersPrp[i].get('columns').length; j++) {
            let current = usersPrp[i].get('columns')[j];
            let text = '';
            if (!current) {
              text = '-';
            } else if (current.grading_standard_id === 2) {
              // final grade column
              text = current.grade ? current.grade : "-";
            } else if (current.grade) {
              if (current.grade === 'Complete') {
                text = '+';
              } else if (current.grade === 'N/A' || !current.score || current.score === 'N/A') {
                text = '0% (F)';
              } else {
                text = current.score + (current.score.toString().includes('%') ? '' : '%') + ' (' + current.grade + ')';
              }
            } else if (current.score) {
              text = current.score;
            } else if (current.content) {
              // live class attendance column
              text = current.content;
            } else if (current.name) {
              text = current.name;
            } else {
              text = '-';
            }
            if (current && current.late) {
              text += ' (LATE)';
            }
            rowsTemp.push('"' + text.replaceAll(/"/gm, '""') + '"');
          }
          csvArr.push(rowsTemp);
        }
        let csvContent = 'data:text/csv;charset=utf-8,' + csvArr.map(e => e.join(',')).join('\n');
        let encodedUri = encodeURI(csvContent);
        let link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        /* eslint-disable-next-line ember/no-get */
        link.setAttribute('download', this.get('session.course.code') + '_' + this.get('session.course.term.name') + '_gradebook.csv');
        document.body.appendChild(link); // Required for FF
        link.click(); // This will download the data file named "my_data.csv".
      },
      closeGradebook: function () {
        this.classroom.set('gradebookOpen', false);
      },
      changeFilter: function (filter) {
        let currentFilters = this.filters;
        if (filter === 'All') {
          this.set('filters', ['All']);
        } else {
          if (currentFilters.includes(filter)) {
            currentFilters = currentFilters.filter(curFilter => curFilter !== filter);
          } else {
            currentFilters.push(filter);
          }
          currentFilters = currentFilters.filter(curFilter => curFilter !== 'All');
          if (currentFilters.length === 0) {
            currentFilters.push('All');
          }
          this.set('filters', currentFilters);
        }

        // set columns to be muted based on the current filter
        let usersPrp = this.assignmentUsers;
        for (let i = 0; i < usersPrp.length; i++) {
          for (let j = 0; j < usersPrp[i].columns.length; j++) {
            let itm = usersPrp[i].columns[j];
            if (itm.assignment_type && !this.filters.includes('All')) {
              let filterAssignments = itm.assignment_type.includes("Assignment") && !this.filters.includes("Assignments");
              let filterQuizzes = itm.assignment_type.includes("Quiz") && !this.filters.includes("Quizzes");
              // filterDiscussions is a catch-all for the rest of the assignments not included in assignments or quizzes
              let filterDiscussions = !itm.assignment_type.includes("Assignment") && !itm.assignment_type.includes("Quiz") && !this.filters.includes("Discussions");
              let isFiltered = filterAssignments || filterDiscussions || filterQuizzes;
              this.set('assignmentUsers.' + i + '.columns.' + j + '.muted', isFiltered);
              this.set('assignmentHeaders.columns.' + j + '.muted', isFiltered);
            } else if (itm.assignment_type && this.filters.includes('All')) {
              this.set('assignmentUsers.' + i + '.columns.' + j + '.muted', false);
              this.set('assignmentHeaders.columns.' + j + '.muted', false);
            }
          }
        }
      },
      view: async function (quizId) {
        let quiz = await store.findRecord('quiz', quizId);
        let item = await store.findRecord('item', quiz.id);
        this.transitionToRoute('classroom.lessons', item.lesson.id, item.id);
        this.send('closeGradebook');
      }
    }
  });
});