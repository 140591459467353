define("bocce/services/grade-calculator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class GradeCalculatorService extends Ember.Service {
    calculateFinalGrade(assignments, unmuteAllGrades, ungradedAsZero, gradeOverride) {
      let existingGroupNames = [];
      let totalWeight = 0;
      let categoryGrades = [];
      let avgStore = [];
      let gradeTypes = assignments.filter(function (itm) {
        let isNewName = !!itm.assignment_type && existingGroupNames.indexOf(itm.assignment_type) === -1;
        if (!isNewName) {
          return false;
        }
        existingGroupNames.push(itm.assignment_type);
        return true;
      });
      for (let i = 0; i < gradeTypes.length; i++) {
        let filteredAssignments = assignments.filter(function (itm) {
          let notYetDue = !unmuteAllGrades ? itm.due_at && moment(itm.due_at).isAfter(moment()) || (itm.score == null || itm.score === false) && !!itm.submitted_at || itm.grade === "UNGRADED" && !!itm.submitted_at : false;
          if (
          // Is it inscrutable?
          typeof itm === 'undefined' || !itm || !itm.assignment_type ||
          // Is it a dummy assignment?
          itm.assignment_weight === 0 ||
          // Is it the manual final grade?
          itm.grading_standard_id === 2 ||
          // Is this assignment not yet due?
          notYetDue) {
            return false;
          }
          return gradeTypes[i].assignment_type === itm.assignment_type;
        }).map(item => JSON.parse(JSON.stringify(item)));
        let assignmentCount = filteredAssignments.length,
          scoreCalc = 0,
          gradeCalc = '',
          scoreSum = 0,
          possibleSum = 0,
          dedicatedDiscussionGrp = gradeTypes.filter(function (itm) {
            return itm.assignment_type.toLowerCase().indexOf('discussion') > -1;
          });
        let noDedicatedDiscussionGrp = dedicatedDiscussionGrp.length === 0;
        for (let j = 0; j < filteredAssignments.length; j++) {
          // Remove ungraded zeros from equation, if the option is enabled -- and remove manual final grade
          // Also remove all dropped grades (some classes have a setting to drop N of the student's lowest grades)
          /* eslint-disable-next-line ember/no-get */
          if (!ungradedAsZero && (filteredAssignments[j].score === null || filteredAssignments[j].grade === null) || filteredAssignments[j].due_at === null && filteredAssignments[j].grade === null || filteredAssignments[j].excused || filteredAssignments[j].omit_from_final_grade || filteredAssignments[j].grading_standard_id === 2 || filteredAssignments[j].muted || filteredAssignments[j].low_order || filteredAssignments[j].assignment_type === 'Assignments' && filteredAssignments[j].points_possible <= 2 && !noDedicatedDiscussionGrp) {
            assignmentCount--;
            continue;
          }
          if (gradeOverride && gradeOverride.grade && gradeOverride.score) {
            // The gradeOveride grade and score are names of the fields in the gradebook
            filteredAssignments[j].grade = filteredAssignments[j][gradeOverride.grade];
            filteredAssignments[j].score = filteredAssignments[j][gradeOverride.score];
          }
          let addScore = parseFloat(filteredAssignments[j].score);
          if (!isNaN(addScore)) {
            scoreSum += addScore;
          }
          possibleSum += filteredAssignments[j].points_possible;
        }
        if (assignmentCount === 0) {
          scoreCalc = 'N/A';
          gradeCalc = 'N/A';
        } else {
          scoreCalc = (scoreSum / possibleSum * 100).toFixed(2);
          gradeCalc = this.calcLetter(scoreCalc);
        }
        categoryGrades.push({
          category: gradeTypes[i].assignment_type,
          score: scoreCalc + '%',
          grade: gradeCalc,
          weight: gradeTypes[i].assignment_weight
        });
        if (assignmentCount === 0) {
          continue;
        }
        // Check if title of the given assignment group is "Live Class Participation (Bonus)"
        // If so, then add the score to the total score, but do not add the weight to the total weight
        if (filteredAssignments[0] && filteredAssignments[0].assignment_type !== 'Live Class Participation (Bonus)') {
          totalWeight += filteredAssignments[0].assignment_weight;
        }
        avgStore.push(scoreCalc * (filteredAssignments[0].assignment_weight / 100));
      }
      if (avgStore.length === 0) {
        return {
          categoryGrades,
          weightedScore: 0,
          finalGrade: 'N/A'
        };
      }
      let totalScore = avgStore.reduce(function (total, num) {
        return total + num;
      });

      // Weght score based on total number of filled group criteria
      let weightedScore = (totalScore / totalWeight * 100).toFixed(2);
      let finalGrade = this.calcLetter(weightedScore);
      return {
        categoryGrades,
        weightedScore: weightedScore + '%',
        finalGrade
      };
    }
    calcLetter(scoreCalc) {
      let gradeCalc = '';
      if (scoreCalc >= 93) {
        gradeCalc = 'A';
      } else if (scoreCalc >= 90) {
        gradeCalc = 'A-';
      } else if (scoreCalc >= 87) {
        gradeCalc = 'B+';
      } else if (scoreCalc >= 83) {
        gradeCalc = 'B';
      } else if (scoreCalc >= 80) {
        gradeCalc = 'B-';
      } else if (scoreCalc >= 77) {
        gradeCalc = 'C+';
      } else if (scoreCalc >= 73) {
        gradeCalc = 'C';
      } else if (scoreCalc >= 70) {
        gradeCalc = 'C-';
      } else if (scoreCalc >= 60) {
        gradeCalc = 'D';
      } else {
        gradeCalc = 'F';
      }
      return gradeCalc;
    }
  }
  _exports.default = GradeCalculatorService;
});