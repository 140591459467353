define("bocce/mixins/quiz-question", ["exports", "lodash.isequal"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable-next-line ember/no-new-mixins */
  var _default = _exports.default = Ember.Mixin.create({
    isLegacy() {
      return this.type === 'multichoice' || this.type === 'multianswer';
    },
    isAnswerCorrect(callback) {
      let givenAnswer = this.get('given_answer');
      let correctAnswer = this.get('correct_answer');
      if (this.isLegacy()) {
        return this.isAnswerCorrectLegacy(givenAnswer);
      } else if (correctAnswer == null || givenAnswer == null) {
        return false;
      }
      return callback(givenAnswer, correctAnswer);
    },
    isAnswerCorrectLegacy(answerId) {
      const answers = this.get('answers');
      if (Array.isArray(answers) && answers.length) {
        let answer = answers.filter(answer => answer.correct);
        if (answer && answer.length) {
          return answerId == answer[0].id;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    get answerCorrectFeedback() {
      let message = null;
      const correctFeedback = this.get('correct_feedback');
      const wrongFeedback = this.get('wrong_feedback');
      let isEqual = this.isAnswerCorrect();
      if (isEqual) {
        message = correctFeedback ? correctFeedback : 'Correct!';
        this.gotAnswerCorrect = true;
      } else {
        message = wrongFeedback ? wrongFeedback : 'Incorrect';
      }
      return message;
    },
    async updateCanvas() {
      await this.quizzes.updateCanvasQuestionAnswer(this);
      this.answerUnsaved = false;
    },
    async onNextOrFinish() {
      this.answerUnsaved = false;
    },
    get nextOrFinishDisabled() {
      return this.quizzes.gradingQuiz || this.quizzes.uploadingQuestion || this.quizzes.uploadFailed;
    },
    async onAnswerChange() {
      this.answerUnsaved = true;
      if (this.isLegacy()) {
        if (this.quizzes.isTimedQuiz) {
          await this.updateCanvas();
        }
      }
    },
    async getAnswer() {
      return this.given_answer;
    },
    doCheckAnswer: Ember.computed(function () {
      if (this.isLegacy()) {
        return true;
      } else {
        return false;
      }
    }),
    get textFormatted() {
      return this.text;
    },
    get hasAnswer() {
      return !(0, _lodash.default)(this.given_answer, this.default_answer);
    },
    //I'm pretty sure this can be removed.
    get statistics() {
      const stats = this.quiz.quiz_statistics.find(qs => qs.position == this.position);
    },
    formattedCorrectAnswer: Ember.computed(function () {
      return this.get('correct_answer');
    }),
    blankIds: Ember.computed('answers', function () {
      let answers = this.get('answers');
      let ids = new Set();
      answers.forEach(answer => {
        ids.add(answer.blank_id);
      });
      return ids;
    }),
    optionAnswers: Ember.computed('answers', function () {
      let answers = this.get('answers');
      let ret = {};
      answers.forEach(answer => {
        let blankId = answer.blank_id;
        if (!ret[blankId]) {
          ret[blankId] = [];
        }
        ret[blankId].push({
          id: answer.id,
          text: answer.text
        });
      });
      return ret;
    }),
    //Gets overwritten for non-archived question types.
    default_answer: Ember.computed('type', function () {
      switch (this.get('type')) {
        case 'multianswer':
        case 'multichoice':
        default:
          return null;
      }
    })
  });
});