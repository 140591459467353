define("bocce/components/modals/attachments/attachments-record-audio", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ModalsAttachmentsAttachmentsRecordAudioComponent = _exports.default = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, (_class = class ModalsAttachmentsAttachmentsRecordAudioComponent extends _component.default {
    constructor(owner, args) {
      super(owner, args);
      _initializerDefineProperty(this, "recordedAudio", _descriptor, this);
      _initializerDefineProperty(this, "currentlyRecordingAudio", _descriptor2, this);
      _initializerDefineProperty(this, "timeRecorded", _descriptor3, this);
      _initializerDefineProperty(this, "echoCancellation", _descriptor4, this);
      _initializerDefineProperty(this, "showEraseConfirmation", _descriptor5, this);
      _defineProperty(this, "recorder", void 0);
      this.echoCancellation = localStorage.getItem('bocceEchoCancellation') === 'true' || false;
      this.reInitialize();
    }
    get generatedUrl() {
      if (!this.recordedAudio) {
        return "placeholder";
      } else if (!this.recordedAudio.url) {
        return URL.createObjectURL(this.recordedAudio);
      }

      // Grab Kaltura thumbnail
      let url = this.recordedAudio.url;
      let kaltura = url.split('https://cdnapisec.kaltura.com/p/2588802/sp/258880200/playManifest/entryId/');
      if (kaltura.length > 1) {
        kaltura = kaltura[1].split('/format/url/protocol/https/flavorParamId/4128/name/course_video.mp4');
        let thumbnailUrl = 'https://cdnsecakmi.kaltura.com/p/2588802/thumbnail/entry_id/' + kaltura[0] + '/width/250';
        return thumbnailUrl;
      }
      return url;
    }
    startUserMedia(stream) {
      this.recorder = new Recorder(stream);
      console.debug('Recorder initialised.');
    }
    async startRecording() {
      let constraints = {
        audio: true
      };
      if (this.echoCancellation) {
        constraints.audio = {
          echoCancellation: false,
          mozAutoGainControl: false,
          mozNoiseSuppression: false,
          googEchoCancellation: false,
          googAutoGainControl: false,
          googNoiseSuppression: false,
          googHighpassFilter: false
        };
      }
      navigator.mediaDevices.getUserMedia(constraints).then(stream => {
        this.currentlyRecordingAudio = true;
        this.startUserMedia(stream);
        this.recorder.record(time => {
          this.timeRecorded = time;
        });
      }).catch(error => {
        console.debug('No live audio input: ' + error);
      });
    }
    stopRecording() {
      let rec = this.recorder.stop();
      if (rec) {
        // create MP3 download link using audio data blob
        this.recorder.exportMP3((blob, mp3Name) => {
          // this.args.controller.send('addValidFile', file); todo maybe dont need this?
          this.recordedAudio = new File([blob], mp3Name, {
            type: 'audio/mpeg3'
          });
        }, this);
        this.currentlyRecordingAudio = false;
      }
    }
    attachToPost() {
      if (this.recordedAudio) {
        this.args.addFile(this.recordedAudio);
        this.args.closeModal();
      }
    }
    reRecord() {
      this.showEraseConfirmation = true;
    }
    toggleEchoCancellation() {
      this.echoCancellation = !this.echoCancellation;
      localStorage.setItem('echoCancellation', this.echoCancellation);
    }
    reInitialize() {
      this.recordedAudio = undefined;
      this.currentlyRecordingAudio = false;
      this.showEraseConfirmation = false;
      this.timeRecorded = "0:00";
      this.recorder = undefined;
    }
    cancelErase() {
      this.showEraseConfirmation = false;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "recordedAudio", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentlyRecordingAudio", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "timeRecorded", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "echoCancellation", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "showEraseConfirmation", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "startRecording", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "startRecording"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "stopRecording", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "stopRecording"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "attachToPost", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "attachToPost"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reRecord", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "reRecord"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleEchoCancellation", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "toggleEchoCancellation"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reInitialize", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "reInitialize"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancelErase", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "cancelErase"), _class.prototype)), _class));
});