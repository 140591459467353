define("bocce/models/attachment", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // app/models/attachment.js
  var _default = _exports.default = _model.default.extend({
    name: (0, _model.attr)('string'),
    size: (0, _model.attr)('number'),
    type: (0, _model.attr)('string'),
    url: (0, _model.attr)('string'),
    created_at: (0, _model.attr)('date'),
    thumbnail_url: (0, _model.attr)('string'),
    attachment: (0, _model.attr)(),
    file_context: (0, _model.attr)()
  });
});