define("bocce/components/quiz-admin/unlock-for-next-attempt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const UnlockForNextAttempt2 = Ember.Component.extend({
    selectedUserChanged: Ember.observer('selectedUser.quizAttemptMade', function () {
      this.quizAttemptMade = this.get('selectedUser.quizAttemptMade');
    })
  });
  let UnlockForNextAttempt = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, (_class = class UnlockForNextAttempt extends UnlockForNextAttempt2 {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "session", _descriptor, this);
      _defineProperty(this, "allowedExtraAttempts", [1, 2, 3, 4, 5]);
      _initializerDefineProperty(this, "selectedExtraAttempts", _descriptor2, this);
      _initializerDefineProperty(this, "quizAttemptMade", _descriptor3, this);
    }
    init(...args) {
      super.init(...args);
      this.set('onAction', this._onAction.bind(this));
      this.quizAttemptMade = this.selectedUser.quizAttemptMade;
    }
    async _onAction() {
      const result = await Ember.$.get(`interface/quiz-admin/extend/${this.session.get('course.id')}/${this.selectedQuiz.get('search_id')}/${this.selectedUser.get('id')}/${this.selectedExtraAttempts}`);
      return result.quiz_extensions ? "success" : "error";
    }
    get disabledReason() {
      //This is here because Canvas generates a 'settings_only' quiz submission if you try to add more attempts when there 
      //are none to begin with. The 'settings_only' quiz submission screws things up.
      if (!this.quizAttemptMade) {
        return "Quiz attempt needed to add more.";
      } else {
        return null;
      }
    }
    changeSelectedExtraAttempts(event) {
      this.selectedExtraAttempts = +event.target.value;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "selectedExtraAttempts", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "quizAttemptMade", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "_onAction", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "_onAction"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeSelectedExtraAttempts", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "changeSelectedExtraAttempts"), _class.prototype)), _class));
  ;
});