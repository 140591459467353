define("bocce/templates/components/quiz-scenarios/quiz-single-graded-results", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "orpN+mb1",
    "block": "{\"symbols\":[],\"statements\":[[8,\"quiz/previous-questions\",[],[[\"@quizzes\"],[[34,0]]],null],[2,\"\\n\"],[8,\"quiz/results\",[],[[\"@onRetry\",\"@quizzes\"],[[32,0,[\"quizzes\",\"retryQuiz\"]],[34,0]]],null]],\"hasEval\":false,\"upvars\":[\"quizzes\"]}",
    "meta": {
      "moduleName": "bocce/templates/components/quiz-scenarios/quiz-single-graded-results.hbs"
    }
  });
});