define("bocce/helpers/spacelist", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // app/helpers/spacelist.js
  // Turn string to dashed string
  var _default = _exports.default = Ember.Helper.helper(function (dec) {
    var item = dec[0];
    return item.join(' ');
  });
});