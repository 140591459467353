define("bocce/components/classroom/current-lesson-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['current-course-topic'],
    text: Ember.computed('lesson', 'title', function () {
      let lesson = this.lesson;
      if (Number(lesson) === 0) {
        lesson = 'Getting Started';
      }
      let title = this.title;
      if (!title) {
        return '';
      }
      return `${lesson} - ${title}`;
    })
  });
});