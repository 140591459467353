define("bocce/components/quiz-stats/question-statistics-manual", ["exports", "bocce/utilities/dialog"], function (_exports, _dialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  //Used for essay and file upload question types.
  //this.statistics gets passed in to the component. Origin is canvas quiz stats api.
  let QuestionStatisticsManual = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember._action, _dec3 = Ember._action, (_class = class QuestionStatisticsManual extends Ember.Component {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "session", _descriptor, this);
    }
    init(...args) {
      super.init(...args);
      this.stats = this.getStats();
    }
    getStats() {
      let stats = {};
      let scores = {
        graded: [],
        ungraded: []
      };
      let numResponses = this.statistics.responses;
      let usernames = [];
      let numUngraded = 0;
      for (const answer of this.statistics.answers) {
        for (const username of answer.user_names) {
          if (answer.id == 'ungraded') {
            scores.ungraded.push({
              name: username,
              score: -1
            });
          } else {
            scores.graded.push({
              name: username,
              score: answer.score
            });
          }
          usernames.push(username);
        }
        if (answer.id == 'ungraded') {
          numUngraded += answer.responses;
        }
      }
      const numStudents = this.session.get('section.numStudents');

      //First, we run the calculation, then we convert it to a float, then we ensure we only have two decimal places, finally, 
      //we strip off trailing zeros with the '+' at the front of this calculation. For example, the '+' makes sure that '100.00' gets stored 
      //in the string as '100', '100.10' as '100.1', etc.
      stats.percentageUngraded = `${+parseFloat(numUngraded / numResponses * 100).toFixed(2)}`;
      stats.percentageGraded = `${+parseFloat((numResponses - numUngraded) / numResponses * 100).toFixed(2)}`;
      stats.percentageSubmitted = `${+parseFloat(numResponses / numStudents * 100).toFixed(2)}`;
      stats.scores = scores;
      stats.usernames = usernames;
      stats.numResponses = numResponses;
      return stats;
    }
    showUsers(usernames) {
      let usersHtml = `<table class="question-stats-usernames">
                        <tbody>`;
      for (let user of [...new Set(usernames.sort())]) {
        usersHtml += `<tr>
                       <td>${user}</td>
                     </tr>`;
      }
      usersHtml += '</tbody></table>';
      (0, _dialog.default)('', ['OK'], usersHtml, 0);
    }
    showScores(scores, type) {
      let scoresHtml = `<table class="question-stats-usernames">
                        <tbody>`;
      if (type == 'graded') {
        scoresHtml += `
      <tr>
        <th>User</th>
        <th>Score</th>
      </tr>`;
      }
      for (let score of scores[type]) {
        scoresHtml += `<tr>
                       <td>${score.name}</td>
                       ${type == 'graded' ? `<td>${score.score}</td>` : ''}
                     </tr>`;
      }
      scoresHtml += '</tbody></table>';
      (0, _dialog.default)('', ['OK'], scoresHtml, 0, ["quiz-stats-essay-scores"]);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "showUsers", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "showUsers"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showScores", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "showScores"), _class.prototype)), _class));
});