define("bocce/helpers/show-ungraded", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // app/helpers/show-ungraded.js
  // Show ungraded items in the dashboard
  var _default = _exports.default = Ember.Helper.helper(function (inp) {
    var grade = inp[0],
      showUngraded = inp[1];
    if (grade) {
      return true;
    }
    if (!grade && showUngraded) {
      return true;
    }
    return false;
  });
});