define("bocce/templates/components/assignment-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "hS/w/LI+",
    "block": "{\"symbols\":[],\"statements\":[[8,\"user-portrait\",[],[[\"@user\"],[[34,0]]],null],[2,\"\\n\"],[11,\"button\"],[24,0,\"author-name\"],[4,[38,1],[[32,0],\"toggleUserProfile\",[35,0,[\"id\"]]],[[\"target\"],[[32,0,[\"userprofileService\"]]]]],[12],[2,\"\\n  \"],[1,[35,0,[\"short_name\"]]],[2,\" \"],[8,\"user-icons\",[],[[\"@user\",\"@isInstructor\"],[[34,0],[34,2,[\"isInstructor\"]]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"author\",\"action\",\"session\"]}",
    "meta": {
      "moduleName": "bocce/templates/components/assignment-user.hbs"
    }
  });
});