define("bocce/helpers/letter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // app/helpers/letter.js
  // Convert number to letter
  var _default = _exports.default = Ember.Helper.helper(function (item) {
    if (!item) {
      return false;
    }
    return String.fromCharCode(65 + item[0]);
  });
});