define("bocce/models/quiz_statistic", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // app/models/quiz_question.js
  var _default = _exports.default = _model.default.extend({
    url: (0, _model.attr)('string'),
    html_url: (0, _model.attr)('string'),
    multiple_attempts_exist: (0, _model.attr)('string'),
    generated_at: (0, _model.attr)('date'),
    includes_all_versions: (0, _model.attr)('boolean'),
    includes_sis_ids: (0, _model.attr)('boolean'),
    points_possible: (0, _model.attr)('number'),
    anonymous_survey: (0, _model.attr)('boolean'),
    speed_grader_url: (0, _model.attr)('string'),
    quiz_submissions_zip_url: (0, _model.attr)('string'),
    question_statistics: (0, _model.hasMany)('question_statistic', {
      async: false
    }),
    submission_statistics: (0, _model.attr)(),
    links: (0, _model.attr)()
  });
});