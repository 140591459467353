define("bocce/models/banner", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const dismissConditions = {
    1: 'Grading Reminder',
    2: 'Student Survey',
    3: 'Individual User Banner',
    4: 'Course Banner',
    5: 'Global Instructors',
    6: 'Global Students',
    7: 'Dashboard Banner'
  };
  var _default = _exports.default = _model.default.extend({
    message: (0, _model.attr)('string'),
    created_at: (0, _model.attr)('date'),
    is_global: (0, _model.attr)('boolean'),
    dismiss_condition_id: (0, _model.attr)('number'),
    dismiss_at: (0, _model.attr)('date'),
    priority: (0, _model.attr)('number'),
    context_id: (0, _model.attr)('number'),
    link: (0, _model.attr)('string'),
    context_data: Ember.computed('context_id', function () {
      let context_id = this.get('context_id'),
        type = this.get('type');
      if (type === "Individual User Banner") {
        return this.store.peekRecord('user', context_id).get('sortable_name');
      } else {
        return context_id;
      }
    }),
    type: Ember.computed('dismiss_condition_id', 'is_global', function () {
      if (this.is_global) {
        return 'Global';
      } else {
        const condition = this.dismiss_condition_id;
        return dismissConditions[condition];
      }
    })
  });
});