define("bocce/js/utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.saveFile = saveFile;
  _exports.xmlToJson = void 0;
  //Taken from https://stackoverflow.com/a/36899900
  function saveFile(name, type, data) {
    if (data !== null && navigator.msSaveBlob) return navigator.msSaveBlob(new Blob([data], {
      type: type
    }), name);
    var a = $("<a style='display: none;'/>");
    var url = window.URL.createObjectURL(new Blob([data], {
      type: type
    }));
    a.attr("href", url);
    a.attr("download", name);
    $("body").append(a);
    a[0].click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }
  const xmlToJson = _exports.xmlToJson = function xmlToJson(xml) {
    let obj = {};
    if (xml.nodeType == 1) {
      // element
      if (xml.attributes.length > 0) {
        obj['@attributes'] = {};
        for (let j = 0; j < xml.attributes.length; j++) {
          let attribute = xml.attributes.item(j);
          obj['@attributes'][attribute.nodeName] = attribute.nodeValue;
        }
      }
    } else if (xml.nodeType == 3) {
      // text
      obj = xml.nodeValue;
    }

    // do children
    if (xml.hasChildNodes()) {
      let children = xml.childNodes;
      if (children.length === 1 && children[0].nodeName === '#text') {
        obj = children[0].nodeValue;
      } else {
        for (let i = 0; i < children.length; i++) {
          let item = children.item(i);
          let nodeName = item.nodeName;
          if (typeof obj[nodeName] === 'undefined') {
            obj[nodeName] = xmlToJson(item); // Call the function directly
          } else {
            if (!Array.isArray(obj[nodeName])) {
              obj[nodeName] = [obj[nodeName]];
            }
            obj[nodeName].push(xmlToJson(item)); // Call the function directly
          }
        }
      }
    }
    return obj;
  };
});