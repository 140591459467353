define("bocce/helpers/diff", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.diff = diff;
  function diff([a, b] /*, hash*/) {
    return a !== b;
  }
  var _default = _exports.default = Ember.Helper.helper(diff);
});