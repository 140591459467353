define("bocce/services/templates", ["exports", "bocce/utilities/dialog"], function (_exports, _dialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let TemplatesService = _exports.default = (_dec = Ember._tracked, _dec2 = Ember.inject.service, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, (_class = class TemplatesService extends Ember.Service {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "selectedTemplateIndex", _descriptor, this);
      _initializerDefineProperty(this, "session", _descriptor2, this);
    }
    //Get the user's saved templates.
    get templates() {
      let eventSchedulerTemplates = this.session.get('user.eventSchedulerTemplates');
      return eventSchedulerTemplates ?? [];
    }

    //Get the selected template. Automatically updates when the selected template index updates. 
    get selectedTemplate() {
      const selectedTemplateIndex = this.selectedTemplateIndex;
      const selectedTemplate = selectedTemplateIndex != null && selectedTemplateIndex >= 0 && selectedTemplateIndex < this.templates.length ? this.templates[selectedTemplateIndex] : null;
      return selectedTemplate;
    }

    //Automatically gets updated when 'get selectedTemplate()' updates.
    get selectedTemplateId() {
      const selectedTemplate = this.get('selectedTemplate');
      return selectedTemplate ? selectedTemplate.id : -1;
    }
    changeTemplate(index) {
      //This will trigger the updating of 'selectedTemplate()' and 'selectedTemplateId()' 
      this.selectedTemplateIndex = +index;
      if (index >= 0 && index < this.templates.length) {
        this.onTemplateChange(this.templates[index]);
      } else {
        this.onTemplateChange();
      }
    }
    deleteTemplate(templateId, templateName) {
      (0, _dialog.default)(`Are you sure you wish to delete template '${templateName}'? This action CANNOT be undone!`, ['Yes', 'No']).then(choice => {
        if (choice === 'No') {
          return false;
        }
        const self = this;
        const sectionId = this.session.get('section.id');
        $.ajax({
          type: 'DELETE',
          url: `/interface/sections/${sectionId}/event-scheduler-templates/${templateId}`,
          success() {
            let templateIndex = self.templates.findIndex(t => t.id == templateId);
            if (templateIndex >= 0) {
              self.templates.removeAt(templateIndex);
              if (self.templates.length === 0 || templateIndex == self.selectedTemplateIndex) {
                self.selectedTemplateIndex = -1;
              } else if (templateIndex < self.selectedTemplateIndex) {
                self.selectedTemplateIndex = self.selectedTemplateIndex - 1;
              }
              self.onDeleteTemplate();
            }
          },
          error(jqXHR, textStatus) {
            console.error('Delete template error: ' + JSON.stringify(jqXHR));
            (0, _dialog.default)(`Failed to delete template '${templateName}'. Please try again later.`);
          }
        });
      });
    }
    renameTemplate(currentName, id) {
      (0, _dialog.default)(`Please enter the new name for template '${currentName}'`, ['Save and Continue', 'Cancel'], false, ['rename-template']).then(name => {
        // dialog returns false if the user clicks "cancel"
        if (name === false) {
          return false;
        }
        const self = this;
        const sectionId = this.session.get('section.id');
        $.ajax({
          type: 'PUT',
          url: `/interface/sections/${sectionId}/event-scheduler-templates/${id}/rename`,
          data: JSON.stringify({
            name
          }),
          success() {
            let template = self.templates.find(t => t.id == id);
            if (template) {
              Ember.set(template, 'name', name);
            }
          },
          error(jqXHR, textStatus) {
            console.error('Rename template error: ' + JSON.stringify(jqXHR));
            (0, _dialog.default)(`Failed to rename template '${currentName}'. Please try again later.`);
          }
        });
      });
    }
    saveTemplate(eventsModel) {
      (0, _dialog.default)('Please enter a short title for your template. Note: date, time and sections do not get saved.', ['Save', 'Cancel'], false, true, ['save-template']).then(name => {
        // dialog returns false if the user clicks "cancel"
        if (name === false) {
          return false;
        }
        const sectionId = this.session.get('section.id');
        const self = this;
        $.ajax({
          type: 'POST',
          url: `/interface/sections/${sectionId}/event-scheduler-templates`,
          data: JSON.stringify({
            name,
            user_id: this.session.get('user.id'),
            course_id: this.session.get('section.course.id'),
            events: eventsModel.map(em => ({
              title: em.titleInput,
              body: em.bodyInput
            }))
          }),
          success(templateId) {
            self.templates.pushObject({
              id: templateId,
              name,
              events: eventsModel.map(event => ({
                title: event.titleInput,
                body: event.bodyInput
              }))
            });
            self.changeTemplate(self.templates.length - 1);
          },
          error(jqXHR, textStatus) {
            console.error('save as template error: ' + JSON.stringify(jqXHR));
            (0, _dialog.default)(`Failed to save template '${name}'. Please try again later.`);
          }
        });
      });
    }
    updateTemplate(eventsModel) {
      if (this.selectedTemplateIndex == null) {
        console.error('no template index');
        return;
      } else if (this.selectedTemplateId == null) {
        console.error('no selected template id');
        return;
      } else if (this.selectedTemplate == null) {
        console.error('no selected template');
        return;
      }
      const name = this.selectedTemplate ? this.selectedTemplate.name : null;
      if (!name) {
        console.error('no template name');
        return;
      }
      (0, _dialog.default)(`Are you sure you wish to update template '${name}'? Note: date, time, and sections do not get saved.`, ['Yes', 'No']).then(choice => {
        if (choice === 'No') {
          return false;
        }
        const templateId = this.selectedTemplateId,
          name = this.selectedTemplate.name,
          selectedTemplateIndex = this.selectedTemplateIndex,
          self = this,
          events = eventsModel.map(em => ({
            title: em.titleInput,
            body: em.bodyInput
          }));
        if (selectedTemplateIndex != null && selectedTemplateIndex >= 0) {
          const sectionId = this.session.get('section.id');
          $.ajax({
            type: 'PUT',
            url: `/interface/sections/${sectionId}/event-scheduler-templates/${templateId}`,
            data: JSON.stringify({
              name,
              user_id: this.session.get('user.id'),
              course_id: this.session.get('section.course.id'),
              events
            }),
            success(newTemplateId) {
              self.templates.replace(selectedTemplateIndex, 1, [{
                id: newTemplateId,
                name,
                events
              }]);
              self.changeTemplate(selectedTemplateIndex);
            },
            error(jqXHR, textStatus) {
              console.error('Update template error: ' + JSON.stringify(jqXHR));
              (0, _dialog.default)(`Failed to update template '${name}'. Please try again later.`);
            }
          });
        }
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "selectedTemplateIndex", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return -1;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "changeTemplate", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "changeTemplate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteTemplate", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "deleteTemplate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "renameTemplate", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "renameTemplate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveTemplate", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "saveTemplate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateTemplate", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "updateTemplate"), _class.prototype)), _class));
});