define("bocce/helpers/decode", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // app/helpers/decode.js
  // Decode URI-style properties
  var _default = _exports.default = Ember.Helper.helper(function (dec) {
    var retval = '',
      item = dec.join(' ');
    try {
      retval = decodeURIComponent(item);
    } catch (err) {
      retval = unescape(item);
    }
    return retval;
  });
});