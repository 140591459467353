define("bocce/mixins/interactions/javascript", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* jshint unused: false */

  function Javascript($el, data) {
    this.$el = $el;
    this.data = data.config;
    this.data.runnable = !!parseInt(this.data.runnable, 10);
    this.data.line_numbers = !!parseInt(this.data.line_numbers, 10);
    this.data.editable = !!parseInt(this.data.editable, 10);
    this.content = this.$el.find('.interaction_content');
    this.height = this.content.css('min-height');
    this.content.css('width', '').css('min-height', '');
  }
  Javascript.prototype = {
    format_code: function () {
      let retval = this.data.code;
      if (this.data.line_numbers && !this.data.editable) {
        let parts = this.data.code.split('\n'),
          lines = parts.length,
          digits = ('' + lines).length;
        retval = [];
        for (let i = 0; i < parts.length; i++) {
          let part = parts[i],
            num = i + 1,
            nd = ('' + num).length;
          if (nd < digits) {
            for (; nd < digits; nd++) {
              num = '0' + num;
            }
          }
          retval.push(num + '| ' + part);
        }
        retval = retval.join('\n');
      }
      return retval.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
    },
    init: function () {
      let html = '<div class="code_runner"><div class="language">' + this.data.language + '</div>';
      if (this.data.sandbox) {
        html += `<div class="sandbox">${this.data.sandbox}</div>`;
      }
      if (this.data.editable) {
        html += '<div><textarea class="code" wrap="hard" style="width: 95%; height: ' + this.height + ';">' + this.format_code() + '</textarea></div>';
      } else {
        html += '<div class="code">' + this.format_code() + '</div>';
      }
      if (this.data.runnable) {
        html += '<button class="run_code">Run!</button>' + '<div class="output"></div>';
      }
      if (this.data.boot_code) {
        try {
          eval(this.data.boot_code);
        } catch (err) {
          console.log(`Boot code failed: ${err.message}`);
        }
      }
      html += '</div>';
      this.content.html(html);
      this.run_button = this.content.find('button.run_code');
      this.output_area = this.content.find('div.output');
      this.code_area = this.content.find('textarea');
      this.sandbox = this.content.find('.sandbox');
      this.run_button.click(this.run.bind(this));
    },
    run: function () {
      let code,
        that = this;
      if (this.data.editable) {
        code = this.code_area.val();
      } else {
        code = this.data.code;
      }
      code = code.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&');
      let funcs = code.match(/function ([a-z0-9_]+)[^a-z0-9_]/ig);
      if (funcs && funcs.length > 0) {
        funcs = funcs.map(function (fn) {
          return fn.match(/function ([a-z0-9_]+)[^a-z0-9_]/i)[1];
        });
        funcs.forEach(function (fn) {
          code = code.replace('function ' + fn, 'function __orig_' + fn);
          code = 'function ' + fn + '() {guard(\'' + fn + '\'); return __orig_' + fn + '.apply(null, arguments);}' + code;
        });
      }
      let guard_counts = {},
        guard; //eslint-disable-line no-unused-vars

      // eslint-disable-next-line no-unused-vars
      guard = function (fn) {
        if (!guard_counts[fn]) {
          guard_counts[fn] = 0;
        }
        guard_counts[fn]++;
        if (guard_counts[fn] >= 100) {
          throw 'Recursion safety net: ' + fn + ' called more than 100 times';
        }
      };
      let print = function (val) {
        if (val instanceof Set) {
          return 'Set: ' + Array.from(val).join(', ');
        }
        if (val instanceof Map) {
          return 'Map: ' + Array.from(val).map(x => x.join(' => ')).join(', ');
        }
        if (Array.isArray(val)) {
          return '[' + val.map(print).join(', ') + ']';
        }
        if (val && typeof val === 'object' && Object.keys(val).length > 0) {
          let retval = 'Object';
          if (typeof val !== 'object') {
            retval += ' ' + typeof val;
          }
          return retval + ' { ' + Object.keys(val).map(k => k + ' => ' + print(val[k])).join(', ') + ' }';
        }
        return "" + val;
      };
      this.output_area.html('');
      try {
        // Run it in a closure to avoid polluting the global space
        (function (code) {
          let window = {},
            //eslint-disable-line no-unused-vars
            $output_area = that.output_area,
            //eslint-disable-line no-unused-vars
            $sandbox = that.sandbox,
            //eslint-disable-line no-unused-vars
            sandbox = $sandbox[0]; //eslint-disable-line no-unused-vars

          let console = {
            //eslint-disable-line no-unused-vars
            log: function () {
              for (let val of arguments) {
                that.output_area[0].innerHTML += print(val) + '\n';
              }
            }
          };
          eval(code);
        })(code);
      } catch (err) {
        this.output_area[0].innerHTML += err.toString() + '\n';
      }
    }
  };
  var _default = _exports.default = Javascript;
});