define("bocce/templates/components/quiz/check-answer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "/bEQMjNK",
    "block": "{\"symbols\":[\"@disabled\",\"@question\",\"@quizId\",\"@onCheckAnswer\"],\"statements\":[[11,\"button\"],[24,\"aria-hidden\",\"true\"],[16,0,[31,[\"check-answer \",[30,[36,0],[[32,1],\"disabled\"],null]]]],[16,\"for\",[31,[\"quiz-\",[32,3],\"-question-\",[32,2,[\"d\"]],\"-checker\"]]],[16,\"disabled\",[32,1]],[24,4,\"button\"],[4,[38,2],[\"click\",[30,[36,1],[[32,4],[32,2],false],null]],null],[12],[2,\"\\n    Check Answer\\n\"],[13],[2,\"\\n\"],[11,\"button\"],[24,0,\"sr-only check-answer submit-question-button\"],[4,[38,2],[\"click\",[30,[36,1],[[32,4],[32,2],true],null]],null],[12],[2,\"Check\\n    Answer\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"fn\",\"on\"]}",
    "meta": {
      "moduleName": "bocce/templates/components/quiz/check-answer.hbs"
    }
  });
});