define("bocce/mixins/conversable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable-next-line ember/no-new-mixins */
  var _default = _exports.default = Ember.Mixin.create({
    viewConversationdashboard: function (conversation_id) {
      let conversationController = this.conversation;
      if (!conversationController) {
        console.error('conversationController not found');
        return;
      }
      return this.store.findRecord('conversation', conversation_id, {
        reload: true
      }).then(conversation => {
        conversationController.set('model', conversation);
        Ember.run.schedule('afterRender', this, () => {
          // Scroll .modal-content to bottom
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('.modal-content').scrollTop(Ember.$('.modal-content')[0].scrollHeight);
          this.set('conversation.dashboard.messageListOn', false);

          // Uncheck #convo-new-release
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('#convo-new-release').prop('checked', false);
        });
      });
    },
    newConversationdashboard: function (user_id_string) {
      if (!user_id_string) {
        return;
      }
      let user_ids = user_id_string.toString().split('-');
      let users = user_ids.map(id => {
        return this.store.findRecord('user', id);
      });
      users = Promise.all(users);
      users.then(users => {
        if (users) {
          let group_conversation = user_ids.length > 2 ? true : false;
          let conversation = this.store.createRecord('conversation', {
            recipients: user_ids,
            private: !group_conversation,
            conversationPartners: users,
            group_conversation
          });
          let conversationController = this.conversation;
          conversationController.set('model', conversation);
          this.set('conversation.dashboard.messageListOn', false);

          // Uncheck #convo-new-release
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('#convo-new-release').prop('checked', false);
        }
      });
    },
    actions: {
      createConversation: function (recipient_id) {
        if (this.target.currentRouteName === "dashboard") {
          this.newConversationdashboard(recipient_id);
        } else {
          this.transitionToRoute('classroom.lessons.conversation-new-with', recipient_id);
        }
      },
      viewConversation: function (conversation_id) {
        if (this.target.currentRouteName === "dashboard") {
          this.viewConversationdashboard(conversation_id);
        } else {
          this.transitionToRoute('classroom.lessons.conversation', conversation_id);
        }
      },
      toggleConversationStar: function (conversation_id) {
        this.store.findRecord('conversation', conversation_id).then(conversation => {
          let starred = !conversation.get('starred');
          conversation.set('starred', starred);
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$.ajax({
            type: 'put',
            url: `/interface/conversations/${conversation_id}`,
            data: JSON.stringify({
              conversation: {
                starred
              }
            }),
            success: () => {
              // debug('Success starring conversation');
            },
            error: error => {
              Ember.debug('Error starring conversation');
              Ember.debug(error);
            }
          });
        });
      }
    }
  });
});