define("bocce/templates/components/side-panel/late-grading-policy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "wozk6C0w",
    "block": "{\"symbols\":[\"@closeLateGradingPolicy\"],\"statements\":[[11,\"div\"],[24,\"aria-hidden\",\"true\"],[24,0,\"late-grading-policy-dialog-backdrop\"],[4,[38,0],[\"click\",[32,1]],null],[12],[13],[2,\"\\n  \"],[10,\"div\"],[14,\"role\",\"dialog\"],[14,\"aria-modal\",\"true\"],[14,\"aria-describedby\",\"late-grading-policy\"],[14,0,\"late-grading-policy-dialog active\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"late-grading-policy-header\"],[12],[2,\"\\n          \"],[10,\"div\"],[14,0,\"late-grading-policy-title\"],[14,1,\"late-grading-policy\"],[12],[2,\"Late Grading Policy\"],[13],[2,\"\\n          \"],[11,\"button\"],[24,\"aria-label\",\"Close Late Grading Policy Panel\"],[24,0,\"late-grading-policy-close\"],[4,[38,0],[\"click\",[32,1]],null],[12],[10,\"i\"],[14,0,\"fas fa-times\"],[14,\"aria-hidden\",\"true\"],[12],[13],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n\"],[2,\"      \"],[10,\"div\"],[14,\"tabindex\",\"0\"],[14,0,\"late-grading-policy-content\"],[12],[1,[34,1]],[2,\"\\n      \"],[13],[2,\"\\n  \"],[13]],\"hasEval\":false,\"upvars\":[\"on\",\"lateGradingPolicy\"]}",
    "meta": {
      "moduleName": "bocce/templates/components/side-panel/late-grading-policy.hbs"
    }
  });
});