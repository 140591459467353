define("bocce/helpers/letter-grade", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // app/helpers/letter-grade.js
  // Convert points to letter grade
  var _default = _exports.default = Ember.Helper.helper(function (scores) {
    if (!scores[1]) {
      return '';
    }
    let pct = scores[0] / scores[1];
    if (pct >= 0.93) {
      return 'A';
    }
    if (pct >= 0.90) {
      return 'A-';
    }
    if (pct >= 0.87) {
      return 'B+';
    }
    if (pct >= 0.83) {
      return 'B';
    }
    if (pct >= 0.80) {
      return 'B-';
    }
    if (pct >= 0.77) {
      return 'C+';
    }
    if (pct >= 0.73) {
      return 'C';
    }
    if (pct >= 0.70) {
      return 'C-';
    }
    if (pct >= 0.6) {
      return 'D';
    }
    return 'F';
  });
});