define("bocce/components/quiz-scenarios/quiz-intercept", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class QuizIntercept extends Ember.Component {
    get noTimeLeft() {
      return this.quizzes.hasAttempt && !this.quizzes.isRetrying && !this.quizzes.hasTimeLeft;
    }
    get submitButtonCallback() {
      if (this.noTimeLeft) {
        return this.quizzes.submitQuiz;
      } else {
        return this.quizzes.startQuiz;
      }
    }
    get submitButtonText() {
      if (this.noTimeLeft) {
        return "Submit Quiz";
      } else {
        return "Start Quiz";
      }
    }
  }
  _exports.default = QuizIntercept;
});