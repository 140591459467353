define("bocce/mixins/interactions/bpm_tap_pad", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function BpmTapPad($el) {
    this.$el = $el;
    this.$stage = this.$el.find('.interaction_content');
  }
  BpmTapPad.prototype = {
    init: function () {
      var template = '<div class="tap_pad_container"><div class="tap_pad"></div><div class="bpm_label"><span class="value">0</span> BPM</div></div>',
        /* eslint-disable-next-line ember/no-jquery */
        $container = Ember.$(template).appendTo(this.$stage),
        $tapPad = $container.find('.tap_pad');

      // cache el
      this.$bpmLabel = $container.find('.bpm_label .value');

      /* eslint-disable-next-line ember/no-jquery */
      $tapPad.click(Ember.$.proxy(this.onPadClick, this));
    },
    BEATS_TO_AVG: 4,
    lastClickTime: 0,
    tempos: [],
    onPadClick: function () {
      var bpm = this.calculateBpm();
      this.$bpmLabel.text(bpm);
    },
    calculateBpm: function () {
      var currentTime = new Date(),
        diffSecs = (currentTime - this.lastClickTime) / 1000,
        bpm = 60 / diffSecs;
      this.tempos.push(bpm);
      this.lastClickTime = currentTime;

      // when we've collected enough tempos, return the average of them
      if (this.tempos.length >= this.BEATS_TO_AVG) {
        // keep this.tempos length locked at BEATS_TO_AVG
        this.tempos.shift();
        // set bpm to average
        bpm = this.calculateAverage(this.tempos);
      }
      // return bpm between last two clicks until we get enough data
      return Math.round(bpm);
    },
    calculateAverage: function (vals) {
      var average = 0;
      /* eslint-disable-next-line ember/no-jquery */
      Ember.$(vals).each(function (i, val) {
        average += val;
      });
      average /= vals.length;
      return average;
    }
  };
  var _default = _exports.default = BpmTapPad;
});