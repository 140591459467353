define("bocce/components/character-limited-textarea", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <textarea
    class="char-limied-textarea rte-editor-input"
    value={{this.text}}
    maxlength={{this.charLimit}}
    {{on "input" this.updateText}}
  ></textarea>
  <div class="textarea-minimal-charcount">Character Count: {{this.charCount}}/{{this.charLimit}}</div>
  */
  {
    "id": "Mi6WPn2t",
    "block": "{\"symbols\":[],\"statements\":[[11,\"textarea\"],[24,0,\"char-limied-textarea rte-editor-input\"],[16,2,[32,0,[\"text\"]]],[16,\"maxlength\",[32,0,[\"charLimit\"]]],[4,[38,0],[\"input\",[32,0,[\"updateText\"]]],null],[12],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"textarea-minimal-charcount\"],[12],[2,\"Character Count: \"],[1,[32,0,[\"charCount\"]]],[2,\"/\"],[1,[32,0,[\"charLimit\"]]],[13]],\"hasEval\":false,\"upvars\":[\"on\"]}",
    "meta": {
      "moduleName": "bocce/components/character-limited-textarea.hbs"
    }
  });
  let CharacterLimitedTextarea = _exports.default = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, (_class = class CharacterLimitedTextarea extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "text", _descriptor, this);
    }
    get charLimit() {
      return this.args.charLimit || 500;
    }
    updateOverCharLimit() {
      if (this.charCount > this.charLimit) {
        this.args.updateOverCharLimit(true);
      } else {
        this.args.updateOverCharLimit(false);
      }
    }
    updateText(event) {
      let inputWithoutNewlines = event.target.value.replace(/[\r\n]+/gm, '');
      this.text = inputWithoutNewlines.substring(0, this.charLimit);
      if (this.args.updateBodyText) {
        this.args.updateBodyText(this.text);
      }
    }
    get charCount() {
      return this.text.length;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "text", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "updateOverCharLimit", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "updateOverCharLimit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateText", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "updateText"), _class.prototype)), _class));
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, CharacterLimitedTextarea);
});