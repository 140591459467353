define("bocce/components/quiz-stats/quiz-stats-actions", ["exports", "bocce/utilities/dialog", "bocce/js/utils"], function (_exports, _dialog, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let QuizStatsActions = _exports.default = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, (_class = class QuizStatsActions extends Ember.Component {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "answersTracked", _descriptor, this);
      _initializerDefineProperty(this, "working", _descriptor2, this);
      _initializerDefineProperty(this, "action", _descriptor3, this);
    }
    //So hacky, but it was the only way I could get this to re-render when the 'answers' arg changed
    //as in the case of fill-in-multiple-blanks or multiple-dropdowns
    willRender() {
      this.answersTracked = this.answers;
    }
    get hasAttachments() {
      return this.has('file_upload_question');
    }
    get hasEssays() {
      return this.has('essay_question');
    }
    has(type) {
      let retval = false;
      for (const questionStats of this.quizzes.quiz.quiz_statistics.question_statistics.toArray()) {
        if (questionStats.get('question_type') == type && questionStats.get('responses') > 0) {
          retval = true;
          break;
        }
      }
      return retval;
    }
    setAction(event) {
      this.action = event.target.value;
    }
    performAction() {
      if (this.action == 'download-attachments') {
        this.downloadAttachments();
      } else if (this.action == 'download-essays') {
        this.downloadEssays();
      } else if (this.action == 'show-quiz') {
        this.toggleShowStats();
      }
    }
    async downloadAttachments() {
      let numAttempts = 0;
      const getAttachments = async () => {
        this.working = true;
        try {
          let path = this.quizzes.quiz.quiz_statistics.quiz_submissions_zip_url;
          let response = await fetch(path, {
            cache: "no-cache"
          });
          if (!response.ok) {
            throw new Error();

            //assuming that s3 is being used to store the attachments
          } else if (!response.url.indexOf('s3.amazonaws')) {
            numAttempts++;
            if (numAttempts > 30) {
              this.working = false;
              (0, _dialog.default)('No attachments found');
              return;
            }
            setTimeout(getAttachments, 1000);
            return;
          }
          let blob = await response.blob();
          (0, _utils.saveFile)('attachments.zip', 'application/zip', blob);
          this.working = false;
        } catch (e) {
          this.working = false;
          (0, _dialog.default)('No attachments found.');
        }
      };
      getAttachments();
    }
    async downloadEssays() {
      try {
        this.working = true;
        let path = `/interface/sections/${session.get('section.id')}/quizzes/${this.quizzes.quiz.id}/get_essay_responses/-1`;
        let response = await fetch(path);
        if (!response.ok) {
          throw new Error();
        }
        let blob = await response.blob();
        (0, _utils.saveFile)(`${this.quizzes.quiz.title.substring(0, 20)}-essay-responses.zip`, 'application/zip', blob);
        this.working = false;
      } catch (e) {
        this.working = false;
        (0, _dialog.default)('No essays found.');
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "answersTracked", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "working", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "action", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'show-quiz';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setAction", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "setAction"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "performAction", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "performAction"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "downloadAttachments", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "downloadAttachments"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "downloadEssays", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "downloadEssays"), _class.prototype)), _class));
});