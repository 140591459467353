define("bocce/components/side-panel/activity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    session: Ember.inject.service(),
    // Element info

    classNames: ['activity-component'],
    // Default empty arrays for when assignments or discussions is falsey
    // These arrays are also responsible for filtering out locked items.
    discussionsArray: Ember.computed('discussions.@each.locked', function () {
      let discussions = this.discussions || Ember.ArrayProxy.create();
      return discussions.filter(d => !d.get('locked'));
    }),
    assignmentsArray: Ember.computed('assignments', function () {
      return this.assignments || Ember.ArrayProxy.create();
    }),
    // Since the criteria for "unread" is different for teachers vs. students,
    // this computed property attempts to hide that logic.
    assignmentsNeedingAttention: Ember.computed('session.isInstructor', 'assignmentsArray.@each.{needsTeacherAttention,needsStudentAttention}', function () {
      let assignments = this.assignmentsArray;
      /* eslint-disable-next-line ember/no-get */
      let filter = this.get('session.isInstructor') ? 'needsTeacherAttention' : 'needsStudentAttention';
      return assignments.filterBy(filter);
    }),
    // Sets the type of model to filter on.
    // possible values:
    //  * 'all'
    //  * 'assignments'
    //  * 'discussions'
    //  * 'requiredDiscussions'
    //  * 'announcements'
    filterType: 'all',
    // Sets whether to show unread items (and what constitutes 'unread')
    // Possible values:
    //   * 'all'
    //   * 'unread'
    //   * 'ungraded'
    //   * 'resubmission'
    //   * 'comments'
    //   * 'new'
    filterUnread: 'all',
    // This computed property makes the filter template easier to read.
    filterString: Ember.computed('filterType', 'filterUnread', function () {
      let type = this.filterType;
      let unread = this.filterUnread;
      // For development/testing builds, this is a safety check to try and catch
      // if we're accidentally setting either filter option to an unsupported value.
      (true && !(['all', 'assignments', 'conversations', 'requiredDiscussions', 'announcements'].includes(type)) && Ember.assert(`filterType must be a supported type: ${type}`, ['all', 'assignments', 'conversations', 'requiredDiscussions', 'announcements'].includes(type)));
      (true && !(['all', 'unread', 'ungraded', 'resubmission', 'comments', 'new', 'unsubmitted'].includes(unread)) && Ember.assert(`filterUnread must be a supported type: ${unread}`, ['all', 'unread', 'ungraded', 'resubmission', 'comments', 'new', 'unsubmitted'].includes(unread)));
      return `${this.filterType}_${this.filterUnread}`;
    }),
    // Functions for filtered categories

    all_all: Ember.computed('discussionsArray.[]', 'assignmentsArray.[]', function () {
      let discussions = this.discussionsArray.toArray();
      let assignments = this.assignmentsArray.toArray();
      return Ember.ArrayProxy.create({
        content: [...discussions, ...assignments]
      });
    }),
    all_unread: Ember.computed('discussionsArray.@each.unread', 'assignmentsNeedingAttention.[]', function () {
      let discussions = this.discussionsArray.filterBy('unread');
      let assignments = this.assignmentsNeedingAttention;
      return Ember.ArrayProxy.create({
        content: [...discussions, ...assignments]
      });
    }),
    announcements_all: Ember.computed.filterBy('discussionsArray', 'is_announcement'),
    announcements_unread: Ember.computed.filterBy('announcements_all', 'unread'),
    assignments_all: Ember.computed.alias('assignmentsArray'),
    assignments_unsubmitted: Ember.computed.filterBy('assignments_all', 'needsStudentAttention'),
    assignments_ungraded: Ember.computed('assignmentsArray.@each.numUngradedSubmissions', function () {
      let ungraded = this.assignmentsArray.filterBy('numUngradedSubmissions');
      return ungraded;
    }),
    assignments_comments: Ember.computed.filterBy('assignments_all', 'numUnreadSubmissions'),
    assignments_resubmission: Ember.computed.filterBy('assignments_all', 'numResubmissionsPending'),
    nonAnnouncements: Ember.computed.filterBy('discussionsArray', 'isNotAnnouncement'),
    requiredDiscussions_all: Ember.computed('nonAnnouncements.@each.is_required', 'session.isInstructor', function () {
      let required = this.nonAnnouncements.filterBy('is_required');

      /* eslint-disable-next-line ember/no-get */
      if (this.get('session.isInstructor')) {
        required = required.filter(d => {
          let now = moment();
          let due = moment(d.get('due_at'));
          return !d.get('due_at') || due < now || d.get('dueWithinOneWeek') || d.get('dueWithinTwoWeeks');
        });
      }
      return required;
    }),
    requiredDiscussions_unread: Ember.computed.filterBy('requiredDiscussions_all', 'unread'),
    conversations_all: Ember.computed.filterBy('nonAnnouncements', 'isConversation'),
    conversations_unread: Ember.computed.filterBy('conversations_all', 'unread'),
    // activities computed property selects the correct filter computed property,
    // and returns that value. Activities is also responsible for sorting.
    activities: Ember.computed('filterString', 'all_all.[]', 'all_unread.[]', 'announcements_all.[]', 'announcements_unread.[]', 'assignments_all.[]', 'assignments_unsubmitted.[]', 'assignments_ungraded.[]', 'assignments_comments.[]', 'assignments_resubmission.[]', 'requiredDiscussions_all.[]', 'requiredDiscussions_unread.[]', 'conversations_all.[]', 'conversations_unread.[]', function () {
      let filter = this.filterString;
      let result = this.get(filter);
      result = this.filterBuggedAnnouncements(this.announcements_all, result);
      (true && !(result !== undefined) && Ember.assert(`${filter} must be defined on activity class`, result !== undefined));
      return result.toArray().sort((a, b) => {
        return new Date(b.get('sortDate')) - new Date(a.get('sortDate'));
      });
    }),
    filterBuggedAnnouncements(announcements_all, result) {
      let dupes = [];
      let find_dupes_helper = new Set();
      let dirty_announcements = announcements_all.filter(announcement => announcement.hasDirtyAttributes);
      dirty_announcements = dirty_announcements.map(item => item.idSlug);
      for (let item of announcements_all) {
        if (find_dupes_helper.has(item.db_id)) {
          dupes.push(item.db_id);
        }
        find_dupes_helper.add(item.db_id);
      }
      return result.filter(item => !(dirty_announcements.includes(item.idSlug) && dupes.includes(item.db_id)));
    },
    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    totalUngradedSubmissions: Ember.computed('assignmentsArray.@each.numUngradedSubmissions', function () {
      let ungraded = 0;
      this.assignments_ungraded.forEach(assignment => {
        ungraded += assignment.get('numUngradedSubmissions');
      });
      return ungraded;
    }),
    actions: {
      changeFilter(type, unread = 'all') {
        if (typeof unread !== 'string') {
          (true && !(unread.target && unread.target.value && typeof unread.target.value === 'string') && Ember.assert('If not a string, unread must be an event', unread.target && unread.target.value && typeof unread.target.value === 'string'));
          unread = unread.target.value;
        }
        this.set('filterType', type);
        this.set('filterUnread', unread);
      }
    }
  });
});