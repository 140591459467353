define("bocce/components/attachment-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const supportedVideoTypes = {
    chrome: ["video/mp4", "video/webm"],
    firefox: ["video/mp4", "video/quicktime", "video/ogg"],
    edge: ["video/mp4", "video/webm"],
    safari: ["video/mp4"],
    opera: ["video/mp4"],
    other: ["video/mp4"]
  };
  const isEdge = navigator.userAgent.indexOf('Edg') !== -1;
  const isOpera = !!window.opera || navigator.userAgent.indexOf('OPR/') !== -1;
  const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1 && 'netscape' in window && / rv:/.test(navigator.userAgent);
  const isChrome = !isOpera && !isEdge && !!navigator.webkitGetUserMedia || navigator.userAgent.toLowerCase().indexOf('chrome/') !== -1;
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  function browserCanPlayVideoType(type) {
    let canPlay = false;
    if (isChrome) {
      canPlay = supportedVideoTypes.chrome.includes(type);
    } else if (isFirefox) {
      canPlay = supportedVideoTypes.firefox.includes(type);
    } else if (isEdge) {
      canPlay = supportedVideoTypes.edge.includes(type);
    } else if (isSafari) {
      canPlay = supportedVideoTypes.safari.includes(type);
    } else if (isOpera) {
      canPlay = supportedVideoTypes.opera.includes(type);
    } else {
      canPlay = supportedVideoTypes.other.includes(type);
    }
    return type.includes('video') && (canPlay || document.createElement('video').canPlayType(type) === "probably");
  }
  var _default = _exports.default = Ember.Component.extend({
    att_URL: Ember.computed('attachment.url', function () {
      // HOTFIX: Swap docker IP for bocce URL
      /* eslint-disable-next-line ember/no-get */
      let attachment = this.get('attachment.url') || '';
      let docker_ip_regex = /https:\/\/(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)/;
      let host = window.location.origin;
      return attachment.replace(docker_ip_regex, host);
    }),
    created_at_formatted: Ember.computed('attachment.created_at', function () {
      return moment(this.get('attachment.created_at')).tz('America/New_York').format('MMM Do[,] h:mm:ss a') + " ET";
    }),
    attachment_name: Ember.computed.or('attachment.display_name', 'attachment.name'),
    att_URL_noDL: Ember.computed('att_URL', function () {
      let url = this.att_URL;

      // download file contents from URL and put in a blob
      let xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.responseType = 'blob';
      xhr.onload = e => {
        if (e.target.status == 200 && e.loaded === e.total) {
          let blob = e.target.response;
          let url = window.URL.createObjectURL(blob);
          this.set('att_URL_noDL', url);
        }
      };
      xhr.send();
      return false;
    }),
    isMobile: Ember.computed(function () {
      /* eslint-disable-next-line ember/no-jquery */
      return $.isMobile;
    }),
    is_image: Ember.computed('attachment.type', function () {
      // eslint-disable-next-line ember/no-get
      let t = this.get('attachment.type') || this.attachment["content-type"];
      return t === 'image/jpeg' || t === 'image/png';
    }),
    is_video: Ember.computed('attachment.type', function () {
      // eslint-disable-next-line ember/no-get
      let t = this.get('attachment.type') || this.attachment["content-type"];
      return browserCanPlayVideoType(t);
    }),
    is_quicktime: Ember.computed('attachment.type', function () {
      // eslint-disable-next-line ember/no-get
      let t = this.get('attachment.type') || this.attachment["content-type"];
      return t === 'video/quicktime';
    }),
    is_audio: Ember.computed('attachment.type', function () {
      // eslint-disable-next-line ember/no-get
      let t = this.get('attachment.type') || this.attachment["content-type"];
      return t === 'audio/mpeg3' || t === 'audio/mp3' || t === 'audio/mpeg' || t === 'audio/x-m4a';
    }),
    is_word: Ember.computed('attachment.type', function () {
      // eslint-disable-next-line ember/no-get
      let t = this.get('attachment.type') || this.attachment["content-type"];
      return t === 'application/msword';
    }),
    is_pdf: Ember.computed('attachment.type', function () {
      // eslint-disable-next-line ember/no-get
      let t = this.get('attachment.type') || this.attachment["content-type"];
      return t === 'application/pdf';
    }),
    is_zip: Ember.computed('attachment.type', function () {
      // eslint-disable-next-line ember/no-get
      let t = this.get('attachment.type') || this.attachment["content-type"];
      return t === 'application/zip';
    })
  });
});