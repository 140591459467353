define("bocce/templates/components/attachments-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "SiE67klk",
    "block": "{\"symbols\":[\"video\",\"attachment\",\"index\",\"@uploadSingleFile\",\"@deleteAttachment\",\"@encodingVideos\",\"@renameAttachment\",\"@hideAudioPreview\",\"@attachmentFiles\"],\"statements\":[[10,\"div\"],[14,0,\"attachments-list-container\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,9]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[32,2,[\"deleted\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"attachments/attached-file\",[],[[\"@attachment\",\"@name\",\"@index\",\"@uploading\",\"@uploadSingleFile\",\"@deleteAttachment\",\"@renameAttachment\",\"@hideAudioPreview\"],[[32,2],[32,2,[\"name\"]],[32,3],[32,2,[\"uploading\"]],[32,4],[32,5],[32,7],[32,8]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[2,3]}]]],[6,[37,3],[[32,6]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,6]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,\"attachments/attached-file\",[],[[\"@attachment\",\"@uploading\",\"@uploadSingleFile\",\"@deleteAttachment\"],[[32,1],true,[32,4],[32,5]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\",\"unless\",\"if\"]}",
    "meta": {
      "moduleName": "bocce/templates/components/attachments-container.hbs"
    }
  });
});