define("bocce/helpers/activity-count", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // app/helpers/activity-count.js
  var _default = _exports.default = Ember.Helper.helper(function (activity) {
    var total = activity[0].get('responses.length');
    if (total > 1) {
      return total + ' replies';
    } else if (total === 1) {
      return '1 reply';
    }
  });
});