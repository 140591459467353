define("bocce/components/assignment/assignment-comment", ["exports", "bocce/utilities/dialog"], function (_exports, _dialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    userprofileService: Ember.inject.service('userprofile'),
    tagName: 'li',
    classNames: ['reply'],
    classNameBindings: ['deleted'],
    deleted: Ember.computed.reads('response.is_deleted'),
    actions: {
      deleteConfirm() {
        const message = 'Are you sure you wish to delete this comment? This action CANNOT be undone!';
        (0, _dialog.default)(message, ['Yes. If permanent deletion of attachments is desired, first delete manually in S3. Then manually delete in Canvas.', 'No']).then(choice => {
          if (choice.indexOf('Yes') === 0) {
            /* eslint-disable-next-line ember/no-get */
            const id = this.get('response.id');
            this.send('sendParentAction', 'deleteAssignmentComment', id);
          }
        });
      },
      sendParentAction(action, ...args) {
        (true && !(typeof this.get(action) !== 'undefined') && Ember.assert('action should be defined.', typeof this.get(action) !== 'undefined'));
        this.get(action)(...args);
      }
    }
  });
});