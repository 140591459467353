define("bocce/routes/classroom/lessons/event", ["exports", "bocce/routes/classroom/lessons/modal-base", "bocce/mixins/webex"], function (_exports, _modalBase, _webex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable-next-line ember/no-mixins */
  var _default = _exports.default = _modalBase.default.extend(_webex.default, {
    afterModel() {
      this._super.apply(this, arguments);
      /* eslint-disable-next-line ember/no-jquery */
      Ember.$('.side-panel').addClass('on-modal');
      /* eslint-disable-next-line ember/no-jquery */
      Ember.$('.panel-container.chat-panel').addClass('active');
    },
    setupController(controller, model) {
      this._super.apply(this, arguments);
      let meetingKeys = model.get('meetingKeys');
      if (meetingKeys) {
        controller.send('recordParticipation', meetingKeys);
      }
      controller.set('id', model.get('id'));
      controller.set('tempEventStartAt', model.get('startAt'));
      controller.set('tempEventTitle', model.get('title'));
      controller.set('bodyInput', model.get('description'));
    }
  });
});