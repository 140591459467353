define("bocce/templates/components/side-panel/panel-list-item/assignment-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "jws5dOlC",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[30,[36,2],[[35,1,[\"teacher_requires_resubmission\"]],\"needs_resubmit\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"resubmit-assignment\"],[14,\"aria-label\",\"Activity needs to be resubmitted\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"fa-stack fa-lg\"],[14,\"aria-hidden\",\"true\"],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"far fa-file-alt fa-stack-1x\"],[12],[13],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fas fa-sync fa-stack-2x text-danger\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,3],[[30,[36,2],[\"0\",[35,1,[\"currentUserSubmissionScore\"]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"assignment-submission-grade\"],[12],[2,\"NC\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"assignment-submission-grade\"],[12],[2,\"\\n      \"],[1,[35,1,[\"currentUserSubmissionGrade\"]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"i\"],[15,0,[34,0]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"iconClass\",\"item\",\"same\",\"if\",\"grade\"]}",
    "meta": {
      "moduleName": "bocce/templates/components/side-panel/panel-list-item/assignment-icon.hbs"
    }
  });
});