define("bocce/mixins/interactions/slideshow", ["exports", "bocce/mixins/support/render-template"], function (_exports, _renderTemplate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function Slideshow($element, data) {
    var that = this;

    // class vars
    this.el = $element;
    this.playerNum = $element[0].id.replace('area', '');

    /* eslint-disable-next-line ember/no-jquery */
    this.data = Ember.$.parseJSON(data.initial_json || null) || [];
    this.images = data.assets.images;
    this.audioPath = data.assets.audio_track[0];
    this.totalPages = this.getTotalPages(this.data);

    // refactored from a _.bindAll call -- I _think_ this will do the same thing
    ['backPage', 'forwardPage', 'getPlayer', 'getTotalPages'].forEach(function (fn) {
      that[fn] = that[fn].bind(that);
    });
  }
  Slideshow.prototype = {
    init: function () {
      let primary_setup = (0, _renderTemplate.default)('Slideshow', 'main', {
        id: this.playerNum
      }).then(content => {
        let contentArea = this.el.find('.interaction_content');
        contentArea.append(content);
      });
      primary_setup.then(() => {
        this.images.forEach((path, index) => {
          var img = new Image();
          img.src = path;
          if (index === 0) {
            img.onload = () => {
              let contentArea = this.el.find('.interaction_content'),
                imgHeight = img.height,
                imgWidth = img.width;
              // find highlightSVG as a JavaScript object, because jQuery sets 'viewBox' incorrectly
              this.highlightSVG = contentArea[0].getElementsByClassName('highlight_svg')[0];
              this.firstImageDimensions = {
                height: imgHeight,
                width: imgWidth
              };
              this.zoomedIn = false;
              this.setHighlightViewbox(false, false, this.highlightSVG);
              // add first image
              this.changeImage(this.images, 0);
            };
          }
          this.images[index] = img;
        });
        this.addPlayer(this.getPlayer(), this.audioPath);
        let contentArea = this.el.find('.interaction_content');
        if (this.el.parents('.hideSlideshowControls')) {
          if (this.totalPages <= 1 || this.el.parents('.hideSlideshowControls').length > 0) {
            contentArea.find('.page-controls').addClass('hidden');
          }
        }

        // set initial page info
        this.setPages(1, this.totalPages);

        //initialize nav links
        this.el.find('.page-controls .back').on('click', this.backPage);
        this.el.find('.page-controls .forward').on('click', this.forwardPage);

        // initialize zoom controls
        this.addZoomControls();
      });
      return primary_setup;
    },
    addBgImage: function (target, images, index) {
      /* eslint-disable-next-line ember/no-jquery */
      var img = Ember.$(images[index])[0],
        url = 'url(' + img.src + ')';
      target.addClass('bg-image');
      target.css('background-image', url);
    },
    addPlayer: function (playerEl, audioPath) {
      var seek = this.el.find('.audio-player-controls #player-seek'),
        controls = this.el.find('.audio-player-controls .playpause'),
        loop = this.el.find('.audio-player-controls .loop'),
        highlight = this.el.find('.highlight');
      playerEl.attributes.src.value = audioPath;

      /* eslint-disable-next-line ember/no-jquery */
      controls.on('click', Ember.$.proxy(this.playPauseAudio, {
        audio: playerEl,
        controls: controls
      }));
      /* eslint-disable-next-line ember/no-jquery */
      loop.on('click', Ember.$.proxy(this.loopToggle, {
        audio: playerEl,
        loop: loop
      }));
      /* eslint-disable-next-line ember/no-jquery */
      seek.on('change.fndtn.slider', Ember.$.proxy(this.seekAudio, playerEl));

      /* eslint-disable-next-line ember/no-jquery */
      Ember.$(playerEl)
      /* eslint-disable-next-line ember/no-jquery */.on('timeupdate', Ember.$.proxy(this.updateSeek, seek))
      /* eslint-disable-next-line ember/no-jquery */.on('timeupdate', Ember.$.proxy(this.onTimeUpdate, {
        context: this,
        highlight: highlight,
        highlightSVG: this.highlightSVG
      }))
      /* eslint-disable-next-line ember/no-jquery */.on('ended', Ember.$.proxy(this.endedOrPaused, controls))
      /* eslint-disable-next-line ember/no-jquery */.on('pause', Ember.$.proxy(this.endedOrPaused, controls))
      /* eslint-disable-next-line ember/no-jquery */.on('playing', Ember.$.proxy(this.isPlaying, controls));
    },
    // Audio Player Methods
    seekAudio: function (item) {
      if (this.duration) {
        this.currentTime = parseInt(item.target.value) / 100 * this.duration;
      }
    },
    updateSeek: function (item) {
      if (item.target.duration) {
        this.val(Math.round(1000 * (item.target.currentTime / item.target.duration)) / 10);
      } else {
        this.val(50);
      }
    },
    playPauseAudio: function () {
      if (this.audio.buffered.length < 1) {
        this.audio.load();
      }
      if (this.audio.paused) {
        this.audio.play();
      } else {
        this.audio.pause();
      }
    },
    loopToggle: function () {
      if (this.audio.loop) {
        this.loop.removeClass('active');
        this.audio.loop = false;
      } else {
        this.loop.addClass('active');
        this.audio.loop = true;
      }
    },
    endedOrPaused: function () {
      this.removeClass('inactive');
      this.get(1).classList.add('inactive');
    },
    isPlaying: function () {
      this.removeClass('inactive');
      this.get(0).classList.add('inactive');
    },
    setHighlightViewbox: function (images, index, highlightSVG) {
      var imageWidth, imageHeight;
      if (images.length > 0 && index) {
        imageWidth = images[index].width;
        imageHeight = images[index].height;
      }
      if (!imageWidth || !imageHeight && imageWidth === 0 || imageHeight === 0) {
        imageWidth = this.firstImageDimensions.width;
        imageHeight = this.firstImageDimensions.height;
      }
      if (!highlightSVG || !imageWidth || !imageHeight) {
        return;
      }

      // change svg viewBox dimensions to match image's, so highlight is responsive
      highlightSVG.setAttribute('viewBox', '0 0 ' + imageWidth + ' ' + imageHeight);
    },
    changeImage: function (images, index) {
      var imageContainer, img, imageWidth, highlightContainer;
      imageContainer = this.el.find('.image_area > .image_container');
      imageContainer.empty();
      highlightContainer = this.el.find('.Slideshow .highlight_container');
      img = images[index];
      this.setHighlightViewbox(images, index, this.highlightSVG);

      /* eslint-disable-next-line ember/no-jquery */
      Ember.$(img).appendTo(imageContainer);
      imageWidth = img.naturalWidth;
      if (!imageWidth || imageWidth === 0) {
        imageWidth = this.firstImageDimensions.width;
      }
      if (this.zoomedIn === false) {
        imageContainer.css('max-width', imageWidth);
        highlightContainer.css('max-width', imageWidth);
      }
      this.currentImageIndex = index;
    },
    audioPath: '',
    currentImageIndex: 0,
    // keep track of the page we're on
    currentIndex: -1,
    currentPage: 1,
    data: [],
    images: [],
    el: {},
    backPage: function () {
      var time,
        buttonNext = this.el.find('.page-controls .forward');
      buttonNext.removeClass('noclick');
      if (this.currentPage > 1) {
        this.currentPage -= 1;
        time = this.getTimeForPage(this.currentPage, this.data);
        this.getPlayer().currentTime = time;
      }
    },
    forwardPage: function () {
      var time,
        buttonPrev = this.el.find('.page-controls .back');
      buttonPrev.removeClass('noclick');
      if (this.currentPage < this.totalPages) {
        this.currentPage += 1;
        time = this.getTimeForPage(this.currentPage, this.data);
        this.getPlayer().currentTime = time;
      }
    },
    getPlayer: function () {
      return this.el.find('audio').get(0);
    },
    getTimeForPage: function (pageIndex, data) {
      // TODO: figure out how to break this loop
      try {
        data.forEach(function (i) {
          if (i.pageIndex === pageIndex) {
            throw i.time;
          }
        });
      } catch (e) {
        return e;
      }
    },
    getTotalPages: function (data) {
      var currentImageIndex = -1,
        total = 0;
      data.forEach(function (p) {
        var index = p.imageIndex;
        if (currentImageIndex !== index) {
          currentImageIndex = index;
          total++;
        }

        // add a page index onto data obj
        p.pageIndex = total;
      });
      return total;
    },
    onTimeUpdate: function (e) {
      // round current time to 3 decimal places
      var currentTime = e.target.currentTime,
        i,
        item,
        time,
        bgImageIndex;
      // TODO: slightly inefficient, but doesn't seem to choke at all
      for (i = this.context.data.length - 1; i >= 0; i--) {
        item = this.context.data[i];
        time = item.time;

        // compares time and currentTime,
        // rounding to 3 decimal places.
        if (time - currentTime <= 0.001) {
          // only execute the rest of this if we encounter a
          // new page/item:
          if (i === this.context.currentIndex) {
            return;
          }

          // change image if necessary
          if (item.imageIndex !== this.context.currentImageIndex) {
            this.context.changeImage(this.context.images, item.imageIndex);

            // update page numbers for new images
            this.context.setPages(item.pageIndex, this.context.totalPages);
          }
          if (item.rect) {
            this.highlight.attr('class', 'highlight');
            this.highlight.attr('y', item.rect.y);
            this.highlight.attr('x', item.rect.x);
            this.highlight.attr('width', item.rect.w);
            this.highlight.attr('height', item.rect.h);

            // set color and opacity
            this.highlight.css('fill', item.rect.color);
            this.highlight.css('opacity', item.rect.opacity);

            // render the bg image if it exists
            bgImageIndex = item.rect.bgImageIndex;
            if (bgImageIndex !== null) {
              this.context.addBgImage(this.highlight, this.context.images, bgImageIndex);
            } else {
              this.context.removeBgImage(this.highlight);
            }
          } else {
            this.highlight.attr('class', 'highlight hidden');
          }
          this.context.currentIndex = i;
          break;
        }
      }
    },
    removeBgImage: function (target) {
      target.css('background-image', '');
      target.removeClass('bg-image');
    },
    setPages: function (current, total) {
      this.currentPage = current;
      var buttonPrev = this.el.find('.page-controls .back');
      var buttonNext = this.el.find('.page-controls .forward');
      this.el.find('.page-controls .pages').text(current + ' of ' + total);
      if (current >= total) {
        buttonNext.addClass('noclick');
      } else if (current <= 1) {
        buttonPrev.addClass('noclick');
      } else {
        buttonPrev.removeClass('noclick');
        buttonNext.removeClass('noclick');
      }
    },
    addZoomControls: function () {
      var zoomIn = this.el.find('.zoom-controls .zoom_in'),
        zoomOut = this.el.find('.zoom-controls .zoom_out'),
        zoomZero = this.el.find('.zoom-controls .zoom_zero'),
        pageContainer = this.el.find('.Slideshow .page_container'),
        highlightContainer = this.el.find('.Slideshow .highlight_container'),
        imageContainer = this.el.find('.Slideshow .image_area .image_container'),
        zoomLimit = 3;
      this.zoomIn = zoomIn;
      this.zoomOut = zoomOut;
      this.zoomZero = zoomZero;
      this.zoomDegree = 0;

      /* eslint-disable-next-line ember/no-jquery */
      zoomIn.on('click', Ember.$.proxy(this.zoom, {
        context: this,
        imageContainer: imageContainer,
        highlightContainer: highlightContainer,
        pageContainer: pageContainer,
        zoomLimit: zoomLimit
      }, 'in'));
      /* eslint-disable-next-line ember/no-jquery */
      zoomOut.on('click', Ember.$.proxy(this.zoom, {
        context: this,
        imageContainer: imageContainer,
        highlightContainer: highlightContainer,
        pageContainer: pageContainer,
        zoomLimit: zoomLimit
      }, 'out'));
      /* eslint-disable-next-line ember/no-jquery */
      zoomZero.on('click', Ember.$.proxy(this.zoom, {
        context: this,
        imageContainer: imageContainer,
        highlightContainer: highlightContainer,
        pageContainer: pageContainer,
        zoomLimit: zoomLimit
      }, 'zero'));
    },
    zoom: function (zoomDirection) {
      var btnZoomIn = this.context.zoomIn,
        btnZoomOut = this.context.zoomOut,
        btnZoomZero = this.context.zoomZero,
        imageContainer = this.imageContainer,
        highlightContainer = this.highlightContainer,
        pageContainer = this.pageContainer;
      function zoomControlsViewToggle() {
        btnZoomOut.toggleClass('hidden');
        btnZoomZero.toggleClass('hidden');
        pageContainer.toggleClass('zoom');
      }
      function zoomView(zoomDegree) {
        btnZoomIn.removeClass('zoom_limit');
        var zoomPercent = zoomDegree * 50 + 100 + '%';
        imageContainer.css('width', zoomPercent);
        highlightContainer.css('width', zoomPercent);
      }

      // close zoom controls
      if (this.context.zoomDegree === 0) {
        zoomControlsViewToggle();
      }

      // update zoom_degree variable
      if (zoomDirection === 'in') {
        this.context.zoomDegree++;
      } else if (zoomDirection === 'out') {
        this.context.zoomDegree--;
      } else if (zoomDirection === 'zero') {
        this.context.zoomDegree = 0;
      }

      // check for hitting zoom limits
      if (this.context.zoomDegree >= this.zoomLimit) {
        this.context.zoomDegree = this.zoomLimit;
        zoomView(this.context.zoomDegree);
        btnZoomIn.addClass('zoom_limit');
      } else if (this.context.zoomDegree <= 0) {
        zoomView(this.context.zoomDegree);
        zoomControlsViewToggle();
        var imageWidth = imageContainer.find('img')[0].naturalWidth;
        this.context.zoomedIn = false;
        imageContainer.css('max-width', imageWidth);
        highlightContainer.css('max-width', imageWidth);
      } else if (0 < this.context.zoomDegree < this.zoomLimit) {
        imageContainer.css('max-width', 'none');
        highlightContainer.css('max-width', 'none');
        this.context.zoomedIn = true;
        zoomView(this.context.zoomDegree);
      }
    },
    totalPages: 0
  };
  var _default = _exports.default = Slideshow;
});