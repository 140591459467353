define("bocce/models/page", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // app/models/page.js
  var _default = _exports.default = _model.default.extend({
    title: (0, _model.attr)('string'),
    body: (0, _model.attr)('string'),
    item: (0, _model.belongsTo)('item', {
      async: false
    }),
    advice_card_submitter: (0, _model.attr)('boolean'),
    advice_card_viewer: (0, _model.attr)('boolean'),
    discussion: (0, _model.belongsTo)('discussion', {
      async: false
    })
  });
});