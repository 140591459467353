define("bocce/controllers/classroom/lessons/admin/banners", ["exports", "bocce/utilities/dialog"], function (_exports, _dialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const bannerTypes = {
    'global instructors': {
      dismiss_condition_id: 5,
      is_global: false
    },
    'global students': {
      dismiss_condition_id: 6,
      is_global: false
    },
    'global': {
      dismiss_condition_id: null,
      is_global: true
    },
    'grading': {
      dismiss_condition_id: 1,
      is_global: false
    },
    'survey': {
      dismiss_condition_id: 2,
      is_global: false
    },
    'user': {
      dismiss_condition_id: 3,
      is_global: false
    },
    'course': {
      dismiss_condition_id: 4,
      is_global: false
    },
    'dashboard-banner': {
      dismiss_condition_id: 7,
      is_global: false
    }
  };

  // This is written as a fetch because the banners API doesn't
  // follow the structure that Ember expects:
  async function addRecipientsToBanner(bannerID, dataObject) {
    const url = `/interface/banners/${bannerID}/add_recipients`;
    const body = JSON.stringify(dataObject);
    let response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body
    });
    if (!response.ok) {
      return Promise.reject(response.status);
    }
    return await response.json();
  }
  function getTwoMonthsFromNow() {
    const twoMonths = (new Date().getMonth() + 1) % 12;
    const twoMonthsFromNow = new Date();
    twoMonthsFromNow.setMonth(twoMonths);
    return moment(twoMonthsFromNow);
  }
  function isValidUrl(url) {
    try {
      new URL(url);
    } catch (e) {
      return false;
    }
    return true;
  }
  var _default = _exports.default = Ember.Controller.extend({
    newBannerMessage: '',
    newBannerDismissCondition: 5,
    newBannerIsGlobal: false,
    newBannerDismissAt: getTwoMonthsFromNow(),
    newBannerHyperlink: '',
    bannerTypeNames: Object.keys(bannerTypes),
    courseUsers: [],
    showUserDropDown: false,
    individualUserId: 0,
    // Sort banners by ID in descending order:
    sortedBanners: Ember.computed('model.@each.id', function () {
      const modelArray = this.model.toArray();
      const sortedModel = modelArray.sort((a, b) => parseInt(b.id) - parseInt(a.id));
      const filteredModel = sortedModel.filter((banner, index) => {
        let type = banner.get('type');
        if (type === "Course Banner" || type === "Individual User Banner" || type === 'Dashboard Banner') {
          return false;
        } else {
          return true;
        }
      });
      return filteredModel;
    }),
    targetedBanners: Ember.computed('model.@each.id', function () {
      let models = this.model.toArray(),
        courseUserIds = this.store.peekAll('user').mapBy('id'),
        courseId = this.get('session.course.id');
      const filteredBanners = models.filter((banner, index) => {
        let type = banner.get('type'),
          context_id = banner.get('context_id');
        if (["Course Banner", "Dashboard Banner"].includes(type) && courseId === context_id.toString()) {
          return true;
        }
        if (type === "Individual User Banner" && courseUserIds.includes(context_id.toString())) {
          return true;
        }
        return false;
      });
      return filteredBanners;
    }),
    init() {
      this._super(...arguments);
      let users = this.store.findAll('user');
      this.set('courseUsers', users);
    },
    actions: {
      createNewBanner() {
        const message = this.newBannerMessage,
          dismiss_at = this.newBannerDismissAt.toDate(),
          link = this.newBannerHyperlink || null;
        if (message.length === 0) {
          alert('Banner Message cannot be empty.');
          return;
        }
        if (dismiss_at <= new Date()) {
          alert('Dismiss date should be in the future.');
          return;
        }
        if (link && !isValidUrl(link)) {
          alert('Invalid link format');
          return;
        }
        const dismiss_condition_id = this.newBannerDismissCondition;
        const is_global = this.newBannerIsGlobal;
        let context_id = 0,
          targeted = false;
        if (dismiss_condition_id === 3) {
          context_id = this.get('individualUserId');
          targeted = true;
        }
        if (dismiss_condition_id === 4 || dismiss_condition_id == 7) {
          context_id = this.get('session.course.id');
          targeted = true;
        }
        const newBannerObject = {
          message,
          dismiss_condition_id,
          priority: 1,
          dismiss_at,
          is_global,
          link,
          context_id
        };

        // Create banner:
        const banner = this.store.createRecord('banner', newBannerObject);
        return banner.save().then(() => {
          // Add recipients to the banner
          /* eslint-disable-next-line ember/no-get */
          const term_id = this.get('session.termID');
          const data = {
            term_id,
            by_course: true,
            user_id: this.get('individualUserId'),
            course_id: this.get('session.course.id') || 0
          };
          switch (dismiss_condition_id) {
            case 1:
              data.type = 'TeacherEnrollment';
              break;
            case 2:
              data.type = 'StudentEnrollment';
              break;
            case 3:
              data.type = 'IndividualUser';
              break;
            case 4:
              data.type = 'Course';
              break;
            case 5:
              data.type = 'TeacherEnrollment';
              break;
            case 6:
              data.type = 'StudentEnrollment';
              break;
            case 7:
              data.type = 'Course';
              break;
            default:
              data.type = 'Global';
              break;
          }
          const id = banner.get('id');
          addRecipientsToBanner(id, data);
          if (targeted) {
            this.send('navigate', 'targeted');
          }
          this.set('newBannerMessage', '');
        });
      },
      async deleteBanner(banner) {
        await $.get(`/interface/banners/${banner.id}/dismissAll`);
        return banner.destroyRecord();
      },
      setNewBannerTypeID() {
        // 'event' is the click event
        const typeName = event.target.value;
        if (typeName === 'user') {
          this.set('showUserDropDown', true);
          this.set('individualUserId', this.get('courseUsers').get('firstObject').get('id'));
        } else {
          this.set('showUserDropDown', false);
        }
        (true && !(Object.keys(bannerTypes).includes(typeName)) && Ember.assert('typeName is in bannerTypes', Object.keys(bannerTypes).includes(typeName)));
        const type = bannerTypes[typeName];
        const {
          dismiss_condition_id,
          is_global
        } = type;
        (true && !(typeof dismiss_condition_id !== 'undefined') && Ember.assert('Banner type has dismiss_condition_id', typeof dismiss_condition_id !== 'undefined'));
        (true && !(typeof is_global !== 'undefined') && Ember.assert('Banner type has is_global', typeof is_global !== 'undefined'));
        this.set('newBannerDismissCondition', type.dismiss_condition_id);
        this.set('newBannerIsGlobal', type.is_global);
      },
      setIndividual() {
        const user_id = event.target.value;
        this.set('individualUserId', user_id);
      },
      navigate(tabName) {
        $(".admin-banner-nav").removeClass("selected");
        $("table.global-banners").removeClass("hidden");
        $("table.targeted-banners").removeClass("hidden");
        if (tabName === "global") {
          $(".admin-banner-nav.global").addClass("selected");
          $("table.targeted-banners").addClass("hidden");
        } else {
          $(".admin-banner-nav.targeted").addClass("selected");
          $("table.global-banners").addClass("hidden");
        }
      }
    }
  });
});