define("bocce/controllers/classroom/lessons/event-quick-new", ["exports", "bocce/controllers/classroom/lessons/event-new"], function (_exports, _eventNew) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _eventNew.default.extend({
    init(...args) {
      this._super(...args);
      let baseEventDate = moment().tz('America/New_York');
      this.eventsModel = Ember.ArrayProxy.create({
        content: Ember.A([{
          bodyInput: '',
          titleInput: 'Live Class',
          startDate: baseEventDate,
          startDate_date: baseEventDate.format('YYYY-MM-DD'),
          startDate_time: baseEventDate.format('HH:mm'),
          additionalSectionsCourseIds: Ember.ArrayProxy.create({
            content: Ember.A([])
          }),
          shown: true
        }])
      });
    },
    isWorking: false,
    // saveEvents without invalid time check, since the time is now
    saveEvents() {
      const section = this.session.get('section');
      const course = section.get('course');
      const courseId = course.get('id'),
        eventData = [];
      this.eventsModel.forEach((em, index) => {
        let start_time = moment().tz('America/New_York');
        const eventDataSingle = {
          courseIds: [],
          event: {}
        };

        // Force the value of the time input to avoid DST conversion
        start_time = `${start_time.format('YYYY-MM-DD')}T${start_time.format('HH:mm')}:00`;
        let start_time_moment = moment(start_time),
          end_time_moment = moment(start_time).add("1", "hours");
        let startTimeMomentFormatted = start_time_moment.format('YYYY-MM-DDTHH:mm:ss');
        eventDataSingle.event = {
          title: "Live Class",
          description: '',
          futureEvent: true,
          startAt: startTimeMomentFormatted,
          endAt: end_time_moment.format('YYYY-MM-DDTHH:mm:ss')
        };
        eventDataSingle.courseIds.push(courseId);
        em.additionalSectionsCourseIds.forEach(additionalSectionCourseId => {
          eventDataSingle.courseIds.push(additionalSectionCourseId);
        });
        eventData.push(eventDataSingle);
      });
      this.set('isWorking', true);
      _eventNew.sendEventsToInterfaceAndSendEmail.call(this, eventData, true);
    }
  });
});