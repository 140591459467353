define("bocce/templates/components/quiz-admin/operation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "GnqegRfI",
    "block": "{\"symbols\":[\"&default\",\"@copy\",\"@noConfig\"],\"statements\":[[2,\"\\n\"],[10,\"tr\"],[14,0,\"quiz-operation\"],[12],[2,\"\\n    \"],[10,\"td\"],[14,0,\"quiz-operation-info\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"quiz-operation-copy\"],[12],[1,[32,2]],[13],[2,\"\\n\"],[6,[37,2],[[32,3]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"div\"],[14,0,\"quiz-operation-config\"],[12],[2,\"\\n                \"],[18,1,null],[2,\"\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,1],[[30,[36,3],[[35,0],[32,0,[\"performingAction\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"div\"],[14,0,\"quiz-operation-result\"],[12],[2,\"\\n\"],[6,[37,1],[[32,0,[\"performingAction\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"                    \"],[10,\"i\"],[14,0,\"fas fa-spinner fa-spin\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"                    \"],[1,[34,0]],[2,\" \\n                \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"            \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n    \"],[11,\"td\"],[24,0,\"quiz-operation-action\"],[4,[38,4],[[32,0],\"onAction\"],null],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"fa fa-check\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"result\",\"if\",\"unless\",\"or\",\"action\"]}",
    "meta": {
      "moduleName": "bocce/templates/components/quiz-admin/operation.hbs"
    }
  });
});