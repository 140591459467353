define("bocce/helpers/teaser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // app/helpers/teaser.js
  // Trims the string to first <br> or 100 characters
  var _default = _exports.default = Ember.Helper.helper(function (item) {
    if (!item || !item[0] || typeof item[0] !== 'string') {
      return false;
    }
    let str = item[0];
    let br = str.indexOf('<br>');
    if (br > 0) {
      return str.substring(0, br);
    } else {
      return str.substring(0, 100);
    }
  });
});