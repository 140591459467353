define("bocce/helpers/dropdown-scale", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // app/helpers/dropdown-scale.js
  // Produce an array of sequential numbers
  var _default = _exports.default = Ember.Helper.helper(function (counter) {
    var total = parseInt(counter[0]),
      arr = ['-'];
    if (typeof total !== 'number') {
      return false;
    }
    for (let i = 0; i < total; i++) {
      arr.push(i + 1);
    }
    return arr;
  });
});