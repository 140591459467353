define("bocce/mixins/interactions/image_explorer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CMS_EDITOR_DATA = 'cms-editor-data';
  function ImageExplorer($el, data) {
    data = convertToJSON($el[0], data);
    this.$el = $el;
    this.interactivityId = data.interactivity_id;
    this.kalturaVideoObjects = data.interactivity_id;

    // cache container and set it to autosize by default ;
    this.$container = this.$el.find('.interaction_content').addClass('autosize');

    // take a look at this when redo panorama
    this.extra_width = 4;
    this.data = data;

    // paths of cms images. will be overwritten with full image objs ;
    this.images = this.data.assets.images;
    this.assetIndex = 0;
  }
  function get_html_attribute($parent, name) {
    return $parent.find('span.' + name).first().text();
  }
  function convertToJSON(element, data) {
    let initial_json = JSON.parse(data.initial_json) || "";
    element.querySelectorAll("table tr").forEach((row, index) => {
      if (index > 0) {
        const data = row.querySelectorAll("td");
        let popupContent = data[2].innerHTML;
        let videoPlayer = data[2].querySelector(".player.video");
        let kalturaPlayer = data[2].querySelector(".kaltura_video");
        if (videoPlayer) {
          popupContent = getVideosJSON(data[2].children, data[1].innerText);
        } else if (kalturaPlayer) {
          popupContent = getVideosJSON([kalturaPlayer], data[1].innerText);
        }
        let hotspotIndex = initial_json[0].hotspots.findIndex(hotspot => hotspot.id === data[0].innerText);
        initial_json[0].hotspots[hotspotIndex].popupContent = popupContent;
        initial_json[0].hotspots[hotspotIndex].rolloverContent = data[3].innerHTML;
      }
    });
    return {
      ...data,
      initial_json
    };
  }
  function getVideosJSON(videoData, hotSpotName) {
    let title = hotSpotName;
    const videos = [];
    for (let i = 0; i < videoData.length; i++) {
      const node = videoData[i];
      if (node.classList.contains("body")) {
        title = node.innerText;
      } else {
        /* eslint-disable-next-line ember/no-jquery */
        let $node = Ember.$(node);
        let kaltura = $node[0].classList.value.split('kaltura_embed_');
        let src = get_html_attribute($node, 'source');
        let poster = get_html_attribute($node, 'poster');
        let track = get_html_attribute($node, 'caption');
        let account_name = get_html_attribute($node, 'account');
        let account = account_name && account_name === 'campus' ? 730212 : 2588802;
        let player_id = get_html_attribute($node, 'account') || 44413892;
        let node_id = node.id || $node.parents(".component")[0].id;

        // Mark all non-YT videos accordingly
        $node.closest('.video-component, .video_component').addClass('not-youtube');
        if (kaltura.length > 1) {
          let kaltura_classlist = $node[0].classList;
          kaltura = kaltura_classlist[1].split('kaltura_embed_')[1];
          src = kaltura;
          if (kaltura_classlist.length > 2) {
            let player_id_raw = kaltura_classlist[2];
            let account_name_raw = kaltura_classlist[3];
            player_id = player_id_raw ? player_id_raw.split('kaltura_player_id_')[1] || 44413892 : 44413892;
            account_name = account_name_raw ? account_name_raw.split('kaltura_account_')[1] : false;
            account = account_name && account_name === 'campus' ? 730212 : 2588802;
          }
          videos.push({
            title: title,
            style: "width: 687px; height: 420px; overflow: hidden; margin: 0 auto;",
            kaltura: {
              targetId: `kaltura-embed-${node_id}`,
              flashvars: {
                'autoPlay': false
              },
              wid: '_' + account,
              uiconf_id: player_id,
              entry_id: src
            }
          });
        } else {
          videos.push({
            title: title,
            style: "",
            posterImage: poster,
            vttFile: track,
            src: src
          });
        }
      }
    }
    return {
      videos
    };
  }
  ImageExplorer.prototype.init = function () {
    // preload images for faster loading and to obtain width/height info ;
    return this.preloadImages(this.images);
  };
  ImageExplorer.prototype.preloadImages = function (imgArray) {
    var i, img, path, that, _len, _ref, onload;
    this.$container.html(this.$el.find('.loading-template').html());
    that = this;
    _ref = this.images;
    return new Promise(resolve => {
      onload = function () {
        // overwrite images array with full image el obj;
        imgArray[this.imageIndex] = {
          'src': this.src,
          'width': this.width,
          'height': this.height
        };
        if (this.imageIndex === 0) {
          resolve(that.showFirstSlide());
        }
      };
      for (i = 0, _len = _ref.length; i < _len; i++) {
        path = _ref[i];
        img = new Image();
        img.src = path;
        img.imageIndex = i;
        img.onload = onload;
      }
    });
  };
  ImageExplorer.prototype.showFirstSlide = function () {
    var parsedJSON;
    if (typeof this.data.initial_json == "string") {
      parsedJSON = JSON.parse(this.data.initial_json);
    } else {
      parsedJSON = this.data.initial_json;
    }
    // adapt parsed JSON data to avoid repetitive operations;
    this.slides = this.adaptData(parsedJSON);
    // merge additional data from CMS editor tables;
    this.slides = this.addTableContentItems(this.slides);
    // keep track of the slide the user is currently viewing;
    this.currentSlide = void 0;
    this.currentSlideIndex = 0;
    return this.loadSlide(0);
  };

  // put the data in a better format for the template;

  ImageExplorer.prototype.adaptData = function (data) {
    var button, hotspot, img, slide, _i, _j, _k, _len, _len2, _len3, _ref, _ref2;
    for (_i = 0, _len = data.length; _i < _len; _i++) {
      slide = data[_i];
      img = this.images[slide.imageIndex];
      slide.info_width = img.width + this.extra_width;
      if (slide.options && slide.options.panoramaOpts) {
        if (slide.options.panoramaOpts.maskWidth) {
          slide.info_width = slide.options.panoramaOpts.maskWidth + this.extra_width;
        }
        if (slide.options.panoramaOpts.maskHeight) {
          slide.info_height = slide.options.panoramaOpts.maskHeight;
        }
      }
      if (!img.src) {
        slide.imgEl = `<img src="${img}" />`;
      } else {
        slide.imgEl = `<img src="${img.src}" width="${img.width}" height="${img.height}" />`;
      }
      slide.initialText = unescape(slide.initialText);
      // take the $ CSS objs and smush them into a string
      // that the template can inject into an el's style tag
      _ref = slide.hotspots;
      for (_j = 0, _len2 = _ref.length; _j < _len2; _j++) {
        hotspot = _ref[_j];
        hotspot.css = this.cssToString(hotspot.css);
      }
      _ref2 = slide.buttons;
      for (_k = 0, _len3 = _ref2.length; _k < _len3; _k++) {
        button = _ref2[_k];
        button.css = this.cssToString(button.css);
        button.title = unescape(button.title);
      }
    }
    return data;
  };
  ImageExplorer.prototype.cssToString = function (css) {
    var prop, str, val;
    str = '';
    for (prop in css) {
      val = css[prop];
      str += '' + prop + ':' + val + ';';
    }
    return str;
  };

  // merge cms editor data into json data;

  ImageExplorer.prototype.addTableContentItems = function (dataArray) {
    let items = [],
      _self = this;
    for (let i = 0; i < dataArray.length; i++) {
      let data = dataArray[i];
      for (let j = 0; j < data.hotspots.length; j++) {
        let hotspotData = data.hotspots[j];
        let item = new ContentItem(hotspotData, _self.interactivityId);
        if (item.id) {
          items.push(item);
        }
      }
      dataArray[i].cmsEditorData = items;
    }
    return dataArray;
  };
  ImageExplorer.prototype.loadSlide = function (index) {
    this.currentSlideIndex = index;
    this.currentSlide = this.slides[index];

    // render UI
    const stageContainer = this.renderStageContainer();
    const infoContainer = this.renderInfoContainer();
    const navContainer = this.renderNavContainer();
    this.$container.append(stageContainer + infoContainer + navContainer);
    this.initNav();
    this.createComponents(this.currentSlide);
    this.initBranching();
    if (this.currentSlide.options.duration) {
      this.startTimer(this.currentSlide.options.duration);
    }
    if (this.currentSlide.options.panorama) {
      this.createPanorama(this.currentSlide.options.panoramaOpts);
    }
    this.updateSlideCount(index + 1, this.slides.length);
  };
  ImageExplorer.prototype.renderStageContainer = function () {
    return `
  <div class="stage-container border scrollbar-mobile">
    <div class="stage">
      <div class="content">
        ${this.currentSlide.imgEl}
        ${this.currentSlide.hotspots.map((hotspot, index) => {
      return `<button class="hotspot tababble" id="${hotspot.id}"  data-target-slide=""  tabindex="${index}" aria-label="${hotspot.id}"></button>`;
    }).join("")}
        ${this.currentSlide.buttons.map((button, index) => {
      return `<button class="user-button tababble" style="${button.css}" id="${button.id}"  data-target-slide="${button.targetSlide}"  tabindex="${index}" aria-label="${button.id}">${button.title}</button>`;
    }).join("")}
      </div>
    </div>
  </div>`;
  };
  ImageExplorer.prototype.renderInfoContainer = function () {
    return `<div class="info border scrollbar-mobile">
    <p class="blank"></p>
  </div>`;
  };
  ImageExplorer.prototype.renderNavContainer = function () {
    return `<div class="nav" style="display: none;">
          <button class="prev" aria-label="Previous"> <i class="fas fa-arrow-circle-left" aria-hidden=""></i> </button> <span class="count">1 of 1</span>
          <button class="next" aria-label="Next"> <i class="fas fa-arrow-circle-right" aria-hidden=""></i> </button>
        </div>`;
  };
  ImageExplorer.prototype.initNav = function () {
    this.$container.find('.next').click(this.nextSlide);
    this.$container.find('.prev').click(this.prevSlide);
    // hide nav if we only have one slide or if this is a timed slide;
    if (this.slides.length === 1) {
      return this.$container.find('.nav').hide();
    }
  };
  ImageExplorer.prototype.createComponents = function (slide) {
    // If the user has specified some data in the CMS editor,
    // map it to the proper components
    let $component, data, styles, _i, _len, _ref;
    let hotspots_length = 0,
      indx = 0;
    if (slide.cmsEditorData) {
      _ref = slide.cmsEditorData;
      indx = 0;
      var $info = this.$container.find('.info');
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        data = _ref[_i];
        $component = this.$container.find('#' + data.id);
        $component.data(CMS_EDITOR_DATA, data);
        if (slide.hotspots.length > 0) {
          if (slide.hotspots[_i].css) {
            if (data.css && (data.css === "" || data.css === " ")) {
              data.css = slide.hotspots[_i].css;
            }
          }
        }
        if (data.rolloverContent.length) {
          // $component.mouseover(this.componentMouseover);
          $component.on('mouseover click focusin', Ember.$.proxy(this.componentMouseover, {
            contex: this
          }));
          hotspots_length += data.rolloverContent.length;
          if (slide.hotspots.length > 0) {
            if (slide.hotspots[indx] && $component.hasClass("hotspot")) {
              $component.attr("style", slide.hotspots[indx].css);
              indx++;
            }
          }
        }
        if (data.popupContent && data.popupContent.length) {
          $component.on('click', Ember.$.proxy(this.componentClick, {
            contex: this
          })).addClass('pointer-cursor');
        }
        if (data.css) {
          styles = ($component.attr('style') || "") + data.css;
          $component.attr('style', styles);
        }
      }
      // If the 'info (text)' area is unused, hide it
      if (typeof slide.initialText !== 'string' || slide.initialText.length === 0 && hotspots_length === 0) {
        return $info.hide();
      }
      // if the initial text is missing but hotspots are used, put in filler text
      else if (typeof slide.initialText !== 'string' || slide.initialText.length === 0 && hotspots_length > 0) {
        return $info.html('<p class="blank"></p>');
      }
    }
  };
  ImageExplorer.prototype.initBranching = function () {
    // set up branching:
    // this value is stored in data-target-slide attr
    var _this = this;
    this.$container.find('.user-button, .hotspot').each(function (index, el) {
      var $compEl;
      /* eslint-disable-next-line ember/no-jquery */
      $compEl = Ember.$(el);
      if ($compEl.data('target-slide').length) {
        return $compEl.on('click', Ember.$.proxy(_this.navigateToSlide, {
          contex: _this
        }));
        // return $compEl.click(_this.navigateToSlide);
      }
    });
  };
  ImageExplorer.prototype.navigateToSlide = function (e) {
    var index, targetSlide;
    /* eslint-disable-next-line ember/no-jquery */
    targetSlide = Ember.$(e.target).data('target-slide');
    index = this.contex.getSlideIndexByName(targetSlide);
    // return this.contex.loadSlide(index);
    if (Ember.$(e.target).hasClass("user-button")) {
      this.contex.assetIndex++;
      if (!this.contex.data.initial_json[this.contex.assetIndex]) {
        this.contex.assetIndex = 0;
      }
      this.contex.updateSlide(Ember.$(e.target));
    }
  };
  ImageExplorer.prototype.updateSlide = function (evt) {
    let view = evt.closest(".interaction_content"),
      content = view.find(".content"),
      images = this.data.assets.images,
      currData = this.data.initial_json[this.assetIndex];
    content.find("button").text(currData.buttons[0].title);
    content.find("button").attr("style", currData.buttons[0].css);
    content.find("img").attr("src", images[currData.imageIndex].src).css({
      "width": images[currData.imageIndex].width,
      "height": images[currData.imageIndex].height
    });
    view.find(".info").html(currData.initialText);
    content.find("button").attr("aria-label", currData.buttons[0].title);
    content.find("button").focus();
  };
  ImageExplorer.prototype.getSlideIndexByName = function (name) {
    var i, slide, _len, _ref;
    _ref = this.slides;
    for (i = 0, _len = _ref.length; i < _len; i++) {
      slide = _ref[i];
      if (slide.name === name) {
        return i;
      }
    }
  };
  ImageExplorer.prototype.componentMouseover = function (e) {
    var $info, $component, $components, data, height, prevHeight;
    /* eslint-disable-next-line ember/no-jquery */
    data = Ember.$(e.target).data(CMS_EDITOR_DATA);
    $component = this.contex.$container.find('#' + data.id);
    $components = this.contex.$container.find('.hotspot');
    $info = this.contex.$el.find('.info');
    prevHeight = $info.height();
    $info.html(data.rolloverContent);
    height = $info.height();
    // sticky rollover
    $components.removeClass('stuck');
    $component.addClass('stuck');
    // lazy lock the min-height of the info area to decrease page jumps.
    // this requires much less work than computing all possible
    // heights and choosing the largest one
    if (height > prevHeight) {
      $info.css('min-height', height + 'px');
    }

    // clear in case a course dev floats some content ;
    /* eslint-disable-next-line ember/no-jquery */
    return Ember.$('<div></div>').css('clear', 'both').appendTo($info);
  };
  ImageExplorer.prototype.componentMouseout = function () {
    var $info, $components;
    $info = this.$el.find('.info');
    $components = this.$container.find('.hotspot');
    $components.removeClass('stuck');
    // revert the height back to normal on mouseout. This prevents the
    // box from remaining large if one caption is longer than others.
    $info.css('min-height', '0');
    return $info.html('<p>' + this.currentSlide.initialText + '</p>');
  };
  ImageExplorer.prototype.componentClick = function (e) {
    e.preventDefault();

    /* eslint-disable-next-line ember/no-jquery */
    const popupContent = Ember.$(e.target).data(CMS_EDITOR_DATA).popupContent;
    const kalturaVideos = Ember.$(e.target).data(CMS_EDITOR_DATA).kalturaVideoObjects;

    // do nothing if there is no popupContent
    if (!popupContent.length) {
      return;
    }

    // Put the content inside a div because otherwise it gets WAY too wide.
    /* eslint-disable-next-line ember/no-jquery */
    const content = Ember.$('<div class=\'image-explorer\'></div>');
    content.html('');
    for (let i = 0; i < popupContent.length; ++i) {
      content.append(popupContent[i]);
    }

    //append the content to the popup_container div.
    /* eslint-disable-next-line ember/no-jquery */
    const popup_container = Ember.$('.popup-container');
    const popup_content = popup_container.find('.content');
    popup_content.html('');
    popup_content.append(content);
    popup_container.addClass('active');
    Ember.$('.image-explorer-main-panel').addClass('no-scroll');
    Ember.$('.popup-backdrop').addClass('show');
    Ember.$("[tabindex]").each(function () {
      let tabIndex = Ember.$(this).attr("tabindex");
      Ember.$(this).attr("data-tabindex-num", tabIndex);
      Ember.$(this).attr("tabindex", "-1");
    });
    let hotspotIndex = Ember.$(e.target).attr("id");
    let activityId = Ember.$(e.target).closest(".interaction_component").attr("id");
    popup_container.find(".close-button").attr("data-hotsplot-id", hotspotIndex).attr("data-activity-id", activityId);
    let tabCount = 1;
    popup_content.find("video").each(function (inx) {
      Ember.$(this).attr("tabindex", tabCount).addClass("tababble");
      tabCount++;
      Ember.$(this).find("button.pause").attr("aria-label", "play " + Ember.$(this).attr("aria-label"));
      Ember.$(this).find("button.play").attr("aria-label", "pause " + Ember.$(this).attr("aria-label"));
      if (inx === 0) {
        Ember.$(this).focus();
      }
    });
    popup_container.find(".close-button").attr("tabindex", tabCount).addClass("tababble");
    for (let i = 0; i < kalturaVideos.length; i++) {
      const videoObj = kalturaVideos[i];
      kWidget.embed(videoObj);
    }
  };
  ImageExplorer.prototype.startTimer = function (duration) {
    var ms,
      _this = this;
    ms = duration * 1000;
    setTimeout(function () {
      return _this.nextSlide();
    }, ms);
    return this.$container.find('.nav').hide();
  };
  ImageExplorer.prototype.createPanorama = function (opts) {
    var $content, $img, $stage, config, css, offset, x1, x2, y1, y2;
    css = {
      'overflow': 'hidden'
    };
    if (opts.maskWidth) {
      css.width = opts.maskWidth + 'px';
    }
    if (opts.maskHeight) {
      css.height = opts.maskHeight + 'px';
    }
    $stage = this.$container.find('.stage');
    $stage.css(css);
    $img = $stage.find('img:first');
    config = {};
    // set drag boundaries if image is masked so the user can't drag
    // it outside of the stage
    if ($img.width() > $stage.width() || $img.height() > $stage.height()) {
      offset = $stage.offset();
      x1 = $stage.width() - $img.width() + offset.left;
      y1 = $stage.height() - $img.height() + offset.top;
      x2 = offset.left;
      y2 = offset.top;
      config.containment = [x1, y1, x2, y2];
    }
    $content = $stage.find('.content');
    $content.draggable(config);
    // set initial position, if user specifies values ;
    $content.css({
      'left': (opts.x || 0) + 'px',
      'top': (opts.y || 0) + 'px'
    });
    return $content.addClass('move-cursor');
  };
  ImageExplorer.prototype.nextSlide = function (e) {
    if (e) {
      e.preventDefault();
    }
    this.currentSlideIndex++;
    if (this.currentSlideIndex >= this.slides.length) {
      this.currentSlideIndex = 0;
    }
    return this.loadSlide(this.currentSlideIndex);
  };
  ImageExplorer.prototype.prevSlide = function (e) {
    if (e) {
      e.preventDefault();
    }
    this.currentSlideIndex--;
    if (this.currentSlideIndex < 0) {
      this.currentSlideIndex = this.slides.length - 1;
    }
    return this.loadSlide(this.currentSlideIndex);
  };
  ImageExplorer.prototype.updateSlideCount = function (current, total) {
    this.$el.find('.count').text(`${current} of ${total}`);
  };
  function ContentItem(hotspotData, interactivityId) {
    var offset,
      $popupContent,
      kalturaVideoObjects = [];
    if (hotspotData.popupContent) {
      if (hotspotData.popupContent.videos?.length) {
        let videoContainer = "";
        let videos = hotspotData.popupContent.videos;
        for (let i = 0; i < videos.length; i++) {
          const video = videos[i];
          let str = "";
          let videoTitle = video.title || "";
          if (videoTitle) {
            str = '<p class="body" >' + video.title + '</p>';
          }
          videoTitle = videoTitle.replace(":", "");
          if (video.src) {
            str += '<div class="component quicktime-component float-center clear-none ">';
            str += '<div><div id="video-player-' + interactivityId + '-' + (i + 1) + '" class="player video booted" style = "' + video.style + '">';
            str += '<video aria-label="' + videoTitle + ' Video" preload="none" poster="' + video.posterImage + '" controls="" controlslist="nodownload">';
            str += '<source src="' + video.src + '" type="video/mp4">';
            str += '<track src="' + video.vttFile + '" kind="subtitles" srclang="en" label="English">';
            str += '</video></div></div></div>';
          } else if (video.kaltura) {
            str += '<div class="component quicktime-component float-center clear-none ">';
            str += '<div id="' + video.kaltura.targetId + '" class="player video booted"  style = "' + video.style + '"">';
            str += '</div></div>';
            kalturaVideoObjects.push(video.kaltura);
          }
          videoContainer += str;
        }
        $popupContent = Ember.$(videoContainer);
      } else {
        $popupContent = hotspotData.popupContent;
      }
    }

    /* eslint-disable-next-line ember/no-jquery */
    this.id = hotspotData.id || "";
    /* eslint-disable-next-line ember/no-jquery */
    this.title = hotspotData.label || "";
    /* eslint-disable-next-line ember/no-jquery */
    this.popupContent = $popupContent ? Ember.$($popupContent) : undefined;
    /* eslint-disable-next-line ember/no-jquery */
    this.rolloverContent = hotspotData.rolloverContent || "";
    /* eslint-disable-next-line ember/no-jquery */
    this.css = hotspotData.css || "";
    /* eslint-disable-next-line ember/no-jquery */
    this.popupWidth = hotspotData.popupWidth || "";
    /* eslint-disable-next-line ember/no-jquery */
    this.popupHeight = hotspotData.popupHeight || "";
    /* eslint-disable-next-line ember/no-jquery */
    offset = hotspotData.offset || [0, 0];
    this.offsetX = offset[0] || "";
    this.offsetY = offset[1] || "";
    this.kalturaVideoObjects = kalturaVideoObjects;
  }
  var _default = _exports.default = ImageExplorer;
});