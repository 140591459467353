define("bocce/helpers/upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = uploadFile;
  _exports.uploadURL = uploadURL;
  function attemptUpload(url, data, return_url, on_progress) {
    // Using XMLHttpRequest instead of fetch here, because we ened to have a
    // callback for updating the upload progress bar.
    let request = new XMLHttpRequest();
    request.open('POST', url);
    if (on_progress) {
      request.addEventListener('progress', event => {
        if (event.lengthComputable) {
          on_progress(Math.ceil(100 * event.loaded / event.total));
        }
      });
    }
    return new Promise((resolve, reject) => {
      request.addEventListener('load', () => {
        if (request.status !== 200) {
          reject(request.status);
          return;
        }
        let raw = request.responseText;
        let obj = parseBrokenCanvasJSON(raw);
        if (return_url) {
          resolve(obj.url);
        } else {
          resolve(obj.id);
        }
      });
      request.send(data);
    });
  }
  function proxyUpload(store, file, path, return_url) {
    let att = store.createRecord('attachment');
    att.set('attachment', file);
    if (path) {
      att.set('file_context', path);
    }
    return att.save().then(obj => {
      if (return_url) {
        return obj.get('thumbnail_url');
      }
      return obj.get('id');
    }, err => {
      throw err;
    });
  }
  let force_proxy_uploads = false;

  // Returns a promise that resolves to the ID of the new attachent
  async function uploadFile(file, user_id, store, destination_folder, return_url, on_progress, postUrl) {
    if (force_proxy_uploads) {
      Ember.debug('Uploading via proxy due to previous failures');
      return proxyUpload(store, file, destination_folder, return_url);
    }
    if (file.isUrl) {
      return uploadURL(file, destination_folder);
    }
    let data = {
      name: file.name,
      content_type: file.type,
      on_duplicate: 'rename'
    };
    if (destination_folder) {
      data.parent_folder_path = destination_folder;
    }
    Ember.debug('Beginning direct upload of ' + data.name);
    let target = await postFileDataToCanvas(data, postUrl);
    if (!target.upload_url) {
      if (target.attachments instanceof Array && target.attachments.length > 0) {
        target = target.attachments[0];
      }
    }
    let fd = buildUploadFormData(target, file);
    Ember.debug('Sending file to ' + target.upload_url);
    let attachment_id = null;
    try {
      for (let i = 0; i < 2; ++i) {
        try {
          attachment_id = await attemptUpload(target.upload_url, fd, return_url, on_progress);
          break;
        } catch (err) {
          Ember.debug({
            Location: 'Initial upload attempt failed',
            Status: err,
            Message: 'User ' + user_id + ' uploading ' + data.name + ', ' + data.content_type,
            URL: target.upload_url,
            formdata: fd
          });
        }
      }
      // If we're still having errors, try uploading through the interface and
      // make a note to do that in the future.
      if (attachment_id === null) {
        force_proxy_uploads = true;
        attachment_id = await proxyUpload(store, file, destination_folder, return_url);
      }
    } catch (err) {
      Ember.debug('Failed uploading ' + data.name + ': ', err);
      Ember.debug({
        Location: 'Upload failed',
        Status: err.status,
        Message: 'User ' + user_id + ' uploading ' + data.name + ', ' + data.content_type,
        URL: target.upload_url,
        formdata: fd
      });
      throw err;
    }
    Ember.debug('Successfully uploaded ' + data.name + ' as attachment ' + attachment_id);
    return attachment_id;
  }
  function buildUploadFormData(target, file) {
    let fd = new FormData();
    for (let key in target.upload_params) {
      fd.append(key, target.upload_params[key]);
    }
    fd.append(target.file_param, file);
    return fd;
  }
  async function uploadURL(file, destination_folder) {
    let data = {
      url: file.url,
      content_type: file.type,
      on_duplicate: 'rename'
    };
    if (destination_folder) {
      data.parent_folder_path = destination_folder;
    }
    Ember.debug('Beginning upload of ' + data.name);
    let result = await postFileDataToCanvas(data);
    let id = result.id;
    let filePending = true;
    while (filePending) {
      filePending = await fetchFilePendingFlag(result.status_url);
      if (filePending) {
        await pauseOneSecond();
      }
    }
    return id;
  }
  async function fetchFilePendingFlag(statusURL) {
    let statusRequest = await fetch(statusURL);
    if (!statusRequest.ok) {
      throw statusRequest.status;
    }
    let stat = await statusRequest.text();
    let tmp = parseBrokenCanvasJSON(stat);
    return tmp && tmp.upload_status === 'pending';
  }
  async function postFileDataToCanvas(fileData, url) {
    let response = await fetch(url ?? '/api/v1/users/self/files', {
      method: 'POST',
      body: new URLSearchParams(fileData)
    });
    if (!response.ok) {
      throw response.status;
    }
    return response.json();
  }
  function parseBrokenCanvasJSON(text) {
    return JSON.parse(text.replace(/^while\(1\);/, ''));
  }
  function pauseOneSecond() {
    return new Promise(res => {
      setTimeout(() => res(), 1000);
    });
  }
});