define("bocce/controllers/index", ["exports", "bocce/mixins/menus"], function (_exports, _menus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  var _default = _exports.default = Ember.Controller.extend(_menus.default, {
    userprofileService: Ember.inject.service('userprofile'),
    nextTerm: Ember.computed('model.terms.length', function () {
      return {};
    }),
    previousTerm: Ember.computed('model.terms.length', function () {
      return {};
    }),
    currentTerm: Ember.computed('model.terms.length', function () {
      /* eslint-disable-next-line ember/no-get */
      var terms = this.get('model.terms'),
        best;
      if (!terms || terms.get('length') === 0) {
        return {};
      }
      best = terms.objectAt(0);
      if (terms.get('length') > 1) {
        // do something here with startsOn.
      }
      return best;
    })
  });
});