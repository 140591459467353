define("bocce/components/bookmarks/bookmark-note", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let BookmarksHeaderComponent = _exports.default = (_dec = Ember._action, (_class = class BookmarksHeaderComponent extends Ember.Component {
    //This executes when a bookmark note is first rendered. It also executes when 
    //a bookmark note is modified via the UI, which ensures that the note's
    //'Show All' button is appropriately shown/hidden depending on 
    //the length of the modified note.
    didRender() {
      //This logic prevents an infinite loop caused by the below setting of
      //'noteClipped'.
      if (!this.bookmark.noteClipped) {
        const elem = this.element.querySelector('.bookmark-item-note');
        if (elem && elem.clientHeight < elem.scrollHeight) {
          Ember.set(this.bookmark, 'noteClipped', true);
        }
      }
    }
    setNotesMoreShowing(bookmark, moreShowing) {
      Ember.set(bookmark, 'notesMoreShowing', moreShowing);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "setNotesMoreShowing", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "setNotesMoreShowing"), _class.prototype)), _class));
});