define("bocce/components/login-streak", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let LoginStreak = _exports.default = (_dec = Ember.inject.service('login-streak'), (_class = class LoginStreak extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "loginStreakService", _descriptor, this);
    }
    get consecutiveWeeks() {
      return this.loginStreakService.consecutiveWeeks;
    }
    get weekNum() {
      return this.loginStreakService.weekNum;
    }
    get daysThisWeek() {
      return this.loginStreakService.daysThisWeek;
    }
    get days() {
      return [{
        day: 'M',
        num: 1
      }, {
        day: 'Tu',
        num: 2
      }, {
        day: 'W',
        num: 3
      }, {
        day: 'Th',
        num: 4
      }, {
        day: 'F',
        num: 5
      }, {
        day: 'Sa',
        num: 6
      }, {
        day: 'Su',
        num: 7
      }];
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "loginStreakService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
});