define("bocce/helpers/or", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.or = or;
  function or(params) {
    for (let p of params) {
      if (p) {
        return true;
      }
    }
    return false;
  }
  var _default = _exports.default = Ember.Helper.helper(or);
});