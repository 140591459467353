define("bocce/helpers/moment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // app/helpers/moment.js
  // Convert date into relative time with Moment.JS
  var _default = _exports.default = Ember.Helper.helper(function (args) {
    if (args.length === 0) {
      return 'Date Incorrect';
    }
    let [date, format] = args;
    let dateObj = new Date(date);
    let val = moment(dateObj).tz('America/New_York');
    if (format) {
      return val.format(format);
    } else {
      return val.calendar();
    }
  });
});