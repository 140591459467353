define("bocce/mixins/notify", ["exports", "bocce/utilities/dialog"], function (_exports, _dialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable-next-line ember/no-new-mixins */
  var _default = _exports.default = Ember.Mixin.create({
    hasNotification: Ember.computed('session.notification', function () {
      /* eslint-disable-next-line ember/no-get */
      return !!this.get('session.notification');
    }),
    isBannerNotification: false,
    currentBannerId: 0,
    actions: {
      notify: function (message, dismissable, modal_type, modal_id, priority, session, reload, link, id, type = "notification", dismiss_condition_id) {
        // Let us use this mixin in session-less types, like views
        session = session || this.session;
        let is_banner = false;
        // IF the banner in question is the survey banner, and it is passed 9 weeks from term start
        // Then we forego the banner and display as a full-screen modal instead.
        if (modal_type === "survey") {
          let now = new Date();
          let termStart = new Date(session.course.term.startsOn);
          let tenWeeks = 1000 * 60 * 60 * 24 * 63;
          let tenWeeksAfterTermStart = new Date(termStart.getTime() + tenWeeks);
          let thirteenWeeksAfterTermStart = new Date(termStart.getTime() + tenWeeks * 1.38);
          let fifteenWeeksAfterTermStart = new Date(termStart.getTime() + tenWeeks * 1.5);
          let courseConcluded = false;
          let displaySurvey = tenWeeksAfterTermStart.getTime() < now.getTime();

          // Check for survey_muted cookie
          let cookies = document.cookie.split(';');
          let surveyMuted = false;
          for (let i = 0; i < cookies.length; i++) {
            let cookie = cookies[i].trim();
            if (cookie.startsWith('survey_muted')) {
              surveyMuted = true;
            }
          }
          if (courseConcluded) {
            session.set('courseConcludedNoSurvey', true);
            this.transitionToRoute('classroom.lessons.survey', {
              queryParams: {
                concluded: true
              }
            });
          } else if (displaySurvey && !surveyMuted) {
            this.transitionToRoute('classroom.lessons.survey', {
              queryParams: {
                nudge: true
              }
            });
          }
        }
        let existing = session.get('notification');
        if (type === "banner" || modal_type === "survey") {
          is_banner = true;
        }
        priority = priority || 0;
        let notif_type = 'survey';
        if (modal_type === 'discussion') {
          notif_type = 'global';
        } else if (modal_type === 'status') {
          notif_type = 'status';
        } else if (reload) {
          notif_type = 'urgent';
        }
        if (existing && existing.priority > priority) {
          Ember.debug('Ignoring notification ' + message + ', priority is lower than visible notification');
        } else {
          session.set('notification', false);
          session.set('notification', {
            message,
            dismissable,
            modal_type,
            type: notif_type,
            modal_id,
            priority,
            link,
            reload,
            active: link || reload,
            id,
            is_banner,
            dismiss_condition_id
          });
        }
        if (!session.get('banners')) {
          session.set('banners', []);
        }
        if (modal_type !== "survey") {
          session.get('banners').pushObject({
            message: message,
            dismissable: dismissable,
            modal_type: modal_type,
            type: notif_type,
            modal_id: modal_id,
            priority: priority,
            link: link,
            reload: reload,
            active: link || reload,
            id,
            is_banner,
            dismiss_condition_id
          });
          session.set('banners', session.get('banners').sortBy('priority').reverse());
        }
      },
      dismissNotif: function (session) {
        let term_id = this.get('session.termID'),
          course_id = this.get('session.course.id');
        if (this.get('isBannerNotification')) {
          this.set('isBannerNotification', false);
          this.set('currentBannerId', 0);
        }
        if (session.is_banner) {
          let dismissRequest = $.get(`/interface/banners/${session.id}/dismiss/${term_id}/${course_id}`);
          this.session.set('banners', []);
        }
        this.session.set('notification', null);
        this.send("closeModals");
      },
      muteSurvey: function () {
        // Add a cookie to the user's browser that will prevent the survey from showing up again for 1 day.
        let now = new Date();
        let expires = new Date(now.getTime() + 1000 * 60 * 60 * 12);
        document.cookie = `survey_muted=true; expires=${expires.toUTCString()}; path=/`;
        this.transitionToRoute('classroom.lessons');
      },
      viewNotif: function (modal_type, modal_id, session) {
        if (this.session.get('notification.reload')) {
          location.reload(true);
          return;
        }

        // Should this be higher up, before the if statement?
        let s = session || this.session,
          link = s.get('notification.link'),
          message = s.get('notification.message');
        s.set('notification', null);
        if (link) {
          window.open(link, '_blank');
        } else if (modal_id) {
          this.send('viewModal', modal_type, modal_id);
        } else {
          (0, _dialog.default)(message);
        }
      }
    }
  });
});