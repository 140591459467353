define("bocce/controllers/classroom/lessons/discussion", ["exports", "bocce/mixins/editable", "bocce/mixins/audio-rec", "bocce/mixins/video-rec", "bocce/mixins/rtc-rec", "bocce/mixins/uploadable", "bocce/mixins/discussable", "bocce/mixins/enmodal", "bocce/utilities/dialog"], function (_exports, _editable, _audioRec, _videoRec, _rtcRec, _uploadable, _discussable, _enmodal, _dialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-observers */
  var _default = _exports.default = Ember.Controller.extend(_editable.default, _audioRec.default, _videoRec.default, _rtcRec.default, _uploadable.default, _discussable.default, _enmodal.default, {
    init(...args) {
      this._super(...args);
      this.preExistingRespIds = this.preExistingRespIds || [];
      this.selectedPollIds = this.selectedPollIds || [];
    },
    userprofileService: Ember.inject.service('userprofile'),
    kalturaService: Ember.inject.service('kaltura-upload'),
    classroom: Ember.inject.controller(),
    // required by DiscussableMixin
    discussion: Ember.inject.controller('classroom.lessons.discussion'),
    // required by DiscussableMixin
    newDiscussion: Ember.inject.controller('classroom.lessons.discussion-new'),
    // required by DiscussableMixin
    discussions: Ember.inject.controller(),
    lessons: Ember.inject.controller('classroom.lessons'),
    // required by UploadableMixin

    prevResp: false,
    isWorking: false,
    parentsLoaded: false,
    childrenLoaded: false,
    /* eslint-disable-next-line ember/no-observers */
    pollLoader: Ember.observer('model.poll_id', function () {
      // EF Temp fix:
      // Wait on full render before tryign to gather poll data to ensure the entire record is loaded
      //this.model.reload().then(() => {
      if (!this.model || !this.model.id) {
        return;
      }
      // TODO (NK): This is a workaround to fix issues with timing/reloading the
      // I'm leaving it as-is for the ember 3 upgrade, but this should be rewritten
      // to depend on the normal route lifecycle instead of making another request
      // and using the runloop.
      /* eslint-disable-next-line ember/no-jquery */
      this.store.findRecord('discussion', this.model.id, {
        reload: true
      }).then(d => {
        Ember.run.schedule('afterRender', this, () => {
          let pr = d.poll_raw;
          if (pr && pr.length > 0) {
            pr = JSON.parse(pr);
            if (pr.did_vote) {
              pr.voter_info = JSON.parse(pr.did_vote);
            }
            this.set('model.poll', pr);
          }
        });
      });
    }),
    /* eslint-disable-next-line ember/no-observers */
    didVoteObserver: Ember.observer('model.poll.voters', function () {
      /* eslint-disable-next-line ember/no-get */
      let did_vote = this.get('model.poll.did_vote');
      if (typeof did_vote === 'string') {
        this.set('model.poll.did_vote', JSON.parse(did_vote));
      }
    }),
    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    voterData: Ember.computed('model.poll.voters', function () {
      /* eslint-disable-next-line ember/no-get */
      let data = this.get('model.poll.voters');
      if (data && data.length > 0) {
        data = JSON.parse(data);
        for (let i = 0; i < data.length; i++) {
          data[i].user = this.store.findRecord('user', data[i].user_id);
        }
        return data;
      }
      return [];
    }),
    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    myVote: Ember.computed('model.poll.voters', function () {
      /* eslint-disable-next-line ember/no-get */
      let data = this.get('model.poll.did_vote');
      if (data && data.length > 0) {
        data = JSON.parse(data);
        if (data.length > 0) {
          return data[0];
        }
      }
      return [];
    }),
    /* eslint-disable-next-line ember/no-observers */
    updateLesson: Ember.observer('model.message', function () {
      /* eslint-disable-next-line ember/no-get */
      if (this.get('model.id')) {
        /* eslint-disable-next-line ember/no-get */
        this.store.findRecord('item', this.get('model.id')).then(item => {
          var lessonId = item.get('lesson') ? item.get('lesson').id : false;
          if (lessonId) {
            this.set('isLesson', lessonId);
            /* eslint-disable-next-line ember/no-get */
            this.set('topicId', this.get('model.id'));
          }
        }, () => {
          // FAIL SILENTLY -- if no failure callback present, app borks
        });
      }
    }),
    // Returns an array of top-level responses, ie replies to the main discussion thread.
    topLevelResponses: Ember.computed('model.responses.@each.parent', 'session.user.id', 'session.{isInstructor,isLimitedStudentTerm}', function () {
      /* eslint-disable-next-line ember/no-get */
      let responses = this.get('model.responses').toArray();
      let limited = this.get("session.isLimitedStudentTerm") && !this.get("session.isInstructor");
      let currentUserId = this.get('session.user.id') ? parseInt(this.get('session.user.id')) : false;
      let top_level = responses.filter(response => {
        let parent = parseInt(response.get('parent'));
        let respUserId = response.get('user.id') ? parseInt(response.get('user.id')) : false;
        if (!parent) {
          let instructorIds = response.get('instructor_ids') || [];
          if (limited && respUserId !== currentUserId && !instructorIds.includes(respUserId)) {
            return false;
          }
          return true;
        } else {
          // If there's a parent ID, make sure that the parent exists.
          // response.some(...) here returns true if the parent is found.
          return !responses.some(r => parseInt(r.get('id')) === parent);
        }
      });
      top_level.sort((a, b) => {
        return a.get('date') - b.get('date');
      });
      this.set('parentsLoaded', true);
      return top_level;
    }),
    // Returns an object whose keys are top-level response IDs (see topLevelResponses),
    // and whose values are arrays of child responses. For example:
    //    {
    //      1234: [
    //        { /* child response */ },
    //        { /* child response */ },
    //      ]
    //    }
    childResponses: Ember.computed('model.responses.@each.parent', 'session.user.id', 'session.{isInstructor,isLimitedStudentTerm}', 'topLevelResponses', function () {
      /* eslint-disable-next-line ember/no-get */
      let responses = this.get('model.responses').toArray();
      let parent_ids = this.topLevelResponses.map(r => parseInt(r.get('id')));
      let limited = this.get("session.isLimitedStudentTerm") && !this.get("session.isInstructor");
      let currentUserId = this.get('session.user.id') ? parseInt(this.get('session.user.id')) : false;
      let childResponses = {};
      // For each top level response, find all children of that response.
      for (let parent_id of parent_ids) {
        // look for responses that point to the current top-level response as a parent
        childResponses[parent_id] = responses.filter(function (response) {
          if (limited) {
            let instructorIds = response.get('instructor_ids') || [];
            let respUserID = response.get('user.id') ? parseInt(response.get('user.id')) : false;
            if (respUserID !== currentUserId && !instructorIds.includes(respUserID)) {
              return false;
            }
          }
          return parseInt(response.get('parent')) === parent_id;
        });
        // sort them by date!
        childResponses[parent_id].sort((a, b) => a.get('date') - b.get('date'));
      }
      this.set('childrenLoaded', true);
      return childResponses;
    }),
    responsesLoaded: Ember.computed.and('parentsLoaded', 'childrenLoaded'),
    message: Ember.computed.alias('model.message'),
    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    isInstructorAndOwner: Ember.computed('model.user', function () {
      /* eslint-disable-next-line ember/no-get */
      return this.get('model.user.id') === this.get('session.user.id') && this.get('session.isInstructor');
    }),
    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    truncated: Ember.computed('message', function () {
      if (this.message.length > 1000) {
        return true;
      }
      return false;
    }),
    actions: {
      addFile: function (file) {
        if (file.type.includes('video')) {
          let encoding_video = {
            name: file.name,
            percent_uploaded: 0
          };
          this.encoding_videos.pushObject(encoding_video);
          let fileIndex = this.encoding_videos.length - 1;
          this.kalturaUploadVideo(file, async () => {
            Ember.debug('Unable to upload video to Kaltura. Uploading to S3...');
            await (0, _dialog.default)('Video embedding unsuccessful; this file will be available for download only.', ['OK']);
            this.encoding_videos.removeObject(encoding_video);
            file.ignoreDownloadOnlyPrompt = true;
            this.send('addValidFile', file);
          }, fileIndex, URL.createObjectURL(file), encoding_video);
        } else if (this.mimeTypes.indexOf(file.type) === -1) {
          this.send('addValidFile', file);
        } else {
          this.send('addInvalidFile', file);
        }
      },
      removeAttachment: function (file) {
        this.send('deleteFile', file);
      },
      renameAttachment: function (attachment, newName) {
        if (attachment.file?.isUrl) {
          this.kalturaService.kalturaRenameVideo(attachment.uploaded_id, newName);
          Ember.set(attachment, 'name', newName);
        } else {
          Ember.set(attachment, 'name', newName);
          this.store.findRecord('attachment', attachment.uploaded_id).then(loadedAttachment => {
            loadedAttachment.set('name', newName);
            loadedAttachment.save();
          });
        }
      },
      destroyEditor: function () {
        this._super();
        this.set('replyId', false);
      },
      deleteAttachment(courseId, discussionId, attachmentId, type, recordId, userId) {
        const message = 'Are you sure you wish to delete this attachment? This action CANNOT be undone!';
        const self = this;
        (0, _dialog.default)(message, [`Yes. Soft delete.`, `Yes. Leave no trace. But, before you click this button, manually delete the file from S3. Attachment id: ${attachmentId}.`, 'No']).then(choice => {
          if (choice.indexOf('Yes') === 0) {
            let destroy = choice === 'Yes. Leave no trace.';
            self.set('isWorking', true);
            this.store.findRecord('attachment', attachmentId).then(record => {
              this.store.nestResources('attachment', [{
                course: courseId
              }, {
                discussion: discussionId + ":" + recordId
              }, {
                user: userId
              }, {
                destroy
              }, {
                type
              }]);
              try {
                record.destroyRecord().then(() => {
                  this.store.findRecord(type, recordId).then(function (record) {
                    let currentAttachmentIds = record.get('attachments');
                    record.set('attachments', currentAttachmentIds.filter(a => a != attachmentId));
                    self.set('isWorking', false);
                  });
                  this.store.nestResources('attachment', []);
                }).catch(() => {
                  (0, _dialog.default)('Problem occurred deleting attachment. Please reload the page and try again.');
                  self.set('isWorking', false);
                });
              } catch (e) {
                (0, _dialog.default)('Problem occurred deleting attachment. Please reload the page and try again.');
                self.set('isWorking', false);
              }
            });
          }
        });
      },
      viewInLesson: function (lesson) {
        this.transitionToRoute('classroom.lessons', lesson, this.topicId);
      },
      replyToDiscussion: function (arg) {
        this.set('replyId', arg.id);
        this.set('replyText', arg.text);
        this.set('replyAuthor', arg.user);
        let rte = document.querySelector('.rte-editor-input');
        if (rte) {
          rte.focus();
        }
      },
      replyCancel: function () {
        this.set('replyId', false);
        this.set('replyText', '');
        this.set('replyAuthor', '');
      },
      togglePollId: function (poll_choice_id, indx) {
        /* eslint-disable-next-line ember/no-get */
        if (this.get('model.poll.old_poll')) {
          this.set('selectedPollIds', []);
          this.set('selectedPollIds.' + indx, poll_choice_id);
        } else {
          if (!this.get('selectedPollIds.' + indx)) {
            this.set('selectedPollIds.' + indx, poll_choice_id);
          } else {
            this.set('selectedPollIds.' + indx, null);
          }
        }
        Ember.notifyPropertyChange(this, 'selectedPollIds');
      },
      votePoll: async function (poll_id) {
        let choices = this.selectedPollIds;
        let choicesArray = [];
        for (let i = 0; i < choices.length; i++) {
          choicesArray.push({
            poll_choice_id: choices[i]
          });
        }
        let voteInPollElem = document.querySelectorAll('.vote-in-poll');
        for (let e of voteInPollElem) {
          e.classList.add('submitting');
        }
        let response = await fetch(`/interface/vote_in_poll/${poll_id}`, {
          method: 'POST',
          body: JSON.stringify({
            poll_submissions: choicesArray
          })
        });
        if (!response.ok) {
          Ember.debug('Unable to vote in poll. Something went wrong:', response.statusText);
        }
        this.pollLoader();
        for (let e of voteInPollElem) {
          e.classList.remove('submitting');
        }
      }
    }
  });
});