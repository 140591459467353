define("bocce/controllers/dashboard", ["exports", "bocce/utilities/logout", "bocce/utilities/dialog"], function (_exports, _logout, _dialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    loginStreak: Ember.inject.service('login-streak'),
    dashboardSorter: Ember.inject.service('dashboard-sorter'),
    currentTab: 'courses',
    maximizedCard: false,
    hasStudentEnrollment: false,
    showScrollToTop: true,
    lastUpdateTimestamp: null,
    displayStatsPopup: false,
    hideClassmateStats: false,
    experimentalFeaturesEnabled: false,
    startPolling() {
      // Set local storage var "dashboardHB" to true -- set to false to manually stop the heartbeat
      localStorage.setItem('dashboardHB', true);

      // Make sure user has the dashboard tab open
      let tabIsActive = !document.hidden;
      let lastMouseMovement = Date.now();
      const HEARTBEAT_INTERVAL = 30000; // polling interval in milliseconds
      const INACTIVITY_THRESHOLD = 60000; // after 60 seconds of inactivity, skip heartbeat

      document.addEventListener('visibilitychange', () => {
        tabIsActive = !document.hidden;
      });
      document.addEventListener('mousemove', () => {
        lastMouseMovement = Date.now();
      });
      this.polling = setInterval(() => {
        // Check local storage var "dashboardHB" to see if it's true
        let dashboardHB = localStorage.getItem('dashboardHB');
        let currentTime = Date.now();
        let doHB = dashboardHB && tabIsActive && currentTime - lastMouseMovement < INACTIVITY_THRESHOLD;
        if (doHB) {
          console.log("Beep.");
          this.refreshModel();
        } else {
          console.log("Skip.");
        }
      }, HEARTBEAT_INTERVAL); // 30 seconds
    },
    refreshModel() {
      this.set('lastUpdateTimestamp', new Date().getTime());
      this.store.queryRecord('dashboard', {
        fingerprint: this.get('model.firstObject.fingerprint')
      }).then(updatedModel => {
        if (!updatedModel || !updatedModel.get('length')) {
          return;
        }
        this.set('model', updatedModel);
      }).catch(error => {
        console.error(`Error while reloading the model: ${error}`);
      });
    },
    // Make sure to clear the polling when the controller is destroyed
    willDestroy() {
      super.willDestroy(...arguments);
      clearInterval(this.polling);
    },
    init() {
      this._super(...arguments);

      //Comment to stop heartbeat
      this.startPolling();

      //Check Local Storage for the dashboardMessageNoClassPerm
      let dashboardMessageNoClassPerm = localStorage.getItem('dashboardMessageNoClassPerm');
      if (dashboardMessageNoClassPerm) {
        (0, _dialog.default)(`You do not have permission to view this class. If you believe this is an error, please contact support@online.berklee.edu.`);
        localStorage.removeItem('dashboardMessageNoClassPerm');
      }
    },
    terms: Ember.computed('model', 'model.[]', function () {
      let prefs = this.get('session.user.profile');
      this.set('hideClassmateStats', prefs ? prefs.hideClassmateStats : false);
      this.set('experimentalFeaturesEnabled', prefs ? prefs.experimentalFeaturesEnabled : false);
      this.setSorterProperties();
      let model = this.get('model');
      if (!model) {
        return [];
      } else {
        let modelBackup = this.get('modelBackup');
        let fingerprint = model.get('firstObject.fingerprint');
        if (modelBackup && modelBackup.get('fingerprint') === fingerprint) {
          return modelBackup.get('terms');
        }
        this.set('modelBackup', model.firstObject);
        return model.get('firstObject.terms');
      }
    }),
    userId: Ember.computed('model', 'model.[]', function () {
      return this.get('model.firstObject.user_id');
    }),
    upcoming_live_class: Ember.computed('model', 'model.[]', function () {
      let model = this.get('model');
      return model.get('firstObject.next_live_class');
    }),
    currentTermLoginData: Ember.computed('model', 'model.userTermData', function () {
      let model = this.get('model');
      if (!model) {
        return;
      }
      let termData = model.get('firstObject.userTermData');
      const currentWeek = model.get('firstObject.terms.firstObject.week_num');
      if (termData && termData.login_days && currentWeek) {
        this.loginStreak.initialize(currentWeek, termData?.login_days || {});
      }
      return termData;
    }),
    displayIntroPopup: Ember.computed('session.user.profile', function () {
      let prefs = this.get('session.user.profile');
      if (!prefs) {
        return false;
      }

      // Check the local storage var "accepteddashboardBeta2IntroPopup" to see if it's true
      let acceptedDashboardBetaIntroPopup = prefs.acceptedDashboardBeta2IntroPopup || localStorage.getItem('acceptedDashboardBeta2IntroPopup');
      return !acceptedDashboardBetaIntroPopup;
    }),
    setSorterProperties() {
      this.dashboardSorter.setProperty('showUngraded', true);
    },
    savePrefs(prefs) {
      this.store.findRecord('user', this.get('session.user.id')).then(record => {
        record.set('profile_updated', true);
        record.set('profile', prefs);
        record.save();
      });
    },
    actions: {
      toggleSideMenu: function () {
        this.toggleProperty('showSideMenu');
      },
      toggleSideWidgets: function () {
        this.toggleProperty('showSideWidgets');
      },
      scrollElementIntoView: function (element, offset = 0) {
        if (!element) {
          return;
        }

        // Find the first scrollable parent
        let parent = element.parentNode;
        while (parent && (parent.scrollHeight === parent.clientHeight || getComputedStyle(parent).overflowY === 'visible')) {
          parent = parent.parentNode;
        }
        if (!parent || !(parent instanceof HTMLElement)) {
          return;
        }
        const parentRect = parent.getBoundingClientRect();
        const elementRect = element.getBoundingClientRect();

        // Calculate the distance the element needs to travel to be aligned with the top of the viewport + offset.
        const delta = elementRect.top - (parentRect.top + offset);

        // Adjust the parent's scrollTop by this delta
        parent.scrollTop += delta;
      },
      dismissIntroPopup: function () {
        let prefs = this.get('session.user.profile');
        if (!prefs) {
          return;
        }
        Ember.set(prefs, 'acceptedDashboardBeta2IntroPopup', true);
        localStorage.setItem('acceptedDashboardBeta2IntroPopup', 'true');
        this.savePrefs(prefs);
        this.set('displayIntroPopup', false);
      },
      toggleExperimentalFeatures: function () {
        let prefs = this.get('session.user.profile');
        if (!prefs) {
          return;
        }
        let experimentalOn = !this.get('experimentalFeaturesEnabled');
        this.setSorterProperties();
        if (!experimentalOn) {
          this.set('hideClassmateStats', true);
        }
        Ember.set(prefs, 'experimentalFeaturesEnabled', experimentalOn);
        this.set('experimentalFeaturesEnabled', experimentalOn);
        let element = document.getElementsByClassName('dashboard-intro-popup')[0];
        if (element) {
          element.scrollTo(0, element.scrollHeight);
        }
        this.savePrefs(prefs);
      },
      displayIntro: function () {
        this.set('displayIntroPopup', true);
      },
      tabNavigation: function (tab) {
        this.set('showSideMenu', false);
        this.set('currentTab', tab);
      },
      messageUser: function (id) {
        this.set('sendMessageTo', id);
        this.send('tabNavigation', 'inbox');
      },
      logout: function () {
        (0, _logout.default)();
      },
      viewTerm: function (termNumber) {
        event.stopPropagation();
        // Scroll to element with id #term_{{termNumber}}
        let element = document.getElementById(`term_${termNumber}`);
        if (element) {
          this.scrollElementIntoView(element);
          document.getElementsByClassName('dashboard')[0].scrollBy(0, -100);
        }
      },
      navigate(courseClosed, startDate) {
        event.stopPropagation();
        // If the course is closed, show a dialog
        if (courseClosed) {
          // force date to be EST, formatted as MM/DD/YYYY
          let startDateFormatted = startDate ? new Date(startDate).toLocaleString('en-US', {
            timeZone: 'America/New_York',
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
          }) : 'the first day of the term';
          (0, _dialog.default)(`Please note this course hasn't opened yet. Sections open between 9 AM and 6 PM ET on ${startDateFormatted}. You will receive an email notification once this course becomes available. We hope you have a great semester!`);
          return;
        }

        // Check the href of the link clicked and navigate to this page in a new tab, unless sameWindow is true
        let href = event.target.href;
        if (!href) {
          let closetsLink = event.target.closest('a');
          if (closetsLink) {
            href = closetsLink.href;
          }
        }
        window.open(href, '_blank');
      },
      scrollToTop() {
        event.stopPropagation();
        // Scroll contents of .dashboard to top
        let element = document.getElementsByClassName('dashboard')[0];
        if (element) {
          element.scrollTo(0, 0);
        }
      },
      updateMaximizedCard(newIndex) {
        this.set('maximizedCard', newIndex);
      },
      toggleClassmateStats: function () {
        let prefs = this.get('session.user.profile');
        if (!prefs) {
          return;
        }
        let hideClassmateStats = this.get('hideClassmateStats');
        Ember.set(prefs, 'hideClassmateStats', !hideClassmateStats);
        this.set('hideClassmateStats', !hideClassmateStats);
        this.savePrefs(prefs);
      },
      dismissStatsInfo: function () {
        this.set('displayStatsPopup', false);
      },
      displayStatsInfo: function () {
        this.set('displayStatsPopup', true);
      }
    }
  });
});