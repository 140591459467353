define("bocce/controllers/classroom/lessons/discussion-new", ["exports", "bocce/mixins/enmodal"], function (_exports, _enmodal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable-next-line ember/no-mixins */
  var _default = _exports.default = Ember.Controller.extend(_enmodal.default, {
    classroom: Ember.inject.controller(),
    discussions: Ember.inject.controller(),
    lessons: Ember.inject.controller('classroom.lessons') // required by UploadableMixin
  });
});