define("bocce/mixins/menus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable-next-line ember/no-new-mixins */
  var _default = _exports.default = Ember.Mixin.create({
    actions: {
      toggleMenu: function (menu) {
        let menuElem = document.querySelector('.menu.' + menu);
        if (menuElem) {
          menuElem.classList.toggle('active');
        }
        let menuBackdrop = document.querySelector('.clear-backdrop.' + menu);
        if (menuBackdrop) {
          menuBackdrop.classList.toggle('active');
        } else {
          let clear = document.querySelector('.clear-backdrop');
          if (clear) {
            clear.classList.toggle('active');
          }
        }
      },
      closeMenu: function () {
        let menu = document.querySelector('.menu.active');
        if (menu) {
          menu.classList.remove('active');
        }
        let backdrop = document.querySelector('.clear-backdrop');
        if (backdrop) {
          backdrop.classList.remove('active');
        }
      },
      toggleHamburger: function () {
        let classroom = document.querySelector('.classroom');
        if (classroom) {
          classroom.classList.toggle('active');
        }
      },
      goToDashboard: function () {
        /* eslint-disable-next-line ember/no-get */
        if (!this.get('session.isBCM')) {
          window.open('https://' + window.location.hostname + '/#/dashboard', '_blank');
        }
      }
    }
  });
});