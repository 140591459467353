define("bocce/mixins/interactions/audio_comments", ["exports", "bocce/mixins/support/util"], function (_exports, util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* ************************** *
   * Audio Comments interaction *
   * ************************** */

  /*
   * Helper functions
   */

  // return the duration of a comment in seconds
  // if there is no end time on the comment, return FALSE
  function getCommentLength(comment) {
    let start = comment.start,
      end = comment.end;
    return end ? end - start : false;
  }

  // check for comment equivilency
  function sameComment(commentA, commentB) {
    return commentA && commentB && commentA.label === commentB.label && commentA.start === commentB.start;
  }

  // sort an array by key
  function sortBy(key, data) {
    data.sort(function (a, b) {
      return a[key] - b[key];
    });
    return data;
  }

  // using a string of form MM:SS, return number of seconds
  function timeToSeconds(input) {
    if (/\d+:\d+/.test(input)) {
      let split = input.split(':'),
        m = parseInt(split[0]),
        s = parseInt(split[1]);
      return m * 60 + s;
    } else {
      return undefined;
    }
  }

  // truncate a comment so it fits nicely
  // $comment is the jquery object for the comment span
  function truncateComment($comment) {
    let $label = $comment.find('.label'),
      text = $label.text().trim(),
      parentWidth = $comment.parent().width(),
      left = $comment.position().left,
      width = $label.width();

    // truncate the comment to it doesn't spill off the right edge,
    // but limit the truncation so it won't go below 15 characters
    while (left + width > parentWidth && text.length >= 15) {
      text = truncateText(text, -5);
      $label.text(text);
      width = $label.width();
    }

    // if the comment is still spilling off, shift it to the left
    if (left + width + 10 > parentWidth) {
      $label.css('position', 'absolute');
      $label.css('right', '0');
    }
  }
  function truncateText(text, endIndex) {
    text = text.slice(0, endIndex);
    if (text.slice(-1) === ' ') {
      text = text.slice(0, -1);
    }
    text += '...';
    return text;
  }

  /* ***************************** *
   * AudioComments object; this is *
   * the object that gets exported *
   * ***************************** */

  // AudioComments constructor
  function AudioComments($el, data) {
    data = this.convertToJSON($el[0], data);
    // binds all methods to this
    util.bindAll(this);
    this.$el = $el;
    this.$containerEl = $el.find('.interaction_content');
    this.config = data.config;
    this.duration = timeToSeconds(data.config.duration);
    this.tabIndex = 0;

    // array of all comments
    let commentsArray = data.initial_data;
    let formatedArray = [];
    for (let i = 0; i < commentsArray.length; i++) {
      const commentObj = commentsArray[i];
      let comment = {
        "label": commentObj.label || '',
        "comment": commentObj.comment.trim() ? '<p class="body" >' + commentObj.comment + "</p>" : '',
        "start": timeToSeconds(commentObj.start) || 0,
        "end": timeToSeconds(commentObj.end) || undefined,
        "bgImage": commentObj.bg_image || '',
        "stopAtEnd": commentObj.stopAtEnd || false,
        "id": 'comment-' + i
      };
      comment = this.setCommentDimensions(comment);
      formatedArray.push(comment);
    }
    this.comments = sortBy('start', formatedArray);
    this.currentActiveComment = false;

    // path to the waveform image
    this.bgImagePath = data.assets.bg_image[0];
    // path to the audio file
    this.audioPath = data.assets.audio_track[0];
  }

  // AudioComments prototype
  AudioComments.prototype = {
    convertToJSON(element, data) {
      const table = element.querySelector("table");
      // array of columns in the table from the cms:
      const COLUMNS = ['label', 'comment', 'start', 'end', 'bgImage', 'stopAtEnd', 'css', 'autoPopup', 'labelOffset', 'dimensions'];
      let initialData = [];
      /* eslint-disable-next-line ember/no-jquery */
      for (let i = 0; i < Ember.$(table).find('tr').length; ++i) {
        // skip first row (title row)
        if (i === 0) {
          continue;
        }
        let args = {},
          /* eslint-disable-next-line ember/no-jquery */
          $row = Ember.$(table).find('tr');
        for (let j = 0; j < $row.length; ++j) {
          args[COLUMNS[j]] = $row.eq(i).find('td').eq(j).html();
        }
        ['label', 'start', 'end', 'stopAtEnd', 'css', 'autoPopup'].forEach(field => {
          if (args[field]) {
            /* eslint-disable-next-line ember/no-jquery */
            args[field] = Ember.$.trim(Ember.$(args[field]).text());
          }
        });
        let comment = {
          label: args.label || '',
          /* eslint-disable-next-line ember/no-jquery */
          comment: args.comment || '',
          start: args.start || 0,
          end: args.end || undefined,
          bgImage: args.bgImage || '',
          stopAtEnd: args.stopAtEnd === 'true'
        };
        initialData.push(comment);
      }
      initialData = this.defineEndTimes(initialData);
      initialData.forEach((d, i) => {
        d.id = 'comment-' + i;
      });
      const config = {
        ...data.config,
        activityMinHeight: "12rem"
      };
      return {
        ...data,
        interactivity_id: element.id,
        config,
        initial_data: initialData
      };
    },
    // get things up and running.
    init() {
      this.$containerEl.attr('style', '');
      // create audio comments div and add to container
      this.$containerEl.append(this.createAudioCommentDiv());

      // save ref to the content div
      this.$contentEl = this.$containerEl.find('.content');
      this.$contentEl.css("min-height", this.config.activityMinHeight);
      /* eslint-disable-next-line ember/no-jquery */
      this.$activeCommentText = Ember.$('<div class="active-comment"></div>');
      this.$contentEl.empty();
      let imageSrc = this.bgImagePath;
      let audioSrc = this.audioPath;
      let comments = this.comments;
      let $image;
      /* eslint-disable-next-line ember/no-jquery */
      $image = Ember.$('<img class="bg-image" src="' + imageSrc + '">');
      this.$el.find('audio').attr("src", audioSrc);
      this.tabIndex++;
      let $audioControls = this.$containerEl.find('.audio-controls');
      $audioControls.find(".fa-play").attr("tabindex", this.tabIndex).attr("aria-label", "Play").addClass("tababble");
      $audioControls.find(".fa-pause").attr("tabindex", this.tabIndex).attr("aria-label", "Pause").addClass("tababble");
      $audioControls.find(".player-seek").attr("tabindex", ++this.tabIndex).attr("aria-label", "player seekbar").addClass("tababble");
      // add comment BG
      for (let i = 0; i < comments.length; i++) {
        const comment = comments[i];
        let str = '<div class="' + comment.divType + ' background" style="width:' + comment.divWidth + ' ; left: ' + comment.divPos + ' ;';
        str += "background: url('" + comment.bgImage + "');";
        str += 'background-size: 100% 100%;"> </div>';
        this.$contentEl.append(str);
      }
      this.$contentEl.append('<div class="highlight" ></div>');
      /* eslint-disable-next-line ember/no-jquery */
      let $imageWrapper = Ember.$('<div class="bg-image-wrapper"></div>');
      $imageWrapper.append($image);
      this.$contentEl.append($imageWrapper);

      // add comments
      for (let i = 0; i < comments.length; i++) {
        const comment = comments[i];
        let str = '<div class="tababble ' + comment.divType + ' ' + comment.id + '" style="width: ' + comment.divWidth + '; left: ' + comment.divPos + '; background-size: 100% 100%;" aria-label="' + comment.label + '" tabindex=' + ++this.tabIndex + '><div aria-hidden="true" class="label">' + comment.label + '</div></div>';
        this.$contentEl.append(str);
      }
      this.$contentEl.append(this.$activeCommentText);
      // save ref to the image
      /* eslint-disable-next-line ember/no-jquery */
      this.$image = $image;
      this.$image.click(this.bgImageClick);
      // initialize the audio player
      this.initPlayer();
      this.$containerEl.find('.loading').remove();

      // truncate comments
      this.$contentEl.find('.comment, .comment-span').each(function () {
        /* eslint-disable-next-line ember/no-jquery */
        truncateComment(Ember.$(this));
      });
    },
    /*
     * Logic
     * These functions make decisions but don't render anything;
     * if they need to change something in the UI, call a UI function
     */

    initPlayer() {
      let $player = this.$el.find('audio');
      let _self = this;
      this.player = $player.get(0);

      // add *all* the event handlers!
      $player.on('timeupdate', this.timeUpdate);

      // set up the controls
      this.$seek = this.$containerEl.find('.player-seek');
      this.$seek.on('change.fndtn.slider', this.seekBarClick);
      this.$play = this.$containerEl.find('.playpause');
      this.$play.on('click', this.playPause);
      // below event must bind lastly
      this.$play.on('click', function (evt) {
        let $audioControls = _self.$containerEl.find('.audio-controls');
        /* eslint-disable-next-line ember/no-jquery */
        if (Ember.$(evt.currentTarget).hasClass("fa-play")) {
          $audioControls.find(".fa-pause").focus();
        } else {
          $audioControls.find(".fa-play").focus();
        }
      });
      this.player.onended = function () {
        _self.$play.filter('.fa-play').removeClass('inactive');
        _self.$play.filter('.fa-pause').addClass('inactive');
      };
      this.$contentEl.find('.comment, .comment-span').click(this.seekToComment);

      // save references to useful DOM elements
      this.$highlight = this.$contentEl.find('.highlight');
      this.$highlight.click(this.bgImageClick);
    },
    // given args as input, return a comment object
    commentFactory(args) {
      ['label', 'start', 'end', 'stopAtEnd', 'css', 'autoPopup'].forEach(field => {
        if (args[field]) {
          /* eslint-disable-next-line ember/no-jquery */
          args[field] = Ember.$.trim(Ember.$(args[field]).text());
        }
      });

      /* eslint-disable-next-line ember/no-jquery */
      let bgImagePath = Ember.$(args.bgImage).find('img').attr('src') || '';
      let comment = {
        label: args.label || '',
        /* eslint-disable-next-line ember/no-jquery */
        comment: Ember.$.trim(Ember.$("<div>" + args.comment + "</div>").html()) || '',
        start: timeToSeconds(args.start) || 0,
        end: timeToSeconds(args.end) || undefined,
        bgImage: bgImagePath,
        stopAtEnd: args.stopAtEnd === 'true'
      };
      comment = this.setCommentDimensions(comment);
      return comment;
    },
    // If the user clicks on an area that isn't covered by
    // a comment span, this seeks the audio to the correct time
    bgImageClick(e) {
      let player = this.player;
      let imageWidth = this.$image.width();
      /* eslint-disable-next-line ember/no-jquery */
      let box = Ember.$(e.target);
      let pxFromLeft = e.pageX - box.offset().left;
      let percentPosition = pxFromLeft / imageWidth;
      let time = percentPosition * this.duration;
      player.currentTime = time;
    },
    defineEndTimes(data) {
      // if an end time isn't defined, make it either
      // the start time of the next
      for (let i = 0; i < data.length; ++i) {
        if (!data[i].end) {
          let end = data[i].start + 10;
          if (data[i + 1] && data[i + 1].start < end) {
            data[i].end = data[i + 1].start;
          } else if (this.duration < end) {
            data[i].end = this.duration;
          } else {
            data[i].end = end;
          }
        }
      }
      return data;
    },
    getCommentFromTime() {
      let time = this.player.currentTime,
        theChosenOne = false;
      // start checking for comments from the last
      // comments, so if there's any overlap the
      // newer comment takes priority
      for (let i = this.comments.length - 1; i >= 0; --i) {
        let comment = this.comments[i];
        let start = comment.start,
          end = comment.end;
        if (start < time && time < end) {
          theChosenOne = comment;
          break;
        }
      }
      return theChosenOne;
    },
    // return an array of comments based on the table contents
    getDataFromTable(table) {
      // array of columns in the table from the cms:
      const COLUMNS = ['label', 'comment', 'start', 'end', 'bgImage', 'stopAtEnd', 'css', 'autoPopup', 'labelOffset', 'dimensions'];
      let data = [];
      /* eslint-disable-next-line ember/no-jquery */
      for (let i = 0; i < Ember.$(table).find('tr').length; ++i) {
        // skip first row (title row)
        if (i === 0) {
          continue;
        }
        let args = {},
          /* eslint-disable-next-line ember/no-jquery */
          $row = Ember.$(table).find('tr');
        for (let j = 0; j < $row.length; ++j) {
          args[COLUMNS[j]] = $row.eq(i).find('td').eq(j).html();
        }
        data.push(this.commentFactory(args));
      }
      data = this.defineEndTimes(data);
      data.forEach((d, i) => {
        d.id = 'comment-' + i;
      });
      return data;
    },
    playPause(evt) {
      let player = this.player;
      if (player.buffered.length < 1) {
        player.load();
      }
      if (player.paused) {
        player.play();
      } else {
        player.pause();
      }
      this.togglePlayButton();
    },
    seekBarClick(e) {
      let player = this.player;
      if (!player.duration) {
        return;
      }
      let time = parseInt(e.target.value, 10) / 100 * player.duration;
      player.currentTime = time;
    },
    // Move playback to the clicked comment's time and play
    seekToComment(e) {
      let target = e.target;
      if (target.className === 'label') {
        // if the user clicks on the label,
        // we want the comment span instead
        target = target.parentElement;
      }
      // get the numeric index of the element
      let elId = target.className.split(' ').find(c => /comment-\d+/.test(c));
      let comment = this.comments.find(c => c.id === elId);
      this.player.currentTime = comment.start;
      this.player.play();
      this.togglePlayButton();
    },
    timeUpdate() {
      let player = this.player;
      this.percent = player.currentTime / player.duration;
      this.updateSeekBar();
      this.updateHighlight();

      // check if comment needs to stop playback
      if (this.currentActiveComment.stopAtEnd && player.currentTime >= this.currentActiveComment.end) {
        player.pause();
        this.togglePlayButton();
      }

      // check if the comment display needs to be updated
      let activeComment = this.getCommentFromTime();
      if (!sameComment(activeComment, this.currentActiveComment)) {
        this.currentActiveComment = activeComment;
        this.displayComment();
      }
    },
    /*
     * UI Functions
     * These change the visual display
     * but shouldn't handle any logic
     */
    createAudioCommentDiv() {
      const slideshowPlayer = this.createSlideshowPlayer();
      return `<div class="audio-comments">
                ${slideshowPlayer}
                <div class="content"></div>
            </div>`;
    },
    createSlideshowPlayer() {
      const audioPlayer = this.createAudioPlayer();
      const audioControls = this.createAudioControls();
      return `<div class="slideshow-player">
              ${audioPlayer}
              ${audioControls}
            </div>`;
    },
    createAudioPlayer() {
      return `<audio class="player"></audio>`;
    },
    createAudioControls() {
      return `<div class="audio-controls">
                <i class="fas fa-play playpause"></i>
                <i class="fas fa-pause inactive playpause"></i>
                <input class="player-seek" min="0" max="100" value="0" type="range"
                step="1">
            </div>`;
    },
    displayComment() {
      if (this.currentActiveComment) {
        let comment = this.currentActiveComment.comment,
          label = this.currentActiveComment.label,
          str = '<div class="ember-view">';
        str += '<div class="comment-title">' + label + '</div>';
        str += '<div class="comment-body">';
        str += '<p class="body" >' + comment + '</p>';
        str += '</div></div>';
        this.$contentEl.find('.comment, .comment-span').removeClass('current');
        this.$contentEl.find(`.${this.currentActiveComment.id}`).addClass('current');
        this.$activeCommentText.html(str);
      } else {
        // if we're not currently in a comment, empty the comment div
        this.$activeCommentText.empty();
        this.$contentEl.find('.comment, .comment-span').removeClass('current');
      }
    },
    setCommentDimensions(comment) {
      // sets display settings for the comment
      // doesn't render anything but these
      // settings are used by render functions
      let start = comment.start,
        length = getCommentLength(comment),
        dur = this.duration;
      if (length) {
        comment.divWidth = length / dur * 100 + '%';
        comment.divType = 'comment-span';
      } else {
        comment.divWidth = '8px';
        comment.divType = 'comment';
      }
      comment.divPos = start / dur * 100 + '%';
      return comment;
    },
    togglePlayButton() {
      if (this.player.paused) {
        this.$play.filter('.fa-play').removeClass('inactive');
        this.$play.filter('.fa-pause').addClass('inactive');
      } else {
        this.$play.filter('.fa-play').addClass('inactive');
        this.$play.filter('.fa-pause').removeClass('inactive');
      }
    },
    updateHighlight() {
      let width = `${this.percent * 100}%`;
      let height = this.$image.height() + 'px';
      this.$highlight.width(width).height(height);
    },
    updateSeekBar() {
      this.$seek.val(this.percent * 100.0);
    }
  };
  var _default = _exports.default = AudioComments;
});