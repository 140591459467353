define("bocce/templates/components/quiz-admin/unlock-for-next-attempt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "MIs/5Guz",
    "block": "{\"symbols\":[\"a\"],\"statements\":[[8,\"quiz-admin/operation\",[],[[\"@copy\",\"@disabledReason\",\"@operationOnAction\"],[\"Manually unlock quiz for next attempt\",[32,0,[\"disabledReason\"]],[32,0,[\"onAction\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"quiz-allowed-extra-attempts\"],[12],[2,\"\\n        \"],[10,\"span\"],[14,0,\"attempts-copy\"],[12],[2,\"Allowed Extra Attempts:\"],[13],[2,\" \\n        \"],[10,\"select\"],[14,1,\"allowed-extra-attempts\"],[15,\"onchange\",[30,[36,1],[[32,0],\"changeSelectedExtraAttempts\"],null]],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"allowedExtraAttempts\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[10,\"option\"],[15,\"selected\",[30,[36,0],[[32,0,[\"selectedExtraAttempts\"]],[32,1]],null]],[15,2,[32,1]],[12],[1,[32,1]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"same\",\"action\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "bocce/templates/components/quiz-admin/unlock-for-next-attempt.hbs"
    }
  });
});